import { Decimal as BigDecimal } from 'decimal.js/decimal';
import * as ast from '@/generated/ast_pb';
import * as execution_tracer from '@/revlang/execution_tracer';
import * as instruction_set from '@/revlang/instruction_set';
import * as values from '@/revlang/values';
import * as span_nodes from '@/revlang/span_nodes';
import * as type_system from '@/revlang/type_system';

export class RevlangLibrary {
  public readonly emptyValueArray: Array<values.Value>;

  public readonly voidValue: values.VoidValue;

  public readonly trueValue: values.BoolValue;

  public readonly falseValue: values.BoolValue;

  public readonly nullValue: values.NullValue;

  public constructor(
    readonly typeSystem: type_system.RevlangTypeSystem,
    readonly executionTracer: execution_tracer.ExecutionTracer,
  ) {
    this.emptyValueArray = [];
    this.voidValue = new values.VoidValue(typeSystem.voidType);
    this.trueValue = new values.BoolValue(typeSystem.boolType, true);
    this.falseValue = new values.BoolValue(typeSystem.boolType, false);
    this.nullValue = new values.NullValue(typeSystem.nullType);
  }

  public intValueFromString(value: string): values.IntValue {
    return new values.IntValue(this.typeSystem.intType, BigInt(value));
  }

  public numberValueFromString(value: string): values.NumberValue {
    return new values.NumberValue(this.typeSystem.numberType, new BigDecimal(value));
  }

  public charValueFromNumber(value: number): values.CharValue {
    return new values.CharValue(this.typeSystem.charType, value);
  }

  public stringValueFromString(value: string): values.StringValue {
    return new values.StringValue(this.typeSystem.stringType, value);
  }

  public bigIntToBigDecimal(bi: bigint): BigDecimal {
    return new BigDecimal(bi.toString());
  }

  public bigDecimalToBigInt(bd: BigDecimal): bigint {
    return BigInt(bd.toFixed());
  }

  public bitsValueFromString(
    length: number,
    hexValue: string,
  ): values.BitsValue {
    return new values.BitsValue(
      this.typeSystem.createBitsType(BigInt(length)),
      BigInt(`0x${hexValue}`),
    );
  }

  public funcValueFromBlock(
    funcName: string,
    funcType: type_system.FuncType,
    paramsAttributes: Map<string, Array<ast.AttributeNode>>,
    blockNode: ast.BlockNode,
    instructions: Array<instruction_set.Instruction>,
    funcRootSpanId: span_nodes.StepIdNode,
  ): values.FuncValue {
    return new values.FuncValue(
      funcName,
      funcType,
      paramsAttributes,
      blockNode,
      instructions,
      funcRootSpanId,
    );
  }

  public tupleValueFromArrayDataValue(
    arrayDataValue: values.IntrArrayDataValue,
    tupleType: type_system.TupleType,
  ): values.TupleValue {
    arrayDataValue.checkAssignableToTupleType(tupleType);
    return values.TupleValue.createFromArrayData(tupleType, arrayDataValue);
  }

  public arrayValueFromArrayDataValue(
    shapeIntrArrayDataValue: values.IntrArrayDataValue,
    elementsArrayDataValue: values.IntrArrayDataValue,
    arrayType: type_system.ArrayType,
  ): values.ArrayValue {
    const shape = shapeIntrArrayDataValue.checkExtractShape();
    elementsArrayDataValue.checkAssignableToArrayType(shape, arrayType);
    return values.ArrayValue.createFromArrayData(arrayType, shape, elementsArrayDataValue);
  }

  public structValueFromNameExprMap(
    nameValueDataValue: values.IntrNameValueDataValue,
    structType: type_system.StructType,
  ): values.StructValue {
    nameValueDataValue.checkAssignableToStructType(structType);
    return values.StructValue.createFromNameValueData(structType, nameValueDataValue);
  }

  public containerValueFromArrayDataValue(
    arrayDataValue: values.IntrArrayDataValue,
    containerType: type_system.ContainerType,
  ): values.Value {
    arrayDataValue.checkAssignableToContainerType(containerType);
    if (containerType.kind === type_system.TypeKind.LIST) {
      return values.ListValue.createFromArrayData(
        containerType, arrayDataValue,
      );
    }
    if (containerType.kind === type_system.TypeKind.SET) {
      if (containerType.elementType.isPrimitive()) {
        return values.PrimitiveSetValue.createFromArrayData(
          containerType, arrayDataValue,
        );
      }
      return values.SetValue.createFromArrayData(
        containerType, arrayDataValue,
      );
    }
    if (containerType.kind === type_system.TypeKind.QUEUE) {
      return values.QueueValue.createFromArrayData(
        containerType, arrayDataValue,
      );
    }
    if (containerType.kind === type_system.TypeKind.STACK) {
      return values.StackValue.createFromArrayData(
        containerType, arrayDataValue,
      );
    }
    if (containerType.kind === type_system.TypeKind.DEQUE) {
      return values.DequeValue.createFromArrayData(
        containerType, arrayDataValue,
      );
    }
    throw new Error('Incorrect container type');
  }

  public mapValueFromMapDataValue(
    mapDataValue: values.IntrMapDataValue,
    mapType: type_system.MapType,
  ): values.Value {
    mapDataValue.checkAssignableToMapType(mapType);
    if (mapType.keyType.isPrimitive()) {
      return values.PrimitiveMapValue.createFromMapData(mapType, mapDataValue);
    }
    return values.MapValue.createFromMapData(mapType, mapDataValue);
  }

  public execStdFuncCall(
    exprInstruction: instruction_set.ExprInstruction,
    stdFuncCallKind: ast.StdFuncCallKindMap[keyof ast.StdFuncCallKindMap],
    ...args: Array<values.Value>
  ): values.Value {
    switch (stdFuncCallKind) {
      case ast.StdFuncCallKind.COPY: {
        if (args.length !== 1 || !args[0].isContainer()) {
          throw new Error('Expected 1 container arg for copy');
        }
        return args[0].copy();
      }
      case ast.StdFuncCallKind.SIZE: {
        if (args.length !== 1 || !args[0].isContainer()) {
          throw new Error('Expected 1 container arg for size');
        }
        return new values.IntValue(
          this.typeSystem.intType, BigInt(args[0].size()),
        );
      }
      case ast.StdFuncCallKind.IS_EMPTY: {
        if (args.length !== 1 || !args[0].isContainer()) {
          throw new Error('Expected 1 container arg for is_empty');
        }
        return new values.BoolValue(this.typeSystem.intType, args[0].size() === 0);
      }
      case ast.StdFuncCallKind.APPEND: {
        if (args.length !== 2 || args[0].kind !== values.ValueKind.LIST) {
          throw new Error('Expected 2 args with 1st being list');
        }
        const listValue = args[0] as values.ListValue;
        listValue.append(args[1]);
        this.executionTracer.trace(() => new execution_tracer.AppendStdFuncTrace(
          exprInstruction,
          listValue,
          args[1],
        ));
        return this.voidValue;
      }
      case ast.StdFuncCallKind.REMOVE_AT: {
        if (args.length !== 2
            || args[0].kind !== values.ValueKind.LIST
            || args[1].kind !== values.ValueKind.INT) {
          throw new Error('Expected 2 args with 1st being list and 2nd int');
        }
        const listValue = args[0] as values.ListValue;
        const indexValue = args[1] as values.IntValue;
        const removedValue = listValue.removeAt(indexValue);
        this.executionTracer.trace(() => new execution_tracer.RemoveAtStdFuncTrace(
          exprInstruction,
          listValue,
          indexValue,
          removedValue,
        ));
        return this.voidValue;
      }
      case ast.StdFuncCallKind.INSERT_AT: {
        if (args.length !== 3
            || args[0].kind !== values.ValueKind.LIST
            || args[1].kind !== values.ValueKind.INT) {
          throw new Error('Expected 3 args with 1st being list and 1nd int');
        }
        const listValue = args[0] as values.ListValue;
        const indexValue = args[1] as values.IntValue;
        listValue.insertAt(indexValue, args[2]);
        this.executionTracer.trace(() => new execution_tracer.InsertAtStdFuncTrace(
          exprInstruction,
          listValue,
          indexValue,
          args[2],
        ));
        return this.voidValue;
      }
      case ast.StdFuncCallKind.EXTEND_AT: {
        if (args.length !== 3
            || args[0].kind !== values.ValueKind.LIST
            || args[1].kind !== values.ValueKind.INT
            || args[2].kind !== values.ValueKind.LIST) {
          throw new Error('Expected 3 args list, int, list');
        }
        const listValue = args[0] as values.ListValue;
        const indexValue = args[1] as values.IntValue;
        const otherListValue = args[2] as values.ListValue;
        listValue.extendAt(indexValue, otherListValue);
        this.executionTracer.trace(() => new execution_tracer.ExtendAtStdFuncTrace(
          exprInstruction,
          listValue,
          indexValue,
          otherListValue,
        ));
        return this.voidValue;
      }
      case ast.StdFuncCallKind.CLEAR: {
        if (args.length !== 1 || !args[0].isContainer()) {
          throw new Error('Expected 1 container arg for clear');
        }
        const payload = args[0].clear();
        this.executionTracer.trace(() => new execution_tracer.ClearStdFuncTrace(
          exprInstruction,
          args[0],
          payload,
        ));
        return this.voidValue;
      }
      case ast.StdFuncCallKind.REVERSE: {
        if (args.length !== 1
            || args[0].kind !== values.ValueKind.LIST) {
          throw new Error('Expected 1 list arg');
        }
        const listValue = args[0] as values.ListValue;
        listValue.reverse();
        this.executionTracer.trace(() => new execution_tracer.ReverseStdFuncTrace(
          exprInstruction,
          listValue,
        ));
        return this.voidValue;
      }
      case ast.StdFuncCallKind.CONTAINS: {
        if (args.length !== 2) {
          throw new Error('Expected 2 args');
        }
        if (args[0].kind === values.ValueKind.PRIMITIVE_SET) {
          const primitiveSetValue = args[0] as values.PrimitiveSetValue;
          return new values.BoolValue(
            this.typeSystem.boolType,
            primitiveSetValue.contains(args[1]),
          );
        }
        if (args[0].kind === values.ValueKind.SET) {
          const setValue = args[0] as values.SetValue;
          return new values.BoolValue(
            this.typeSystem.boolType,
            setValue.contains(args[1]),
          );
        }
        if (args[0].kind === values.ValueKind.PRIMITIVE_MAP) {
          const primitiveMapValue = args[0] as values.PrimitiveMapValue;
          return new values.BoolValue(
            this.typeSystem.boolType,
            primitiveMapValue.contains(args[1]),
          );
        }
        if (args[0].kind === values.ValueKind.MAP) {
          const mapValue = args[0] as values.MapValue;
          return new values.BoolValue(
            this.typeSystem.boolType,
            mapValue.contains(args[1]),
          );
        }
        throw new Error('Bad 1st arg for contains');
      }
      case ast.StdFuncCallKind.ADD: {
        if (args.length !== 2) {
          throw new Error('Expected 2 args');
        }
        if (args[0].kind === values.ValueKind.PRIMITIVE_SET) {
          const primitiveSetValue = args[0] as values.PrimitiveSetValue;
          const payload = primitiveSetValue.addKey(args[1]);
          this.executionTracer.trace(() => new execution_tracer.AddKStdFuncTrace(
            exprInstruction,
            primitiveSetValue,
            payload,
          ));
          return this.voidValue;
        }
        if (args[0].kind === values.ValueKind.SET) {
          const setValue = args[0] as values.SetValue;
          const payload = setValue.addKey(args[1]);
          this.executionTracer.trace(() => new execution_tracer.AddKStdFuncTrace(
            exprInstruction,
            setValue,
            payload,
          ));
          return this.voidValue;
        }
        throw new Error('Bad 1st arg for add');
      }
      case ast.StdFuncCallKind.REMOVE: {
        if (args.length !== 2) {
          throw new Error('Expected 2 args');
        }
        if (args[0].kind === values.ValueKind.PRIMITIVE_SET) {
          const primitiveSetValue = args[0] as values.PrimitiveSetValue;
          const payload = primitiveSetValue.remove(args[1]);
          this.executionTracer.trace(() => new execution_tracer.RemoveStdFuncTrace(
            exprInstruction,
            primitiveSetValue,
            payload,
          ));
          return this.voidValue;
        }
        if (args[0].kind === values.ValueKind.SET) {
          const setValue = args[0] as values.SetValue;
          const payload = setValue.remove(args[1]);
          this.executionTracer.trace(() => new execution_tracer.RemoveStdFuncTrace(
            exprInstruction,
            setValue,
            payload,
          ));
          return this.voidValue;
        }
        if (args[0].kind === values.ValueKind.PRIMITIVE_MAP) {
          const primitiveMapValue = args[0] as values.PrimitiveMapValue;
          const payload = primitiveMapValue.remove(args[1]);
          this.executionTracer.trace(() => new execution_tracer.RemoveStdFuncTrace(
            exprInstruction,
            primitiveMapValue,
            payload,
          ));
          return this.voidValue;
        }
        if (args[0].kind === values.ValueKind.MAP) {
          const mapValue = args[0] as values.MapValue;
          const payload = mapValue.remove(args[1]);
          this.executionTracer.trace(() => new execution_tracer.RemoveStdFuncTrace(
            exprInstruction,
            mapValue,
            payload,
          ));
          return this.voidValue;
        }
        throw new Error('Bad 1st arg for add');
      }
      case ast.StdFuncCallKind.IS_DISJOINT: {
        if (args.length !== 2) {
          throw new Error('Expected 2 args');
        }
        if (args[0].kind === values.ValueKind.PRIMITIVE_SET
            && args[1].kind === values.ValueKind.PRIMITIVE_SET) {
          const primitiveSetValue1 = args[0] as values.PrimitiveSetValue;
          const primitiveSetValue2 = args[1] as values.PrimitiveSetValue;
          return new values.BoolValue(
            this.typeSystem.boolType,
            primitiveSetValue1.isDisjoint(primitiveSetValue2),
          );
        }
        if (args[0].kind === values.ValueKind.SET
            && args[1].kind === values.ValueKind.SET) {
          const setValue1 = args[0] as values.SetValue;
          const setValue2 = args[1] as values.SetValue;
          return new values.BoolValue(
            this.typeSystem.boolType,
            setValue1.isDisjoint(setValue2),
          );
        }
        throw new Error('Bad args for disjoint');
      }
      case ast.StdFuncCallKind.ENQUEUE: {
        if (args.length !== 2
            || args[0].kind !== values.ValueKind.QUEUE) {
          throw new Error('Expected 2 args with first being queue');
        }
        const queueValue = args[0] as values.QueueValue;
        queueValue.enqueue(args[1]);
        this.executionTracer.trace(() => new execution_tracer.EnqueueStdFuncTrace(
          exprInstruction,
          queueValue,
          args[1],
        ));
        return this.voidValue;
      }
      case ast.StdFuncCallKind.DEQUEUE: {
        if (args.length !== 1
            || args[0].kind !== values.ValueKind.QUEUE) {
          throw new Error('Expected 1 queue arg');
        }
        const queueValue = args[0] as values.QueueValue;
        const dequeuedValue = queueValue.dequeue();
        this.executionTracer.trace(() => new execution_tracer.DequeueStdFuncTrace(
          exprInstruction,
          queueValue,
          dequeuedValue,
        ));
        return dequeuedValue;
      }
      case ast.StdFuncCallKind.PUSH: {
        if (args.length !== 2
            || args[0].kind !== values.ValueKind.STACK) {
          throw new Error('Expected 2 args with first being stack');
        }
        const stackValue = args[0] as values.StackValue;
        stackValue.push(args[1]);
        this.executionTracer.trace(() => new execution_tracer.PushStdFuncTrace(
          exprInstruction,
          stackValue,
          args[1],
        ));
        return this.voidValue;
      }
      case ast.StdFuncCallKind.POP: {
        if (args.length !== 1
            || args[0].kind !== values.ValueKind.STACK) {
          throw new Error('Expected 1 stack arg');
        }
        const stackValue = args[0] as values.StackValue;
        const poppedValue = stackValue.pop();
        this.executionTracer.trace(() => new execution_tracer.PopStdFuncTrace(
          exprInstruction,
          stackValue,
          poppedValue,
        ));
        return poppedValue;
      }
      case ast.StdFuncCallKind.PUSH_FRONT: {
        if (args.length !== 2
            || args[0].kind !== values.ValueKind.DEQUE) {
          throw new Error('Expected 2 args with first being deque');
        }
        const dequeValue = args[0] as values.DequeValue;
        dequeValue.pushFront(args[1]);
        this.executionTracer.trace(() => new execution_tracer.PushFrontStdFuncTrace(
          exprInstruction,
          dequeValue,
          args[1],
        ));
        return this.voidValue;
      }
      case ast.StdFuncCallKind.POP_FRONT: {
        if (args.length !== 1
            || args[0].kind !== values.ValueKind.DEQUE) {
          throw new Error('Expected 1 deque arg');
        }
        const dequeValue = args[0] as values.DequeValue;
        const poppedFrontValue = dequeValue.popFront();
        this.executionTracer.trace(() => new execution_tracer.PopFrontStdFuncTrace(
          exprInstruction,
          dequeValue,
          poppedFrontValue,
        ));
        return poppedFrontValue;
      }
      case ast.StdFuncCallKind.PUSH_BACK: {
        if (args.length !== 2
            || args[0].kind !== values.ValueKind.DEQUE) {
          throw new Error('Expected 2 args with first being deque');
        }
        const dequeValue = args[0] as values.DequeValue;
        dequeValue.pushBack(args[1]);
        this.executionTracer.trace(() => new execution_tracer.PushBackStdFuncTrace(
          exprInstruction,
          dequeValue,
          args[1],
        ));
        return this.voidValue;
      }
      case ast.StdFuncCallKind.POP_BACK: {
        if (args.length !== 1
            || args[0].kind !== values.ValueKind.DEQUE) {
          throw new Error('Expected 1 deque arg');
        }
        const dequeValue = args[0] as values.DequeValue;
        const poppedBackValue = dequeValue.popBack();
        this.executionTracer.trace(() => new execution_tracer.PopBackStdFuncTrace(
          exprInstruction,
          dequeValue,
          poppedBackValue,
        ));
        return poppedBackValue;
      }
      case ast.StdFuncCallKind.DIMS: {
        if (args.length !== 1
            || args[0].kind !== values.ValueKind.ARRAY) {
          throw new Error('Expected 1 array arg');
        }
        const arrayValue = args[0] as values.ArrayValue;
        return new values.IntValue(this.typeSystem.intType, arrayValue.dims());
      }
      case ast.StdFuncCallKind.LEN: {
        if (args.length === 1) {
          if (args[0].kind === values.ValueKind.ARRAY) {
            const arrayValue = args[0] as values.ArrayValue;
            return new values.IntValue(
              this.typeSystem.intType,
              BigInt(arrayValue.dimLenNum(0)),
            );
          }
          if (args[0].kind === values.ValueKind.STRING) {
            const stringValue = args[0] as values.StringValue;
            return new values.IntValue(
              this.typeSystem.intType,
              BigInt(stringValue.value.length),
            );
          }
          if (args[0].kind === values.ValueKind.LIST) {
            const listValue = args[0] as values.ListValue;
            return new values.IntValue(
              this.typeSystem.intType,
              BigInt(listValue.elements.length),
            );
          }
          throw new Error('Expected 1st arg to be array/list/string');
        }
        if (args.length === 2) {
          if (args[0].kind !== values.ValueKind.ARRAY
              || args[1].kind !== values.ValueKind.INT) {
            throw new Error('Expected 1st array and 2nd int arg');
          }
          const arrayValue = args[0] as values.ArrayValue;
          const intValue = args[1] as values.IntValue;
          return new values.IntValue(this.typeSystem.intType, arrayValue.dimLen(intValue));
        }
        throw new Error('Expected 1 or 2 args for len');
      }
      case ast.StdFuncCallKind.STR_TO_LIST: {
        if (args.length !== 1
            || args[0].kind !== values.ValueKind.STRING) {
          throw new Error('Expected 1 string arg');
        }
        const stringValue = args[0] as values.StringValue;
        const listType = this.typeSystem.createContainerType(
          type_system.TypeKind.LIST,
          this.typeSystem.charType,
        );
        const charValueArray: Array<values.CharValue> = [];
        for (const c of stringValue.value) {
          charValueArray.push(
            new values.CharValue(
              this.typeSystem.charType,
              c.charCodeAt(0),
            ),
          );
        }
        return new values.ListValue(listType, charValueArray);
      }
      case ast.StdFuncCallKind.LIST_TO_STR: {
        if (args.length !== 1
            || args[0].kind !== values.ValueKind.LIST) {
          throw new Error('Expected 1 list arg');
        }
        const listValue = args[0] as values.ListValue;
        if (listValue.listType.elementType !== this.typeSystem.charType) {
          throw new Error('Expected 1 char list arg');
        }
        let str = '';
        for (const value of listValue.elements) {
          const charValue = value as values.CharValue;
          if (charValue.kind !== values.ValueKind.CHAR) {
            throw new Error(`Unexpected value kind: ${charValue.kind}`);
          }
          str = `${str}${String.fromCharCode(charValue.value)}`;
        }
        return new values.StringValue(this.typeSystem.stringType, str);
      }
      case ast.StdFuncCallKind.SLICE: {
        if (args.length !== 2 && args.length !== 3) {
          throw new Error('Expected 2 or 3 args');
        }
        if (args[1].kind !== values.ValueKind.INT) {
          throw new Error('Expected 2nd arg to be int');
        }
        const startValue = args[1] as values.IntValue;
        const start = Number(startValue.value);
        let end: number;
        if (args.length === 3) {
          if (args[2].kind !== values.ValueKind.INT) {
            throw new Error('Expected 3rd arg to be int');
          }
          const endValue = args[2] as values.IntValue;
          end = Number(endValue.value);
        }
        if (args[0].kind === values.ValueKind.LIST) {
          const listValue = args[0] as values.ListValue;
          if (start < 0 || start > listValue.elements.length) {
            throw new Error('Incorrect start value');
          }
          if (end !== undefined) {
            if (end < start || end > listValue.elements.length) {
              throw new Error('Incorrect end value');
            }
            return new values.ListValue(
              listValue.listType,
              listValue.elements.slice(start, end),
            );
          }
          return new values.ListValue(
            listValue.listType,
            listValue.elements.slice(start),
          );
        }
        if (args[0].kind === values.ValueKind.STRING) {
          const strValue = args[0] as values.StringValue;
          if (start < 0 || start > strValue.value.length) {
            throw new Error('Incorrect start value');
          }
          if (end !== undefined) {
            if (end < start || end > strValue.value.length || end < start) {
              throw new Error('Incorrect end value');
            }
            return new values.StringValue(
              strValue.type,
              strValue.value.slice(start, end),
            );
          }
          return new values.StringValue(
            strValue.type,
            strValue.value.slice(start),
          );
        }
        throw new Error(`Expected str or list, found: ${args[0].kind}`);
      }
      default:
        throw new Error(`Internal error: Unknown standard function call ${stdFuncCallKind}`);
    }
  }

  public indexedAccess(
    containerValue: values.Value,
    indicesValue: values.IntrArrayDataValue,
  ): values.Value {
    if (containerValue.kind === values.ValueKind.TUPLE) {
      const tupleValue = containerValue as values.TupleValue;
      return tupleValue.getAtIndex(indicesValue);
    }
    if (containerValue.kind === values.ValueKind.ARRAY) {
      const arrayValue = containerValue as values.ArrayValue;
      return arrayValue.getAtIndex(indicesValue);
    }
    if (containerValue.kind === values.ValueKind.LIST) {
      const listValue = containerValue as values.ListValue;
      return listValue.getAtIndex(indicesValue);
    }
    if (containerValue.kind === values.ValueKind.PRIMITIVE_MAP) {
      const mapValue = containerValue as values.PrimitiveMapValue;
      return mapValue.getAtIndex(indicesValue);
    }
    if (containerValue.kind === values.ValueKind.MAP) {
      const mapValue = containerValue as values.MapValue;
      return mapValue.getAtIndex(indicesValue);
    }
    throw new Error(`Incorrect containerValue: ${containerValue.kind}`);
  }

  public execSetIndexed(
    assignInstruction: instruction_set.AssignInstruction,
    containerValue: values.Value,
    indicesValue: values.IntrArrayDataValue,
    rValue: values.Value,
  ): void {
    if (containerValue.kind === values.ValueKind.TUPLE) {
      const tupleValue = containerValue as values.TupleValue;
      const oldValue = tupleValue.setAtIndex(indicesValue, rValue);
      this.executionTracer.trace(() => new execution_tracer.AssignIndexedTrace(
        assignInstruction,
        tupleValue,
        indicesValue,
        oldValue,
        rValue,
      ));
    } else if (containerValue.kind === values.ValueKind.ARRAY) {
      const arrayValue = containerValue as values.ArrayValue;
      const oldValue = arrayValue.setAtIndex(indicesValue, rValue);
      this.executionTracer.trace(() => new execution_tracer.AssignIndexedTrace(
        assignInstruction,
        arrayValue,
        indicesValue,
        oldValue,
        rValue,
      ));
    } else if (containerValue.kind === values.ValueKind.LIST) {
      const listValue = containerValue as values.ListValue;
      const oldValue = listValue.setAtIndex(indicesValue, rValue);
      this.executionTracer.trace(() => new execution_tracer.AssignIndexedTrace(
        assignInstruction,
        listValue,
        indicesValue,
        oldValue,
        rValue,
      ));
    } else if (containerValue.kind === values.ValueKind.PRIMITIVE_MAP) {
      const mapValue = containerValue as values.PrimitiveMapValue;
      const payload = mapValue.setAtIndex(indicesValue, rValue);
      this.executionTracer.trace(() => new execution_tracer.AssignMapIndexedTrace(
        assignInstruction,
        mapValue,
        payload,
      ));
    } else if (containerValue.kind === values.ValueKind.MAP) {
      const mapValue = containerValue as values.MapValue;
      const payload = mapValue.setAtIndex(indicesValue, rValue);
      this.executionTracer.trace(() => new execution_tracer.AssignMapIndexedTrace(
        assignInstruction,
        mapValue,
        payload,
      ));
    } else {
      throw new Error(`Incorrect containerValue: ${containerValue.kind}`);
    }
  }

  public fieldAccess(
    value: values.Value,
    fieldName: string,
  ): values.Value {
    if (value.kind === values.ValueKind.STRUCT) {
      const structValue = value as values.StructValue;
      return structValue.getFieldValue(fieldName);
    }
    if (value.kind === values.ValueKind.TYPE) {
      const typeValue = value as values.TypeValue;
      if (typeValue.typeValue.kind === type_system.TypeKind.ENUM) {
        const enumType = typeValue.typeValue as type_system.EnumType;
        const enumElement = enumType.findEnumElement(fieldName);
        if (enumElement !== undefined) {
          return new values.EnumValue(enumType, enumElement);
        }
      }
    }
    throw new Error('Incorrect struct/enum value');
  }

  public execSetField(
    assignInstruction: instruction_set.AssignInstruction,
    value: values.Value,
    fieldName: string,
    rValue: values.Value,
  ): void {
    if (value.kind !== values.ValueKind.STRUCT) {
      throw new Error('Incorrect struct value');
    }
    const structValue = value as values.StructValue;
    const oldValue = structValue.setFieldValue(fieldName, rValue);
    this.executionTracer.trace(() => new execution_tracer.AssignFieldTrace(
      assignInstruction,
      structValue,
      fieldName,
      oldValue,
      rValue,
    ));
  }

  public negation(value: values.Value): values.Value {
    if (value.kind === values.ValueKind.INT) {
      const intValue = value as values.IntValue;
      return new values.IntValue(intValue.type, -intValue.value);
    }
    if (value.kind === values.ValueKind.NUMBER) {
      const numValue = value as values.NumberValue;
      return new values.NumberValue(
        numValue.type, numValue.value.negated(),
      );
    }
    throw new Error('Bad type for negation');
  }

  public bitNot(value: values.Value): values.Value {
    if (value.kind === values.ValueKind.BITS) {
      const bitValue = value as values.BitsValue;
      const not = BigInt.asUintN(
        Number(bitValue.bitsType.length), ~bitValue.value,
      );
      return new values.BitsValue(bitValue.bitsType, not);
    }
    throw new Error('Bad type for bit not');
  }

  public boolNot(value: values.Value): values.Value {
    if (value.kind === values.ValueKind.BOOL) {
      const boolValue = value as values.BoolValue;
      return new values.BoolValue(boolValue.type, !boolValue.value);
    }
    throw new Error('Bad type for boolean not');
  }

  public arithmeticOperation(
    value1: values.Value,
    value2: values.Value,
    opName: string,
    bigIntArithOp: (v1: bigint, v2: bigint) => bigint,
    bigDecimalArithOp: (v1: BigDecimal, v2: BigDecimal) => BigDecimal,
  ): values.Value {
    if (value1.kind === values.ValueKind.INT && value2.kind === values.ValueKind.INT) {
      const intVal1 = value1 as values.IntValue;
      const intVal2 = value2 as values.IntValue;
      return new values.IntValue(
        this.typeSystem.intType, bigIntArithOp(intVal1.value, intVal2.value),
      );
    }
    let bigDec1: BigDecimal;
    if (value1.kind === values.ValueKind.INT) {
      const intVal = value1 as values.IntValue;
      bigDec1 = this.bigIntToBigDecimal(intVal.value);
    } else if (value1.kind === values.ValueKind.NUMBER) {
      const numVal = value1 as values.NumberValue;
      bigDec1 = numVal.value;
    } else {
      throw new Error(`Incorrect first type for ${opName} operation`);
    }
    let bigDec2: BigDecimal;
    if (value2.kind === values.ValueKind.INT) {
      const intVal = value2 as values.IntValue;
      bigDec2 = this.bigIntToBigDecimal(intVal.value);
    } else if (value2.kind === values.ValueKind.NUMBER) {
      const numVal = value2 as values.NumberValue;
      bigDec2 = numVal.value;
    } else {
      throw new Error(`Incorrect second type for ${opName} operation`);
    }
    return new values.NumberValue(
      this.typeSystem.numberType, bigDecimalArithOp(bigDec1, bigDec2),
    );
  }

  public power(value1: values.Value, value2: values.Value): values.Value {
    return this.arithmeticOperation(
      value1,
      value2,
      'power',
      (v1: bigint, v2: bigint) => v1 ** v2,
      (v1: BigDecimal, v2: BigDecimal) => v1.pow(v2),
    );
  }

  public modulo(value1: values.Value, value2: values.Value): values.Value {
    return this.arithmeticOperation(
      value1,
      value2,
      'modulo',
      (v1: bigint, v2: bigint) => v1 % v2,
      (v1: BigDecimal, v2: BigDecimal) => v1.mod(v2),
    );
  }

  public multiply(value1: values.Value, value2: values.Value): values.Value {
    return this.arithmeticOperation(
      value1,
      value2,
      'multiply',
      (v1: bigint, v2: bigint) => v1 * v2,
      (v1: BigDecimal, v2: BigDecimal) => v1.mul(v2),
    );
  }

  public divide(value1: values.Value, value2: values.Value): values.Value {
    return this.arithmeticOperation(
      value1,
      value2,
      'modulo',
      (v1: bigint, v2: bigint) => v1 / v2,
      (v1: BigDecimal, v2: BigDecimal) => v1.div(v2),
    );
  }

  public bitwiseOperation(
    value1: values.Value,
    value2: values.Value,
    opName: string,
    bigIntBitwiseOp: (v1: bigint, v2: bigint) => bigint,
  ): values.Value {
    if (value1.kind === values.ValueKind.BITS && value2.kind === values.ValueKind.BITS) {
      const bitsVal1 = value1 as values.BitsValue;
      const bitsVal2 = value2 as values.BitsValue;
      if (bitsVal1.type !== bitsVal2.type) {
        throw new Error(`Incompatible bit types for ${opName} operation`);
      }
      return new values.BitsValue(
        bitsVal1.bitsType, bigIntBitwiseOp(bitsVal1.value, bitsVal2.value),
      );
    }
    throw new Error(`Incompatible bit types for ${opName} operation`);
  }

  public bitAnd(value1: values.Value, value2: values.Value): values.Value {
    return this.bitwiseOperation(
      value1,
      value2,
      'and',
      (v1: bigint, v2: bigint) => v1 & v2,
    );
  }

  public bitOr(value1: values.Value, value2: values.Value): values.Value {
    return this.bitwiseOperation(
      value1,
      value2,
      'or',
      (v1: bigint, v2: bigint) => v1 | v2,
    );
  }

  public bitXor(value1: values.Value, value2: values.Value): values.Value {
    return this.bitwiseOperation(
      value1,
      value2,
      'xor',
      (v1: bigint, v2: bigint) => v1 ^ v2,
    );
  }

  public shiftOperation(
    value1: values.Value,
    value2: values.Value,
    opName: string,
    bigIntShiftOp: (v1: bigint, v2: bigint) => bigint,
  ): values.Value {
    if (value1.kind === values.ValueKind.BITS && value2.kind === values.ValueKind.INT) {
      const bitsVal1 = value1 as values.BitsValue;
      const intVal2 = value2 as values.IntValue;
      const result = bigIntShiftOp(bitsVal1.value, intVal2.value);
      return new values.BitsValue(
        bitsVal1.bitsType,
        BigInt.asUintN(Number(bitsVal1.bitsType.length), result),
      );
    }
    throw new Error(`Incompatible bit types for ${opName} operation`);
  }

  public leftShift(value1: values.Value, value2: values.Value): values.Value {
    return this.shiftOperation(
      value1,
      value2,
      'leftshift',
      (v1: bigint, v2: bigint) => v1 << v2,
    );
  }

  public rightShift(value1: values.Value, value2: values.Value): values.Value {
    return this.shiftOperation(
      value1,
      value2,
      'righshift',
      (v1: bigint, v2: bigint) => v1 >> v2,
    );
  }

  public add(value1: values.Value, value2: values.Value): values.Value {
    return this.arithmeticOperation(
      value1,
      value2,
      'add',
      (v1: bigint, v2: bigint) => v1 + v2,
      (v1: BigDecimal, v2: BigDecimal) => v1.add(v2),
    );
  }

  public subtract(value1: values.Value, value2: values.Value): values.Value {
    return this.arithmeticOperation(
      value1,
      value2,
      'subtract',
      (v1: bigint, v2: bigint) => v1 - v2,
      (v1: BigDecimal, v2: BigDecimal) => v1.minus(v2),
    );
  }

  public arithmeticComparision(
    value1: values.Value,
    value2: values.Value,
    opName: string,
    bigIntCompOp: (v1: bigint, v2: bigint) => boolean,
    bigDecimalCompOp: (v1: BigDecimal, v2: BigDecimal) => boolean,
  ): values.Value {
    if (value1.kind === values.ValueKind.INT && value2.kind === values.ValueKind.INT) {
      const intVal1 = value1 as values.IntValue;
      const intVal2 = value2 as values.IntValue;
      return new values.BoolValue(
        this.typeSystem.boolType, bigIntCompOp(intVal1.value, intVal2.value),
      );
    }
    let bigDec1: BigDecimal;
    if (value1.kind === values.ValueKind.INT) {
      const intVal = value1 as values.IntValue;
      bigDec1 = this.bigIntToBigDecimal(intVal.value);
    } else if (value1.kind === values.ValueKind.NUMBER) {
      const numVal = value1 as values.NumberValue;
      bigDec1 = numVal.value;
    } else {
      return new values.BoolValue(this.typeSystem.boolType, false);
    }
    let bigDec2: BigDecimal;
    if (value2.kind === values.ValueKind.INT) {
      const intVal = value2 as values.IntValue;
      bigDec2 = this.bigIntToBigDecimal(intVal.value);
    } else if (value2.kind === values.ValueKind.NUMBER) {
      const numVal = value2 as values.NumberValue;
      bigDec2 = numVal.value;
    } else {
      return new values.BoolValue(this.typeSystem.boolType, false);
    }
    return new values.BoolValue(
      this.typeSystem.boolType, bigDecimalCompOp(bigDec1, bigDec2),
    );
  }

  public equals(value1: values.Value, value2: values.Value): values.Value {
    if (value1 === value2) {
      return new values.BoolValue(this.typeSystem.boolType, true);
    }
    if (value1.isNumerical() && value2.isNumerical()) {
      return this.arithmeticComparision(
        value1,
        value2,
        'equals',
        (v1: bigint, v2: bigint) => v1 === v2,
        (v1: BigDecimal, v2: BigDecimal) => v1.cmp(v2) === 0,
      );
    }
    if (value1.kind === values.ValueKind.INT && value2.kind === values.ValueKind.BITS) {
      const intValue = value1 as values.IntValue;
      const bitsValue = value2 as values.BitsValue;
      return new values.BoolValue(
        this.typeSystem.boolType,
        intValue.value === bitsValue.value,
      );
    }
    if (value1.kind === values.ValueKind.BITS && value2.kind === values.ValueKind.INT) {
      const bitsValue = value1 as values.BitsValue;
      const intValue = value2 as values.IntValue;
      return new values.BoolValue(
        this.typeSystem.boolType,
        intValue.value === bitsValue.value,
      );
    }
    if (value1.kind !== value2.kind) {
      return new values.BoolValue(this.typeSystem.boolType, false);
    }
    if (value1.isPrimitive() && value2.isPrimitive()) {
      return new values.BoolValue(
        this.typeSystem.boolType, value1.codedValue() === value2.codedValue(),
      );
    }
    return new values.BoolValue(this.typeSystem.boolType, false);
  }

  public notEquals(value1: values.Value, value2: values.Value): values.Value {
    const equalsValue = this.equals(value1, value2);
    if (equalsValue.kind !== values.ValueKind.BOOL) {
      throw new Error('Internal error:  equals returning non boolean');
    }
    const boolResultValue = equalsValue as values.BoolValue;
    return new values.BoolValue(this.typeSystem.boolType, !boolResultValue.value);
  }

  public lessThan(value1: values.Value, value2: values.Value): values.Value {
    const compOp = 'lessthan';
    if (value1.isNumerical() && value2.isNumerical()) {
      return this.arithmeticComparision(
        value1,
        value2,
        compOp,
        (v1: bigint, v2: bigint) => v1 < v2,
        (v1: BigDecimal, v2: BigDecimal) => v1.cmp(v2) === -1,
      );
    }
    throw new Error(`Incorrect types for ${compOp} comparision`);
  }

  public lessThanEquals(value1: values.Value, value2: values.Value): values.Value {
    const compOp = 'lessequals';
    if (value1.isNumerical() && value2.isNumerical()) {
      return this.arithmeticComparision(
        value1,
        value2,
        compOp,
        (v1: bigint, v2: bigint) => v1 <= v2,
        (v1: BigDecimal, v2: BigDecimal) => v1.cmp(v2) <= 0,
      );
    }
    throw new Error(`Incorrect types for ${compOp} comparision`);
  }

  public greaterThan(value1: values.Value, value2: values.Value): values.Value {
    const compOp = 'greater';
    if (value1.isNumerical() && value2.isNumerical()) {
      return this.arithmeticComparision(
        value1,
        value2,
        compOp,
        (v1: bigint, v2: bigint) => v1 > v2,
        (v1: BigDecimal, v2: BigDecimal) => v1.cmp(v2) === 1,
      );
    }
    throw new Error(`Incorrect types for ${compOp} comparision`);
  }

  public greaterThanEquals(value1: values.Value, value2: values.Value): values.Value {
    const compOp = 'greaterequals';
    if (value1.isNumerical() && value2.isNumerical()) {
      return this.arithmeticComparision(
        value1,
        value2,
        compOp,
        (v1: bigint, v2: bigint) => v1 >= v2,
        (v1: BigDecimal, v2: BigDecimal) => v1.cmp(v2) >= 0,
      );
    }
    throw new Error(`Incorrect types for ${compOp} comparision`);
  }

  public booleanComparision(
    value1: values.Value,
    value2: values.Value,
    opName: string,
    booleanCompOp: (v1: boolean, v2: boolean) => boolean,
  ): values.Value {
    if (value1.kind === values.ValueKind.BOOL && value2.kind === values.ValueKind.BOOL) {
      const boolVal1 = value1 as values.BoolValue;
      const boolVal2 = value2 as values.BoolValue;
      return new values.BoolValue(
        this.typeSystem.boolType, booleanCompOp(boolVal1.value, boolVal2.value),
      );
    }
    throw new Error(`Incorrect types for ${opName} comparision`);
  }

  public boolAnd(value1: values.Value, value2: values.Value): values.Value {
    return this.booleanComparision(
      value1,
      value2,
      'and',
      (v1: boolean, v2: boolean) => v1 && v2,
    );
  }

  public boolOr(value1: values.Value, value2: values.Value): values.Value {
    return this.booleanComparision(
      value1,
      value2,
      'and',
      (v1: boolean, v2: boolean) => v1 || v2,
    );
  }
}
