
import { VNode } from 'vue';
import { Component } from 'vue-property-decorator';
import {
  VarVizDataType,
  VarVizData,
  PointerVizData,
} from '@/store/rev_module';
import VueBaseComponent from '@/components/VueBaseComponent';
import SimpleVarVisualizer from '@/components/revlang/visualizers/SimpleVarVisualizer.vue';
import SimpleContainerVarVisualizer from '@/components/revlang/visualizers/SimpleContainerVarVisualizer.vue';

@Component({
  components: {
    SimpleVarVisualizer,
    SimpleContainerVarVisualizer,
  },
})
export default class StateVisualizer extends VueBaseComponent {
  addDataElements(
    createElement,
    elements: Array<VNode>,
    varVizState: Record<string, VarVizData>,
  ): void {
    for (const varVizData of Object.values(varVizState)) {
      let elementName: string;
      const props = {
        'var-viz-data': varVizData,
      };
      switch (varVizData.type) {
        case VarVizDataType.Simple:
          elementName = 'SimpleVarVisualizer';
          break;
        case VarVizDataType.SimpleContainer:
          elementName = 'SimpleContainerVarVisualizer';
          break;
        default:
          continue;
      }
      elements.push(createElement(
        elementName,
        {
          class: {
            data: true,
          },
          props,
        },
      ));
    }
  }

  addPointerElements(
    createElement,
    elements: Array<VNode>,
    pointerVizState: Array<PointerVizData>,
  ): void {
    for (const pointerVizData of pointerVizState) {
      elements.push(createElement(
        'line',
        {
          class: {
            pointer: true,
          },
          attrs: {
            x1: pointerVizData.x1,
            y1: pointerVizData.y1,
            x2: pointerVizData.x2,
            y2: pointerVizData.y2,
            'marker-end': 'url(#arrowhead)',
          },
        },
      ));
    }
  }

  render(createElement): VNode {
    const elements: Array<VNode> = [];
    this.addPointerElements(createElement, elements, this.revModule.pointerVizState);
    this.addDataElements(createElement, elements, this.revModule.varVizState);
    return createElement('g', elements);
  }
}
