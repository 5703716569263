





















import {
  Component,
  Prop,
} from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import VueBaseComponent from '@/components/VueBaseComponent';
import { AlgorithmSummary } from '@/store/algorithm_summary';

@Component
export default class AlgoCard extends VueBaseComponent {
  @Prop({
    type: AlgorithmSummary,
    required: true,
  }) readonly algorithmSummary: AlgorithmSummary;

  @Prop({
    type: Boolean,
    required: true,
  }) readonly visualizeLink: boolean;
}
