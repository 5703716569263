import * as ast from '@/generated/ast_pb';

export type StepableASTNode = ast.ExprNode | ast.StatementNode | ast.BlockNode;

export enum SpanNodeKind {
  Newline,
  Whitespace,
  Indent,
  Text,
  TreeNode,
}

export enum SpanNodeTextKind {
  Keyword,
  DefName,
  RefName,
  FieldName,
  ParamName,
  Literal,
  Operator,
  Seperator,
  Comment,
}

export class SpanNode {
  private constructor(
    public readonly kind: SpanNodeKind,
    public readonly indentSize?: number,
    public readonly textKind?: SpanNodeTextKind,
    public readonly text?: string,
    public readonly spanId?: string,
    public readonly subNodes?: Array<SpanNode>,
  ) {}

  static newLine(): SpanNode {
    return new SpanNode(SpanNodeKind.Newline);
  }

  static whitespace(): SpanNode {
    return new SpanNode(SpanNodeKind.Whitespace);
  }

  static indent(indentSize: number): SpanNode {
    return new SpanNode(SpanNodeKind.Indent, indentSize);
  }

  static text(textKind: SpanNodeTextKind, text: string): SpanNode {
    return new SpanNode(SpanNodeKind.Text, undefined, textKind, text);
  }

  static tree(subNodes: Array<SpanNode>): SpanNode {
    return new SpanNode(
      SpanNodeKind.TreeNode,
      undefined,
      undefined,
      undefined,
      undefined,
      subNodes,
    );
  }

  static spanIdTree(spanId: string, subNodes: Array<SpanNode>): SpanNode {
    return new SpanNode(SpanNodeKind.TreeNode, undefined, undefined, undefined, spanId, subNodes);
  }
}

export class StepIdNode {
  private static EMPTY_SET = new Set<StepIdNode>();

  readonly ancestorSet: Set<StepIdNode>;

  readonly ancestorAndSelfSet: Set<StepIdNode>;

  private _firstChild: StepIdNode;

  private _lastChild: StepIdNode;

  private _sibling: StepIdNode;

  public constructor(
    readonly stepId: string,
    readonly astNode: StepableASTNode,
    readonly parent: StepIdNode,
  ) {
    if (this.parent === undefined) {
      this.ancestorSet = StepIdNode.EMPTY_SET;
    } else {
      this.ancestorSet = this.parent.ancestorAndSelfSet;
    }
    this.ancestorAndSelfSet = new Set(this.ancestorSet);
    this.ancestorAndSelfSet.add(this);
  }

  public get firstChild(): StepIdNode {
    return this._firstChild;
  }

  public get lastChild(): StepIdNode {
    return this._lastChild;
  }

  public get sibling(): StepIdNode {
    return this._sibling;
  }

  public addChild(stepIdNode: StepIdNode): void {
    if (this._lastChild === undefined) {
      this._firstChild = stepIdNode;
      this._lastChild = stepIdNode;
    } else {
      this._lastChild._sibling = stepIdNode;
      this._lastChild = stepIdNode;
    }
  }

  private isAncesteralOrSelfSiblingOf(otherStepIfNode: StepIdNode): boolean {
    const otherAncestorSet = otherStepIfNode.ancestorSet;
    for (const stepIdNode of this.ancestorSet) {
      if (!otherAncestorSet.has(stepIdNode)) {
        return false;
      }
    }
    return true;
  }

  public findAncesteralOrSelfSiblingOf(otherStepIfNode: StepIdNode): StepIdNode {
    let iterNode: StepIdNode = this;
    while (iterNode !== undefined) {
      if (iterNode.isAncesteralOrSelfSiblingOf(otherStepIfNode)) {
        return iterNode;
      }
      iterNode = iterNode.parent;
    }
    throw new Error('Internal Error: Should have found ancesternal sibling');
  }

  public findChildContaining(otherStepIfNode: StepIdNode): StepIdNode {
    let iterNode: StepIdNode = otherStepIfNode;
    while (iterNode !== undefined) {
      if (iterNode.parent === this) {
        return iterNode;
      }
      iterNode = iterNode.parent;
    }
    throw new Error('Internal Error: Should have found child');
  }
}

export class SourceMap {
  readonly stepIdMap: Map<string, StepIdNode>;

  readonly funcRootStepIdMap: Map<string, StepIdNode>;

  readonly programRootStepIdNode: StepIdNode;

  private isActive: boolean;

  public constructor() {
    this.stepIdMap = new Map<string, StepIdNode>();
    this.funcRootStepIdMap = new Map<string, StepIdNode>();
    this.programRootStepIdNode = new StepIdNode('<<program>>', undefined, undefined);
    this.stepIdMap.set(this.programRootStepIdNode.stepId, this.programRootStepIdNode);
  }

  public setIsActive(val: boolean): void {
    this.isActive = val;
  }

  public createChildStepIdNode(
    astNode: StepableASTNode,
    parentNode: StepIdNode,
  ): StepIdNode {
    const addedNode = new StepIdNode(
      astNode.getStepId(),
      astNode,
      parentNode === this.programRootStepIdNode ? undefined : parentNode,
    );
    if (parentNode !== undefined) {
      parentNode.addChild(addedNode);
    }
    if (this.isActive) {
      this.stepIdMap.set(astNode.getStepId(), addedNode);
    }
    return addedNode;
  }

  public addFuncRootStepId(funcName: string, stepId: string): void {
    if (this.isActive) {
      this.funcRootStepIdMap.set(funcName, this.stepIdMap.get(stepId));
    }
  }
}

export function simpleSpanNodeToString(spanNode: SpanNode): string {
  if (spanNode.kind === SpanNodeKind.Newline) {
    return '\n';
  }
  if (spanNode.kind === SpanNodeKind.Whitespace) {
    return ' ';
  }
  if (spanNode.kind === SpanNodeKind.Indent) {
    return '  '.repeat(spanNode.indentSize);
  }
  if (spanNode.kind === SpanNodeKind.Text) {
    return spanNode.text;
  }
  return spanNode.subNodes.map(sn => simpleSpanNodeToString(sn)).join('');
}

export function annotatedSpanNodeToString(spanNode: SpanNode): string {
  if (spanNode.kind === SpanNodeKind.Newline) {
    return '\n';
  }
  if (spanNode.kind === SpanNodeKind.Whitespace) {
    return ' ';
  }
  if (spanNode.kind === SpanNodeKind.Indent) {
    return '  '.repeat(spanNode.indentSize);
  }
  if (spanNode.kind === SpanNodeKind.Text) {
    return spanNode.text;
  }
  const code = spanNode.subNodes.map(sn => annotatedSpanNodeToString(sn)).join('');
  if (spanNode.spanId !== undefined) {
    return `•${spanNode.spanId}☾${code}☽`;
  }
  return code;
}

export function stepIdSetToString(stepIdSet: Set<StepIdNode>): string {
  const stepIds: Array<string> = [];
  stepIdSet.forEach((val, _, __) => {
    stepIds.push(val.stepId);
  });
  return stepIds.join(',');
}

export function stepIdTreeToString(
  stepIdNode: StepIdNode,
  parentStepIdNode: StepIdNode,
  indent: string,
): string {
  if (stepIdNode.parent !== parentStepIdNode
      && parentStepIdNode.stepId !== '<<program>>') {
    // program as parent is special where it essentially
    // acts like list with no parent set as its immediate
    // child.
    throw new Error('Parent node improper');
  }
  if (stepIdNode.firstChild === undefined) {
    return `${indent}${stepIdNode.stepId}(${stepIdSetToString(stepIdNode.ancestorSet)})`;
  }
  let nextChildNode = stepIdNode.firstChild;
  const childNodeStrings: Array<string> = [];
  const childIndent = `${indent}  `;
  while (nextChildNode !== undefined) {
    childNodeStrings.push(stepIdTreeToString(nextChildNode, stepIdNode, childIndent));
    nextChildNode = nextChildNode.sibling;
  }
  return `${indent}${stepIdNode.stepId}(${stepIdSetToString(stepIdNode.ancestorSet)})`
    + `[\n${childNodeStrings.join('\n')}\n${indent}]`;
}
