// source: ast.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

goog.exportSymbol('proto.ArrayCtorNode', null, global);
goog.exportSymbol('proto.ArrayCtorNode.TypeCase', null, global);
goog.exportSymbol('proto.ArrayElementsListNode', null, global);
goog.exportSymbol('proto.ArrayElementsNode', null, global);
goog.exportSymbol('proto.ArrayElementsNode.ElementsCase', null, global);
goog.exportSymbol('proto.ArrayTypeNode', null, global);
goog.exportSymbol('proto.AssignNode', null, global);
goog.exportSymbol('proto.AttributeListNode', null, global);
goog.exportSymbol('proto.AttributeNode', null, global);
goog.exportSymbol('proto.AttributeNode.AttrCase', null, global);
goog.exportSymbol('proto.AttributeType', null, global);
goog.exportSymbol('proto.BinaryExprNode', null, global);
goog.exportSymbol('proto.BitsLiteralNode', null, global);
goog.exportSymbol('proto.BlockNode', null, global);
goog.exportSymbol('proto.CharLiteralNode', null, global);
goog.exportSymbol('proto.ContainerCtorNode', null, global);
goog.exportSymbol('proto.ContainerCtorNode.TypeCase', null, global);
goog.exportSymbol('proto.DataDefNode', null, global);
goog.exportSymbol('proto.EnumDefNode', null, global);
goog.exportSymbol('proto.ExprBlockNode', null, global);
goog.exportSymbol('proto.ExprListNode', null, global);
goog.exportSymbol('proto.ExprNode', null, global);
goog.exportSymbol('proto.ExprNode.ExprCase', null, global);
goog.exportSymbol('proto.ExprNodeKind', null, global);
goog.exportSymbol('proto.ExprPrecedence', null, global);
goog.exportSymbol('proto.FieldAccessNode', null, global);
goog.exportSymbol('proto.FuncCallNode', null, global);
goog.exportSymbol('proto.FuncDefNode', null, global);
goog.exportSymbol('proto.FuncTypeNode', null, global);
goog.exportSymbol('proto.IfElseNode', null, global);
goog.exportSymbol('proto.IndexAttrData', null, global);
goog.exportSymbol('proto.IndexedAccessNode', null, global);
goog.exportSymbol('proto.IntLiteralNode', null, global);
goog.exportSymbol('proto.LFieldAccessNode', null, global);
goog.exportSymbol('proto.LIndexedAccessNode', null, global);
goog.exportSymbol('proto.LValueExprNode', null, global);
goog.exportSymbol('proto.LValueExprNode.LvalueCase', null, global);
goog.exportSymbol('proto.MapCtorNode', null, global);
goog.exportSymbol('proto.MapCtorNode.TypeCase', null, global);
goog.exportSymbol('proto.MapElementNode', null, global);
goog.exportSymbol('proto.MapElementsListNode', null, global);
goog.exportSymbol('proto.MapTypeNode', null, global);
goog.exportSymbol('proto.NameExprListNode', null, global);
goog.exportSymbol('proto.NameExprNode', null, global);
goog.exportSymbol('proto.NameNode', null, global);
goog.exportSymbol('proto.NameTypeListNode', null, global);
goog.exportSymbol('proto.NameTypeNode', null, global);
goog.exportSymbol('proto.NumLiteralNode', null, global);
goog.exportSymbol('proto.ProgramNode', null, global);
goog.exportSymbol('proto.StatementNode', null, global);
goog.exportSymbol('proto.StatementNode.StmtCase', null, global);
goog.exportSymbol('proto.StdFuncCallKind', null, global);
goog.exportSymbol('proto.StdFuncCallNode', null, global);
goog.exportSymbol('proto.StdFuncCallNode.ArgsCase', null, global);
goog.exportSymbol('proto.StmtNodeKind', null, global);
goog.exportSymbol('proto.StringLiteralNode', null, global);
goog.exportSymbol('proto.StructCtorNode', null, global);
goog.exportSymbol('proto.StructDefNode', null, global);
goog.exportSymbol('proto.TernaryExprNode', null, global);
goog.exportSymbol('proto.TupleCtorNode', null, global);
goog.exportSymbol('proto.TupleCtorNode.TypeCase', null, global);
goog.exportSymbol('proto.TupleTypeNode', null, global);
goog.exportSymbol('proto.TypeDefNode', null, global);
goog.exportSymbol('proto.TypeDefNode.DefineCase', null, global);
goog.exportSymbol('proto.TypeDefNodeKind', null, global);
goog.exportSymbol('proto.TypeNode', null, global);
goog.exportSymbol('proto.TypeNode.TypeCase', null, global);
goog.exportSymbol('proto.TypeNodeKind', null, global);
goog.exportSymbol('proto.VarDeclNode', null, global);
goog.exportSymbol('proto.VarDefNode', null, global);
goog.exportSymbol('proto.VisualizerAttrData', null, global);
goog.exportSymbol('proto.VisualizerType', null, global);
goog.exportSymbol('proto.WhileNode', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.NameNode = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.NameNode, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.NameNode.displayName = 'proto.NameNode';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ExprListNode = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.ExprListNode.repeatedFields_, null);
};
goog.inherits(proto.ExprListNode, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ExprListNode.displayName = 'proto.ExprListNode';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.VisualizerAttrData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.VisualizerAttrData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.VisualizerAttrData.displayName = 'proto.VisualizerAttrData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.IndexAttrData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.IndexAttrData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.IndexAttrData.displayName = 'proto.IndexAttrData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.AttributeNode = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.AttributeNode.oneofGroups_);
};
goog.inherits(proto.AttributeNode, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.AttributeNode.displayName = 'proto.AttributeNode';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.AttributeListNode = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.AttributeListNode.repeatedFields_, null);
};
goog.inherits(proto.AttributeListNode, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.AttributeListNode.displayName = 'proto.AttributeListNode';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.NameTypeNode = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.NameTypeNode, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.NameTypeNode.displayName = 'proto.NameTypeNode';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.NameTypeListNode = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.NameTypeListNode.repeatedFields_, null);
};
goog.inherits(proto.NameTypeListNode, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.NameTypeListNode.displayName = 'proto.NameTypeListNode';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.NameExprNode = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.NameExprNode, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.NameExprNode.displayName = 'proto.NameExprNode';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.NameExprListNode = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.NameExprListNode.repeatedFields_, null);
};
goog.inherits(proto.NameExprListNode, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.NameExprListNode.displayName = 'proto.NameExprListNode';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.FuncTypeNode = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.FuncTypeNode, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.FuncTypeNode.displayName = 'proto.FuncTypeNode';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.TupleTypeNode = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.TupleTypeNode.repeatedFields_, null);
};
goog.inherits(proto.TupleTypeNode, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.TupleTypeNode.displayName = 'proto.TupleTypeNode';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ArrayTypeNode = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ArrayTypeNode, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ArrayTypeNode.displayName = 'proto.ArrayTypeNode';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.MapTypeNode = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.MapTypeNode, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.MapTypeNode.displayName = 'proto.MapTypeNode';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.TypeNode = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.TypeNode.oneofGroups_);
};
goog.inherits(proto.TypeNode, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.TypeNode.displayName = 'proto.TypeNode';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.BinaryExprNode = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.BinaryExprNode, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.BinaryExprNode.displayName = 'proto.BinaryExprNode';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.TernaryExprNode = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.TernaryExprNode, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.TernaryExprNode.displayName = 'proto.TernaryExprNode';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.StdFuncCallNode = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.StdFuncCallNode.oneofGroups_);
};
goog.inherits(proto.StdFuncCallNode, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.StdFuncCallNode.displayName = 'proto.StdFuncCallNode';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.IntLiteralNode = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.IntLiteralNode, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.IntLiteralNode.displayName = 'proto.IntLiteralNode';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.NumLiteralNode = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.NumLiteralNode, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.NumLiteralNode.displayName = 'proto.NumLiteralNode';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.CharLiteralNode = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.CharLiteralNode, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.CharLiteralNode.displayName = 'proto.CharLiteralNode';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.StringLiteralNode = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.StringLiteralNode, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.StringLiteralNode.displayName = 'proto.StringLiteralNode';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.BitsLiteralNode = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.BitsLiteralNode, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.BitsLiteralNode.displayName = 'proto.BitsLiteralNode';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.TupleCtorNode = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.TupleCtorNode.oneofGroups_);
};
goog.inherits(proto.TupleCtorNode, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.TupleCtorNode.displayName = 'proto.TupleCtorNode';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ArrayElementsListNode = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.ArrayElementsListNode.repeatedFields_, null);
};
goog.inherits(proto.ArrayElementsListNode, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ArrayElementsListNode.displayName = 'proto.ArrayElementsListNode';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ArrayElementsNode = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.ArrayElementsNode.oneofGroups_);
};
goog.inherits(proto.ArrayElementsNode, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ArrayElementsNode.displayName = 'proto.ArrayElementsNode';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ArrayCtorNode = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.ArrayCtorNode.oneofGroups_);
};
goog.inherits(proto.ArrayCtorNode, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ArrayCtorNode.displayName = 'proto.ArrayCtorNode';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.StructCtorNode = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.StructCtorNode, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.StructCtorNode.displayName = 'proto.StructCtorNode';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ContainerCtorNode = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.ContainerCtorNode.oneofGroups_);
};
goog.inherits(proto.ContainerCtorNode, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ContainerCtorNode.displayName = 'proto.ContainerCtorNode';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.MapElementNode = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.MapElementNode, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.MapElementNode.displayName = 'proto.MapElementNode';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.MapElementsListNode = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.MapElementsListNode.repeatedFields_, null);
};
goog.inherits(proto.MapElementsListNode, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.MapElementsListNode.displayName = 'proto.MapElementsListNode';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.MapCtorNode = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.MapCtorNode.oneofGroups_);
};
goog.inherits(proto.MapCtorNode, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.MapCtorNode.displayName = 'proto.MapCtorNode';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.IndexedAccessNode = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.IndexedAccessNode, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.IndexedAccessNode.displayName = 'proto.IndexedAccessNode';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.FieldAccessNode = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.FieldAccessNode, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.FieldAccessNode.displayName = 'proto.FieldAccessNode';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.FuncCallNode = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.FuncCallNode, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.FuncCallNode.displayName = 'proto.FuncCallNode';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ExprNode = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.ExprNode.oneofGroups_);
};
goog.inherits(proto.ExprNode, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ExprNode.displayName = 'proto.ExprNode';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.LIndexedAccessNode = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.LIndexedAccessNode, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.LIndexedAccessNode.displayName = 'proto.LIndexedAccessNode';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.LFieldAccessNode = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.LFieldAccessNode, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.LFieldAccessNode.displayName = 'proto.LFieldAccessNode';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.LValueExprNode = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.LValueExprNode.oneofGroups_);
};
goog.inherits(proto.LValueExprNode, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.LValueExprNode.displayName = 'proto.LValueExprNode';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.VarDeclNode = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.VarDeclNode, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.VarDeclNode.displayName = 'proto.VarDeclNode';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.VarDefNode = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.VarDefNode, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.VarDefNode.displayName = 'proto.VarDefNode';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.AssignNode = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.AssignNode, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.AssignNode.displayName = 'proto.AssignNode';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ExprBlockNode = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ExprBlockNode, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ExprBlockNode.displayName = 'proto.ExprBlockNode';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.IfElseNode = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.IfElseNode.repeatedFields_, null);
};
goog.inherits(proto.IfElseNode, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.IfElseNode.displayName = 'proto.IfElseNode';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.WhileNode = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.WhileNode, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.WhileNode.displayName = 'proto.WhileNode';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.StatementNode = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.StatementNode.oneofGroups_);
};
goog.inherits(proto.StatementNode, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.StatementNode.displayName = 'proto.StatementNode';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.BlockNode = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.BlockNode.repeatedFields_, null);
};
goog.inherits(proto.BlockNode, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.BlockNode.displayName = 'proto.BlockNode';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.StructDefNode = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.StructDefNode, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.StructDefNode.displayName = 'proto.StructDefNode';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.EnumDefNode = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.EnumDefNode.repeatedFields_, null);
};
goog.inherits(proto.EnumDefNode, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.EnumDefNode.displayName = 'proto.EnumDefNode';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.TypeDefNode = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.TypeDefNode.oneofGroups_);
};
goog.inherits(proto.TypeDefNode, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.TypeDefNode.displayName = 'proto.TypeDefNode';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.DataDefNode = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.DataDefNode, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.DataDefNode.displayName = 'proto.DataDefNode';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.FuncDefNode = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.FuncDefNode, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.FuncDefNode.displayName = 'proto.FuncDefNode';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ProgramNode = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.ProgramNode.repeatedFields_, null);
};
goog.inherits(proto.ProgramNode, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ProgramNode.displayName = 'proto.ProgramNode';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.NameNode.prototype.toObject = function(opt_includeInstance) {
  return proto.NameNode.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.NameNode} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.NameNode.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.NameNode}
 */
proto.NameNode.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.NameNode;
  return proto.NameNode.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.NameNode} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.NameNode}
 */
proto.NameNode.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.NameNode.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.NameNode.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.NameNode} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.NameNode.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.NameNode.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.NameNode} returns this
 */
proto.NameNode.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.ExprListNode.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ExprListNode.prototype.toObject = function(opt_includeInstance) {
  return proto.ExprListNode.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ExprListNode} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ExprListNode.toObject = function(includeInstance, msg) {
  var f, obj = {
    exprsList: jspb.Message.toObjectList(msg.getExprsList(),
    proto.ExprNode.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ExprListNode}
 */
proto.ExprListNode.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ExprListNode;
  return proto.ExprListNode.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ExprListNode} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ExprListNode}
 */
proto.ExprListNode.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.ExprNode;
      reader.readMessage(value,proto.ExprNode.deserializeBinaryFromReader);
      msg.addExprs(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ExprListNode.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ExprListNode.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ExprListNode} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ExprListNode.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getExprsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.ExprNode.serializeBinaryToWriter
    );
  }
};


/**
 * repeated ExprNode exprs = 1;
 * @return {!Array<!proto.ExprNode>}
 */
proto.ExprListNode.prototype.getExprsList = function() {
  return /** @type{!Array<!proto.ExprNode>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.ExprNode, 1));
};


/**
 * @param {!Array<!proto.ExprNode>} value
 * @return {!proto.ExprListNode} returns this
*/
proto.ExprListNode.prototype.setExprsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.ExprNode=} opt_value
 * @param {number=} opt_index
 * @return {!proto.ExprNode}
 */
proto.ExprListNode.prototype.addExprs = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.ExprNode, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.ExprListNode} returns this
 */
proto.ExprListNode.prototype.clearExprsList = function() {
  return this.setExprsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.VisualizerAttrData.prototype.toObject = function(opt_includeInstance) {
  return proto.VisualizerAttrData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.VisualizerAttrData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.VisualizerAttrData.toObject = function(includeInstance, msg) {
  var f, obj = {
    visualizerType: jspb.Message.getFieldWithDefault(msg, 1, 0),
    x: jspb.Message.getFieldWithDefault(msg, 2, 0),
    y: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.VisualizerAttrData}
 */
proto.VisualizerAttrData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.VisualizerAttrData;
  return proto.VisualizerAttrData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.VisualizerAttrData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.VisualizerAttrData}
 */
proto.VisualizerAttrData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.VisualizerType} */ (reader.readEnum());
      msg.setVisualizerType(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setX(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setY(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.VisualizerAttrData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.VisualizerAttrData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.VisualizerAttrData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.VisualizerAttrData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVisualizerType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getX();
  if (f !== 0) {
    writer.writeUint64(
      2,
      f
    );
  }
  f = message.getY();
  if (f !== 0) {
    writer.writeUint64(
      3,
      f
    );
  }
};


/**
 * optional VisualizerType visualizer_type = 1;
 * @return {!proto.VisualizerType}
 */
proto.VisualizerAttrData.prototype.getVisualizerType = function() {
  return /** @type {!proto.VisualizerType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.VisualizerType} value
 * @return {!proto.VisualizerAttrData} returns this
 */
proto.VisualizerAttrData.prototype.setVisualizerType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional uint64 x = 2;
 * @return {number}
 */
proto.VisualizerAttrData.prototype.getX = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.VisualizerAttrData} returns this
 */
proto.VisualizerAttrData.prototype.setX = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional uint64 y = 3;
 * @return {number}
 */
proto.VisualizerAttrData.prototype.getY = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.VisualizerAttrData} returns this
 */
proto.VisualizerAttrData.prototype.setY = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.IndexAttrData.prototype.toObject = function(opt_includeInstance) {
  return proto.IndexAttrData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.IndexAttrData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.IndexAttrData.toObject = function(includeInstance, msg) {
  var f, obj = {
    varName: (f = msg.getVarName()) && proto.NameNode.toObject(includeInstance, f),
    dimension: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.IndexAttrData}
 */
proto.IndexAttrData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.IndexAttrData;
  return proto.IndexAttrData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.IndexAttrData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.IndexAttrData}
 */
proto.IndexAttrData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.NameNode;
      reader.readMessage(value,proto.NameNode.deserializeBinaryFromReader);
      msg.setVarName(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setDimension(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.IndexAttrData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.IndexAttrData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.IndexAttrData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.IndexAttrData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVarName();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.NameNode.serializeBinaryToWriter
    );
  }
  f = message.getDimension();
  if (f !== 0) {
    writer.writeUint64(
      2,
      f
    );
  }
};


/**
 * optional NameNode var_name = 1;
 * @return {?proto.NameNode}
 */
proto.IndexAttrData.prototype.getVarName = function() {
  return /** @type{?proto.NameNode} */ (
    jspb.Message.getWrapperField(this, proto.NameNode, 1));
};


/**
 * @param {?proto.NameNode|undefined} value
 * @return {!proto.IndexAttrData} returns this
*/
proto.IndexAttrData.prototype.setVarName = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.IndexAttrData} returns this
 */
proto.IndexAttrData.prototype.clearVarName = function() {
  return this.setVarName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.IndexAttrData.prototype.hasVarName = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional uint64 dimension = 2;
 * @return {number}
 */
proto.IndexAttrData.prototype.getDimension = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.IndexAttrData} returns this
 */
proto.IndexAttrData.prototype.setDimension = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.AttributeNode.oneofGroups_ = [[2,3]];

/**
 * @enum {number}
 */
proto.AttributeNode.AttrCase = {
  ATTR_NOT_SET: 0,
  VISUALIZER_DATA: 2,
  INDEX_DATA: 3
};

/**
 * @return {proto.AttributeNode.AttrCase}
 */
proto.AttributeNode.prototype.getAttrCase = function() {
  return /** @type {proto.AttributeNode.AttrCase} */(jspb.Message.computeOneofCase(this, proto.AttributeNode.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.AttributeNode.prototype.toObject = function(opt_includeInstance) {
  return proto.AttributeNode.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.AttributeNode} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.AttributeNode.toObject = function(includeInstance, msg) {
  var f, obj = {
    type: jspb.Message.getFieldWithDefault(msg, 1, 0),
    visualizerData: (f = msg.getVisualizerData()) && proto.VisualizerAttrData.toObject(includeInstance, f),
    indexData: (f = msg.getIndexData()) && proto.IndexAttrData.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.AttributeNode}
 */
proto.AttributeNode.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.AttributeNode;
  return proto.AttributeNode.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.AttributeNode} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.AttributeNode}
 */
proto.AttributeNode.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.AttributeType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 2:
      var value = new proto.VisualizerAttrData;
      reader.readMessage(value,proto.VisualizerAttrData.deserializeBinaryFromReader);
      msg.setVisualizerData(value);
      break;
    case 3:
      var value = new proto.IndexAttrData;
      reader.readMessage(value,proto.IndexAttrData.deserializeBinaryFromReader);
      msg.setIndexData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.AttributeNode.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.AttributeNode.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.AttributeNode} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.AttributeNode.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getVisualizerData();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.VisualizerAttrData.serializeBinaryToWriter
    );
  }
  f = message.getIndexData();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.IndexAttrData.serializeBinaryToWriter
    );
  }
};


/**
 * optional AttributeType type = 1;
 * @return {!proto.AttributeType}
 */
proto.AttributeNode.prototype.getType = function() {
  return /** @type {!proto.AttributeType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.AttributeType} value
 * @return {!proto.AttributeNode} returns this
 */
proto.AttributeNode.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional VisualizerAttrData visualizer_data = 2;
 * @return {?proto.VisualizerAttrData}
 */
proto.AttributeNode.prototype.getVisualizerData = function() {
  return /** @type{?proto.VisualizerAttrData} */ (
    jspb.Message.getWrapperField(this, proto.VisualizerAttrData, 2));
};


/**
 * @param {?proto.VisualizerAttrData|undefined} value
 * @return {!proto.AttributeNode} returns this
*/
proto.AttributeNode.prototype.setVisualizerData = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.AttributeNode.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.AttributeNode} returns this
 */
proto.AttributeNode.prototype.clearVisualizerData = function() {
  return this.setVisualizerData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.AttributeNode.prototype.hasVisualizerData = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional IndexAttrData index_data = 3;
 * @return {?proto.IndexAttrData}
 */
proto.AttributeNode.prototype.getIndexData = function() {
  return /** @type{?proto.IndexAttrData} */ (
    jspb.Message.getWrapperField(this, proto.IndexAttrData, 3));
};


/**
 * @param {?proto.IndexAttrData|undefined} value
 * @return {!proto.AttributeNode} returns this
*/
proto.AttributeNode.prototype.setIndexData = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.AttributeNode.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.AttributeNode} returns this
 */
proto.AttributeNode.prototype.clearIndexData = function() {
  return this.setIndexData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.AttributeNode.prototype.hasIndexData = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.AttributeListNode.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.AttributeListNode.prototype.toObject = function(opt_includeInstance) {
  return proto.AttributeListNode.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.AttributeListNode} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.AttributeListNode.toObject = function(includeInstance, msg) {
  var f, obj = {
    attributesList: jspb.Message.toObjectList(msg.getAttributesList(),
    proto.AttributeNode.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.AttributeListNode}
 */
proto.AttributeListNode.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.AttributeListNode;
  return proto.AttributeListNode.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.AttributeListNode} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.AttributeListNode}
 */
proto.AttributeListNode.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.AttributeNode;
      reader.readMessage(value,proto.AttributeNode.deserializeBinaryFromReader);
      msg.addAttributes(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.AttributeListNode.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.AttributeListNode.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.AttributeListNode} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.AttributeListNode.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAttributesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.AttributeNode.serializeBinaryToWriter
    );
  }
};


/**
 * repeated AttributeNode attributes = 1;
 * @return {!Array<!proto.AttributeNode>}
 */
proto.AttributeListNode.prototype.getAttributesList = function() {
  return /** @type{!Array<!proto.AttributeNode>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.AttributeNode, 1));
};


/**
 * @param {!Array<!proto.AttributeNode>} value
 * @return {!proto.AttributeListNode} returns this
*/
proto.AttributeListNode.prototype.setAttributesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.AttributeNode=} opt_value
 * @param {number=} opt_index
 * @return {!proto.AttributeNode}
 */
proto.AttributeListNode.prototype.addAttributes = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.AttributeNode, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.AttributeListNode} returns this
 */
proto.AttributeListNode.prototype.clearAttributesList = function() {
  return this.setAttributesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.NameTypeNode.prototype.toObject = function(opt_includeInstance) {
  return proto.NameTypeNode.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.NameTypeNode} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.NameTypeNode.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: (f = msg.getName()) && proto.NameNode.toObject(includeInstance, f),
    attributesList: (f = msg.getAttributesList()) && proto.AttributeListNode.toObject(includeInstance, f),
    type: (f = msg.getType()) && proto.TypeNode.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.NameTypeNode}
 */
proto.NameTypeNode.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.NameTypeNode;
  return proto.NameTypeNode.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.NameTypeNode} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.NameTypeNode}
 */
proto.NameTypeNode.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.NameNode;
      reader.readMessage(value,proto.NameNode.deserializeBinaryFromReader);
      msg.setName(value);
      break;
    case 2:
      var value = new proto.AttributeListNode;
      reader.readMessage(value,proto.AttributeListNode.deserializeBinaryFromReader);
      msg.setAttributesList(value);
      break;
    case 3:
      var value = new proto.TypeNode;
      reader.readMessage(value,proto.TypeNode.deserializeBinaryFromReader);
      msg.setType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.NameTypeNode.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.NameTypeNode.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.NameTypeNode} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.NameTypeNode.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.NameNode.serializeBinaryToWriter
    );
  }
  f = message.getAttributesList();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.AttributeListNode.serializeBinaryToWriter
    );
  }
  f = message.getType();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.TypeNode.serializeBinaryToWriter
    );
  }
};


/**
 * optional NameNode name = 1;
 * @return {?proto.NameNode}
 */
proto.NameTypeNode.prototype.getName = function() {
  return /** @type{?proto.NameNode} */ (
    jspb.Message.getWrapperField(this, proto.NameNode, 1));
};


/**
 * @param {?proto.NameNode|undefined} value
 * @return {!proto.NameTypeNode} returns this
*/
proto.NameTypeNode.prototype.setName = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.NameTypeNode} returns this
 */
proto.NameTypeNode.prototype.clearName = function() {
  return this.setName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.NameTypeNode.prototype.hasName = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional AttributeListNode attributes_list = 2;
 * @return {?proto.AttributeListNode}
 */
proto.NameTypeNode.prototype.getAttributesList = function() {
  return /** @type{?proto.AttributeListNode} */ (
    jspb.Message.getWrapperField(this, proto.AttributeListNode, 2));
};


/**
 * @param {?proto.AttributeListNode|undefined} value
 * @return {!proto.NameTypeNode} returns this
*/
proto.NameTypeNode.prototype.setAttributesList = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.NameTypeNode} returns this
 */
proto.NameTypeNode.prototype.clearAttributesList = function() {
  return this.setAttributesList(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.NameTypeNode.prototype.hasAttributesList = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional TypeNode type = 3;
 * @return {?proto.TypeNode}
 */
proto.NameTypeNode.prototype.getType = function() {
  return /** @type{?proto.TypeNode} */ (
    jspb.Message.getWrapperField(this, proto.TypeNode, 3));
};


/**
 * @param {?proto.TypeNode|undefined} value
 * @return {!proto.NameTypeNode} returns this
*/
proto.NameTypeNode.prototype.setType = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.NameTypeNode} returns this
 */
proto.NameTypeNode.prototype.clearType = function() {
  return this.setType(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.NameTypeNode.prototype.hasType = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.NameTypeListNode.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.NameTypeListNode.prototype.toObject = function(opt_includeInstance) {
  return proto.NameTypeListNode.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.NameTypeListNode} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.NameTypeListNode.toObject = function(includeInstance, msg) {
  var f, obj = {
    nameTypesList: jspb.Message.toObjectList(msg.getNameTypesList(),
    proto.NameTypeNode.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.NameTypeListNode}
 */
proto.NameTypeListNode.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.NameTypeListNode;
  return proto.NameTypeListNode.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.NameTypeListNode} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.NameTypeListNode}
 */
proto.NameTypeListNode.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.NameTypeNode;
      reader.readMessage(value,proto.NameTypeNode.deserializeBinaryFromReader);
      msg.addNameTypes(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.NameTypeListNode.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.NameTypeListNode.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.NameTypeListNode} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.NameTypeListNode.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNameTypesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.NameTypeNode.serializeBinaryToWriter
    );
  }
};


/**
 * repeated NameTypeNode name_types = 1;
 * @return {!Array<!proto.NameTypeNode>}
 */
proto.NameTypeListNode.prototype.getNameTypesList = function() {
  return /** @type{!Array<!proto.NameTypeNode>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.NameTypeNode, 1));
};


/**
 * @param {!Array<!proto.NameTypeNode>} value
 * @return {!proto.NameTypeListNode} returns this
*/
proto.NameTypeListNode.prototype.setNameTypesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.NameTypeNode=} opt_value
 * @param {number=} opt_index
 * @return {!proto.NameTypeNode}
 */
proto.NameTypeListNode.prototype.addNameTypes = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.NameTypeNode, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.NameTypeListNode} returns this
 */
proto.NameTypeListNode.prototype.clearNameTypesList = function() {
  return this.setNameTypesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.NameExprNode.prototype.toObject = function(opt_includeInstance) {
  return proto.NameExprNode.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.NameExprNode} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.NameExprNode.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: (f = msg.getName()) && proto.NameNode.toObject(includeInstance, f),
    expr: (f = msg.getExpr()) && proto.ExprNode.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.NameExprNode}
 */
proto.NameExprNode.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.NameExprNode;
  return proto.NameExprNode.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.NameExprNode} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.NameExprNode}
 */
proto.NameExprNode.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.NameNode;
      reader.readMessage(value,proto.NameNode.deserializeBinaryFromReader);
      msg.setName(value);
      break;
    case 2:
      var value = new proto.ExprNode;
      reader.readMessage(value,proto.ExprNode.deserializeBinaryFromReader);
      msg.setExpr(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.NameExprNode.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.NameExprNode.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.NameExprNode} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.NameExprNode.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.NameNode.serializeBinaryToWriter
    );
  }
  f = message.getExpr();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.ExprNode.serializeBinaryToWriter
    );
  }
};


/**
 * optional NameNode name = 1;
 * @return {?proto.NameNode}
 */
proto.NameExprNode.prototype.getName = function() {
  return /** @type{?proto.NameNode} */ (
    jspb.Message.getWrapperField(this, proto.NameNode, 1));
};


/**
 * @param {?proto.NameNode|undefined} value
 * @return {!proto.NameExprNode} returns this
*/
proto.NameExprNode.prototype.setName = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.NameExprNode} returns this
 */
proto.NameExprNode.prototype.clearName = function() {
  return this.setName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.NameExprNode.prototype.hasName = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ExprNode expr = 2;
 * @return {?proto.ExprNode}
 */
proto.NameExprNode.prototype.getExpr = function() {
  return /** @type{?proto.ExprNode} */ (
    jspb.Message.getWrapperField(this, proto.ExprNode, 2));
};


/**
 * @param {?proto.ExprNode|undefined} value
 * @return {!proto.NameExprNode} returns this
*/
proto.NameExprNode.prototype.setExpr = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.NameExprNode} returns this
 */
proto.NameExprNode.prototype.clearExpr = function() {
  return this.setExpr(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.NameExprNode.prototype.hasExpr = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.NameExprListNode.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.NameExprListNode.prototype.toObject = function(opt_includeInstance) {
  return proto.NameExprListNode.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.NameExprListNode} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.NameExprListNode.toObject = function(includeInstance, msg) {
  var f, obj = {
    nameExprsList: jspb.Message.toObjectList(msg.getNameExprsList(),
    proto.NameExprNode.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.NameExprListNode}
 */
proto.NameExprListNode.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.NameExprListNode;
  return proto.NameExprListNode.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.NameExprListNode} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.NameExprListNode}
 */
proto.NameExprListNode.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.NameExprNode;
      reader.readMessage(value,proto.NameExprNode.deserializeBinaryFromReader);
      msg.addNameExprs(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.NameExprListNode.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.NameExprListNode.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.NameExprListNode} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.NameExprListNode.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNameExprsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.NameExprNode.serializeBinaryToWriter
    );
  }
};


/**
 * repeated NameExprNode name_exprs = 1;
 * @return {!Array<!proto.NameExprNode>}
 */
proto.NameExprListNode.prototype.getNameExprsList = function() {
  return /** @type{!Array<!proto.NameExprNode>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.NameExprNode, 1));
};


/**
 * @param {!Array<!proto.NameExprNode>} value
 * @return {!proto.NameExprListNode} returns this
*/
proto.NameExprListNode.prototype.setNameExprsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.NameExprNode=} opt_value
 * @param {number=} opt_index
 * @return {!proto.NameExprNode}
 */
proto.NameExprListNode.prototype.addNameExprs = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.NameExprNode, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.NameExprListNode} returns this
 */
proto.NameExprListNode.prototype.clearNameExprsList = function() {
  return this.setNameExprsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.FuncTypeNode.prototype.toObject = function(opt_includeInstance) {
  return proto.FuncTypeNode.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.FuncTypeNode} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.FuncTypeNode.toObject = function(includeInstance, msg) {
  var f, obj = {
    params: (f = msg.getParams()) && proto.NameTypeListNode.toObject(includeInstance, f),
    returnType: (f = msg.getReturnType()) && proto.TypeNode.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.FuncTypeNode}
 */
proto.FuncTypeNode.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.FuncTypeNode;
  return proto.FuncTypeNode.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.FuncTypeNode} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.FuncTypeNode}
 */
proto.FuncTypeNode.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.NameTypeListNode;
      reader.readMessage(value,proto.NameTypeListNode.deserializeBinaryFromReader);
      msg.setParams(value);
      break;
    case 2:
      var value = new proto.TypeNode;
      reader.readMessage(value,proto.TypeNode.deserializeBinaryFromReader);
      msg.setReturnType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.FuncTypeNode.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.FuncTypeNode.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.FuncTypeNode} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.FuncTypeNode.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getParams();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.NameTypeListNode.serializeBinaryToWriter
    );
  }
  f = message.getReturnType();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.TypeNode.serializeBinaryToWriter
    );
  }
};


/**
 * optional NameTypeListNode params = 1;
 * @return {?proto.NameTypeListNode}
 */
proto.FuncTypeNode.prototype.getParams = function() {
  return /** @type{?proto.NameTypeListNode} */ (
    jspb.Message.getWrapperField(this, proto.NameTypeListNode, 1));
};


/**
 * @param {?proto.NameTypeListNode|undefined} value
 * @return {!proto.FuncTypeNode} returns this
*/
proto.FuncTypeNode.prototype.setParams = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.FuncTypeNode} returns this
 */
proto.FuncTypeNode.prototype.clearParams = function() {
  return this.setParams(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.FuncTypeNode.prototype.hasParams = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional TypeNode return_type = 2;
 * @return {?proto.TypeNode}
 */
proto.FuncTypeNode.prototype.getReturnType = function() {
  return /** @type{?proto.TypeNode} */ (
    jspb.Message.getWrapperField(this, proto.TypeNode, 2));
};


/**
 * @param {?proto.TypeNode|undefined} value
 * @return {!proto.FuncTypeNode} returns this
*/
proto.FuncTypeNode.prototype.setReturnType = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.FuncTypeNode} returns this
 */
proto.FuncTypeNode.prototype.clearReturnType = function() {
  return this.setReturnType(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.FuncTypeNode.prototype.hasReturnType = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.TupleTypeNode.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.TupleTypeNode.prototype.toObject = function(opt_includeInstance) {
  return proto.TupleTypeNode.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.TupleTypeNode} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.TupleTypeNode.toObject = function(includeInstance, msg) {
  var f, obj = {
    elementTypesList: jspb.Message.toObjectList(msg.getElementTypesList(),
    proto.TypeNode.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.TupleTypeNode}
 */
proto.TupleTypeNode.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.TupleTypeNode;
  return proto.TupleTypeNode.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.TupleTypeNode} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.TupleTypeNode}
 */
proto.TupleTypeNode.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.TypeNode;
      reader.readMessage(value,proto.TypeNode.deserializeBinaryFromReader);
      msg.addElementTypes(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.TupleTypeNode.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.TupleTypeNode.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.TupleTypeNode} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.TupleTypeNode.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getElementTypesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.TypeNode.serializeBinaryToWriter
    );
  }
};


/**
 * repeated TypeNode element_types = 1;
 * @return {!Array<!proto.TypeNode>}
 */
proto.TupleTypeNode.prototype.getElementTypesList = function() {
  return /** @type{!Array<!proto.TypeNode>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.TypeNode, 1));
};


/**
 * @param {!Array<!proto.TypeNode>} value
 * @return {!proto.TupleTypeNode} returns this
*/
proto.TupleTypeNode.prototype.setElementTypesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.TypeNode=} opt_value
 * @param {number=} opt_index
 * @return {!proto.TypeNode}
 */
proto.TupleTypeNode.prototype.addElementTypes = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.TypeNode, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.TupleTypeNode} returns this
 */
proto.TupleTypeNode.prototype.clearElementTypesList = function() {
  return this.setElementTypesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ArrayTypeNode.prototype.toObject = function(opt_includeInstance) {
  return proto.ArrayTypeNode.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ArrayTypeNode} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ArrayTypeNode.toObject = function(includeInstance, msg) {
  var f, obj = {
    elementType: (f = msg.getElementType()) && proto.TypeNode.toObject(includeInstance, f),
    dims: (f = msg.getDims()) && proto.IntLiteralNode.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ArrayTypeNode}
 */
proto.ArrayTypeNode.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ArrayTypeNode;
  return proto.ArrayTypeNode.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ArrayTypeNode} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ArrayTypeNode}
 */
proto.ArrayTypeNode.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.TypeNode;
      reader.readMessage(value,proto.TypeNode.deserializeBinaryFromReader);
      msg.setElementType(value);
      break;
    case 2:
      var value = new proto.IntLiteralNode;
      reader.readMessage(value,proto.IntLiteralNode.deserializeBinaryFromReader);
      msg.setDims(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ArrayTypeNode.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ArrayTypeNode.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ArrayTypeNode} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ArrayTypeNode.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getElementType();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.TypeNode.serializeBinaryToWriter
    );
  }
  f = message.getDims();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.IntLiteralNode.serializeBinaryToWriter
    );
  }
};


/**
 * optional TypeNode element_type = 1;
 * @return {?proto.TypeNode}
 */
proto.ArrayTypeNode.prototype.getElementType = function() {
  return /** @type{?proto.TypeNode} */ (
    jspb.Message.getWrapperField(this, proto.TypeNode, 1));
};


/**
 * @param {?proto.TypeNode|undefined} value
 * @return {!proto.ArrayTypeNode} returns this
*/
proto.ArrayTypeNode.prototype.setElementType = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ArrayTypeNode} returns this
 */
proto.ArrayTypeNode.prototype.clearElementType = function() {
  return this.setElementType(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ArrayTypeNode.prototype.hasElementType = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional IntLiteralNode dims = 2;
 * @return {?proto.IntLiteralNode}
 */
proto.ArrayTypeNode.prototype.getDims = function() {
  return /** @type{?proto.IntLiteralNode} */ (
    jspb.Message.getWrapperField(this, proto.IntLiteralNode, 2));
};


/**
 * @param {?proto.IntLiteralNode|undefined} value
 * @return {!proto.ArrayTypeNode} returns this
*/
proto.ArrayTypeNode.prototype.setDims = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ArrayTypeNode} returns this
 */
proto.ArrayTypeNode.prototype.clearDims = function() {
  return this.setDims(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ArrayTypeNode.prototype.hasDims = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.MapTypeNode.prototype.toObject = function(opt_includeInstance) {
  return proto.MapTypeNode.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.MapTypeNode} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.MapTypeNode.toObject = function(includeInstance, msg) {
  var f, obj = {
    keyType: (f = msg.getKeyType()) && proto.TypeNode.toObject(includeInstance, f),
    valueType: (f = msg.getValueType()) && proto.TypeNode.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.MapTypeNode}
 */
proto.MapTypeNode.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.MapTypeNode;
  return proto.MapTypeNode.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.MapTypeNode} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.MapTypeNode}
 */
proto.MapTypeNode.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.TypeNode;
      reader.readMessage(value,proto.TypeNode.deserializeBinaryFromReader);
      msg.setKeyType(value);
      break;
    case 2:
      var value = new proto.TypeNode;
      reader.readMessage(value,proto.TypeNode.deserializeBinaryFromReader);
      msg.setValueType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.MapTypeNode.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.MapTypeNode.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.MapTypeNode} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.MapTypeNode.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getKeyType();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.TypeNode.serializeBinaryToWriter
    );
  }
  f = message.getValueType();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.TypeNode.serializeBinaryToWriter
    );
  }
};


/**
 * optional TypeNode key_type = 1;
 * @return {?proto.TypeNode}
 */
proto.MapTypeNode.prototype.getKeyType = function() {
  return /** @type{?proto.TypeNode} */ (
    jspb.Message.getWrapperField(this, proto.TypeNode, 1));
};


/**
 * @param {?proto.TypeNode|undefined} value
 * @return {!proto.MapTypeNode} returns this
*/
proto.MapTypeNode.prototype.setKeyType = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.MapTypeNode} returns this
 */
proto.MapTypeNode.prototype.clearKeyType = function() {
  return this.setKeyType(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.MapTypeNode.prototype.hasKeyType = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional TypeNode value_type = 2;
 * @return {?proto.TypeNode}
 */
proto.MapTypeNode.prototype.getValueType = function() {
  return /** @type{?proto.TypeNode} */ (
    jspb.Message.getWrapperField(this, proto.TypeNode, 2));
};


/**
 * @param {?proto.TypeNode|undefined} value
 * @return {!proto.MapTypeNode} returns this
*/
proto.MapTypeNode.prototype.setValueType = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.MapTypeNode} returns this
 */
proto.MapTypeNode.prototype.clearValueType = function() {
  return this.setValueType(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.MapTypeNode.prototype.hasValueType = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.TypeNode.oneofGroups_ = [[2,3,4,5,6,7,8]];

/**
 * @enum {number}
 */
proto.TypeNode.TypeCase = {
  TYPE_NOT_SET: 0,
  LENGTH: 2,
  FUNC_TYPE: 3,
  TUPLE_TYPE: 4,
  ARRAY_TYPE: 5,
  ELEMENT_TYPE: 6,
  MAP_TYPE: 7,
  ALIAS: 8
};

/**
 * @return {proto.TypeNode.TypeCase}
 */
proto.TypeNode.prototype.getTypeCase = function() {
  return /** @type {proto.TypeNode.TypeCase} */(jspb.Message.computeOneofCase(this, proto.TypeNode.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.TypeNode.prototype.toObject = function(opt_includeInstance) {
  return proto.TypeNode.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.TypeNode} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.TypeNode.toObject = function(includeInstance, msg) {
  var f, obj = {
    kind: jspb.Message.getFieldWithDefault(msg, 1, 0),
    length: (f = msg.getLength()) && proto.IntLiteralNode.toObject(includeInstance, f),
    funcType: (f = msg.getFuncType()) && proto.FuncTypeNode.toObject(includeInstance, f),
    tupleType: (f = msg.getTupleType()) && proto.TupleTypeNode.toObject(includeInstance, f),
    arrayType: (f = msg.getArrayType()) && proto.ArrayTypeNode.toObject(includeInstance, f),
    elementType: (f = msg.getElementType()) && proto.TypeNode.toObject(includeInstance, f),
    mapType: (f = msg.getMapType()) && proto.MapTypeNode.toObject(includeInstance, f),
    alias: (f = msg.getAlias()) && proto.NameNode.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.TypeNode}
 */
proto.TypeNode.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.TypeNode;
  return proto.TypeNode.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.TypeNode} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.TypeNode}
 */
proto.TypeNode.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.TypeNodeKind} */ (reader.readEnum());
      msg.setKind(value);
      break;
    case 2:
      var value = new proto.IntLiteralNode;
      reader.readMessage(value,proto.IntLiteralNode.deserializeBinaryFromReader);
      msg.setLength(value);
      break;
    case 3:
      var value = new proto.FuncTypeNode;
      reader.readMessage(value,proto.FuncTypeNode.deserializeBinaryFromReader);
      msg.setFuncType(value);
      break;
    case 4:
      var value = new proto.TupleTypeNode;
      reader.readMessage(value,proto.TupleTypeNode.deserializeBinaryFromReader);
      msg.setTupleType(value);
      break;
    case 5:
      var value = new proto.ArrayTypeNode;
      reader.readMessage(value,proto.ArrayTypeNode.deserializeBinaryFromReader);
      msg.setArrayType(value);
      break;
    case 6:
      var value = new proto.TypeNode;
      reader.readMessage(value,proto.TypeNode.deserializeBinaryFromReader);
      msg.setElementType(value);
      break;
    case 7:
      var value = new proto.MapTypeNode;
      reader.readMessage(value,proto.MapTypeNode.deserializeBinaryFromReader);
      msg.setMapType(value);
      break;
    case 8:
      var value = new proto.NameNode;
      reader.readMessage(value,proto.NameNode.deserializeBinaryFromReader);
      msg.setAlias(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.TypeNode.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.TypeNode.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.TypeNode} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.TypeNode.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getKind();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getLength();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.IntLiteralNode.serializeBinaryToWriter
    );
  }
  f = message.getFuncType();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.FuncTypeNode.serializeBinaryToWriter
    );
  }
  f = message.getTupleType();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.TupleTypeNode.serializeBinaryToWriter
    );
  }
  f = message.getArrayType();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.ArrayTypeNode.serializeBinaryToWriter
    );
  }
  f = message.getElementType();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.TypeNode.serializeBinaryToWriter
    );
  }
  f = message.getMapType();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.MapTypeNode.serializeBinaryToWriter
    );
  }
  f = message.getAlias();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.NameNode.serializeBinaryToWriter
    );
  }
};


/**
 * optional TypeNodeKind kind = 1;
 * @return {!proto.TypeNodeKind}
 */
proto.TypeNode.prototype.getKind = function() {
  return /** @type {!proto.TypeNodeKind} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.TypeNodeKind} value
 * @return {!proto.TypeNode} returns this
 */
proto.TypeNode.prototype.setKind = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional IntLiteralNode length = 2;
 * @return {?proto.IntLiteralNode}
 */
proto.TypeNode.prototype.getLength = function() {
  return /** @type{?proto.IntLiteralNode} */ (
    jspb.Message.getWrapperField(this, proto.IntLiteralNode, 2));
};


/**
 * @param {?proto.IntLiteralNode|undefined} value
 * @return {!proto.TypeNode} returns this
*/
proto.TypeNode.prototype.setLength = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.TypeNode.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.TypeNode} returns this
 */
proto.TypeNode.prototype.clearLength = function() {
  return this.setLength(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.TypeNode.prototype.hasLength = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional FuncTypeNode func_type = 3;
 * @return {?proto.FuncTypeNode}
 */
proto.TypeNode.prototype.getFuncType = function() {
  return /** @type{?proto.FuncTypeNode} */ (
    jspb.Message.getWrapperField(this, proto.FuncTypeNode, 3));
};


/**
 * @param {?proto.FuncTypeNode|undefined} value
 * @return {!proto.TypeNode} returns this
*/
proto.TypeNode.prototype.setFuncType = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.TypeNode.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.TypeNode} returns this
 */
proto.TypeNode.prototype.clearFuncType = function() {
  return this.setFuncType(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.TypeNode.prototype.hasFuncType = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional TupleTypeNode tuple_type = 4;
 * @return {?proto.TupleTypeNode}
 */
proto.TypeNode.prototype.getTupleType = function() {
  return /** @type{?proto.TupleTypeNode} */ (
    jspb.Message.getWrapperField(this, proto.TupleTypeNode, 4));
};


/**
 * @param {?proto.TupleTypeNode|undefined} value
 * @return {!proto.TypeNode} returns this
*/
proto.TypeNode.prototype.setTupleType = function(value) {
  return jspb.Message.setOneofWrapperField(this, 4, proto.TypeNode.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.TypeNode} returns this
 */
proto.TypeNode.prototype.clearTupleType = function() {
  return this.setTupleType(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.TypeNode.prototype.hasTupleType = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional ArrayTypeNode array_type = 5;
 * @return {?proto.ArrayTypeNode}
 */
proto.TypeNode.prototype.getArrayType = function() {
  return /** @type{?proto.ArrayTypeNode} */ (
    jspb.Message.getWrapperField(this, proto.ArrayTypeNode, 5));
};


/**
 * @param {?proto.ArrayTypeNode|undefined} value
 * @return {!proto.TypeNode} returns this
*/
proto.TypeNode.prototype.setArrayType = function(value) {
  return jspb.Message.setOneofWrapperField(this, 5, proto.TypeNode.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.TypeNode} returns this
 */
proto.TypeNode.prototype.clearArrayType = function() {
  return this.setArrayType(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.TypeNode.prototype.hasArrayType = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional TypeNode element_type = 6;
 * @return {?proto.TypeNode}
 */
proto.TypeNode.prototype.getElementType = function() {
  return /** @type{?proto.TypeNode} */ (
    jspb.Message.getWrapperField(this, proto.TypeNode, 6));
};


/**
 * @param {?proto.TypeNode|undefined} value
 * @return {!proto.TypeNode} returns this
*/
proto.TypeNode.prototype.setElementType = function(value) {
  return jspb.Message.setOneofWrapperField(this, 6, proto.TypeNode.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.TypeNode} returns this
 */
proto.TypeNode.prototype.clearElementType = function() {
  return this.setElementType(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.TypeNode.prototype.hasElementType = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional MapTypeNode map_type = 7;
 * @return {?proto.MapTypeNode}
 */
proto.TypeNode.prototype.getMapType = function() {
  return /** @type{?proto.MapTypeNode} */ (
    jspb.Message.getWrapperField(this, proto.MapTypeNode, 7));
};


/**
 * @param {?proto.MapTypeNode|undefined} value
 * @return {!proto.TypeNode} returns this
*/
proto.TypeNode.prototype.setMapType = function(value) {
  return jspb.Message.setOneofWrapperField(this, 7, proto.TypeNode.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.TypeNode} returns this
 */
proto.TypeNode.prototype.clearMapType = function() {
  return this.setMapType(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.TypeNode.prototype.hasMapType = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional NameNode alias = 8;
 * @return {?proto.NameNode}
 */
proto.TypeNode.prototype.getAlias = function() {
  return /** @type{?proto.NameNode} */ (
    jspb.Message.getWrapperField(this, proto.NameNode, 8));
};


/**
 * @param {?proto.NameNode|undefined} value
 * @return {!proto.TypeNode} returns this
*/
proto.TypeNode.prototype.setAlias = function(value) {
  return jspb.Message.setOneofWrapperField(this, 8, proto.TypeNode.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.TypeNode} returns this
 */
proto.TypeNode.prototype.clearAlias = function() {
  return this.setAlias(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.TypeNode.prototype.hasAlias = function() {
  return jspb.Message.getField(this, 8) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.BinaryExprNode.prototype.toObject = function(opt_includeInstance) {
  return proto.BinaryExprNode.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.BinaryExprNode} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.BinaryExprNode.toObject = function(includeInstance, msg) {
  var f, obj = {
    expr1: (f = msg.getExpr1()) && proto.ExprNode.toObject(includeInstance, f),
    expr2: (f = msg.getExpr2()) && proto.ExprNode.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.BinaryExprNode}
 */
proto.BinaryExprNode.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.BinaryExprNode;
  return proto.BinaryExprNode.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.BinaryExprNode} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.BinaryExprNode}
 */
proto.BinaryExprNode.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.ExprNode;
      reader.readMessage(value,proto.ExprNode.deserializeBinaryFromReader);
      msg.setExpr1(value);
      break;
    case 2:
      var value = new proto.ExprNode;
      reader.readMessage(value,proto.ExprNode.deserializeBinaryFromReader);
      msg.setExpr2(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.BinaryExprNode.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.BinaryExprNode.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.BinaryExprNode} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.BinaryExprNode.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getExpr1();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.ExprNode.serializeBinaryToWriter
    );
  }
  f = message.getExpr2();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.ExprNode.serializeBinaryToWriter
    );
  }
};


/**
 * optional ExprNode expr1 = 1;
 * @return {?proto.ExprNode}
 */
proto.BinaryExprNode.prototype.getExpr1 = function() {
  return /** @type{?proto.ExprNode} */ (
    jspb.Message.getWrapperField(this, proto.ExprNode, 1));
};


/**
 * @param {?proto.ExprNode|undefined} value
 * @return {!proto.BinaryExprNode} returns this
*/
proto.BinaryExprNode.prototype.setExpr1 = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.BinaryExprNode} returns this
 */
proto.BinaryExprNode.prototype.clearExpr1 = function() {
  return this.setExpr1(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.BinaryExprNode.prototype.hasExpr1 = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ExprNode expr2 = 2;
 * @return {?proto.ExprNode}
 */
proto.BinaryExprNode.prototype.getExpr2 = function() {
  return /** @type{?proto.ExprNode} */ (
    jspb.Message.getWrapperField(this, proto.ExprNode, 2));
};


/**
 * @param {?proto.ExprNode|undefined} value
 * @return {!proto.BinaryExprNode} returns this
*/
proto.BinaryExprNode.prototype.setExpr2 = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.BinaryExprNode} returns this
 */
proto.BinaryExprNode.prototype.clearExpr2 = function() {
  return this.setExpr2(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.BinaryExprNode.prototype.hasExpr2 = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.TernaryExprNode.prototype.toObject = function(opt_includeInstance) {
  return proto.TernaryExprNode.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.TernaryExprNode} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.TernaryExprNode.toObject = function(includeInstance, msg) {
  var f, obj = {
    expr1: (f = msg.getExpr1()) && proto.ExprNode.toObject(includeInstance, f),
    expr2: (f = msg.getExpr2()) && proto.ExprNode.toObject(includeInstance, f),
    expr3: (f = msg.getExpr3()) && proto.ExprNode.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.TernaryExprNode}
 */
proto.TernaryExprNode.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.TernaryExprNode;
  return proto.TernaryExprNode.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.TernaryExprNode} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.TernaryExprNode}
 */
proto.TernaryExprNode.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.ExprNode;
      reader.readMessage(value,proto.ExprNode.deserializeBinaryFromReader);
      msg.setExpr1(value);
      break;
    case 2:
      var value = new proto.ExprNode;
      reader.readMessage(value,proto.ExprNode.deserializeBinaryFromReader);
      msg.setExpr2(value);
      break;
    case 3:
      var value = new proto.ExprNode;
      reader.readMessage(value,proto.ExprNode.deserializeBinaryFromReader);
      msg.setExpr3(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.TernaryExprNode.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.TernaryExprNode.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.TernaryExprNode} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.TernaryExprNode.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getExpr1();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.ExprNode.serializeBinaryToWriter
    );
  }
  f = message.getExpr2();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.ExprNode.serializeBinaryToWriter
    );
  }
  f = message.getExpr3();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.ExprNode.serializeBinaryToWriter
    );
  }
};


/**
 * optional ExprNode expr1 = 1;
 * @return {?proto.ExprNode}
 */
proto.TernaryExprNode.prototype.getExpr1 = function() {
  return /** @type{?proto.ExprNode} */ (
    jspb.Message.getWrapperField(this, proto.ExprNode, 1));
};


/**
 * @param {?proto.ExprNode|undefined} value
 * @return {!proto.TernaryExprNode} returns this
*/
proto.TernaryExprNode.prototype.setExpr1 = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.TernaryExprNode} returns this
 */
proto.TernaryExprNode.prototype.clearExpr1 = function() {
  return this.setExpr1(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.TernaryExprNode.prototype.hasExpr1 = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ExprNode expr2 = 2;
 * @return {?proto.ExprNode}
 */
proto.TernaryExprNode.prototype.getExpr2 = function() {
  return /** @type{?proto.ExprNode} */ (
    jspb.Message.getWrapperField(this, proto.ExprNode, 2));
};


/**
 * @param {?proto.ExprNode|undefined} value
 * @return {!proto.TernaryExprNode} returns this
*/
proto.TernaryExprNode.prototype.setExpr2 = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.TernaryExprNode} returns this
 */
proto.TernaryExprNode.prototype.clearExpr2 = function() {
  return this.setExpr2(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.TernaryExprNode.prototype.hasExpr2 = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional ExprNode expr3 = 3;
 * @return {?proto.ExprNode}
 */
proto.TernaryExprNode.prototype.getExpr3 = function() {
  return /** @type{?proto.ExprNode} */ (
    jspb.Message.getWrapperField(this, proto.ExprNode, 3));
};


/**
 * @param {?proto.ExprNode|undefined} value
 * @return {!proto.TernaryExprNode} returns this
*/
proto.TernaryExprNode.prototype.setExpr3 = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.TernaryExprNode} returns this
 */
proto.TernaryExprNode.prototype.clearExpr3 = function() {
  return this.setExpr3(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.TernaryExprNode.prototype.hasExpr3 = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.StdFuncCallNode.oneofGroups_ = [[2,3,4]];

/**
 * @enum {number}
 */
proto.StdFuncCallNode.ArgsCase = {
  ARGS_NOT_SET: 0,
  UNARY_EXPR: 2,
  BINARY_EXPR: 3,
  TERNARY_EXPR: 4
};

/**
 * @return {proto.StdFuncCallNode.ArgsCase}
 */
proto.StdFuncCallNode.prototype.getArgsCase = function() {
  return /** @type {proto.StdFuncCallNode.ArgsCase} */(jspb.Message.computeOneofCase(this, proto.StdFuncCallNode.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.StdFuncCallNode.prototype.toObject = function(opt_includeInstance) {
  return proto.StdFuncCallNode.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.StdFuncCallNode} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.StdFuncCallNode.toObject = function(includeInstance, msg) {
  var f, obj = {
    kind: jspb.Message.getFieldWithDefault(msg, 1, 0),
    unaryExpr: (f = msg.getUnaryExpr()) && proto.ExprNode.toObject(includeInstance, f),
    binaryExpr: (f = msg.getBinaryExpr()) && proto.BinaryExprNode.toObject(includeInstance, f),
    ternaryExpr: (f = msg.getTernaryExpr()) && proto.TernaryExprNode.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.StdFuncCallNode}
 */
proto.StdFuncCallNode.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.StdFuncCallNode;
  return proto.StdFuncCallNode.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.StdFuncCallNode} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.StdFuncCallNode}
 */
proto.StdFuncCallNode.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.StdFuncCallKind} */ (reader.readEnum());
      msg.setKind(value);
      break;
    case 2:
      var value = new proto.ExprNode;
      reader.readMessage(value,proto.ExprNode.deserializeBinaryFromReader);
      msg.setUnaryExpr(value);
      break;
    case 3:
      var value = new proto.BinaryExprNode;
      reader.readMessage(value,proto.BinaryExprNode.deserializeBinaryFromReader);
      msg.setBinaryExpr(value);
      break;
    case 4:
      var value = new proto.TernaryExprNode;
      reader.readMessage(value,proto.TernaryExprNode.deserializeBinaryFromReader);
      msg.setTernaryExpr(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.StdFuncCallNode.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.StdFuncCallNode.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.StdFuncCallNode} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.StdFuncCallNode.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getKind();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getUnaryExpr();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.ExprNode.serializeBinaryToWriter
    );
  }
  f = message.getBinaryExpr();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.BinaryExprNode.serializeBinaryToWriter
    );
  }
  f = message.getTernaryExpr();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.TernaryExprNode.serializeBinaryToWriter
    );
  }
};


/**
 * optional StdFuncCallKind kind = 1;
 * @return {!proto.StdFuncCallKind}
 */
proto.StdFuncCallNode.prototype.getKind = function() {
  return /** @type {!proto.StdFuncCallKind} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.StdFuncCallKind} value
 * @return {!proto.StdFuncCallNode} returns this
 */
proto.StdFuncCallNode.prototype.setKind = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional ExprNode unary_expr = 2;
 * @return {?proto.ExprNode}
 */
proto.StdFuncCallNode.prototype.getUnaryExpr = function() {
  return /** @type{?proto.ExprNode} */ (
    jspb.Message.getWrapperField(this, proto.ExprNode, 2));
};


/**
 * @param {?proto.ExprNode|undefined} value
 * @return {!proto.StdFuncCallNode} returns this
*/
proto.StdFuncCallNode.prototype.setUnaryExpr = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.StdFuncCallNode.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.StdFuncCallNode} returns this
 */
proto.StdFuncCallNode.prototype.clearUnaryExpr = function() {
  return this.setUnaryExpr(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.StdFuncCallNode.prototype.hasUnaryExpr = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional BinaryExprNode binary_expr = 3;
 * @return {?proto.BinaryExprNode}
 */
proto.StdFuncCallNode.prototype.getBinaryExpr = function() {
  return /** @type{?proto.BinaryExprNode} */ (
    jspb.Message.getWrapperField(this, proto.BinaryExprNode, 3));
};


/**
 * @param {?proto.BinaryExprNode|undefined} value
 * @return {!proto.StdFuncCallNode} returns this
*/
proto.StdFuncCallNode.prototype.setBinaryExpr = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.StdFuncCallNode.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.StdFuncCallNode} returns this
 */
proto.StdFuncCallNode.prototype.clearBinaryExpr = function() {
  return this.setBinaryExpr(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.StdFuncCallNode.prototype.hasBinaryExpr = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional TernaryExprNode ternary_expr = 4;
 * @return {?proto.TernaryExprNode}
 */
proto.StdFuncCallNode.prototype.getTernaryExpr = function() {
  return /** @type{?proto.TernaryExprNode} */ (
    jspb.Message.getWrapperField(this, proto.TernaryExprNode, 4));
};


/**
 * @param {?proto.TernaryExprNode|undefined} value
 * @return {!proto.StdFuncCallNode} returns this
*/
proto.StdFuncCallNode.prototype.setTernaryExpr = function(value) {
  return jspb.Message.setOneofWrapperField(this, 4, proto.StdFuncCallNode.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.StdFuncCallNode} returns this
 */
proto.StdFuncCallNode.prototype.clearTernaryExpr = function() {
  return this.setTernaryExpr(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.StdFuncCallNode.prototype.hasTernaryExpr = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.IntLiteralNode.prototype.toObject = function(opt_includeInstance) {
  return proto.IntLiteralNode.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.IntLiteralNode} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.IntLiteralNode.toObject = function(includeInstance, msg) {
  var f, obj = {
    value: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.IntLiteralNode}
 */
proto.IntLiteralNode.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.IntLiteralNode;
  return proto.IntLiteralNode.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.IntLiteralNode} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.IntLiteralNode}
 */
proto.IntLiteralNode.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.IntLiteralNode.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.IntLiteralNode.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.IntLiteralNode} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.IntLiteralNode.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getValue();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string value = 1;
 * @return {string}
 */
proto.IntLiteralNode.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.IntLiteralNode} returns this
 */
proto.IntLiteralNode.prototype.setValue = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.NumLiteralNode.prototype.toObject = function(opt_includeInstance) {
  return proto.NumLiteralNode.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.NumLiteralNode} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.NumLiteralNode.toObject = function(includeInstance, msg) {
  var f, obj = {
    value: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.NumLiteralNode}
 */
proto.NumLiteralNode.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.NumLiteralNode;
  return proto.NumLiteralNode.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.NumLiteralNode} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.NumLiteralNode}
 */
proto.NumLiteralNode.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.NumLiteralNode.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.NumLiteralNode.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.NumLiteralNode} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.NumLiteralNode.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getValue();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string value = 1;
 * @return {string}
 */
proto.NumLiteralNode.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.NumLiteralNode} returns this
 */
proto.NumLiteralNode.prototype.setValue = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.CharLiteralNode.prototype.toObject = function(opt_includeInstance) {
  return proto.CharLiteralNode.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.CharLiteralNode} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.CharLiteralNode.toObject = function(includeInstance, msg) {
  var f, obj = {
    value: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.CharLiteralNode}
 */
proto.CharLiteralNode.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.CharLiteralNode;
  return proto.CharLiteralNode.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.CharLiteralNode} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.CharLiteralNode}
 */
proto.CharLiteralNode.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.CharLiteralNode.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.CharLiteralNode.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.CharLiteralNode} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.CharLiteralNode.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getValue();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
};


/**
 * optional uint32 value = 1;
 * @return {number}
 */
proto.CharLiteralNode.prototype.getValue = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.CharLiteralNode} returns this
 */
proto.CharLiteralNode.prototype.setValue = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.StringLiteralNode.prototype.toObject = function(opt_includeInstance) {
  return proto.StringLiteralNode.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.StringLiteralNode} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.StringLiteralNode.toObject = function(includeInstance, msg) {
  var f, obj = {
    value: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.StringLiteralNode}
 */
proto.StringLiteralNode.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.StringLiteralNode;
  return proto.StringLiteralNode.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.StringLiteralNode} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.StringLiteralNode}
 */
proto.StringLiteralNode.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.StringLiteralNode.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.StringLiteralNode.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.StringLiteralNode} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.StringLiteralNode.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getValue();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string value = 1;
 * @return {string}
 */
proto.StringLiteralNode.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.StringLiteralNode} returns this
 */
proto.StringLiteralNode.prototype.setValue = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.BitsLiteralNode.prototype.toObject = function(opt_includeInstance) {
  return proto.BitsLiteralNode.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.BitsLiteralNode} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.BitsLiteralNode.toObject = function(includeInstance, msg) {
  var f, obj = {
    numBits: jspb.Message.getFieldWithDefault(msg, 1, 0),
    valueHex: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.BitsLiteralNode}
 */
proto.BitsLiteralNode.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.BitsLiteralNode;
  return proto.BitsLiteralNode.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.BitsLiteralNode} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.BitsLiteralNode}
 */
proto.BitsLiteralNode.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setNumBits(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setValueHex(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.BitsLiteralNode.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.BitsLiteralNode.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.BitsLiteralNode} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.BitsLiteralNode.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNumBits();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getValueHex();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional uint32 num_bits = 1;
 * @return {number}
 */
proto.BitsLiteralNode.prototype.getNumBits = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.BitsLiteralNode} returns this
 */
proto.BitsLiteralNode.prototype.setNumBits = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string value_hex = 2;
 * @return {string}
 */
proto.BitsLiteralNode.prototype.getValueHex = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.BitsLiteralNode} returns this
 */
proto.BitsLiteralNode.prototype.setValueHex = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.TupleCtorNode.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.TupleCtorNode.TypeCase = {
  TYPE_NOT_SET: 0,
  TUPLE_TYPE: 1,
  ALIAS: 2
};

/**
 * @return {proto.TupleCtorNode.TypeCase}
 */
proto.TupleCtorNode.prototype.getTypeCase = function() {
  return /** @type {proto.TupleCtorNode.TypeCase} */(jspb.Message.computeOneofCase(this, proto.TupleCtorNode.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.TupleCtorNode.prototype.toObject = function(opt_includeInstance) {
  return proto.TupleCtorNode.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.TupleCtorNode} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.TupleCtorNode.toObject = function(includeInstance, msg) {
  var f, obj = {
    tupleType: (f = msg.getTupleType()) && proto.TupleTypeNode.toObject(includeInstance, f),
    alias: (f = msg.getAlias()) && proto.NameNode.toObject(includeInstance, f),
    exprList: (f = msg.getExprList()) && proto.ExprListNode.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.TupleCtorNode}
 */
proto.TupleCtorNode.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.TupleCtorNode;
  return proto.TupleCtorNode.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.TupleCtorNode} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.TupleCtorNode}
 */
proto.TupleCtorNode.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.TupleTypeNode;
      reader.readMessage(value,proto.TupleTypeNode.deserializeBinaryFromReader);
      msg.setTupleType(value);
      break;
    case 2:
      var value = new proto.NameNode;
      reader.readMessage(value,proto.NameNode.deserializeBinaryFromReader);
      msg.setAlias(value);
      break;
    case 3:
      var value = new proto.ExprListNode;
      reader.readMessage(value,proto.ExprListNode.deserializeBinaryFromReader);
      msg.setExprList(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.TupleCtorNode.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.TupleCtorNode.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.TupleCtorNode} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.TupleCtorNode.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTupleType();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.TupleTypeNode.serializeBinaryToWriter
    );
  }
  f = message.getAlias();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.NameNode.serializeBinaryToWriter
    );
  }
  f = message.getExprList();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.ExprListNode.serializeBinaryToWriter
    );
  }
};


/**
 * optional TupleTypeNode tuple_type = 1;
 * @return {?proto.TupleTypeNode}
 */
proto.TupleCtorNode.prototype.getTupleType = function() {
  return /** @type{?proto.TupleTypeNode} */ (
    jspb.Message.getWrapperField(this, proto.TupleTypeNode, 1));
};


/**
 * @param {?proto.TupleTypeNode|undefined} value
 * @return {!proto.TupleCtorNode} returns this
*/
proto.TupleCtorNode.prototype.setTupleType = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.TupleCtorNode.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.TupleCtorNode} returns this
 */
proto.TupleCtorNode.prototype.clearTupleType = function() {
  return this.setTupleType(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.TupleCtorNode.prototype.hasTupleType = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional NameNode alias = 2;
 * @return {?proto.NameNode}
 */
proto.TupleCtorNode.prototype.getAlias = function() {
  return /** @type{?proto.NameNode} */ (
    jspb.Message.getWrapperField(this, proto.NameNode, 2));
};


/**
 * @param {?proto.NameNode|undefined} value
 * @return {!proto.TupleCtorNode} returns this
*/
proto.TupleCtorNode.prototype.setAlias = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.TupleCtorNode.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.TupleCtorNode} returns this
 */
proto.TupleCtorNode.prototype.clearAlias = function() {
  return this.setAlias(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.TupleCtorNode.prototype.hasAlias = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional ExprListNode expr_list = 3;
 * @return {?proto.ExprListNode}
 */
proto.TupleCtorNode.prototype.getExprList = function() {
  return /** @type{?proto.ExprListNode} */ (
    jspb.Message.getWrapperField(this, proto.ExprListNode, 3));
};


/**
 * @param {?proto.ExprListNode|undefined} value
 * @return {!proto.TupleCtorNode} returns this
*/
proto.TupleCtorNode.prototype.setExprList = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.TupleCtorNode} returns this
 */
proto.TupleCtorNode.prototype.clearExprList = function() {
  return this.setExprList(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.TupleCtorNode.prototype.hasExprList = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.ArrayElementsListNode.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ArrayElementsListNode.prototype.toObject = function(opt_includeInstance) {
  return proto.ArrayElementsListNode.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ArrayElementsListNode} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ArrayElementsListNode.toObject = function(includeInstance, msg) {
  var f, obj = {
    arrayElementsList: jspb.Message.toObjectList(msg.getArrayElementsList(),
    proto.ArrayElementsNode.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ArrayElementsListNode}
 */
proto.ArrayElementsListNode.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ArrayElementsListNode;
  return proto.ArrayElementsListNode.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ArrayElementsListNode} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ArrayElementsListNode}
 */
proto.ArrayElementsListNode.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.ArrayElementsNode;
      reader.readMessage(value,proto.ArrayElementsNode.deserializeBinaryFromReader);
      msg.addArrayElements(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ArrayElementsListNode.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ArrayElementsListNode.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ArrayElementsListNode} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ArrayElementsListNode.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getArrayElementsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.ArrayElementsNode.serializeBinaryToWriter
    );
  }
};


/**
 * repeated ArrayElementsNode array_elements = 1;
 * @return {!Array<!proto.ArrayElementsNode>}
 */
proto.ArrayElementsListNode.prototype.getArrayElementsList = function() {
  return /** @type{!Array<!proto.ArrayElementsNode>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.ArrayElementsNode, 1));
};


/**
 * @param {!Array<!proto.ArrayElementsNode>} value
 * @return {!proto.ArrayElementsListNode} returns this
*/
proto.ArrayElementsListNode.prototype.setArrayElementsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.ArrayElementsNode=} opt_value
 * @param {number=} opt_index
 * @return {!proto.ArrayElementsNode}
 */
proto.ArrayElementsListNode.prototype.addArrayElements = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.ArrayElementsNode, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.ArrayElementsListNode} returns this
 */
proto.ArrayElementsListNode.prototype.clearArrayElementsList = function() {
  return this.setArrayElementsList([]);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.ArrayElementsNode.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.ArrayElementsNode.ElementsCase = {
  ELEMENTS_NOT_SET: 0,
  EXPR_LIST: 1,
  ARRAY_ELEMENTS_LIST: 2
};

/**
 * @return {proto.ArrayElementsNode.ElementsCase}
 */
proto.ArrayElementsNode.prototype.getElementsCase = function() {
  return /** @type {proto.ArrayElementsNode.ElementsCase} */(jspb.Message.computeOneofCase(this, proto.ArrayElementsNode.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ArrayElementsNode.prototype.toObject = function(opt_includeInstance) {
  return proto.ArrayElementsNode.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ArrayElementsNode} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ArrayElementsNode.toObject = function(includeInstance, msg) {
  var f, obj = {
    exprList: (f = msg.getExprList()) && proto.ExprListNode.toObject(includeInstance, f),
    arrayElementsList: (f = msg.getArrayElementsList()) && proto.ArrayElementsListNode.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ArrayElementsNode}
 */
proto.ArrayElementsNode.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ArrayElementsNode;
  return proto.ArrayElementsNode.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ArrayElementsNode} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ArrayElementsNode}
 */
proto.ArrayElementsNode.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.ExprListNode;
      reader.readMessage(value,proto.ExprListNode.deserializeBinaryFromReader);
      msg.setExprList(value);
      break;
    case 2:
      var value = new proto.ArrayElementsListNode;
      reader.readMessage(value,proto.ArrayElementsListNode.deserializeBinaryFromReader);
      msg.setArrayElementsList(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ArrayElementsNode.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ArrayElementsNode.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ArrayElementsNode} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ArrayElementsNode.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getExprList();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.ExprListNode.serializeBinaryToWriter
    );
  }
  f = message.getArrayElementsList();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.ArrayElementsListNode.serializeBinaryToWriter
    );
  }
};


/**
 * optional ExprListNode expr_list = 1;
 * @return {?proto.ExprListNode}
 */
proto.ArrayElementsNode.prototype.getExprList = function() {
  return /** @type{?proto.ExprListNode} */ (
    jspb.Message.getWrapperField(this, proto.ExprListNode, 1));
};


/**
 * @param {?proto.ExprListNode|undefined} value
 * @return {!proto.ArrayElementsNode} returns this
*/
proto.ArrayElementsNode.prototype.setExprList = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.ArrayElementsNode.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ArrayElementsNode} returns this
 */
proto.ArrayElementsNode.prototype.clearExprList = function() {
  return this.setExprList(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ArrayElementsNode.prototype.hasExprList = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ArrayElementsListNode array_elements_list = 2;
 * @return {?proto.ArrayElementsListNode}
 */
proto.ArrayElementsNode.prototype.getArrayElementsList = function() {
  return /** @type{?proto.ArrayElementsListNode} */ (
    jspb.Message.getWrapperField(this, proto.ArrayElementsListNode, 2));
};


/**
 * @param {?proto.ArrayElementsListNode|undefined} value
 * @return {!proto.ArrayElementsNode} returns this
*/
proto.ArrayElementsNode.prototype.setArrayElementsList = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.ArrayElementsNode.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ArrayElementsNode} returns this
 */
proto.ArrayElementsNode.prototype.clearArrayElementsList = function() {
  return this.setArrayElementsList(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ArrayElementsNode.prototype.hasArrayElementsList = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.ArrayCtorNode.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.ArrayCtorNode.TypeCase = {
  TYPE_NOT_SET: 0,
  ARRAY_TYPE: 1,
  ALIAS: 2
};

/**
 * @return {proto.ArrayCtorNode.TypeCase}
 */
proto.ArrayCtorNode.prototype.getTypeCase = function() {
  return /** @type {proto.ArrayCtorNode.TypeCase} */(jspb.Message.computeOneofCase(this, proto.ArrayCtorNode.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ArrayCtorNode.prototype.toObject = function(opt_includeInstance) {
  return proto.ArrayCtorNode.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ArrayCtorNode} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ArrayCtorNode.toObject = function(includeInstance, msg) {
  var f, obj = {
    arrayType: (f = msg.getArrayType()) && proto.ArrayTypeNode.toObject(includeInstance, f),
    alias: (f = msg.getAlias()) && proto.NameNode.toObject(includeInstance, f),
    dimLengths: (f = msg.getDimLengths()) && proto.ExprListNode.toObject(includeInstance, f),
    arrayElements: (f = msg.getArrayElements()) && proto.ArrayElementsNode.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ArrayCtorNode}
 */
proto.ArrayCtorNode.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ArrayCtorNode;
  return proto.ArrayCtorNode.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ArrayCtorNode} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ArrayCtorNode}
 */
proto.ArrayCtorNode.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.ArrayTypeNode;
      reader.readMessage(value,proto.ArrayTypeNode.deserializeBinaryFromReader);
      msg.setArrayType(value);
      break;
    case 2:
      var value = new proto.NameNode;
      reader.readMessage(value,proto.NameNode.deserializeBinaryFromReader);
      msg.setAlias(value);
      break;
    case 3:
      var value = new proto.ExprListNode;
      reader.readMessage(value,proto.ExprListNode.deserializeBinaryFromReader);
      msg.setDimLengths(value);
      break;
    case 4:
      var value = new proto.ArrayElementsNode;
      reader.readMessage(value,proto.ArrayElementsNode.deserializeBinaryFromReader);
      msg.setArrayElements(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ArrayCtorNode.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ArrayCtorNode.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ArrayCtorNode} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ArrayCtorNode.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getArrayType();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.ArrayTypeNode.serializeBinaryToWriter
    );
  }
  f = message.getAlias();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.NameNode.serializeBinaryToWriter
    );
  }
  f = message.getDimLengths();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.ExprListNode.serializeBinaryToWriter
    );
  }
  f = message.getArrayElements();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.ArrayElementsNode.serializeBinaryToWriter
    );
  }
};


/**
 * optional ArrayTypeNode array_type = 1;
 * @return {?proto.ArrayTypeNode}
 */
proto.ArrayCtorNode.prototype.getArrayType = function() {
  return /** @type{?proto.ArrayTypeNode} */ (
    jspb.Message.getWrapperField(this, proto.ArrayTypeNode, 1));
};


/**
 * @param {?proto.ArrayTypeNode|undefined} value
 * @return {!proto.ArrayCtorNode} returns this
*/
proto.ArrayCtorNode.prototype.setArrayType = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.ArrayCtorNode.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ArrayCtorNode} returns this
 */
proto.ArrayCtorNode.prototype.clearArrayType = function() {
  return this.setArrayType(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ArrayCtorNode.prototype.hasArrayType = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional NameNode alias = 2;
 * @return {?proto.NameNode}
 */
proto.ArrayCtorNode.prototype.getAlias = function() {
  return /** @type{?proto.NameNode} */ (
    jspb.Message.getWrapperField(this, proto.NameNode, 2));
};


/**
 * @param {?proto.NameNode|undefined} value
 * @return {!proto.ArrayCtorNode} returns this
*/
proto.ArrayCtorNode.prototype.setAlias = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.ArrayCtorNode.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ArrayCtorNode} returns this
 */
proto.ArrayCtorNode.prototype.clearAlias = function() {
  return this.setAlias(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ArrayCtorNode.prototype.hasAlias = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional ExprListNode dim_lengths = 3;
 * @return {?proto.ExprListNode}
 */
proto.ArrayCtorNode.prototype.getDimLengths = function() {
  return /** @type{?proto.ExprListNode} */ (
    jspb.Message.getWrapperField(this, proto.ExprListNode, 3));
};


/**
 * @param {?proto.ExprListNode|undefined} value
 * @return {!proto.ArrayCtorNode} returns this
*/
proto.ArrayCtorNode.prototype.setDimLengths = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ArrayCtorNode} returns this
 */
proto.ArrayCtorNode.prototype.clearDimLengths = function() {
  return this.setDimLengths(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ArrayCtorNode.prototype.hasDimLengths = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional ArrayElementsNode array_elements = 4;
 * @return {?proto.ArrayElementsNode}
 */
proto.ArrayCtorNode.prototype.getArrayElements = function() {
  return /** @type{?proto.ArrayElementsNode} */ (
    jspb.Message.getWrapperField(this, proto.ArrayElementsNode, 4));
};


/**
 * @param {?proto.ArrayElementsNode|undefined} value
 * @return {!proto.ArrayCtorNode} returns this
*/
proto.ArrayCtorNode.prototype.setArrayElements = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ArrayCtorNode} returns this
 */
proto.ArrayCtorNode.prototype.clearArrayElements = function() {
  return this.setArrayElements(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ArrayCtorNode.prototype.hasArrayElements = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.StructCtorNode.prototype.toObject = function(opt_includeInstance) {
  return proto.StructCtorNode.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.StructCtorNode} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.StructCtorNode.toObject = function(includeInstance, msg) {
  var f, obj = {
    alias: (f = msg.getAlias()) && proto.NameNode.toObject(includeInstance, f),
    fieldValues: (f = msg.getFieldValues()) && proto.NameExprListNode.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.StructCtorNode}
 */
proto.StructCtorNode.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.StructCtorNode;
  return proto.StructCtorNode.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.StructCtorNode} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.StructCtorNode}
 */
proto.StructCtorNode.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.NameNode;
      reader.readMessage(value,proto.NameNode.deserializeBinaryFromReader);
      msg.setAlias(value);
      break;
    case 2:
      var value = new proto.NameExprListNode;
      reader.readMessage(value,proto.NameExprListNode.deserializeBinaryFromReader);
      msg.setFieldValues(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.StructCtorNode.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.StructCtorNode.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.StructCtorNode} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.StructCtorNode.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAlias();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.NameNode.serializeBinaryToWriter
    );
  }
  f = message.getFieldValues();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.NameExprListNode.serializeBinaryToWriter
    );
  }
};


/**
 * optional NameNode alias = 1;
 * @return {?proto.NameNode}
 */
proto.StructCtorNode.prototype.getAlias = function() {
  return /** @type{?proto.NameNode} */ (
    jspb.Message.getWrapperField(this, proto.NameNode, 1));
};


/**
 * @param {?proto.NameNode|undefined} value
 * @return {!proto.StructCtorNode} returns this
*/
proto.StructCtorNode.prototype.setAlias = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.StructCtorNode} returns this
 */
proto.StructCtorNode.prototype.clearAlias = function() {
  return this.setAlias(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.StructCtorNode.prototype.hasAlias = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional NameExprListNode field_values = 2;
 * @return {?proto.NameExprListNode}
 */
proto.StructCtorNode.prototype.getFieldValues = function() {
  return /** @type{?proto.NameExprListNode} */ (
    jspb.Message.getWrapperField(this, proto.NameExprListNode, 2));
};


/**
 * @param {?proto.NameExprListNode|undefined} value
 * @return {!proto.StructCtorNode} returns this
*/
proto.StructCtorNode.prototype.setFieldValues = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.StructCtorNode} returns this
 */
proto.StructCtorNode.prototype.clearFieldValues = function() {
  return this.setFieldValues(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.StructCtorNode.prototype.hasFieldValues = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.ContainerCtorNode.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.ContainerCtorNode.TypeCase = {
  TYPE_NOT_SET: 0,
  ELEMENT_TYPE: 1,
  ALIAS: 2
};

/**
 * @return {proto.ContainerCtorNode.TypeCase}
 */
proto.ContainerCtorNode.prototype.getTypeCase = function() {
  return /** @type {proto.ContainerCtorNode.TypeCase} */(jspb.Message.computeOneofCase(this, proto.ContainerCtorNode.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ContainerCtorNode.prototype.toObject = function(opt_includeInstance) {
  return proto.ContainerCtorNode.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ContainerCtorNode} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ContainerCtorNode.toObject = function(includeInstance, msg) {
  var f, obj = {
    elementType: (f = msg.getElementType()) && proto.TypeNode.toObject(includeInstance, f),
    alias: (f = msg.getAlias()) && proto.NameNode.toObject(includeInstance, f),
    exprList: (f = msg.getExprList()) && proto.ExprListNode.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ContainerCtorNode}
 */
proto.ContainerCtorNode.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ContainerCtorNode;
  return proto.ContainerCtorNode.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ContainerCtorNode} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ContainerCtorNode}
 */
proto.ContainerCtorNode.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.TypeNode;
      reader.readMessage(value,proto.TypeNode.deserializeBinaryFromReader);
      msg.setElementType(value);
      break;
    case 2:
      var value = new proto.NameNode;
      reader.readMessage(value,proto.NameNode.deserializeBinaryFromReader);
      msg.setAlias(value);
      break;
    case 3:
      var value = new proto.ExprListNode;
      reader.readMessage(value,proto.ExprListNode.deserializeBinaryFromReader);
      msg.setExprList(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ContainerCtorNode.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ContainerCtorNode.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ContainerCtorNode} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ContainerCtorNode.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getElementType();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.TypeNode.serializeBinaryToWriter
    );
  }
  f = message.getAlias();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.NameNode.serializeBinaryToWriter
    );
  }
  f = message.getExprList();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.ExprListNode.serializeBinaryToWriter
    );
  }
};


/**
 * optional TypeNode element_type = 1;
 * @return {?proto.TypeNode}
 */
proto.ContainerCtorNode.prototype.getElementType = function() {
  return /** @type{?proto.TypeNode} */ (
    jspb.Message.getWrapperField(this, proto.TypeNode, 1));
};


/**
 * @param {?proto.TypeNode|undefined} value
 * @return {!proto.ContainerCtorNode} returns this
*/
proto.ContainerCtorNode.prototype.setElementType = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.ContainerCtorNode.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ContainerCtorNode} returns this
 */
proto.ContainerCtorNode.prototype.clearElementType = function() {
  return this.setElementType(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ContainerCtorNode.prototype.hasElementType = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional NameNode alias = 2;
 * @return {?proto.NameNode}
 */
proto.ContainerCtorNode.prototype.getAlias = function() {
  return /** @type{?proto.NameNode} */ (
    jspb.Message.getWrapperField(this, proto.NameNode, 2));
};


/**
 * @param {?proto.NameNode|undefined} value
 * @return {!proto.ContainerCtorNode} returns this
*/
proto.ContainerCtorNode.prototype.setAlias = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.ContainerCtorNode.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ContainerCtorNode} returns this
 */
proto.ContainerCtorNode.prototype.clearAlias = function() {
  return this.setAlias(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ContainerCtorNode.prototype.hasAlias = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional ExprListNode expr_list = 3;
 * @return {?proto.ExprListNode}
 */
proto.ContainerCtorNode.prototype.getExprList = function() {
  return /** @type{?proto.ExprListNode} */ (
    jspb.Message.getWrapperField(this, proto.ExprListNode, 3));
};


/**
 * @param {?proto.ExprListNode|undefined} value
 * @return {!proto.ContainerCtorNode} returns this
*/
proto.ContainerCtorNode.prototype.setExprList = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ContainerCtorNode} returns this
 */
proto.ContainerCtorNode.prototype.clearExprList = function() {
  return this.setExprList(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ContainerCtorNode.prototype.hasExprList = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.MapElementNode.prototype.toObject = function(opt_includeInstance) {
  return proto.MapElementNode.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.MapElementNode} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.MapElementNode.toObject = function(includeInstance, msg) {
  var f, obj = {
    keyExpr: (f = msg.getKeyExpr()) && proto.ExprNode.toObject(includeInstance, f),
    valueExpr: (f = msg.getValueExpr()) && proto.ExprNode.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.MapElementNode}
 */
proto.MapElementNode.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.MapElementNode;
  return proto.MapElementNode.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.MapElementNode} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.MapElementNode}
 */
proto.MapElementNode.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.ExprNode;
      reader.readMessage(value,proto.ExprNode.deserializeBinaryFromReader);
      msg.setKeyExpr(value);
      break;
    case 2:
      var value = new proto.ExprNode;
      reader.readMessage(value,proto.ExprNode.deserializeBinaryFromReader);
      msg.setValueExpr(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.MapElementNode.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.MapElementNode.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.MapElementNode} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.MapElementNode.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getKeyExpr();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.ExprNode.serializeBinaryToWriter
    );
  }
  f = message.getValueExpr();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.ExprNode.serializeBinaryToWriter
    );
  }
};


/**
 * optional ExprNode key_expr = 1;
 * @return {?proto.ExprNode}
 */
proto.MapElementNode.prototype.getKeyExpr = function() {
  return /** @type{?proto.ExprNode} */ (
    jspb.Message.getWrapperField(this, proto.ExprNode, 1));
};


/**
 * @param {?proto.ExprNode|undefined} value
 * @return {!proto.MapElementNode} returns this
*/
proto.MapElementNode.prototype.setKeyExpr = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.MapElementNode} returns this
 */
proto.MapElementNode.prototype.clearKeyExpr = function() {
  return this.setKeyExpr(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.MapElementNode.prototype.hasKeyExpr = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ExprNode value_expr = 2;
 * @return {?proto.ExprNode}
 */
proto.MapElementNode.prototype.getValueExpr = function() {
  return /** @type{?proto.ExprNode} */ (
    jspb.Message.getWrapperField(this, proto.ExprNode, 2));
};


/**
 * @param {?proto.ExprNode|undefined} value
 * @return {!proto.MapElementNode} returns this
*/
proto.MapElementNode.prototype.setValueExpr = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.MapElementNode} returns this
 */
proto.MapElementNode.prototype.clearValueExpr = function() {
  return this.setValueExpr(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.MapElementNode.prototype.hasValueExpr = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.MapElementsListNode.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.MapElementsListNode.prototype.toObject = function(opt_includeInstance) {
  return proto.MapElementsListNode.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.MapElementsListNode} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.MapElementsListNode.toObject = function(includeInstance, msg) {
  var f, obj = {
    mapElementsList: jspb.Message.toObjectList(msg.getMapElementsList(),
    proto.MapElementNode.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.MapElementsListNode}
 */
proto.MapElementsListNode.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.MapElementsListNode;
  return proto.MapElementsListNode.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.MapElementsListNode} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.MapElementsListNode}
 */
proto.MapElementsListNode.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.MapElementNode;
      reader.readMessage(value,proto.MapElementNode.deserializeBinaryFromReader);
      msg.addMapElements(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.MapElementsListNode.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.MapElementsListNode.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.MapElementsListNode} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.MapElementsListNode.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMapElementsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.MapElementNode.serializeBinaryToWriter
    );
  }
};


/**
 * repeated MapElementNode map_elements = 1;
 * @return {!Array<!proto.MapElementNode>}
 */
proto.MapElementsListNode.prototype.getMapElementsList = function() {
  return /** @type{!Array<!proto.MapElementNode>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.MapElementNode, 1));
};


/**
 * @param {!Array<!proto.MapElementNode>} value
 * @return {!proto.MapElementsListNode} returns this
*/
proto.MapElementsListNode.prototype.setMapElementsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.MapElementNode=} opt_value
 * @param {number=} opt_index
 * @return {!proto.MapElementNode}
 */
proto.MapElementsListNode.prototype.addMapElements = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.MapElementNode, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.MapElementsListNode} returns this
 */
proto.MapElementsListNode.prototype.clearMapElementsList = function() {
  return this.setMapElementsList([]);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.MapCtorNode.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.MapCtorNode.TypeCase = {
  TYPE_NOT_SET: 0,
  MAP_TYPE: 1,
  ALIAS: 2
};

/**
 * @return {proto.MapCtorNode.TypeCase}
 */
proto.MapCtorNode.prototype.getTypeCase = function() {
  return /** @type {proto.MapCtorNode.TypeCase} */(jspb.Message.computeOneofCase(this, proto.MapCtorNode.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.MapCtorNode.prototype.toObject = function(opt_includeInstance) {
  return proto.MapCtorNode.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.MapCtorNode} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.MapCtorNode.toObject = function(includeInstance, msg) {
  var f, obj = {
    mapType: (f = msg.getMapType()) && proto.MapTypeNode.toObject(includeInstance, f),
    alias: (f = msg.getAlias()) && proto.NameNode.toObject(includeInstance, f),
    mapElementsList: (f = msg.getMapElementsList()) && proto.MapElementsListNode.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.MapCtorNode}
 */
proto.MapCtorNode.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.MapCtorNode;
  return proto.MapCtorNode.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.MapCtorNode} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.MapCtorNode}
 */
proto.MapCtorNode.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.MapTypeNode;
      reader.readMessage(value,proto.MapTypeNode.deserializeBinaryFromReader);
      msg.setMapType(value);
      break;
    case 2:
      var value = new proto.NameNode;
      reader.readMessage(value,proto.NameNode.deserializeBinaryFromReader);
      msg.setAlias(value);
      break;
    case 3:
      var value = new proto.MapElementsListNode;
      reader.readMessage(value,proto.MapElementsListNode.deserializeBinaryFromReader);
      msg.setMapElementsList(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.MapCtorNode.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.MapCtorNode.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.MapCtorNode} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.MapCtorNode.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMapType();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.MapTypeNode.serializeBinaryToWriter
    );
  }
  f = message.getAlias();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.NameNode.serializeBinaryToWriter
    );
  }
  f = message.getMapElementsList();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.MapElementsListNode.serializeBinaryToWriter
    );
  }
};


/**
 * optional MapTypeNode map_type = 1;
 * @return {?proto.MapTypeNode}
 */
proto.MapCtorNode.prototype.getMapType = function() {
  return /** @type{?proto.MapTypeNode} */ (
    jspb.Message.getWrapperField(this, proto.MapTypeNode, 1));
};


/**
 * @param {?proto.MapTypeNode|undefined} value
 * @return {!proto.MapCtorNode} returns this
*/
proto.MapCtorNode.prototype.setMapType = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.MapCtorNode.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.MapCtorNode} returns this
 */
proto.MapCtorNode.prototype.clearMapType = function() {
  return this.setMapType(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.MapCtorNode.prototype.hasMapType = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional NameNode alias = 2;
 * @return {?proto.NameNode}
 */
proto.MapCtorNode.prototype.getAlias = function() {
  return /** @type{?proto.NameNode} */ (
    jspb.Message.getWrapperField(this, proto.NameNode, 2));
};


/**
 * @param {?proto.NameNode|undefined} value
 * @return {!proto.MapCtorNode} returns this
*/
proto.MapCtorNode.prototype.setAlias = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.MapCtorNode.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.MapCtorNode} returns this
 */
proto.MapCtorNode.prototype.clearAlias = function() {
  return this.setAlias(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.MapCtorNode.prototype.hasAlias = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional MapElementsListNode map_elements_list = 3;
 * @return {?proto.MapElementsListNode}
 */
proto.MapCtorNode.prototype.getMapElementsList = function() {
  return /** @type{?proto.MapElementsListNode} */ (
    jspb.Message.getWrapperField(this, proto.MapElementsListNode, 3));
};


/**
 * @param {?proto.MapElementsListNode|undefined} value
 * @return {!proto.MapCtorNode} returns this
*/
proto.MapCtorNode.prototype.setMapElementsList = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.MapCtorNode} returns this
 */
proto.MapCtorNode.prototype.clearMapElementsList = function() {
  return this.setMapElementsList(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.MapCtorNode.prototype.hasMapElementsList = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.IndexedAccessNode.prototype.toObject = function(opt_includeInstance) {
  return proto.IndexedAccessNode.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.IndexedAccessNode} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.IndexedAccessNode.toObject = function(includeInstance, msg) {
  var f, obj = {
    containerExpr: (f = msg.getContainerExpr()) && proto.ExprNode.toObject(includeInstance, f),
    indices: (f = msg.getIndices()) && proto.ExprListNode.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.IndexedAccessNode}
 */
proto.IndexedAccessNode.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.IndexedAccessNode;
  return proto.IndexedAccessNode.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.IndexedAccessNode} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.IndexedAccessNode}
 */
proto.IndexedAccessNode.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.ExprNode;
      reader.readMessage(value,proto.ExprNode.deserializeBinaryFromReader);
      msg.setContainerExpr(value);
      break;
    case 2:
      var value = new proto.ExprListNode;
      reader.readMessage(value,proto.ExprListNode.deserializeBinaryFromReader);
      msg.setIndices(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.IndexedAccessNode.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.IndexedAccessNode.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.IndexedAccessNode} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.IndexedAccessNode.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getContainerExpr();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.ExprNode.serializeBinaryToWriter
    );
  }
  f = message.getIndices();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.ExprListNode.serializeBinaryToWriter
    );
  }
};


/**
 * optional ExprNode container_expr = 1;
 * @return {?proto.ExprNode}
 */
proto.IndexedAccessNode.prototype.getContainerExpr = function() {
  return /** @type{?proto.ExprNode} */ (
    jspb.Message.getWrapperField(this, proto.ExprNode, 1));
};


/**
 * @param {?proto.ExprNode|undefined} value
 * @return {!proto.IndexedAccessNode} returns this
*/
proto.IndexedAccessNode.prototype.setContainerExpr = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.IndexedAccessNode} returns this
 */
proto.IndexedAccessNode.prototype.clearContainerExpr = function() {
  return this.setContainerExpr(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.IndexedAccessNode.prototype.hasContainerExpr = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ExprListNode indices = 2;
 * @return {?proto.ExprListNode}
 */
proto.IndexedAccessNode.prototype.getIndices = function() {
  return /** @type{?proto.ExprListNode} */ (
    jspb.Message.getWrapperField(this, proto.ExprListNode, 2));
};


/**
 * @param {?proto.ExprListNode|undefined} value
 * @return {!proto.IndexedAccessNode} returns this
*/
proto.IndexedAccessNode.prototype.setIndices = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.IndexedAccessNode} returns this
 */
proto.IndexedAccessNode.prototype.clearIndices = function() {
  return this.setIndices(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.IndexedAccessNode.prototype.hasIndices = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.FieldAccessNode.prototype.toObject = function(opt_includeInstance) {
  return proto.FieldAccessNode.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.FieldAccessNode} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.FieldAccessNode.toObject = function(includeInstance, msg) {
  var f, obj = {
    structExpr: (f = msg.getStructExpr()) && proto.ExprNode.toObject(includeInstance, f),
    fieldName: (f = msg.getFieldName()) && proto.NameNode.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.FieldAccessNode}
 */
proto.FieldAccessNode.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.FieldAccessNode;
  return proto.FieldAccessNode.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.FieldAccessNode} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.FieldAccessNode}
 */
proto.FieldAccessNode.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.ExprNode;
      reader.readMessage(value,proto.ExprNode.deserializeBinaryFromReader);
      msg.setStructExpr(value);
      break;
    case 2:
      var value = new proto.NameNode;
      reader.readMessage(value,proto.NameNode.deserializeBinaryFromReader);
      msg.setFieldName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.FieldAccessNode.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.FieldAccessNode.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.FieldAccessNode} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.FieldAccessNode.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStructExpr();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.ExprNode.serializeBinaryToWriter
    );
  }
  f = message.getFieldName();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.NameNode.serializeBinaryToWriter
    );
  }
};


/**
 * optional ExprNode struct_expr = 1;
 * @return {?proto.ExprNode}
 */
proto.FieldAccessNode.prototype.getStructExpr = function() {
  return /** @type{?proto.ExprNode} */ (
    jspb.Message.getWrapperField(this, proto.ExprNode, 1));
};


/**
 * @param {?proto.ExprNode|undefined} value
 * @return {!proto.FieldAccessNode} returns this
*/
proto.FieldAccessNode.prototype.setStructExpr = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.FieldAccessNode} returns this
 */
proto.FieldAccessNode.prototype.clearStructExpr = function() {
  return this.setStructExpr(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.FieldAccessNode.prototype.hasStructExpr = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional NameNode field_name = 2;
 * @return {?proto.NameNode}
 */
proto.FieldAccessNode.prototype.getFieldName = function() {
  return /** @type{?proto.NameNode} */ (
    jspb.Message.getWrapperField(this, proto.NameNode, 2));
};


/**
 * @param {?proto.NameNode|undefined} value
 * @return {!proto.FieldAccessNode} returns this
*/
proto.FieldAccessNode.prototype.setFieldName = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.FieldAccessNode} returns this
 */
proto.FieldAccessNode.prototype.clearFieldName = function() {
  return this.setFieldName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.FieldAccessNode.prototype.hasFieldName = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.FuncCallNode.prototype.toObject = function(opt_includeInstance) {
  return proto.FuncCallNode.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.FuncCallNode} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.FuncCallNode.toObject = function(includeInstance, msg) {
  var f, obj = {
    funcExpr: (f = msg.getFuncExpr()) && proto.ExprNode.toObject(includeInstance, f),
    arguments: (f = msg.getArguments()) && proto.NameExprListNode.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.FuncCallNode}
 */
proto.FuncCallNode.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.FuncCallNode;
  return proto.FuncCallNode.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.FuncCallNode} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.FuncCallNode}
 */
proto.FuncCallNode.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.ExprNode;
      reader.readMessage(value,proto.ExprNode.deserializeBinaryFromReader);
      msg.setFuncExpr(value);
      break;
    case 2:
      var value = new proto.NameExprListNode;
      reader.readMessage(value,proto.NameExprListNode.deserializeBinaryFromReader);
      msg.setArguments(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.FuncCallNode.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.FuncCallNode.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.FuncCallNode} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.FuncCallNode.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFuncExpr();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.ExprNode.serializeBinaryToWriter
    );
  }
  f = message.getArguments();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.NameExprListNode.serializeBinaryToWriter
    );
  }
};


/**
 * optional ExprNode func_expr = 1;
 * @return {?proto.ExprNode}
 */
proto.FuncCallNode.prototype.getFuncExpr = function() {
  return /** @type{?proto.ExprNode} */ (
    jspb.Message.getWrapperField(this, proto.ExprNode, 1));
};


/**
 * @param {?proto.ExprNode|undefined} value
 * @return {!proto.FuncCallNode} returns this
*/
proto.FuncCallNode.prototype.setFuncExpr = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.FuncCallNode} returns this
 */
proto.FuncCallNode.prototype.clearFuncExpr = function() {
  return this.setFuncExpr(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.FuncCallNode.prototype.hasFuncExpr = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional NameExprListNode arguments = 2;
 * @return {?proto.NameExprListNode}
 */
proto.FuncCallNode.prototype.getArguments = function() {
  return /** @type{?proto.NameExprListNode} */ (
    jspb.Message.getWrapperField(this, proto.NameExprListNode, 2));
};


/**
 * @param {?proto.NameExprListNode|undefined} value
 * @return {!proto.FuncCallNode} returns this
*/
proto.FuncCallNode.prototype.setArguments = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.FuncCallNode} returns this
 */
proto.FuncCallNode.prototype.clearArguments = function() {
  return this.setArguments(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.FuncCallNode.prototype.hasArguments = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.ExprNode.oneofGroups_ = [[3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20]];

/**
 * @enum {number}
 */
proto.ExprNode.ExprCase = {
  EXPR_NOT_SET: 0,
  TYPE: 3,
  INT_LITERAL: 4,
  NUM_LITERAL: 5,
  CHAR_LITERAL: 6,
  STRING_LITERAL: 7,
  BITS_LITERAL: 8,
  TUPLE_CTOR: 9,
  ARRAY_CTOR: 10,
  STRUCT_CTOR: 11,
  CONTAINER_CTOR: 12,
  MAP_CTOR: 13,
  NAME: 14,
  INDEXED_ACCESS: 15,
  FIELD_ACCESS: 16,
  STD_FUNC_CALL: 17,
  FUNC_CALL: 18,
  UNARY_EXPR: 19,
  BINARY_EXPR: 20
};

/**
 * @return {proto.ExprNode.ExprCase}
 */
proto.ExprNode.prototype.getExprCase = function() {
  return /** @type {proto.ExprNode.ExprCase} */(jspb.Message.computeOneofCase(this, proto.ExprNode.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ExprNode.prototype.toObject = function(opt_includeInstance) {
  return proto.ExprNode.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ExprNode} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ExprNode.toObject = function(includeInstance, msg) {
  var f, obj = {
    stepId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    kind: jspb.Message.getFieldWithDefault(msg, 2, 0),
    type: (f = msg.getType()) && proto.TypeNode.toObject(includeInstance, f),
    intLiteral: (f = msg.getIntLiteral()) && proto.IntLiteralNode.toObject(includeInstance, f),
    numLiteral: (f = msg.getNumLiteral()) && proto.NumLiteralNode.toObject(includeInstance, f),
    charLiteral: (f = msg.getCharLiteral()) && proto.CharLiteralNode.toObject(includeInstance, f),
    stringLiteral: (f = msg.getStringLiteral()) && proto.StringLiteralNode.toObject(includeInstance, f),
    bitsLiteral: (f = msg.getBitsLiteral()) && proto.BitsLiteralNode.toObject(includeInstance, f),
    tupleCtor: (f = msg.getTupleCtor()) && proto.TupleCtorNode.toObject(includeInstance, f),
    arrayCtor: (f = msg.getArrayCtor()) && proto.ArrayCtorNode.toObject(includeInstance, f),
    structCtor: (f = msg.getStructCtor()) && proto.StructCtorNode.toObject(includeInstance, f),
    containerCtor: (f = msg.getContainerCtor()) && proto.ContainerCtorNode.toObject(includeInstance, f),
    mapCtor: (f = msg.getMapCtor()) && proto.MapCtorNode.toObject(includeInstance, f),
    name: (f = msg.getName()) && proto.NameNode.toObject(includeInstance, f),
    indexedAccess: (f = msg.getIndexedAccess()) && proto.IndexedAccessNode.toObject(includeInstance, f),
    fieldAccess: (f = msg.getFieldAccess()) && proto.FieldAccessNode.toObject(includeInstance, f),
    stdFuncCall: (f = msg.getStdFuncCall()) && proto.StdFuncCallNode.toObject(includeInstance, f),
    funcCall: (f = msg.getFuncCall()) && proto.FuncCallNode.toObject(includeInstance, f),
    unaryExpr: (f = msg.getUnaryExpr()) && proto.ExprNode.toObject(includeInstance, f),
    binaryExpr: (f = msg.getBinaryExpr()) && proto.BinaryExprNode.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ExprNode}
 */
proto.ExprNode.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ExprNode;
  return proto.ExprNode.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ExprNode} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ExprNode}
 */
proto.ExprNode.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setStepId(value);
      break;
    case 2:
      var value = /** @type {!proto.ExprNodeKind} */ (reader.readEnum());
      msg.setKind(value);
      break;
    case 3:
      var value = new proto.TypeNode;
      reader.readMessage(value,proto.TypeNode.deserializeBinaryFromReader);
      msg.setType(value);
      break;
    case 4:
      var value = new proto.IntLiteralNode;
      reader.readMessage(value,proto.IntLiteralNode.deserializeBinaryFromReader);
      msg.setIntLiteral(value);
      break;
    case 5:
      var value = new proto.NumLiteralNode;
      reader.readMessage(value,proto.NumLiteralNode.deserializeBinaryFromReader);
      msg.setNumLiteral(value);
      break;
    case 6:
      var value = new proto.CharLiteralNode;
      reader.readMessage(value,proto.CharLiteralNode.deserializeBinaryFromReader);
      msg.setCharLiteral(value);
      break;
    case 7:
      var value = new proto.StringLiteralNode;
      reader.readMessage(value,proto.StringLiteralNode.deserializeBinaryFromReader);
      msg.setStringLiteral(value);
      break;
    case 8:
      var value = new proto.BitsLiteralNode;
      reader.readMessage(value,proto.BitsLiteralNode.deserializeBinaryFromReader);
      msg.setBitsLiteral(value);
      break;
    case 9:
      var value = new proto.TupleCtorNode;
      reader.readMessage(value,proto.TupleCtorNode.deserializeBinaryFromReader);
      msg.setTupleCtor(value);
      break;
    case 10:
      var value = new proto.ArrayCtorNode;
      reader.readMessage(value,proto.ArrayCtorNode.deserializeBinaryFromReader);
      msg.setArrayCtor(value);
      break;
    case 11:
      var value = new proto.StructCtorNode;
      reader.readMessage(value,proto.StructCtorNode.deserializeBinaryFromReader);
      msg.setStructCtor(value);
      break;
    case 12:
      var value = new proto.ContainerCtorNode;
      reader.readMessage(value,proto.ContainerCtorNode.deserializeBinaryFromReader);
      msg.setContainerCtor(value);
      break;
    case 13:
      var value = new proto.MapCtorNode;
      reader.readMessage(value,proto.MapCtorNode.deserializeBinaryFromReader);
      msg.setMapCtor(value);
      break;
    case 14:
      var value = new proto.NameNode;
      reader.readMessage(value,proto.NameNode.deserializeBinaryFromReader);
      msg.setName(value);
      break;
    case 15:
      var value = new proto.IndexedAccessNode;
      reader.readMessage(value,proto.IndexedAccessNode.deserializeBinaryFromReader);
      msg.setIndexedAccess(value);
      break;
    case 16:
      var value = new proto.FieldAccessNode;
      reader.readMessage(value,proto.FieldAccessNode.deserializeBinaryFromReader);
      msg.setFieldAccess(value);
      break;
    case 17:
      var value = new proto.StdFuncCallNode;
      reader.readMessage(value,proto.StdFuncCallNode.deserializeBinaryFromReader);
      msg.setStdFuncCall(value);
      break;
    case 18:
      var value = new proto.FuncCallNode;
      reader.readMessage(value,proto.FuncCallNode.deserializeBinaryFromReader);
      msg.setFuncCall(value);
      break;
    case 19:
      var value = new proto.ExprNode;
      reader.readMessage(value,proto.ExprNode.deserializeBinaryFromReader);
      msg.setUnaryExpr(value);
      break;
    case 20:
      var value = new proto.BinaryExprNode;
      reader.readMessage(value,proto.BinaryExprNode.deserializeBinaryFromReader);
      msg.setBinaryExpr(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ExprNode.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ExprNode.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ExprNode} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ExprNode.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStepId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getKind();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getType();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.TypeNode.serializeBinaryToWriter
    );
  }
  f = message.getIntLiteral();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.IntLiteralNode.serializeBinaryToWriter
    );
  }
  f = message.getNumLiteral();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.NumLiteralNode.serializeBinaryToWriter
    );
  }
  f = message.getCharLiteral();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.CharLiteralNode.serializeBinaryToWriter
    );
  }
  f = message.getStringLiteral();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.StringLiteralNode.serializeBinaryToWriter
    );
  }
  f = message.getBitsLiteral();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.BitsLiteralNode.serializeBinaryToWriter
    );
  }
  f = message.getTupleCtor();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto.TupleCtorNode.serializeBinaryToWriter
    );
  }
  f = message.getArrayCtor();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      proto.ArrayCtorNode.serializeBinaryToWriter
    );
  }
  f = message.getStructCtor();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      proto.StructCtorNode.serializeBinaryToWriter
    );
  }
  f = message.getContainerCtor();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      proto.ContainerCtorNode.serializeBinaryToWriter
    );
  }
  f = message.getMapCtor();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      proto.MapCtorNode.serializeBinaryToWriter
    );
  }
  f = message.getName();
  if (f != null) {
    writer.writeMessage(
      14,
      f,
      proto.NameNode.serializeBinaryToWriter
    );
  }
  f = message.getIndexedAccess();
  if (f != null) {
    writer.writeMessage(
      15,
      f,
      proto.IndexedAccessNode.serializeBinaryToWriter
    );
  }
  f = message.getFieldAccess();
  if (f != null) {
    writer.writeMessage(
      16,
      f,
      proto.FieldAccessNode.serializeBinaryToWriter
    );
  }
  f = message.getStdFuncCall();
  if (f != null) {
    writer.writeMessage(
      17,
      f,
      proto.StdFuncCallNode.serializeBinaryToWriter
    );
  }
  f = message.getFuncCall();
  if (f != null) {
    writer.writeMessage(
      18,
      f,
      proto.FuncCallNode.serializeBinaryToWriter
    );
  }
  f = message.getUnaryExpr();
  if (f != null) {
    writer.writeMessage(
      19,
      f,
      proto.ExprNode.serializeBinaryToWriter
    );
  }
  f = message.getBinaryExpr();
  if (f != null) {
    writer.writeMessage(
      20,
      f,
      proto.BinaryExprNode.serializeBinaryToWriter
    );
  }
};


/**
 * optional string step_id = 1;
 * @return {string}
 */
proto.ExprNode.prototype.getStepId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.ExprNode} returns this
 */
proto.ExprNode.prototype.setStepId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional ExprNodeKind kind = 2;
 * @return {!proto.ExprNodeKind}
 */
proto.ExprNode.prototype.getKind = function() {
  return /** @type {!proto.ExprNodeKind} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.ExprNodeKind} value
 * @return {!proto.ExprNode} returns this
 */
proto.ExprNode.prototype.setKind = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional TypeNode type = 3;
 * @return {?proto.TypeNode}
 */
proto.ExprNode.prototype.getType = function() {
  return /** @type{?proto.TypeNode} */ (
    jspb.Message.getWrapperField(this, proto.TypeNode, 3));
};


/**
 * @param {?proto.TypeNode|undefined} value
 * @return {!proto.ExprNode} returns this
*/
proto.ExprNode.prototype.setType = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.ExprNode.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ExprNode} returns this
 */
proto.ExprNode.prototype.clearType = function() {
  return this.setType(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ExprNode.prototype.hasType = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional IntLiteralNode int_literal = 4;
 * @return {?proto.IntLiteralNode}
 */
proto.ExprNode.prototype.getIntLiteral = function() {
  return /** @type{?proto.IntLiteralNode} */ (
    jspb.Message.getWrapperField(this, proto.IntLiteralNode, 4));
};


/**
 * @param {?proto.IntLiteralNode|undefined} value
 * @return {!proto.ExprNode} returns this
*/
proto.ExprNode.prototype.setIntLiteral = function(value) {
  return jspb.Message.setOneofWrapperField(this, 4, proto.ExprNode.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ExprNode} returns this
 */
proto.ExprNode.prototype.clearIntLiteral = function() {
  return this.setIntLiteral(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ExprNode.prototype.hasIntLiteral = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional NumLiteralNode num_literal = 5;
 * @return {?proto.NumLiteralNode}
 */
proto.ExprNode.prototype.getNumLiteral = function() {
  return /** @type{?proto.NumLiteralNode} */ (
    jspb.Message.getWrapperField(this, proto.NumLiteralNode, 5));
};


/**
 * @param {?proto.NumLiteralNode|undefined} value
 * @return {!proto.ExprNode} returns this
*/
proto.ExprNode.prototype.setNumLiteral = function(value) {
  return jspb.Message.setOneofWrapperField(this, 5, proto.ExprNode.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ExprNode} returns this
 */
proto.ExprNode.prototype.clearNumLiteral = function() {
  return this.setNumLiteral(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ExprNode.prototype.hasNumLiteral = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional CharLiteralNode char_literal = 6;
 * @return {?proto.CharLiteralNode}
 */
proto.ExprNode.prototype.getCharLiteral = function() {
  return /** @type{?proto.CharLiteralNode} */ (
    jspb.Message.getWrapperField(this, proto.CharLiteralNode, 6));
};


/**
 * @param {?proto.CharLiteralNode|undefined} value
 * @return {!proto.ExprNode} returns this
*/
proto.ExprNode.prototype.setCharLiteral = function(value) {
  return jspb.Message.setOneofWrapperField(this, 6, proto.ExprNode.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ExprNode} returns this
 */
proto.ExprNode.prototype.clearCharLiteral = function() {
  return this.setCharLiteral(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ExprNode.prototype.hasCharLiteral = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional StringLiteralNode string_literal = 7;
 * @return {?proto.StringLiteralNode}
 */
proto.ExprNode.prototype.getStringLiteral = function() {
  return /** @type{?proto.StringLiteralNode} */ (
    jspb.Message.getWrapperField(this, proto.StringLiteralNode, 7));
};


/**
 * @param {?proto.StringLiteralNode|undefined} value
 * @return {!proto.ExprNode} returns this
*/
proto.ExprNode.prototype.setStringLiteral = function(value) {
  return jspb.Message.setOneofWrapperField(this, 7, proto.ExprNode.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ExprNode} returns this
 */
proto.ExprNode.prototype.clearStringLiteral = function() {
  return this.setStringLiteral(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ExprNode.prototype.hasStringLiteral = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional BitsLiteralNode bits_literal = 8;
 * @return {?proto.BitsLiteralNode}
 */
proto.ExprNode.prototype.getBitsLiteral = function() {
  return /** @type{?proto.BitsLiteralNode} */ (
    jspb.Message.getWrapperField(this, proto.BitsLiteralNode, 8));
};


/**
 * @param {?proto.BitsLiteralNode|undefined} value
 * @return {!proto.ExprNode} returns this
*/
proto.ExprNode.prototype.setBitsLiteral = function(value) {
  return jspb.Message.setOneofWrapperField(this, 8, proto.ExprNode.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ExprNode} returns this
 */
proto.ExprNode.prototype.clearBitsLiteral = function() {
  return this.setBitsLiteral(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ExprNode.prototype.hasBitsLiteral = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional TupleCtorNode tuple_ctor = 9;
 * @return {?proto.TupleCtorNode}
 */
proto.ExprNode.prototype.getTupleCtor = function() {
  return /** @type{?proto.TupleCtorNode} */ (
    jspb.Message.getWrapperField(this, proto.TupleCtorNode, 9));
};


/**
 * @param {?proto.TupleCtorNode|undefined} value
 * @return {!proto.ExprNode} returns this
*/
proto.ExprNode.prototype.setTupleCtor = function(value) {
  return jspb.Message.setOneofWrapperField(this, 9, proto.ExprNode.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ExprNode} returns this
 */
proto.ExprNode.prototype.clearTupleCtor = function() {
  return this.setTupleCtor(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ExprNode.prototype.hasTupleCtor = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional ArrayCtorNode array_ctor = 10;
 * @return {?proto.ArrayCtorNode}
 */
proto.ExprNode.prototype.getArrayCtor = function() {
  return /** @type{?proto.ArrayCtorNode} */ (
    jspb.Message.getWrapperField(this, proto.ArrayCtorNode, 10));
};


/**
 * @param {?proto.ArrayCtorNode|undefined} value
 * @return {!proto.ExprNode} returns this
*/
proto.ExprNode.prototype.setArrayCtor = function(value) {
  return jspb.Message.setOneofWrapperField(this, 10, proto.ExprNode.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ExprNode} returns this
 */
proto.ExprNode.prototype.clearArrayCtor = function() {
  return this.setArrayCtor(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ExprNode.prototype.hasArrayCtor = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional StructCtorNode struct_ctor = 11;
 * @return {?proto.StructCtorNode}
 */
proto.ExprNode.prototype.getStructCtor = function() {
  return /** @type{?proto.StructCtorNode} */ (
    jspb.Message.getWrapperField(this, proto.StructCtorNode, 11));
};


/**
 * @param {?proto.StructCtorNode|undefined} value
 * @return {!proto.ExprNode} returns this
*/
proto.ExprNode.prototype.setStructCtor = function(value) {
  return jspb.Message.setOneofWrapperField(this, 11, proto.ExprNode.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ExprNode} returns this
 */
proto.ExprNode.prototype.clearStructCtor = function() {
  return this.setStructCtor(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ExprNode.prototype.hasStructCtor = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional ContainerCtorNode container_ctor = 12;
 * @return {?proto.ContainerCtorNode}
 */
proto.ExprNode.prototype.getContainerCtor = function() {
  return /** @type{?proto.ContainerCtorNode} */ (
    jspb.Message.getWrapperField(this, proto.ContainerCtorNode, 12));
};


/**
 * @param {?proto.ContainerCtorNode|undefined} value
 * @return {!proto.ExprNode} returns this
*/
proto.ExprNode.prototype.setContainerCtor = function(value) {
  return jspb.Message.setOneofWrapperField(this, 12, proto.ExprNode.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ExprNode} returns this
 */
proto.ExprNode.prototype.clearContainerCtor = function() {
  return this.setContainerCtor(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ExprNode.prototype.hasContainerCtor = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional MapCtorNode map_ctor = 13;
 * @return {?proto.MapCtorNode}
 */
proto.ExprNode.prototype.getMapCtor = function() {
  return /** @type{?proto.MapCtorNode} */ (
    jspb.Message.getWrapperField(this, proto.MapCtorNode, 13));
};


/**
 * @param {?proto.MapCtorNode|undefined} value
 * @return {!proto.ExprNode} returns this
*/
proto.ExprNode.prototype.setMapCtor = function(value) {
  return jspb.Message.setOneofWrapperField(this, 13, proto.ExprNode.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ExprNode} returns this
 */
proto.ExprNode.prototype.clearMapCtor = function() {
  return this.setMapCtor(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ExprNode.prototype.hasMapCtor = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional NameNode name = 14;
 * @return {?proto.NameNode}
 */
proto.ExprNode.prototype.getName = function() {
  return /** @type{?proto.NameNode} */ (
    jspb.Message.getWrapperField(this, proto.NameNode, 14));
};


/**
 * @param {?proto.NameNode|undefined} value
 * @return {!proto.ExprNode} returns this
*/
proto.ExprNode.prototype.setName = function(value) {
  return jspb.Message.setOneofWrapperField(this, 14, proto.ExprNode.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ExprNode} returns this
 */
proto.ExprNode.prototype.clearName = function() {
  return this.setName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ExprNode.prototype.hasName = function() {
  return jspb.Message.getField(this, 14) != null;
};


/**
 * optional IndexedAccessNode indexed_access = 15;
 * @return {?proto.IndexedAccessNode}
 */
proto.ExprNode.prototype.getIndexedAccess = function() {
  return /** @type{?proto.IndexedAccessNode} */ (
    jspb.Message.getWrapperField(this, proto.IndexedAccessNode, 15));
};


/**
 * @param {?proto.IndexedAccessNode|undefined} value
 * @return {!proto.ExprNode} returns this
*/
proto.ExprNode.prototype.setIndexedAccess = function(value) {
  return jspb.Message.setOneofWrapperField(this, 15, proto.ExprNode.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ExprNode} returns this
 */
proto.ExprNode.prototype.clearIndexedAccess = function() {
  return this.setIndexedAccess(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ExprNode.prototype.hasIndexedAccess = function() {
  return jspb.Message.getField(this, 15) != null;
};


/**
 * optional FieldAccessNode field_access = 16;
 * @return {?proto.FieldAccessNode}
 */
proto.ExprNode.prototype.getFieldAccess = function() {
  return /** @type{?proto.FieldAccessNode} */ (
    jspb.Message.getWrapperField(this, proto.FieldAccessNode, 16));
};


/**
 * @param {?proto.FieldAccessNode|undefined} value
 * @return {!proto.ExprNode} returns this
*/
proto.ExprNode.prototype.setFieldAccess = function(value) {
  return jspb.Message.setOneofWrapperField(this, 16, proto.ExprNode.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ExprNode} returns this
 */
proto.ExprNode.prototype.clearFieldAccess = function() {
  return this.setFieldAccess(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ExprNode.prototype.hasFieldAccess = function() {
  return jspb.Message.getField(this, 16) != null;
};


/**
 * optional StdFuncCallNode std_func_call = 17;
 * @return {?proto.StdFuncCallNode}
 */
proto.ExprNode.prototype.getStdFuncCall = function() {
  return /** @type{?proto.StdFuncCallNode} */ (
    jspb.Message.getWrapperField(this, proto.StdFuncCallNode, 17));
};


/**
 * @param {?proto.StdFuncCallNode|undefined} value
 * @return {!proto.ExprNode} returns this
*/
proto.ExprNode.prototype.setStdFuncCall = function(value) {
  return jspb.Message.setOneofWrapperField(this, 17, proto.ExprNode.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ExprNode} returns this
 */
proto.ExprNode.prototype.clearStdFuncCall = function() {
  return this.setStdFuncCall(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ExprNode.prototype.hasStdFuncCall = function() {
  return jspb.Message.getField(this, 17) != null;
};


/**
 * optional FuncCallNode func_call = 18;
 * @return {?proto.FuncCallNode}
 */
proto.ExprNode.prototype.getFuncCall = function() {
  return /** @type{?proto.FuncCallNode} */ (
    jspb.Message.getWrapperField(this, proto.FuncCallNode, 18));
};


/**
 * @param {?proto.FuncCallNode|undefined} value
 * @return {!proto.ExprNode} returns this
*/
proto.ExprNode.prototype.setFuncCall = function(value) {
  return jspb.Message.setOneofWrapperField(this, 18, proto.ExprNode.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ExprNode} returns this
 */
proto.ExprNode.prototype.clearFuncCall = function() {
  return this.setFuncCall(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ExprNode.prototype.hasFuncCall = function() {
  return jspb.Message.getField(this, 18) != null;
};


/**
 * optional ExprNode unary_expr = 19;
 * @return {?proto.ExprNode}
 */
proto.ExprNode.prototype.getUnaryExpr = function() {
  return /** @type{?proto.ExprNode} */ (
    jspb.Message.getWrapperField(this, proto.ExprNode, 19));
};


/**
 * @param {?proto.ExprNode|undefined} value
 * @return {!proto.ExprNode} returns this
*/
proto.ExprNode.prototype.setUnaryExpr = function(value) {
  return jspb.Message.setOneofWrapperField(this, 19, proto.ExprNode.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ExprNode} returns this
 */
proto.ExprNode.prototype.clearUnaryExpr = function() {
  return this.setUnaryExpr(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ExprNode.prototype.hasUnaryExpr = function() {
  return jspb.Message.getField(this, 19) != null;
};


/**
 * optional BinaryExprNode binary_expr = 20;
 * @return {?proto.BinaryExprNode}
 */
proto.ExprNode.prototype.getBinaryExpr = function() {
  return /** @type{?proto.BinaryExprNode} */ (
    jspb.Message.getWrapperField(this, proto.BinaryExprNode, 20));
};


/**
 * @param {?proto.BinaryExprNode|undefined} value
 * @return {!proto.ExprNode} returns this
*/
proto.ExprNode.prototype.setBinaryExpr = function(value) {
  return jspb.Message.setOneofWrapperField(this, 20, proto.ExprNode.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ExprNode} returns this
 */
proto.ExprNode.prototype.clearBinaryExpr = function() {
  return this.setBinaryExpr(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ExprNode.prototype.hasBinaryExpr = function() {
  return jspb.Message.getField(this, 20) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.LIndexedAccessNode.prototype.toObject = function(opt_includeInstance) {
  return proto.LIndexedAccessNode.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.LIndexedAccessNode} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.LIndexedAccessNode.toObject = function(includeInstance, msg) {
  var f, obj = {
    containerExpr: (f = msg.getContainerExpr()) && proto.LValueExprNode.toObject(includeInstance, f),
    indices: (f = msg.getIndices()) && proto.ExprListNode.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.LIndexedAccessNode}
 */
proto.LIndexedAccessNode.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.LIndexedAccessNode;
  return proto.LIndexedAccessNode.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.LIndexedAccessNode} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.LIndexedAccessNode}
 */
proto.LIndexedAccessNode.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.LValueExprNode;
      reader.readMessage(value,proto.LValueExprNode.deserializeBinaryFromReader);
      msg.setContainerExpr(value);
      break;
    case 2:
      var value = new proto.ExprListNode;
      reader.readMessage(value,proto.ExprListNode.deserializeBinaryFromReader);
      msg.setIndices(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.LIndexedAccessNode.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.LIndexedAccessNode.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.LIndexedAccessNode} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.LIndexedAccessNode.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getContainerExpr();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.LValueExprNode.serializeBinaryToWriter
    );
  }
  f = message.getIndices();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.ExprListNode.serializeBinaryToWriter
    );
  }
};


/**
 * optional LValueExprNode container_expr = 1;
 * @return {?proto.LValueExprNode}
 */
proto.LIndexedAccessNode.prototype.getContainerExpr = function() {
  return /** @type{?proto.LValueExprNode} */ (
    jspb.Message.getWrapperField(this, proto.LValueExprNode, 1));
};


/**
 * @param {?proto.LValueExprNode|undefined} value
 * @return {!proto.LIndexedAccessNode} returns this
*/
proto.LIndexedAccessNode.prototype.setContainerExpr = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.LIndexedAccessNode} returns this
 */
proto.LIndexedAccessNode.prototype.clearContainerExpr = function() {
  return this.setContainerExpr(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.LIndexedAccessNode.prototype.hasContainerExpr = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ExprListNode indices = 2;
 * @return {?proto.ExprListNode}
 */
proto.LIndexedAccessNode.prototype.getIndices = function() {
  return /** @type{?proto.ExprListNode} */ (
    jspb.Message.getWrapperField(this, proto.ExprListNode, 2));
};


/**
 * @param {?proto.ExprListNode|undefined} value
 * @return {!proto.LIndexedAccessNode} returns this
*/
proto.LIndexedAccessNode.prototype.setIndices = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.LIndexedAccessNode} returns this
 */
proto.LIndexedAccessNode.prototype.clearIndices = function() {
  return this.setIndices(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.LIndexedAccessNode.prototype.hasIndices = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.LFieldAccessNode.prototype.toObject = function(opt_includeInstance) {
  return proto.LFieldAccessNode.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.LFieldAccessNode} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.LFieldAccessNode.toObject = function(includeInstance, msg) {
  var f, obj = {
    structExpr: (f = msg.getStructExpr()) && proto.LValueExprNode.toObject(includeInstance, f),
    fieldName: (f = msg.getFieldName()) && proto.NameNode.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.LFieldAccessNode}
 */
proto.LFieldAccessNode.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.LFieldAccessNode;
  return proto.LFieldAccessNode.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.LFieldAccessNode} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.LFieldAccessNode}
 */
proto.LFieldAccessNode.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.LValueExprNode;
      reader.readMessage(value,proto.LValueExprNode.deserializeBinaryFromReader);
      msg.setStructExpr(value);
      break;
    case 2:
      var value = new proto.NameNode;
      reader.readMessage(value,proto.NameNode.deserializeBinaryFromReader);
      msg.setFieldName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.LFieldAccessNode.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.LFieldAccessNode.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.LFieldAccessNode} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.LFieldAccessNode.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStructExpr();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.LValueExprNode.serializeBinaryToWriter
    );
  }
  f = message.getFieldName();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.NameNode.serializeBinaryToWriter
    );
  }
};


/**
 * optional LValueExprNode struct_expr = 1;
 * @return {?proto.LValueExprNode}
 */
proto.LFieldAccessNode.prototype.getStructExpr = function() {
  return /** @type{?proto.LValueExprNode} */ (
    jspb.Message.getWrapperField(this, proto.LValueExprNode, 1));
};


/**
 * @param {?proto.LValueExprNode|undefined} value
 * @return {!proto.LFieldAccessNode} returns this
*/
proto.LFieldAccessNode.prototype.setStructExpr = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.LFieldAccessNode} returns this
 */
proto.LFieldAccessNode.prototype.clearStructExpr = function() {
  return this.setStructExpr(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.LFieldAccessNode.prototype.hasStructExpr = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional NameNode field_name = 2;
 * @return {?proto.NameNode}
 */
proto.LFieldAccessNode.prototype.getFieldName = function() {
  return /** @type{?proto.NameNode} */ (
    jspb.Message.getWrapperField(this, proto.NameNode, 2));
};


/**
 * @param {?proto.NameNode|undefined} value
 * @return {!proto.LFieldAccessNode} returns this
*/
proto.LFieldAccessNode.prototype.setFieldName = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.LFieldAccessNode} returns this
 */
proto.LFieldAccessNode.prototype.clearFieldName = function() {
  return this.setFieldName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.LFieldAccessNode.prototype.hasFieldName = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.LValueExprNode.oneofGroups_ = [[1,2,3]];

/**
 * @enum {number}
 */
proto.LValueExprNode.LvalueCase = {
  LVALUE_NOT_SET: 0,
  NAME: 1,
  INDEXED_ACCESS: 2,
  FIELD_ACCESS: 3
};

/**
 * @return {proto.LValueExprNode.LvalueCase}
 */
proto.LValueExprNode.prototype.getLvalueCase = function() {
  return /** @type {proto.LValueExprNode.LvalueCase} */(jspb.Message.computeOneofCase(this, proto.LValueExprNode.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.LValueExprNode.prototype.toObject = function(opt_includeInstance) {
  return proto.LValueExprNode.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.LValueExprNode} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.LValueExprNode.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: (f = msg.getName()) && proto.NameNode.toObject(includeInstance, f),
    indexedAccess: (f = msg.getIndexedAccess()) && proto.LIndexedAccessNode.toObject(includeInstance, f),
    fieldAccess: (f = msg.getFieldAccess()) && proto.LFieldAccessNode.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.LValueExprNode}
 */
proto.LValueExprNode.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.LValueExprNode;
  return proto.LValueExprNode.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.LValueExprNode} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.LValueExprNode}
 */
proto.LValueExprNode.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.NameNode;
      reader.readMessage(value,proto.NameNode.deserializeBinaryFromReader);
      msg.setName(value);
      break;
    case 2:
      var value = new proto.LIndexedAccessNode;
      reader.readMessage(value,proto.LIndexedAccessNode.deserializeBinaryFromReader);
      msg.setIndexedAccess(value);
      break;
    case 3:
      var value = new proto.LFieldAccessNode;
      reader.readMessage(value,proto.LFieldAccessNode.deserializeBinaryFromReader);
      msg.setFieldAccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.LValueExprNode.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.LValueExprNode.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.LValueExprNode} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.LValueExprNode.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.NameNode.serializeBinaryToWriter
    );
  }
  f = message.getIndexedAccess();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.LIndexedAccessNode.serializeBinaryToWriter
    );
  }
  f = message.getFieldAccess();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.LFieldAccessNode.serializeBinaryToWriter
    );
  }
};


/**
 * optional NameNode name = 1;
 * @return {?proto.NameNode}
 */
proto.LValueExprNode.prototype.getName = function() {
  return /** @type{?proto.NameNode} */ (
    jspb.Message.getWrapperField(this, proto.NameNode, 1));
};


/**
 * @param {?proto.NameNode|undefined} value
 * @return {!proto.LValueExprNode} returns this
*/
proto.LValueExprNode.prototype.setName = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.LValueExprNode.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.LValueExprNode} returns this
 */
proto.LValueExprNode.prototype.clearName = function() {
  return this.setName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.LValueExprNode.prototype.hasName = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional LIndexedAccessNode indexed_access = 2;
 * @return {?proto.LIndexedAccessNode}
 */
proto.LValueExprNode.prototype.getIndexedAccess = function() {
  return /** @type{?proto.LIndexedAccessNode} */ (
    jspb.Message.getWrapperField(this, proto.LIndexedAccessNode, 2));
};


/**
 * @param {?proto.LIndexedAccessNode|undefined} value
 * @return {!proto.LValueExprNode} returns this
*/
proto.LValueExprNode.prototype.setIndexedAccess = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.LValueExprNode.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.LValueExprNode} returns this
 */
proto.LValueExprNode.prototype.clearIndexedAccess = function() {
  return this.setIndexedAccess(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.LValueExprNode.prototype.hasIndexedAccess = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional LFieldAccessNode field_access = 3;
 * @return {?proto.LFieldAccessNode}
 */
proto.LValueExprNode.prototype.getFieldAccess = function() {
  return /** @type{?proto.LFieldAccessNode} */ (
    jspb.Message.getWrapperField(this, proto.LFieldAccessNode, 3));
};


/**
 * @param {?proto.LFieldAccessNode|undefined} value
 * @return {!proto.LValueExprNode} returns this
*/
proto.LValueExprNode.prototype.setFieldAccess = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.LValueExprNode.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.LValueExprNode} returns this
 */
proto.LValueExprNode.prototype.clearFieldAccess = function() {
  return this.setFieldAccess(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.LValueExprNode.prototype.hasFieldAccess = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.VarDeclNode.prototype.toObject = function(opt_includeInstance) {
  return proto.VarDeclNode.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.VarDeclNode} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.VarDeclNode.toObject = function(includeInstance, msg) {
  var f, obj = {
    declarations: (f = msg.getDeclarations()) && proto.NameTypeListNode.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.VarDeclNode}
 */
proto.VarDeclNode.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.VarDeclNode;
  return proto.VarDeclNode.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.VarDeclNode} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.VarDeclNode}
 */
proto.VarDeclNode.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.NameTypeListNode;
      reader.readMessage(value,proto.NameTypeListNode.deserializeBinaryFromReader);
      msg.setDeclarations(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.VarDeclNode.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.VarDeclNode.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.VarDeclNode} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.VarDeclNode.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDeclarations();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.NameTypeListNode.serializeBinaryToWriter
    );
  }
};


/**
 * optional NameTypeListNode declarations = 1;
 * @return {?proto.NameTypeListNode}
 */
proto.VarDeclNode.prototype.getDeclarations = function() {
  return /** @type{?proto.NameTypeListNode} */ (
    jspb.Message.getWrapperField(this, proto.NameTypeListNode, 1));
};


/**
 * @param {?proto.NameTypeListNode|undefined} value
 * @return {!proto.VarDeclNode} returns this
*/
proto.VarDeclNode.prototype.setDeclarations = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.VarDeclNode} returns this
 */
proto.VarDeclNode.prototype.clearDeclarations = function() {
  return this.setDeclarations(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.VarDeclNode.prototype.hasDeclarations = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.VarDefNode.prototype.toObject = function(opt_includeInstance) {
  return proto.VarDefNode.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.VarDefNode} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.VarDefNode.toObject = function(includeInstance, msg) {
  var f, obj = {
    varName: (f = msg.getVarName()) && proto.NameTypeNode.toObject(includeInstance, f),
    valueExpr: (f = msg.getValueExpr()) && proto.ExprNode.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.VarDefNode}
 */
proto.VarDefNode.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.VarDefNode;
  return proto.VarDefNode.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.VarDefNode} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.VarDefNode}
 */
proto.VarDefNode.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.NameTypeNode;
      reader.readMessage(value,proto.NameTypeNode.deserializeBinaryFromReader);
      msg.setVarName(value);
      break;
    case 2:
      var value = new proto.ExprNode;
      reader.readMessage(value,proto.ExprNode.deserializeBinaryFromReader);
      msg.setValueExpr(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.VarDefNode.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.VarDefNode.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.VarDefNode} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.VarDefNode.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVarName();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.NameTypeNode.serializeBinaryToWriter
    );
  }
  f = message.getValueExpr();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.ExprNode.serializeBinaryToWriter
    );
  }
};


/**
 * optional NameTypeNode var_name = 1;
 * @return {?proto.NameTypeNode}
 */
proto.VarDefNode.prototype.getVarName = function() {
  return /** @type{?proto.NameTypeNode} */ (
    jspb.Message.getWrapperField(this, proto.NameTypeNode, 1));
};


/**
 * @param {?proto.NameTypeNode|undefined} value
 * @return {!proto.VarDefNode} returns this
*/
proto.VarDefNode.prototype.setVarName = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.VarDefNode} returns this
 */
proto.VarDefNode.prototype.clearVarName = function() {
  return this.setVarName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.VarDefNode.prototype.hasVarName = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ExprNode value_expr = 2;
 * @return {?proto.ExprNode}
 */
proto.VarDefNode.prototype.getValueExpr = function() {
  return /** @type{?proto.ExprNode} */ (
    jspb.Message.getWrapperField(this, proto.ExprNode, 2));
};


/**
 * @param {?proto.ExprNode|undefined} value
 * @return {!proto.VarDefNode} returns this
*/
proto.VarDefNode.prototype.setValueExpr = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.VarDefNode} returns this
 */
proto.VarDefNode.prototype.clearValueExpr = function() {
  return this.setValueExpr(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.VarDefNode.prototype.hasValueExpr = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.AssignNode.prototype.toObject = function(opt_includeInstance) {
  return proto.AssignNode.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.AssignNode} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.AssignNode.toObject = function(includeInstance, msg) {
  var f, obj = {
    lValueExpr: (f = msg.getLValueExpr()) && proto.LValueExprNode.toObject(includeInstance, f),
    rValueExpr: (f = msg.getRValueExpr()) && proto.ExprNode.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.AssignNode}
 */
proto.AssignNode.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.AssignNode;
  return proto.AssignNode.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.AssignNode} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.AssignNode}
 */
proto.AssignNode.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.LValueExprNode;
      reader.readMessage(value,proto.LValueExprNode.deserializeBinaryFromReader);
      msg.setLValueExpr(value);
      break;
    case 2:
      var value = new proto.ExprNode;
      reader.readMessage(value,proto.ExprNode.deserializeBinaryFromReader);
      msg.setRValueExpr(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.AssignNode.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.AssignNode.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.AssignNode} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.AssignNode.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLValueExpr();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.LValueExprNode.serializeBinaryToWriter
    );
  }
  f = message.getRValueExpr();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.ExprNode.serializeBinaryToWriter
    );
  }
};


/**
 * optional LValueExprNode l_value_expr = 1;
 * @return {?proto.LValueExprNode}
 */
proto.AssignNode.prototype.getLValueExpr = function() {
  return /** @type{?proto.LValueExprNode} */ (
    jspb.Message.getWrapperField(this, proto.LValueExprNode, 1));
};


/**
 * @param {?proto.LValueExprNode|undefined} value
 * @return {!proto.AssignNode} returns this
*/
proto.AssignNode.prototype.setLValueExpr = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.AssignNode} returns this
 */
proto.AssignNode.prototype.clearLValueExpr = function() {
  return this.setLValueExpr(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.AssignNode.prototype.hasLValueExpr = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ExprNode r_value_expr = 2;
 * @return {?proto.ExprNode}
 */
proto.AssignNode.prototype.getRValueExpr = function() {
  return /** @type{?proto.ExprNode} */ (
    jspb.Message.getWrapperField(this, proto.ExprNode, 2));
};


/**
 * @param {?proto.ExprNode|undefined} value
 * @return {!proto.AssignNode} returns this
*/
proto.AssignNode.prototype.setRValueExpr = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.AssignNode} returns this
 */
proto.AssignNode.prototype.clearRValueExpr = function() {
  return this.setRValueExpr(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.AssignNode.prototype.hasRValueExpr = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ExprBlockNode.prototype.toObject = function(opt_includeInstance) {
  return proto.ExprBlockNode.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ExprBlockNode} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ExprBlockNode.toObject = function(includeInstance, msg) {
  var f, obj = {
    expr: (f = msg.getExpr()) && proto.ExprNode.toObject(includeInstance, f),
    block: (f = msg.getBlock()) && proto.BlockNode.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ExprBlockNode}
 */
proto.ExprBlockNode.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ExprBlockNode;
  return proto.ExprBlockNode.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ExprBlockNode} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ExprBlockNode}
 */
proto.ExprBlockNode.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.ExprNode;
      reader.readMessage(value,proto.ExprNode.deserializeBinaryFromReader);
      msg.setExpr(value);
      break;
    case 2:
      var value = new proto.BlockNode;
      reader.readMessage(value,proto.BlockNode.deserializeBinaryFromReader);
      msg.setBlock(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ExprBlockNode.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ExprBlockNode.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ExprBlockNode} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ExprBlockNode.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getExpr();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.ExprNode.serializeBinaryToWriter
    );
  }
  f = message.getBlock();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.BlockNode.serializeBinaryToWriter
    );
  }
};


/**
 * optional ExprNode expr = 1;
 * @return {?proto.ExprNode}
 */
proto.ExprBlockNode.prototype.getExpr = function() {
  return /** @type{?proto.ExprNode} */ (
    jspb.Message.getWrapperField(this, proto.ExprNode, 1));
};


/**
 * @param {?proto.ExprNode|undefined} value
 * @return {!proto.ExprBlockNode} returns this
*/
proto.ExprBlockNode.prototype.setExpr = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ExprBlockNode} returns this
 */
proto.ExprBlockNode.prototype.clearExpr = function() {
  return this.setExpr(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ExprBlockNode.prototype.hasExpr = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional BlockNode block = 2;
 * @return {?proto.BlockNode}
 */
proto.ExprBlockNode.prototype.getBlock = function() {
  return /** @type{?proto.BlockNode} */ (
    jspb.Message.getWrapperField(this, proto.BlockNode, 2));
};


/**
 * @param {?proto.BlockNode|undefined} value
 * @return {!proto.ExprBlockNode} returns this
*/
proto.ExprBlockNode.prototype.setBlock = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ExprBlockNode} returns this
 */
proto.ExprBlockNode.prototype.clearBlock = function() {
  return this.setBlock(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ExprBlockNode.prototype.hasBlock = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.IfElseNode.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.IfElseNode.prototype.toObject = function(opt_includeInstance) {
  return proto.IfElseNode.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.IfElseNode} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.IfElseNode.toObject = function(includeInstance, msg) {
  var f, obj = {
    ifExprBlock: (f = msg.getIfExprBlock()) && proto.ExprBlockNode.toObject(includeInstance, f),
    elifExprBlockList: jspb.Message.toObjectList(msg.getElifExprBlockList(),
    proto.ExprBlockNode.toObject, includeInstance),
    elseBlock: (f = msg.getElseBlock()) && proto.BlockNode.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.IfElseNode}
 */
proto.IfElseNode.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.IfElseNode;
  return proto.IfElseNode.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.IfElseNode} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.IfElseNode}
 */
proto.IfElseNode.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.ExprBlockNode;
      reader.readMessage(value,proto.ExprBlockNode.deserializeBinaryFromReader);
      msg.setIfExprBlock(value);
      break;
    case 2:
      var value = new proto.ExprBlockNode;
      reader.readMessage(value,proto.ExprBlockNode.deserializeBinaryFromReader);
      msg.addElifExprBlock(value);
      break;
    case 3:
      var value = new proto.BlockNode;
      reader.readMessage(value,proto.BlockNode.deserializeBinaryFromReader);
      msg.setElseBlock(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.IfElseNode.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.IfElseNode.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.IfElseNode} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.IfElseNode.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIfExprBlock();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.ExprBlockNode.serializeBinaryToWriter
    );
  }
  f = message.getElifExprBlockList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.ExprBlockNode.serializeBinaryToWriter
    );
  }
  f = message.getElseBlock();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.BlockNode.serializeBinaryToWriter
    );
  }
};


/**
 * optional ExprBlockNode if_expr_block = 1;
 * @return {?proto.ExprBlockNode}
 */
proto.IfElseNode.prototype.getIfExprBlock = function() {
  return /** @type{?proto.ExprBlockNode} */ (
    jspb.Message.getWrapperField(this, proto.ExprBlockNode, 1));
};


/**
 * @param {?proto.ExprBlockNode|undefined} value
 * @return {!proto.IfElseNode} returns this
*/
proto.IfElseNode.prototype.setIfExprBlock = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.IfElseNode} returns this
 */
proto.IfElseNode.prototype.clearIfExprBlock = function() {
  return this.setIfExprBlock(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.IfElseNode.prototype.hasIfExprBlock = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated ExprBlockNode elif_expr_block = 2;
 * @return {!Array<!proto.ExprBlockNode>}
 */
proto.IfElseNode.prototype.getElifExprBlockList = function() {
  return /** @type{!Array<!proto.ExprBlockNode>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.ExprBlockNode, 2));
};


/**
 * @param {!Array<!proto.ExprBlockNode>} value
 * @return {!proto.IfElseNode} returns this
*/
proto.IfElseNode.prototype.setElifExprBlockList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.ExprBlockNode=} opt_value
 * @param {number=} opt_index
 * @return {!proto.ExprBlockNode}
 */
proto.IfElseNode.prototype.addElifExprBlock = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.ExprBlockNode, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.IfElseNode} returns this
 */
proto.IfElseNode.prototype.clearElifExprBlockList = function() {
  return this.setElifExprBlockList([]);
};


/**
 * optional BlockNode else_block = 3;
 * @return {?proto.BlockNode}
 */
proto.IfElseNode.prototype.getElseBlock = function() {
  return /** @type{?proto.BlockNode} */ (
    jspb.Message.getWrapperField(this, proto.BlockNode, 3));
};


/**
 * @param {?proto.BlockNode|undefined} value
 * @return {!proto.IfElseNode} returns this
*/
proto.IfElseNode.prototype.setElseBlock = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.IfElseNode} returns this
 */
proto.IfElseNode.prototype.clearElseBlock = function() {
  return this.setElseBlock(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.IfElseNode.prototype.hasElseBlock = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.WhileNode.prototype.toObject = function(opt_includeInstance) {
  return proto.WhileNode.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.WhileNode} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.WhileNode.toObject = function(includeInstance, msg) {
  var f, obj = {
    label: (f = msg.getLabel()) && proto.NameNode.toObject(includeInstance, f),
    exprBlock: (f = msg.getExprBlock()) && proto.ExprBlockNode.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.WhileNode}
 */
proto.WhileNode.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.WhileNode;
  return proto.WhileNode.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.WhileNode} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.WhileNode}
 */
proto.WhileNode.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.NameNode;
      reader.readMessage(value,proto.NameNode.deserializeBinaryFromReader);
      msg.setLabel(value);
      break;
    case 2:
      var value = new proto.ExprBlockNode;
      reader.readMessage(value,proto.ExprBlockNode.deserializeBinaryFromReader);
      msg.setExprBlock(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.WhileNode.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.WhileNode.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.WhileNode} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.WhileNode.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLabel();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.NameNode.serializeBinaryToWriter
    );
  }
  f = message.getExprBlock();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.ExprBlockNode.serializeBinaryToWriter
    );
  }
};


/**
 * optional NameNode label = 1;
 * @return {?proto.NameNode}
 */
proto.WhileNode.prototype.getLabel = function() {
  return /** @type{?proto.NameNode} */ (
    jspb.Message.getWrapperField(this, proto.NameNode, 1));
};


/**
 * @param {?proto.NameNode|undefined} value
 * @return {!proto.WhileNode} returns this
*/
proto.WhileNode.prototype.setLabel = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.WhileNode} returns this
 */
proto.WhileNode.prototype.clearLabel = function() {
  return this.setLabel(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.WhileNode.prototype.hasLabel = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ExprBlockNode expr_block = 2;
 * @return {?proto.ExprBlockNode}
 */
proto.WhileNode.prototype.getExprBlock = function() {
  return /** @type{?proto.ExprBlockNode} */ (
    jspb.Message.getWrapperField(this, proto.ExprBlockNode, 2));
};


/**
 * @param {?proto.ExprBlockNode|undefined} value
 * @return {!proto.WhileNode} returns this
*/
proto.WhileNode.prototype.setExprBlock = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.WhileNode} returns this
 */
proto.WhileNode.prototype.clearExprBlock = function() {
  return this.setExprBlock(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.WhileNode.prototype.hasExprBlock = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.StatementNode.oneofGroups_ = [[3,4,5,6,7,8,9,10]];

/**
 * @enum {number}
 */
proto.StatementNode.StmtCase = {
  STMT_NOT_SET: 0,
  ASSIGN: 3,
  EXPR: 4,
  VAR_DECL: 5,
  VAR_DEF: 6,
  EXPR_LIST: 7,
  IF_ELSE: 8,
  WHILE: 9,
  LABEL: 10
};

/**
 * @return {proto.StatementNode.StmtCase}
 */
proto.StatementNode.prototype.getStmtCase = function() {
  return /** @type {proto.StatementNode.StmtCase} */(jspb.Message.computeOneofCase(this, proto.StatementNode.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.StatementNode.prototype.toObject = function(opt_includeInstance) {
  return proto.StatementNode.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.StatementNode} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.StatementNode.toObject = function(includeInstance, msg) {
  var f, obj = {
    stepId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    kind: jspb.Message.getFieldWithDefault(msg, 2, 0),
    assign: (f = msg.getAssign()) && proto.AssignNode.toObject(includeInstance, f),
    expr: (f = msg.getExpr()) && proto.ExprNode.toObject(includeInstance, f),
    varDecl: (f = msg.getVarDecl()) && proto.VarDeclNode.toObject(includeInstance, f),
    varDef: (f = msg.getVarDef()) && proto.VarDefNode.toObject(includeInstance, f),
    exprList: (f = msg.getExprList()) && proto.ExprListNode.toObject(includeInstance, f),
    ifElse: (f = msg.getIfElse()) && proto.IfElseNode.toObject(includeInstance, f),
    pb_while: (f = msg.getWhile()) && proto.WhileNode.toObject(includeInstance, f),
    label: (f = msg.getLabel()) && proto.NameNode.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.StatementNode}
 */
proto.StatementNode.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.StatementNode;
  return proto.StatementNode.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.StatementNode} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.StatementNode}
 */
proto.StatementNode.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setStepId(value);
      break;
    case 2:
      var value = /** @type {!proto.StmtNodeKind} */ (reader.readEnum());
      msg.setKind(value);
      break;
    case 3:
      var value = new proto.AssignNode;
      reader.readMessage(value,proto.AssignNode.deserializeBinaryFromReader);
      msg.setAssign(value);
      break;
    case 4:
      var value = new proto.ExprNode;
      reader.readMessage(value,proto.ExprNode.deserializeBinaryFromReader);
      msg.setExpr(value);
      break;
    case 5:
      var value = new proto.VarDeclNode;
      reader.readMessage(value,proto.VarDeclNode.deserializeBinaryFromReader);
      msg.setVarDecl(value);
      break;
    case 6:
      var value = new proto.VarDefNode;
      reader.readMessage(value,proto.VarDefNode.deserializeBinaryFromReader);
      msg.setVarDef(value);
      break;
    case 7:
      var value = new proto.ExprListNode;
      reader.readMessage(value,proto.ExprListNode.deserializeBinaryFromReader);
      msg.setExprList(value);
      break;
    case 8:
      var value = new proto.IfElseNode;
      reader.readMessage(value,proto.IfElseNode.deserializeBinaryFromReader);
      msg.setIfElse(value);
      break;
    case 9:
      var value = new proto.WhileNode;
      reader.readMessage(value,proto.WhileNode.deserializeBinaryFromReader);
      msg.setWhile(value);
      break;
    case 10:
      var value = new proto.NameNode;
      reader.readMessage(value,proto.NameNode.deserializeBinaryFromReader);
      msg.setLabel(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.StatementNode.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.StatementNode.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.StatementNode} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.StatementNode.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStepId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getKind();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getAssign();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.AssignNode.serializeBinaryToWriter
    );
  }
  f = message.getExpr();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.ExprNode.serializeBinaryToWriter
    );
  }
  f = message.getVarDecl();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.VarDeclNode.serializeBinaryToWriter
    );
  }
  f = message.getVarDef();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.VarDefNode.serializeBinaryToWriter
    );
  }
  f = message.getExprList();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.ExprListNode.serializeBinaryToWriter
    );
  }
  f = message.getIfElse();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.IfElseNode.serializeBinaryToWriter
    );
  }
  f = message.getWhile();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto.WhileNode.serializeBinaryToWriter
    );
  }
  f = message.getLabel();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      proto.NameNode.serializeBinaryToWriter
    );
  }
};


/**
 * optional string step_id = 1;
 * @return {string}
 */
proto.StatementNode.prototype.getStepId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.StatementNode} returns this
 */
proto.StatementNode.prototype.setStepId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional StmtNodeKind kind = 2;
 * @return {!proto.StmtNodeKind}
 */
proto.StatementNode.prototype.getKind = function() {
  return /** @type {!proto.StmtNodeKind} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.StmtNodeKind} value
 * @return {!proto.StatementNode} returns this
 */
proto.StatementNode.prototype.setKind = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional AssignNode assign = 3;
 * @return {?proto.AssignNode}
 */
proto.StatementNode.prototype.getAssign = function() {
  return /** @type{?proto.AssignNode} */ (
    jspb.Message.getWrapperField(this, proto.AssignNode, 3));
};


/**
 * @param {?proto.AssignNode|undefined} value
 * @return {!proto.StatementNode} returns this
*/
proto.StatementNode.prototype.setAssign = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.StatementNode.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.StatementNode} returns this
 */
proto.StatementNode.prototype.clearAssign = function() {
  return this.setAssign(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.StatementNode.prototype.hasAssign = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional ExprNode expr = 4;
 * @return {?proto.ExprNode}
 */
proto.StatementNode.prototype.getExpr = function() {
  return /** @type{?proto.ExprNode} */ (
    jspb.Message.getWrapperField(this, proto.ExprNode, 4));
};


/**
 * @param {?proto.ExprNode|undefined} value
 * @return {!proto.StatementNode} returns this
*/
proto.StatementNode.prototype.setExpr = function(value) {
  return jspb.Message.setOneofWrapperField(this, 4, proto.StatementNode.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.StatementNode} returns this
 */
proto.StatementNode.prototype.clearExpr = function() {
  return this.setExpr(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.StatementNode.prototype.hasExpr = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional VarDeclNode var_decl = 5;
 * @return {?proto.VarDeclNode}
 */
proto.StatementNode.prototype.getVarDecl = function() {
  return /** @type{?proto.VarDeclNode} */ (
    jspb.Message.getWrapperField(this, proto.VarDeclNode, 5));
};


/**
 * @param {?proto.VarDeclNode|undefined} value
 * @return {!proto.StatementNode} returns this
*/
proto.StatementNode.prototype.setVarDecl = function(value) {
  return jspb.Message.setOneofWrapperField(this, 5, proto.StatementNode.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.StatementNode} returns this
 */
proto.StatementNode.prototype.clearVarDecl = function() {
  return this.setVarDecl(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.StatementNode.prototype.hasVarDecl = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional VarDefNode var_def = 6;
 * @return {?proto.VarDefNode}
 */
proto.StatementNode.prototype.getVarDef = function() {
  return /** @type{?proto.VarDefNode} */ (
    jspb.Message.getWrapperField(this, proto.VarDefNode, 6));
};


/**
 * @param {?proto.VarDefNode|undefined} value
 * @return {!proto.StatementNode} returns this
*/
proto.StatementNode.prototype.setVarDef = function(value) {
  return jspb.Message.setOneofWrapperField(this, 6, proto.StatementNode.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.StatementNode} returns this
 */
proto.StatementNode.prototype.clearVarDef = function() {
  return this.setVarDef(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.StatementNode.prototype.hasVarDef = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional ExprListNode expr_list = 7;
 * @return {?proto.ExprListNode}
 */
proto.StatementNode.prototype.getExprList = function() {
  return /** @type{?proto.ExprListNode} */ (
    jspb.Message.getWrapperField(this, proto.ExprListNode, 7));
};


/**
 * @param {?proto.ExprListNode|undefined} value
 * @return {!proto.StatementNode} returns this
*/
proto.StatementNode.prototype.setExprList = function(value) {
  return jspb.Message.setOneofWrapperField(this, 7, proto.StatementNode.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.StatementNode} returns this
 */
proto.StatementNode.prototype.clearExprList = function() {
  return this.setExprList(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.StatementNode.prototype.hasExprList = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional IfElseNode if_else = 8;
 * @return {?proto.IfElseNode}
 */
proto.StatementNode.prototype.getIfElse = function() {
  return /** @type{?proto.IfElseNode} */ (
    jspb.Message.getWrapperField(this, proto.IfElseNode, 8));
};


/**
 * @param {?proto.IfElseNode|undefined} value
 * @return {!proto.StatementNode} returns this
*/
proto.StatementNode.prototype.setIfElse = function(value) {
  return jspb.Message.setOneofWrapperField(this, 8, proto.StatementNode.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.StatementNode} returns this
 */
proto.StatementNode.prototype.clearIfElse = function() {
  return this.setIfElse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.StatementNode.prototype.hasIfElse = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional WhileNode while = 9;
 * @return {?proto.WhileNode}
 */
proto.StatementNode.prototype.getWhile = function() {
  return /** @type{?proto.WhileNode} */ (
    jspb.Message.getWrapperField(this, proto.WhileNode, 9));
};


/**
 * @param {?proto.WhileNode|undefined} value
 * @return {!proto.StatementNode} returns this
*/
proto.StatementNode.prototype.setWhile = function(value) {
  return jspb.Message.setOneofWrapperField(this, 9, proto.StatementNode.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.StatementNode} returns this
 */
proto.StatementNode.prototype.clearWhile = function() {
  return this.setWhile(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.StatementNode.prototype.hasWhile = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional NameNode label = 10;
 * @return {?proto.NameNode}
 */
proto.StatementNode.prototype.getLabel = function() {
  return /** @type{?proto.NameNode} */ (
    jspb.Message.getWrapperField(this, proto.NameNode, 10));
};


/**
 * @param {?proto.NameNode|undefined} value
 * @return {!proto.StatementNode} returns this
*/
proto.StatementNode.prototype.setLabel = function(value) {
  return jspb.Message.setOneofWrapperField(this, 10, proto.StatementNode.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.StatementNode} returns this
 */
proto.StatementNode.prototype.clearLabel = function() {
  return this.setLabel(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.StatementNode.prototype.hasLabel = function() {
  return jspb.Message.getField(this, 10) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.BlockNode.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.BlockNode.prototype.toObject = function(opt_includeInstance) {
  return proto.BlockNode.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.BlockNode} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.BlockNode.toObject = function(includeInstance, msg) {
  var f, obj = {
    stepId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    statementsList: jspb.Message.toObjectList(msg.getStatementsList(),
    proto.StatementNode.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.BlockNode}
 */
proto.BlockNode.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.BlockNode;
  return proto.BlockNode.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.BlockNode} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.BlockNode}
 */
proto.BlockNode.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setStepId(value);
      break;
    case 2:
      var value = new proto.StatementNode;
      reader.readMessage(value,proto.StatementNode.deserializeBinaryFromReader);
      msg.addStatements(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.BlockNode.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.BlockNode.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.BlockNode} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.BlockNode.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStepId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStatementsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.StatementNode.serializeBinaryToWriter
    );
  }
};


/**
 * optional string step_id = 1;
 * @return {string}
 */
proto.BlockNode.prototype.getStepId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.BlockNode} returns this
 */
proto.BlockNode.prototype.setStepId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated StatementNode statements = 2;
 * @return {!Array<!proto.StatementNode>}
 */
proto.BlockNode.prototype.getStatementsList = function() {
  return /** @type{!Array<!proto.StatementNode>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.StatementNode, 2));
};


/**
 * @param {!Array<!proto.StatementNode>} value
 * @return {!proto.BlockNode} returns this
*/
proto.BlockNode.prototype.setStatementsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.StatementNode=} opt_value
 * @param {number=} opt_index
 * @return {!proto.StatementNode}
 */
proto.BlockNode.prototype.addStatements = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.StatementNode, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.BlockNode} returns this
 */
proto.BlockNode.prototype.clearStatementsList = function() {
  return this.setStatementsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.StructDefNode.prototype.toObject = function(opt_includeInstance) {
  return proto.StructDefNode.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.StructDefNode} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.StructDefNode.toObject = function(includeInstance, msg) {
  var f, obj = {
    fields: (f = msg.getFields()) && proto.NameTypeListNode.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.StructDefNode}
 */
proto.StructDefNode.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.StructDefNode;
  return proto.StructDefNode.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.StructDefNode} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.StructDefNode}
 */
proto.StructDefNode.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.NameTypeListNode;
      reader.readMessage(value,proto.NameTypeListNode.deserializeBinaryFromReader);
      msg.setFields(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.StructDefNode.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.StructDefNode.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.StructDefNode} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.StructDefNode.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFields();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.NameTypeListNode.serializeBinaryToWriter
    );
  }
};


/**
 * optional NameTypeListNode fields = 1;
 * @return {?proto.NameTypeListNode}
 */
proto.StructDefNode.prototype.getFields = function() {
  return /** @type{?proto.NameTypeListNode} */ (
    jspb.Message.getWrapperField(this, proto.NameTypeListNode, 1));
};


/**
 * @param {?proto.NameTypeListNode|undefined} value
 * @return {!proto.StructDefNode} returns this
*/
proto.StructDefNode.prototype.setFields = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.StructDefNode} returns this
 */
proto.StructDefNode.prototype.clearFields = function() {
  return this.setFields(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.StructDefNode.prototype.hasFields = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.EnumDefNode.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.EnumDefNode.prototype.toObject = function(opt_includeInstance) {
  return proto.EnumDefNode.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.EnumDefNode} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.EnumDefNode.toObject = function(includeInstance, msg) {
  var f, obj = {
    enumElementsList: jspb.Message.toObjectList(msg.getEnumElementsList(),
    proto.NameNode.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.EnumDefNode}
 */
proto.EnumDefNode.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.EnumDefNode;
  return proto.EnumDefNode.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.EnumDefNode} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.EnumDefNode}
 */
proto.EnumDefNode.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.NameNode;
      reader.readMessage(value,proto.NameNode.deserializeBinaryFromReader);
      msg.addEnumElements(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.EnumDefNode.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.EnumDefNode.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.EnumDefNode} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.EnumDefNode.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEnumElementsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.NameNode.serializeBinaryToWriter
    );
  }
};


/**
 * repeated NameNode enum_elements = 1;
 * @return {!Array<!proto.NameNode>}
 */
proto.EnumDefNode.prototype.getEnumElementsList = function() {
  return /** @type{!Array<!proto.NameNode>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.NameNode, 1));
};


/**
 * @param {!Array<!proto.NameNode>} value
 * @return {!proto.EnumDefNode} returns this
*/
proto.EnumDefNode.prototype.setEnumElementsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.NameNode=} opt_value
 * @param {number=} opt_index
 * @return {!proto.NameNode}
 */
proto.EnumDefNode.prototype.addEnumElements = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.NameNode, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.EnumDefNode} returns this
 */
proto.EnumDefNode.prototype.clearEnumElementsList = function() {
  return this.setEnumElementsList([]);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.TypeDefNode.oneofGroups_ = [[4,5,6]];

/**
 * @enum {number}
 */
proto.TypeDefNode.DefineCase = {
  DEFINE_NOT_SET: 0,
  ALIAS_DEF: 4,
  STRUCT_DEF: 5,
  ENUM_DEF: 6
};

/**
 * @return {proto.TypeDefNode.DefineCase}
 */
proto.TypeDefNode.prototype.getDefineCase = function() {
  return /** @type {proto.TypeDefNode.DefineCase} */(jspb.Message.computeOneofCase(this, proto.TypeDefNode.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.TypeDefNode.prototype.toObject = function(opt_includeInstance) {
  return proto.TypeDefNode.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.TypeDefNode} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.TypeDefNode.toObject = function(includeInstance, msg) {
  var f, obj = {
    kind: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: (f = msg.getName()) && proto.NameNode.toObject(includeInstance, f),
    attributesList: (f = msg.getAttributesList()) && proto.AttributeListNode.toObject(includeInstance, f),
    aliasDef: (f = msg.getAliasDef()) && proto.TypeNode.toObject(includeInstance, f),
    structDef: (f = msg.getStructDef()) && proto.StructDefNode.toObject(includeInstance, f),
    enumDef: (f = msg.getEnumDef()) && proto.EnumDefNode.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.TypeDefNode}
 */
proto.TypeDefNode.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.TypeDefNode;
  return proto.TypeDefNode.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.TypeDefNode} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.TypeDefNode}
 */
proto.TypeDefNode.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.TypeDefNodeKind} */ (reader.readEnum());
      msg.setKind(value);
      break;
    case 2:
      var value = new proto.NameNode;
      reader.readMessage(value,proto.NameNode.deserializeBinaryFromReader);
      msg.setName(value);
      break;
    case 3:
      var value = new proto.AttributeListNode;
      reader.readMessage(value,proto.AttributeListNode.deserializeBinaryFromReader);
      msg.setAttributesList(value);
      break;
    case 4:
      var value = new proto.TypeNode;
      reader.readMessage(value,proto.TypeNode.deserializeBinaryFromReader);
      msg.setAliasDef(value);
      break;
    case 5:
      var value = new proto.StructDefNode;
      reader.readMessage(value,proto.StructDefNode.deserializeBinaryFromReader);
      msg.setStructDef(value);
      break;
    case 6:
      var value = new proto.EnumDefNode;
      reader.readMessage(value,proto.EnumDefNode.deserializeBinaryFromReader);
      msg.setEnumDef(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.TypeDefNode.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.TypeDefNode.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.TypeDefNode} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.TypeDefNode.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getKind();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getName();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.NameNode.serializeBinaryToWriter
    );
  }
  f = message.getAttributesList();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.AttributeListNode.serializeBinaryToWriter
    );
  }
  f = message.getAliasDef();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.TypeNode.serializeBinaryToWriter
    );
  }
  f = message.getStructDef();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.StructDefNode.serializeBinaryToWriter
    );
  }
  f = message.getEnumDef();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.EnumDefNode.serializeBinaryToWriter
    );
  }
};


/**
 * optional TypeDefNodeKind kind = 1;
 * @return {!proto.TypeDefNodeKind}
 */
proto.TypeDefNode.prototype.getKind = function() {
  return /** @type {!proto.TypeDefNodeKind} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.TypeDefNodeKind} value
 * @return {!proto.TypeDefNode} returns this
 */
proto.TypeDefNode.prototype.setKind = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional NameNode name = 2;
 * @return {?proto.NameNode}
 */
proto.TypeDefNode.prototype.getName = function() {
  return /** @type{?proto.NameNode} */ (
    jspb.Message.getWrapperField(this, proto.NameNode, 2));
};


/**
 * @param {?proto.NameNode|undefined} value
 * @return {!proto.TypeDefNode} returns this
*/
proto.TypeDefNode.prototype.setName = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.TypeDefNode} returns this
 */
proto.TypeDefNode.prototype.clearName = function() {
  return this.setName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.TypeDefNode.prototype.hasName = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional AttributeListNode attributes_list = 3;
 * @return {?proto.AttributeListNode}
 */
proto.TypeDefNode.prototype.getAttributesList = function() {
  return /** @type{?proto.AttributeListNode} */ (
    jspb.Message.getWrapperField(this, proto.AttributeListNode, 3));
};


/**
 * @param {?proto.AttributeListNode|undefined} value
 * @return {!proto.TypeDefNode} returns this
*/
proto.TypeDefNode.prototype.setAttributesList = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.TypeDefNode} returns this
 */
proto.TypeDefNode.prototype.clearAttributesList = function() {
  return this.setAttributesList(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.TypeDefNode.prototype.hasAttributesList = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional TypeNode alias_def = 4;
 * @return {?proto.TypeNode}
 */
proto.TypeDefNode.prototype.getAliasDef = function() {
  return /** @type{?proto.TypeNode} */ (
    jspb.Message.getWrapperField(this, proto.TypeNode, 4));
};


/**
 * @param {?proto.TypeNode|undefined} value
 * @return {!proto.TypeDefNode} returns this
*/
proto.TypeDefNode.prototype.setAliasDef = function(value) {
  return jspb.Message.setOneofWrapperField(this, 4, proto.TypeDefNode.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.TypeDefNode} returns this
 */
proto.TypeDefNode.prototype.clearAliasDef = function() {
  return this.setAliasDef(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.TypeDefNode.prototype.hasAliasDef = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional StructDefNode struct_def = 5;
 * @return {?proto.StructDefNode}
 */
proto.TypeDefNode.prototype.getStructDef = function() {
  return /** @type{?proto.StructDefNode} */ (
    jspb.Message.getWrapperField(this, proto.StructDefNode, 5));
};


/**
 * @param {?proto.StructDefNode|undefined} value
 * @return {!proto.TypeDefNode} returns this
*/
proto.TypeDefNode.prototype.setStructDef = function(value) {
  return jspb.Message.setOneofWrapperField(this, 5, proto.TypeDefNode.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.TypeDefNode} returns this
 */
proto.TypeDefNode.prototype.clearStructDef = function() {
  return this.setStructDef(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.TypeDefNode.prototype.hasStructDef = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional EnumDefNode enum_def = 6;
 * @return {?proto.EnumDefNode}
 */
proto.TypeDefNode.prototype.getEnumDef = function() {
  return /** @type{?proto.EnumDefNode} */ (
    jspb.Message.getWrapperField(this, proto.EnumDefNode, 6));
};


/**
 * @param {?proto.EnumDefNode|undefined} value
 * @return {!proto.TypeDefNode} returns this
*/
proto.TypeDefNode.prototype.setEnumDef = function(value) {
  return jspb.Message.setOneofWrapperField(this, 6, proto.TypeDefNode.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.TypeDefNode} returns this
 */
proto.TypeDefNode.prototype.clearEnumDef = function() {
  return this.setEnumDef(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.TypeDefNode.prototype.hasEnumDef = function() {
  return jspb.Message.getField(this, 6) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.DataDefNode.prototype.toObject = function(opt_includeInstance) {
  return proto.DataDefNode.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.DataDefNode} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.DataDefNode.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: (f = msg.getName()) && proto.NameNode.toObject(includeInstance, f),
    attributesList: (f = msg.getAttributesList()) && proto.AttributeListNode.toObject(includeInstance, f),
    expr: (f = msg.getExpr()) && proto.ExprNode.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.DataDefNode}
 */
proto.DataDefNode.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.DataDefNode;
  return proto.DataDefNode.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.DataDefNode} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.DataDefNode}
 */
proto.DataDefNode.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.NameNode;
      reader.readMessage(value,proto.NameNode.deserializeBinaryFromReader);
      msg.setName(value);
      break;
    case 2:
      var value = new proto.AttributeListNode;
      reader.readMessage(value,proto.AttributeListNode.deserializeBinaryFromReader);
      msg.setAttributesList(value);
      break;
    case 3:
      var value = new proto.ExprNode;
      reader.readMessage(value,proto.ExprNode.deserializeBinaryFromReader);
      msg.setExpr(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.DataDefNode.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.DataDefNode.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.DataDefNode} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.DataDefNode.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.NameNode.serializeBinaryToWriter
    );
  }
  f = message.getAttributesList();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.AttributeListNode.serializeBinaryToWriter
    );
  }
  f = message.getExpr();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.ExprNode.serializeBinaryToWriter
    );
  }
};


/**
 * optional NameNode name = 1;
 * @return {?proto.NameNode}
 */
proto.DataDefNode.prototype.getName = function() {
  return /** @type{?proto.NameNode} */ (
    jspb.Message.getWrapperField(this, proto.NameNode, 1));
};


/**
 * @param {?proto.NameNode|undefined} value
 * @return {!proto.DataDefNode} returns this
*/
proto.DataDefNode.prototype.setName = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.DataDefNode} returns this
 */
proto.DataDefNode.prototype.clearName = function() {
  return this.setName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.DataDefNode.prototype.hasName = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional AttributeListNode attributes_list = 2;
 * @return {?proto.AttributeListNode}
 */
proto.DataDefNode.prototype.getAttributesList = function() {
  return /** @type{?proto.AttributeListNode} */ (
    jspb.Message.getWrapperField(this, proto.AttributeListNode, 2));
};


/**
 * @param {?proto.AttributeListNode|undefined} value
 * @return {!proto.DataDefNode} returns this
*/
proto.DataDefNode.prototype.setAttributesList = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.DataDefNode} returns this
 */
proto.DataDefNode.prototype.clearAttributesList = function() {
  return this.setAttributesList(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.DataDefNode.prototype.hasAttributesList = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional ExprNode expr = 3;
 * @return {?proto.ExprNode}
 */
proto.DataDefNode.prototype.getExpr = function() {
  return /** @type{?proto.ExprNode} */ (
    jspb.Message.getWrapperField(this, proto.ExprNode, 3));
};


/**
 * @param {?proto.ExprNode|undefined} value
 * @return {!proto.DataDefNode} returns this
*/
proto.DataDefNode.prototype.setExpr = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.DataDefNode} returns this
 */
proto.DataDefNode.prototype.clearExpr = function() {
  return this.setExpr(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.DataDefNode.prototype.hasExpr = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.FuncDefNode.prototype.toObject = function(opt_includeInstance) {
  return proto.FuncDefNode.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.FuncDefNode} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.FuncDefNode.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: (f = msg.getName()) && proto.NameNode.toObject(includeInstance, f),
    attributesList: (f = msg.getAttributesList()) && proto.AttributeListNode.toObject(includeInstance, f),
    funcType: (f = msg.getFuncType()) && proto.FuncTypeNode.toObject(includeInstance, f),
    block: (f = msg.getBlock()) && proto.BlockNode.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.FuncDefNode}
 */
proto.FuncDefNode.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.FuncDefNode;
  return proto.FuncDefNode.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.FuncDefNode} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.FuncDefNode}
 */
proto.FuncDefNode.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.NameNode;
      reader.readMessage(value,proto.NameNode.deserializeBinaryFromReader);
      msg.setName(value);
      break;
    case 2:
      var value = new proto.AttributeListNode;
      reader.readMessage(value,proto.AttributeListNode.deserializeBinaryFromReader);
      msg.setAttributesList(value);
      break;
    case 3:
      var value = new proto.FuncTypeNode;
      reader.readMessage(value,proto.FuncTypeNode.deserializeBinaryFromReader);
      msg.setFuncType(value);
      break;
    case 4:
      var value = new proto.BlockNode;
      reader.readMessage(value,proto.BlockNode.deserializeBinaryFromReader);
      msg.setBlock(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.FuncDefNode.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.FuncDefNode.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.FuncDefNode} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.FuncDefNode.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.NameNode.serializeBinaryToWriter
    );
  }
  f = message.getAttributesList();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.AttributeListNode.serializeBinaryToWriter
    );
  }
  f = message.getFuncType();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.FuncTypeNode.serializeBinaryToWriter
    );
  }
  f = message.getBlock();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.BlockNode.serializeBinaryToWriter
    );
  }
};


/**
 * optional NameNode name = 1;
 * @return {?proto.NameNode}
 */
proto.FuncDefNode.prototype.getName = function() {
  return /** @type{?proto.NameNode} */ (
    jspb.Message.getWrapperField(this, proto.NameNode, 1));
};


/**
 * @param {?proto.NameNode|undefined} value
 * @return {!proto.FuncDefNode} returns this
*/
proto.FuncDefNode.prototype.setName = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.FuncDefNode} returns this
 */
proto.FuncDefNode.prototype.clearName = function() {
  return this.setName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.FuncDefNode.prototype.hasName = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional AttributeListNode attributes_list = 2;
 * @return {?proto.AttributeListNode}
 */
proto.FuncDefNode.prototype.getAttributesList = function() {
  return /** @type{?proto.AttributeListNode} */ (
    jspb.Message.getWrapperField(this, proto.AttributeListNode, 2));
};


/**
 * @param {?proto.AttributeListNode|undefined} value
 * @return {!proto.FuncDefNode} returns this
*/
proto.FuncDefNode.prototype.setAttributesList = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.FuncDefNode} returns this
 */
proto.FuncDefNode.prototype.clearAttributesList = function() {
  return this.setAttributesList(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.FuncDefNode.prototype.hasAttributesList = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional FuncTypeNode func_type = 3;
 * @return {?proto.FuncTypeNode}
 */
proto.FuncDefNode.prototype.getFuncType = function() {
  return /** @type{?proto.FuncTypeNode} */ (
    jspb.Message.getWrapperField(this, proto.FuncTypeNode, 3));
};


/**
 * @param {?proto.FuncTypeNode|undefined} value
 * @return {!proto.FuncDefNode} returns this
*/
proto.FuncDefNode.prototype.setFuncType = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.FuncDefNode} returns this
 */
proto.FuncDefNode.prototype.clearFuncType = function() {
  return this.setFuncType(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.FuncDefNode.prototype.hasFuncType = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional BlockNode block = 4;
 * @return {?proto.BlockNode}
 */
proto.FuncDefNode.prototype.getBlock = function() {
  return /** @type{?proto.BlockNode} */ (
    jspb.Message.getWrapperField(this, proto.BlockNode, 4));
};


/**
 * @param {?proto.BlockNode|undefined} value
 * @return {!proto.FuncDefNode} returns this
*/
proto.FuncDefNode.prototype.setBlock = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.FuncDefNode} returns this
 */
proto.FuncDefNode.prototype.clearBlock = function() {
  return this.setBlock(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.FuncDefNode.prototype.hasBlock = function() {
  return jspb.Message.getField(this, 4) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.ProgramNode.repeatedFields_ = [1,2,3,4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ProgramNode.prototype.toObject = function(opt_includeInstance) {
  return proto.ProgramNode.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ProgramNode} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ProgramNode.toObject = function(includeInstance, msg) {
  var f, obj = {
    typeDefsList: jspb.Message.toObjectList(msg.getTypeDefsList(),
    proto.TypeDefNode.toObject, includeInstance),
    dataDefsList: jspb.Message.toObjectList(msg.getDataDefsList(),
    proto.DataDefNode.toObject, includeInstance),
    funcDefsList: jspb.Message.toObjectList(msg.getFuncDefsList(),
    proto.FuncDefNode.toObject, includeInstance),
    statementsList: jspb.Message.toObjectList(msg.getStatementsList(),
    proto.StatementNode.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ProgramNode}
 */
proto.ProgramNode.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ProgramNode;
  return proto.ProgramNode.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ProgramNode} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ProgramNode}
 */
proto.ProgramNode.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.TypeDefNode;
      reader.readMessage(value,proto.TypeDefNode.deserializeBinaryFromReader);
      msg.addTypeDefs(value);
      break;
    case 2:
      var value = new proto.DataDefNode;
      reader.readMessage(value,proto.DataDefNode.deserializeBinaryFromReader);
      msg.addDataDefs(value);
      break;
    case 3:
      var value = new proto.FuncDefNode;
      reader.readMessage(value,proto.FuncDefNode.deserializeBinaryFromReader);
      msg.addFuncDefs(value);
      break;
    case 4:
      var value = new proto.StatementNode;
      reader.readMessage(value,proto.StatementNode.deserializeBinaryFromReader);
      msg.addStatements(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ProgramNode.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ProgramNode.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ProgramNode} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ProgramNode.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTypeDefsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.TypeDefNode.serializeBinaryToWriter
    );
  }
  f = message.getDataDefsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.DataDefNode.serializeBinaryToWriter
    );
  }
  f = message.getFuncDefsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.FuncDefNode.serializeBinaryToWriter
    );
  }
  f = message.getStatementsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.StatementNode.serializeBinaryToWriter
    );
  }
};


/**
 * repeated TypeDefNode type_defs = 1;
 * @return {!Array<!proto.TypeDefNode>}
 */
proto.ProgramNode.prototype.getTypeDefsList = function() {
  return /** @type{!Array<!proto.TypeDefNode>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.TypeDefNode, 1));
};


/**
 * @param {!Array<!proto.TypeDefNode>} value
 * @return {!proto.ProgramNode} returns this
*/
proto.ProgramNode.prototype.setTypeDefsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.TypeDefNode=} opt_value
 * @param {number=} opt_index
 * @return {!proto.TypeDefNode}
 */
proto.ProgramNode.prototype.addTypeDefs = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.TypeDefNode, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.ProgramNode} returns this
 */
proto.ProgramNode.prototype.clearTypeDefsList = function() {
  return this.setTypeDefsList([]);
};


/**
 * repeated DataDefNode data_defs = 2;
 * @return {!Array<!proto.DataDefNode>}
 */
proto.ProgramNode.prototype.getDataDefsList = function() {
  return /** @type{!Array<!proto.DataDefNode>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.DataDefNode, 2));
};


/**
 * @param {!Array<!proto.DataDefNode>} value
 * @return {!proto.ProgramNode} returns this
*/
proto.ProgramNode.prototype.setDataDefsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.DataDefNode=} opt_value
 * @param {number=} opt_index
 * @return {!proto.DataDefNode}
 */
proto.ProgramNode.prototype.addDataDefs = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.DataDefNode, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.ProgramNode} returns this
 */
proto.ProgramNode.prototype.clearDataDefsList = function() {
  return this.setDataDefsList([]);
};


/**
 * repeated FuncDefNode func_defs = 3;
 * @return {!Array<!proto.FuncDefNode>}
 */
proto.ProgramNode.prototype.getFuncDefsList = function() {
  return /** @type{!Array<!proto.FuncDefNode>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.FuncDefNode, 3));
};


/**
 * @param {!Array<!proto.FuncDefNode>} value
 * @return {!proto.ProgramNode} returns this
*/
proto.ProgramNode.prototype.setFuncDefsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.FuncDefNode=} opt_value
 * @param {number=} opt_index
 * @return {!proto.FuncDefNode}
 */
proto.ProgramNode.prototype.addFuncDefs = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.FuncDefNode, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.ProgramNode} returns this
 */
proto.ProgramNode.prototype.clearFuncDefsList = function() {
  return this.setFuncDefsList([]);
};


/**
 * repeated StatementNode statements = 4;
 * @return {!Array<!proto.StatementNode>}
 */
proto.ProgramNode.prototype.getStatementsList = function() {
  return /** @type{!Array<!proto.StatementNode>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.StatementNode, 4));
};


/**
 * @param {!Array<!proto.StatementNode>} value
 * @return {!proto.ProgramNode} returns this
*/
proto.ProgramNode.prototype.setStatementsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.StatementNode=} opt_value
 * @param {number=} opt_index
 * @return {!proto.StatementNode}
 */
proto.ProgramNode.prototype.addStatements = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.StatementNode, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.ProgramNode} returns this
 */
proto.ProgramNode.prototype.clearStatementsList = function() {
  return this.setStatementsList([]);
};


/**
 * @enum {number}
 */
proto.AttributeType = {
  VISUALIZER: 0,
  INDEX: 1
};

/**
 * @enum {number}
 */
proto.VisualizerType = {
  SIMPLE_VIZ: 0,
  SIMPLE_CONTAINER_VIZ: 1,
  SIMPLE_CONTAINER_TRANSPOSE_VIZ: 2
};

/**
 * @enum {number}
 */
proto.TypeNodeKind = {
  VOID: 0,
  INT: 1,
  NUMBER: 2,
  BOOL: 3,
  CHAR: 4,
  STRING: 5,
  BITS: 6,
  FUNC: 7,
  TUPLE: 8,
  ARRAY: 9,
  LIST: 10,
  SET: 11,
  QUEUE: 12,
  STACK: 13,
  DEQUE: 14,
  MAP: 15,
  ALIAS: 16
};

/**
 * @enum {number}
 */
proto.StdFuncCallKind = {
  COPY: 0,
  SIZE: 1,
  IS_EMPTY: 2,
  APPEND: 3,
  REMOVE_AT: 4,
  INSERT_AT: 5,
  EXTEND_AT: 6,
  CLEAR: 7,
  REVERSE: 8,
  CONTAINS: 9,
  ADD: 10,
  REMOVE: 12,
  IS_DISJOINT: 13,
  ENQUEUE: 14,
  DEQUEUE: 15,
  PUSH: 16,
  POP: 17,
  PUSH_FRONT: 18,
  POP_FRONT: 19,
  PUSH_BACK: 20,
  POP_BACK: 21,
  DIMS: 22,
  LEN: 23,
  STR_TO_LIST: 24,
  LIST_TO_STR: 25,
  SLICE: 26
};

/**
 * @enum {number}
 */
proto.ExprNodeKind = {
  TYPE_EXPR: 0,
  INT_LTRL: 1,
  NUMBER_LTRL: 2,
  CHAR_LTRL: 3,
  STRING_LTRL: 4,
  BITS_LTRL: 5,
  TRUE_LTRL: 6,
  FALSE_LTRL: 7,
  NULL_LTRL: 8,
  TUPLE_CTOR: 10,
  ARRAY_CTOR: 11,
  STRUCT_CTOR: 12,
  LIST_CTOR: 13,
  SET_CTOR: 14,
  QUEUE_CTOR: 15,
  STACK_CTOR: 16,
  DEQUE_CTOR: 17,
  MAP_CTOR: 18,
  NAME_ACCESS: 50,
  STD_FUNC_CALL: 51,
  INDEXED_ACCESS: 52,
  FIELD_ACCESS: 53,
  FUNC_CALL: 54,
  NEGATION: 100,
  BIT_NOT: 101,
  BOOL_NOT: 102,
  POWER: 120,
  MODULO: 130,
  MULTIPLICATION: 131,
  DIVISION: 132,
  BIT_AND: 140,
  BIT_OR: 141,
  BIT_XOR: 142,
  LSHIFT: 150,
  RSHIFT: 151,
  ADDITION: 160,
  SUBTRACTION: 161,
  EQ: 170,
  NEQ: 171,
  LT: 172,
  LTEQ: 173,
  GT: 174,
  GTEQ: 175,
  BOOL_AND: 180,
  BOOL_OR: 181
};

/**
 * @enum {number}
 */
proto.ExprPrecedence = {
  EP_UNARY: 0,
  EP_POWER: 1,
  EP_MULTIPLICATIVE: 2,
  EP_BITWISE: 3,
  EP_BIT_SHIFT: 4,
  EP_ADDITIVE: 5,
  EP_COMPARE: 6,
  EP_BOOLEAN: 7
};

/**
 * @enum {number}
 */
proto.StmtNodeKind = {
  ASSIGN: 0,
  EXPR_STMT: 1,
  VAR_DECL: 2,
  VAR_DEFS: 3,
  PRINT: 4,
  RETURN: 5,
  IF: 6,
  WHILE: 7,
  BREAK: 8,
  CONTINUE: 9
};

/**
 * @enum {number}
 */
proto.TypeDefNodeKind = {
  ALIAS_DEF: 0,
  STRUCT_DEF: 1,
  ENUM_DEF: 2
};

goog.object.extend(exports, proto);
