// Borrowed from https://github.com/posva/vue-reactive-refs/blob/master/src/proxy.ts

import { PluginFunction } from 'vue';

export const DynamicReactiveRefs: PluginFunction<never> = (_Vue) => {
  _Vue.mixin({
    beforeCreate() {
      const $refs = _Vue.observable({});
      // @ts-ignore
      this.$refs = new Proxy($refs, {
        set(target, key, value) {
          // eslint-disable-next-line no-restricted-syntax
          if (!(key in target)) _Vue.set($refs, key as string, value);
          return Reflect.set(target, key, value);
        },
        get(target, key) {
          // eslint-disable-next-line no-restricted-syntax
          if (!(key in target)) _Vue.set($refs, key as string, undefined);
          return Reflect.get(target, key);
        },
      });
    },
  });
};
