
export function truncateStr(str: string, maxLen: number): string {
  const len = str.length;
  if (len <= maxLen) {
    return str;
  }
  const startChars = maxLen / 2;
  // -1 for ... char
  const endChars = maxLen - startChars - 1;
  return `${str.slice(0, startChars)}…${str.slice(len - endChars, len)}`;
}

export function widthForStr(str: string, maxChars: number, fontSize: number): number {
  const widthPerChar = (10.0 * fontSize) / 14.0;
  const width = str.length * widthPerChar + 8;
  const maxWidth = maxChars * widthPerChar;
  const minWidth = widthPerChar + 8;
  return Math.trunc(Math.max(Math.min(width, maxWidth), minWidth));
}

function maxLength(strs: Array<string>): number {
  let maxLen = 0;
  strs.forEach((elemStr) => {
    maxLen = Math.max(maxLen, elemStr.length);
  });
  return maxLen;
}

export function widthForStrs(strs: Array<string>, maxChars: number, fontSize: number) {
  const widthPerChar = (10 * fontSize) / 14.0;
  const strLen = strs ? maxLength(strs) : 0;
  const width = strLen * widthPerChar + 8;
  const maxWidth = maxChars * widthPerChar;
  const minWidth = widthPerChar + 8;
  return Math.trunc(Math.max(Math.min(width, maxWidth), minWidth));
}

export function lineDivisionOffsets(
  numOffsets: number,
  startOffset: number,
  extraFirstSpace: number,
  endOffset: number,
): Array<number> {
  const retOffsets: Array<number> = [];
  if (numOffsets <= 0) {
    return retOffsets;
  }
  const remainLength = endOffset - startOffset - extraFirstSpace;
  const indivLength = remainLength / (numOffsets + 1);
  let currOffset = startOffset + extraFirstSpace;
  for (let i = 0; i < numOffsets; i += 1) {
    currOffset += indivLength;
    retOffsets.push(currOffset);
  }
  return retOffsets;
}

export function textCenterOffsets(
  numOffsets: number,
  startOffset: number,
  extraFirstSpace: number,
  endOffset: number,
): Array<number> {
  const retOffsets: Array<number> = [];
  if (numOffsets <= 0) {
    return retOffsets;
  }
  const remainLength = endOffset - startOffset - extraFirstSpace;
  const indivLength = remainLength / numOffsets;
  let currOffset = startOffset + extraFirstSpace + indivLength / 2;
  for (let i = 0; i < numOffsets; i += 1) {
    retOffsets.push(currOffset);
    currOffset += indivLength;
  }
  return retOffsets;
}
