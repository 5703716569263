import * as ast from '@/generated/ast_pb';
import * as span_nodes from '@/revlang/span_nodes';
import * as type_system from '@/revlang/type_system';
import * as instruction_set from '@/revlang/instruction_set';

export class LoadedAST {
  public constructor(
    public readonly rootSpanNode: span_nodes.SpanNode,
    public readonly sourceMap: span_nodes.SourceMap,
    public readonly typeSystem: type_system.RevlangTypeSystem,
    public readonly instructionsTable: Map<string, Array<instruction_set.Instruction>>,
    public readonly programInstructions: Array<instruction_set.Instruction>,
  ) {}
}

class ASTProgramLoader {
  private indent = 0;

  private spanNodeStack = new Array<Array<span_nodes.SpanNode>>([]);

  private sourceMap = new span_nodes.SourceMap();

  private typeSystem = new type_system.RevlangTypeSystem();

  private instructionsTable = new Map<string, Array<instruction_set.Instruction>>();

  private stepIdNodeStack: Array<span_nodes.StepIdNode> = [];

  private instuctionSetBuilder: instruction_set.InstructionSetBuilder;

  private resetStepIdNodeStack(): void {
    this.stepIdNodeStack.length = 0;
  }

  private currentStepIdNode(): span_nodes.StepIdNode {
    return this.stepIdNodeStack[this.stepIdNodeStack.length - 1];
  }

  private doIndent(): void {
    this.indent += 1;
  }

  private doUnindent(): void {
    this.indent -= 1;
  }

  private pushSpanNode(spanNode: span_nodes.SpanNode): void {
    if (this.spanNodeStack.length > 0) {
      this.spanNodeStack[this.spanNodeStack.length - 1].push(spanNode);
    } else {
      this.spanNodeStack.push([spanNode]);
    }
  }

  private pushSpanNodeStack(astNode?: span_nodes.StepableASTNode): void {
    this.spanNodeStack.push([]);
    if (astNode !== undefined && astNode.getStepId().length !== 0) {
      const topStepIdNode = this.currentStepIdNode();
      const addedStepIdNode = this.sourceMap.createChildStepIdNode(
        astNode,
        topStepIdNode,
      );
      this.stepIdNodeStack.push(addedStepIdNode);
    }
  }

  private reduceSpanNodeStack(astNode?: span_nodes.StepableASTNode): void {
    const subNodes = this.spanNodeStack.pop();
    let node: span_nodes.SpanNode;
    if (astNode === undefined || astNode.getStepId().length === 0) {
      node = span_nodes.SpanNode.tree(subNodes);
    } else {
      const stepId = astNode.getStepId();
      node = span_nodes.SpanNode.spanIdTree(stepId, subNodes);
      this.stepIdNodeStack.pop();
    }
    this.pushSpanNode(node);
  }

  private emitNewLine(): void {
    this.pushSpanNode(span_nodes.SpanNode.newLine());
  }

  private emitWhitespace(): void {
    this.pushSpanNode(span_nodes.SpanNode.whitespace());
  }

  private emitIndent(): void {
    this.pushSpanNode(span_nodes.SpanNode.indent(this.indent));
  }

  private emitKeyword(text: string): void {
    this.pushSpanNode(
      span_nodes.SpanNode.text(span_nodes.SpanNodeTextKind.Keyword, text),
    );
  }

  private emitLiteral(literal: string): void {
    this.pushSpanNode(
      span_nodes.SpanNode.text(span_nodes.SpanNodeTextKind.Literal, literal),
    );
  }

  private emitSeperator(seperator: string): void {
    this.pushSpanNode(
      span_nodes.SpanNode.text(span_nodes.SpanNodeTextKind.Seperator, seperator),
    );
  }

  private emitUnaryOperator(operator: string): void {
    this.pushSpanNode(
      span_nodes.SpanNode.text(span_nodes.SpanNodeTextKind.Operator, operator),
    );
  }

  private emitBinaryOperator(operator: string): void {
    this.emitWhitespace();
    this.pushSpanNode(
      span_nodes.SpanNode.text(span_nodes.SpanNodeTextKind.Operator, operator),
    );
    this.emitWhitespace();
  }

  private emitBlockStart(): void {
    this.pushSpanNode(
      span_nodes.SpanNode.text(span_nodes.SpanNodeTextKind.Seperator, '{'),
    );
    this.doIndent();
    this.instuctionSetBuilder.emitInstruction(
      new instruction_set.PushBlockInstruction(
        this.currentStepIdNode(),
      ),
    );
  }

  private emitBlockEnd(): void {
    this.instuctionSetBuilder.emitInstruction(
      new instruction_set.PopBlockInstruction(
        this.currentStepIdNode(),
      ),
    );
    this.doUnindent();
    this.emitIndent();
    this.pushSpanNode(
      span_nodes.SpanNode.text(span_nodes.SpanNodeTextKind.Seperator, '}'),
    );
  }

  private emitName(
    nameNodeKind: span_nodes.SpanNodeTextKind,
    nameNode: ast.NameNode,
  ): void {
    this.pushSpanNode(
      span_nodes.SpanNode.text(nameNodeKind, nameNode.getName()),
    );
  }

  private emitNameType(
    nameNodeKind: span_nodes.SpanNodeTextKind,
    nameTypeNode: ast.NameTypeNode,
  ): void {
    this.emitName(nameNodeKind, nameTypeNode.getName());
    if (nameTypeNode.getType() !== undefined) {
      this.emitSeperator(':');
      this.emitWhitespace();
      this.emitType(nameTypeNode.getType());
    }
  }

  private emitNameTypeList(
    nameNodeKind: span_nodes.SpanNodeTextKind,
    nameTypeList: ast.NameTypeListNode,
  ): void {
    let isFirst = true;
    for (const nameType of nameTypeList.getNameTypesList()) {
      if (!isFirst) {
        this.emitSeperator(',');
        this.emitWhitespace();
      }
      this.emitNameType(nameNodeKind, nameType);
      isFirst = false;
    }
  }

  private emitNameExpr(
    nameNodeKind: span_nodes.SpanNodeTextKind,
    nameExprNode: ast.NameExprNode,
  ): void {
    this.emitName(nameNodeKind, nameExprNode.getName());
    this.emitBinaryOperator('=');
    this.emitExpr(nameExprNode.getExpr());
    this.instuctionSetBuilder.emitInstruction(
      new instruction_set.NameExprInstruction(
        this.currentStepIdNode(),
        nameExprNode,
      ),
    );
  }

  private emitNameExprList(
    nameNodeKind: span_nodes.SpanNodeTextKind,
    nameExprList: ast.NameExprListNode,
  ): void {
    let isFirst = true;
    for (const nameExpr of nameExprList.getNameExprsList()) {
      if (!isFirst) {
        this.emitSeperator(',');
        this.emitWhitespace();
      }
      this.emitNameExpr(nameNodeKind, nameExpr);
      isFirst = false;
    }
  }

  private emitMapElement(mapElementNode: ast.MapElementNode): void {
    this.emitExpr(mapElementNode.getKeyExpr());
    this.emitWhitespace();
    this.emitSeperator('->');
    this.emitWhitespace();
    this.emitExpr(mapElementNode.getValueExpr());
    this.emitSeperator(',');
    this.instuctionSetBuilder.emitInstruction(
      new instruction_set.MapElementInstruction(
        this.currentStepIdNode(),
        mapElementNode,
      ),
    );
  }

  private emitExprList(exprList: ast.ExprListNode): void {
    let isFirst = true;
    for (const expr of exprList.getExprsList()) {
      if (!isFirst) {
        this.emitSeperator(',');
        this.emitWhitespace();
      }
      this.emitExpr(expr);
      isFirst = false;
    }
  }

  private emitIndentedExprList(exprList: ast.ExprListNode): void {
    this.doIndent();
    this.emitIndent();
    let isFirst = true;
    for (const expr of exprList.getExprsList()) {
      if (!isFirst) {
        this.emitSeperator(',');
        this.emitWhitespace();
      }
      this.emitExpr(expr);
      isFirst = false;
    }
    this.doUnindent();
  }

  private emitFuncType(funcTypeNode: ast.FuncTypeNode): void {
    this.pushSpanNodeStack();
    this.emitKeyword('func');
    this.emitWhitespace();
    this.emitSeperator('(');
    this.emitNameTypeList(
      span_nodes.SpanNodeTextKind.DefName,
      funcTypeNode.getParams(),
    );
    this.emitSeperator(')');
    this.emitWhitespace();
    this.emitSeperator('->');
    this.emitWhitespace();
    this.emitType(funcTypeNode.getReturnType());
    this.reduceSpanNodeStack();
  }

  private emitBitsType(lengthExprNode: ast.IntLiteralNode): void {
    this.pushSpanNodeStack();
    this.emitKeyword('bits');
    this.emitSeperator('<');
    this.emitLiteral(lengthExprNode.getValue());
    this.emitSeperator('>');
    this.reduceSpanNodeStack();
  }

  private emitTupleType(tupleTypeNode: ast.TupleTypeNode): void {
    this.pushSpanNodeStack();
    this.emitKeyword('tuple');
    this.emitSeperator('<');
    let isFirst = true;
    for (const typeNode of tupleTypeNode.getElementTypesList()) {
      if (!isFirst) {
        this.emitSeperator(',');
        this.emitWhitespace();
      }
      this.emitType(typeNode);
      isFirst = false;
    }
    this.emitSeperator('>');
    this.reduceSpanNodeStack();
  }

  private emitArrayType(arrayTypeNode: ast.ArrayTypeNode): void {
    this.pushSpanNodeStack();
    this.emitKeyword('array');
    this.emitSeperator('<');
    this.emitType(arrayTypeNode.getElementType());
    if (arrayTypeNode.getDims() !== undefined) {
      this.emitSeperator(',');
      this.emitWhitespace();
      this.emitLiteral(arrayTypeNode.getDims().getValue());
    }
    this.emitSeperator('>');
    this.reduceSpanNodeStack();
  }

  private emitContainerKeyword(
    containerType: ast.TypeNodeKindMap[keyof ast.TypeNodeKindMap],
  ): void {
    if (containerType === ast.TypeNodeKind.LIST) {
      this.emitKeyword('list');
    } else if (containerType === ast.TypeNodeKind.SET) {
      this.emitKeyword('set');
    } else if (containerType === ast.TypeNodeKind.QUEUE) {
      this.emitKeyword('queue');
    } else if (containerType === ast.TypeNodeKind.STACK) {
      this.emitKeyword('stack');
    } else if (containerType === ast.TypeNodeKind.DEQUE) {
      this.emitKeyword('deque');
    } else {
      throw new Error('Unknown container type');
    }
  }

  private emitContainerType(
    containerType: ast.TypeNodeKindMap[keyof ast.TypeNodeKindMap],
    elementTypeNode: ast.TypeNode,
  ): void {
    this.pushSpanNodeStack();
    this.emitContainerKeyword(containerType);
    this.emitSeperator('<');
    this.emitType(elementTypeNode);
    this.emitSeperator('>');
    this.reduceSpanNodeStack();
  }

  private emitMapType(mapTypeNode: ast.MapTypeNode): void {
    this.pushSpanNodeStack();
    this.emitKeyword('map');
    this.emitSeperator('<');
    this.emitType(mapTypeNode.getKeyType());
    this.emitSeperator(',');
    this.emitWhitespace();
    this.emitType(mapTypeNode.getValueType());
    this.emitSeperator('>');
    this.reduceSpanNodeStack();
  }

  private emitType(typeNode: ast.TypeNode): void {
    switch (typeNode.getKind()) {
      case ast.TypeNodeKind.VOID:
        return this.emitKeyword('void');
      case ast.TypeNodeKind.INT:
        return this.emitKeyword('int');
      case ast.TypeNodeKind.NUMBER:
        return this.emitKeyword('number');
      case ast.TypeNodeKind.BOOL:
        return this.emitKeyword('bool');
      case ast.TypeNodeKind.CHAR:
        return this.emitKeyword('chat');
      case ast.TypeNodeKind.STRING:
        return this.emitKeyword('string');
      case ast.TypeNodeKind.BITS:
        return this.emitBitsType(typeNode.getLength());
      case ast.TypeNodeKind.FUNC:
        return this.emitFuncType(typeNode.getFuncType());
      case ast.TypeNodeKind.TUPLE:
        return this.emitTupleType(typeNode.getTupleType());
      case ast.TypeNodeKind.ARRAY:
        return this.emitArrayType(typeNode.getArrayType());
      case ast.TypeNodeKind.LIST:
      case ast.TypeNodeKind.SET:
      case ast.TypeNodeKind.QUEUE:
      case ast.TypeNodeKind.STACK:
      case ast.TypeNodeKind.DEQUE:
        return this.emitContainerType(typeNode.getKind(), typeNode.getElementType());
      case ast.TypeNodeKind.MAP:
        return this.emitMapType(typeNode.getMapType());
      case ast.TypeNodeKind.ALIAS:
        return this.emitName(
          span_nodes.SpanNodeTextKind.RefName,
          typeNode.getAlias(),
        );
      default:
        throw new Error(`Internal error:  unknown type kind ${typeNode.getKind()}`);
    }
  }

  private emitTupleCtorExpr(tupleCtorExprNode: ast.ExprNode): void {
    const tupleCtorNode = tupleCtorExprNode.getTupleCtor();
    this.pushSpanNodeStack(tupleCtorExprNode);
    if (tupleCtorNode.hasAlias()) {
      this.emitKeyword('tuple');
      this.emitWhitespace();
      this.emitName(
        span_nodes.SpanNodeTextKind.RefName,
        tupleCtorNode.getAlias(),
      );
    } else {
      this.emitTupleType(tupleCtorNode.getTupleType());
    }
    this.emitWhitespace();
    this.emitSeperator('(');
    this.emitNewLine();
    this.emitIndentedExprList(tupleCtorNode.getExprList());
    this.emitNewLine();
    this.emitSeperator(')');
    this.instuctionSetBuilder.emitInstruction(
      new instruction_set.ReduceExprListInstruction(
        this.currentStepIdNode(),
        tupleCtorNode.getExprList(),
      ),
    );
    this.instuctionSetBuilder.emitInstruction(
      new instruction_set.ExprInstruction(
        this.currentStepIdNode(),
        tupleCtorExprNode,
      ),
    );
    this.reduceSpanNodeStack(tupleCtorExprNode);
  }

  private emitArrayElements(arrayElements: ast.ArrayElementsNode, dimLevel: number): void {
    if (arrayElements.hasExprList()) {
      this.emitSeperator('[');
      if (dimLevel === 0) {
        this.emitNewLine();
        this.emitIndentedExprList(arrayElements.getExprList());
        this.emitNewLine();
      } else {
        this.emitExprList(arrayElements.getExprList());
      }
      this.emitSeperator(']');
      this.instuctionSetBuilder.emitInstruction(
        new instruction_set.ReduceExprListInstruction(
          this.currentStepIdNode(),
          arrayElements.getExprList(),
        ),
      );
    } else {
      this.emitSeperator('[');
      this.emitNewLine();
      this.doIndent();
      for (const arrayElems of arrayElements.getArrayElementsList().getArrayElementsList()) {
        this.emitIndent();
        this.emitArrayElements(arrayElems, dimLevel + 1);
        this.emitSeperator(',');
        this.emitNewLine();
      }
      this.doUnindent();
      this.emitSeperator(']');
      this.instuctionSetBuilder.emitInstruction(
        new instruction_set.ReduceExprListListInstruction(
          this.currentStepIdNode(),
          arrayElements.getArrayElementsList(),
        ),
      );
    }
  }

  private emitArrayCtorExpr(arrayCtorExprNode: ast.ExprNode): void {
    const arrayCtorNode = arrayCtorExprNode.getArrayCtor();
    this.pushSpanNodeStack(arrayCtorExprNode);
    if (arrayCtorNode.hasAlias()) {
      this.emitKeyword('array');
      this.emitWhitespace();
      this.emitName(
        span_nodes.SpanNodeTextKind.RefName,
        arrayCtorNode.getAlias(),
      );
    } else {
      this.emitArrayType(arrayCtorNode.getArrayType());
    }
    this.emitSeperator('(');
    this.emitExprList(arrayCtorNode.getDimLengths());
    this.emitSeperator(')');
    this.instuctionSetBuilder.emitInstruction(
      new instruction_set.ReduceExprListInstruction(
        this.currentStepIdNode(),
        arrayCtorNode.getDimLengths(),
      ),
    );
    this.emitWhitespace();
    this.emitArrayElements(arrayCtorNode.getArrayElements(), 0);
    this.instuctionSetBuilder.emitInstruction(
      new instruction_set.ExprInstruction(
        this.currentStepIdNode(),
        arrayCtorExprNode,
      ),
    );
    this.reduceSpanNodeStack(arrayCtorExprNode);
  }

  private emitStructCtorExpr(structCtorExprNode: ast.ExprNode): void {
    const structCtorNode = structCtorExprNode.getStructCtor();
    this.pushSpanNodeStack(structCtorExprNode);
    this.emitKeyword('struct');
    this.emitWhitespace();
    this.emitName(
      span_nodes.SpanNodeTextKind.RefName,
      structCtorNode.getAlias(),
    );
    this.emitWhitespace();
    this.emitSeperator('{');
    this.emitNewLine();
    this.doIndent();
    for (const nameExpr of structCtorNode.getFieldValues().getNameExprsList()) {
      this.emitIndent();
      this.emitNameExpr(
        span_nodes.SpanNodeTextKind.RefName,
        nameExpr,
      );
      this.emitSeperator(',');
      this.emitNewLine();
    }
    this.doUnindent();
    this.emitIndent();
    this.emitSeperator('}');
    this.instuctionSetBuilder.emitInstruction(
      new instruction_set.ReduceNameExprListInstruction(
        this.currentStepIdNode(),
        structCtorNode.getFieldValues(),
      ),
    );
    this.instuctionSetBuilder.emitInstruction(
      new instruction_set.ExprInstruction(
        this.currentStepIdNode(),
        structCtorExprNode,
      ),
    );
    this.reduceSpanNodeStack(structCtorExprNode);
  }

  private emitContainerCtorExpr(
    containerType: ast.TypeNodeKindMap[keyof ast.TypeNodeKindMap],
    containerCtorExprNode: ast.ExprNode,
  ): void {
    const containerCtorNode = containerCtorExprNode.getContainerCtor();
    this.pushSpanNodeStack(containerCtorExprNode);
    if (containerCtorNode.hasAlias()) {
      this.emitContainerKeyword(containerType);
      this.emitWhitespace();
      this.emitName(
        span_nodes.SpanNodeTextKind.RefName,
        containerCtorNode.getAlias(),
      );
    } else {
      this.emitContainerType(containerType, containerCtorNode.getElementType());
    }
    this.emitWhitespace();
    if (containerType === ast.TypeNodeKind.SET) {
      this.emitSeperator('{');
    } else {
      this.emitSeperator('[');
    }
    if (containerCtorNode.getExprList().getExprsList().length > 0) {
      this.emitNewLine();
      this.emitIndentedExprList(containerCtorNode.getExprList());
      this.emitNewLine();
      this.emitIndent();
    }
    if (containerType === ast.TypeNodeKind.SET) {
      this.emitSeperator('}');
    } else {
      this.emitSeperator(']');
    }
    this.instuctionSetBuilder.emitInstruction(
      new instruction_set.ReduceExprListInstruction(
        this.currentStepIdNode(),
        containerCtorNode.getExprList(),
      ),
    );
    this.instuctionSetBuilder.emitInstruction(
      new instruction_set.ExprInstruction(
        this.currentStepIdNode(),
        containerCtorExprNode,
      ),
    );
    this.reduceSpanNodeStack(containerCtorExprNode);
  }

  private emitMapCtorExpr(mapCtorExprNode: ast.ExprNode): void {
    const mapCtorNode = mapCtorExprNode.getMapCtor();
    this.pushSpanNodeStack(mapCtorExprNode);
    if (mapCtorNode.hasAlias()) {
      this.emitKeyword('map');
      this.emitWhitespace();
      this.emitName(
        span_nodes.SpanNodeTextKind.RefName,
        mapCtorNode.getAlias(),
      );
    } else {
      this.emitMapType(mapCtorNode.getMapType());
    }
    this.emitWhitespace();
    this.emitSeperator('{');
    if (mapCtorNode.getMapElementsList().getMapElementsList().length > 0) {
      this.emitNewLine();
      this.doIndent();
      for (const mapElementNode of mapCtorNode.getMapElementsList().getMapElementsList()) {
        this.emitIndent();
        this.emitMapElement(mapElementNode);
        this.emitNewLine();
      }
      this.doUnindent();
      this.emitIndent();
    }
    this.emitSeperator('}');
    this.instuctionSetBuilder.emitInstruction(
      new instruction_set.ReduceMapElementsListInstruction(
        this.currentStepIdNode(),
        mapCtorNode.getMapElementsList(),
      ),
    );
    this.instuctionSetBuilder.emitInstruction(
      new instruction_set.ExprInstruction(
        this.currentStepIdNode(),
        mapCtorExprNode,
      ),
    );
    this.reduceSpanNodeStack(mapCtorExprNode);
  }

  private emitIndexedAccessExpr(indexedAccessExprNode: ast.ExprNode): void {
    this.pushSpanNodeStack(indexedAccessExprNode);
    const indexedAccessNode = indexedAccessExprNode.getIndexedAccess();
    this.emitExpr(indexedAccessNode.getContainerExpr());
    this.emitSeperator('[');
    this.emitExprList(indexedAccessNode.getIndices());
    this.emitSeperator(']');
    this.instuctionSetBuilder.emitInstruction(
      new instruction_set.ReduceExprListInstruction(
        this.currentStepIdNode(),
        indexedAccessNode.getIndices(),
      ),
    );
    this.instuctionSetBuilder.emitInstruction(
      new instruction_set.ExprInstruction(
        this.currentStepIdNode(),
        indexedAccessExprNode,
      ),
    );
    this.reduceSpanNodeStack(indexedAccessExprNode);
  }

  private emitFieldAccessExpr(fieldAccessExprNode: ast.ExprNode): void {
    this.pushSpanNodeStack(fieldAccessExprNode);
    const fieldAccessNode = fieldAccessExprNode.getFieldAccess();
    this.emitExpr(fieldAccessNode.getStructExpr());
    this.emitSeperator('.');
    this.emitName(
      span_nodes.SpanNodeTextKind.RefName,
      fieldAccessNode.getFieldName(),
    );
    this.instuctionSetBuilder.emitInstruction(
      new instruction_set.ExprInstruction(
        this.currentStepIdNode(),
        fieldAccessExprNode,
      ),
    );
    this.reduceSpanNodeStack(fieldAccessExprNode);
  }

  private stdFuncCallKeyword(
    stdFuncCallKind: ast.StdFuncCallKindMap[keyof ast.StdFuncCallKindMap],
  ): string {
    switch (stdFuncCallKind) {
      case ast.StdFuncCallKind.COPY:
        return 'copy';
      case ast.StdFuncCallKind.SIZE:
        return 'size';
      case ast.StdFuncCallKind.IS_EMPTY:
        return 'is_empty';
      case ast.StdFuncCallKind.APPEND:
        return 'append';
      case ast.StdFuncCallKind.REMOVE_AT:
        return 'remove_at';
      case ast.StdFuncCallKind.INSERT_AT:
        return 'insert_at';
      case ast.StdFuncCallKind.EXTEND_AT:
        return 'extend_at';
      case ast.StdFuncCallKind.CLEAR:
        return 'clear';
      case ast.StdFuncCallKind.REVERSE:
        return 'reverse';
      case ast.StdFuncCallKind.CONTAINS:
        return 'contains';
      case ast.StdFuncCallKind.ADD:
        return 'add';
      case ast.StdFuncCallKind.REMOVE:
        return 'remove';
      case ast.StdFuncCallKind.IS_DISJOINT:
        return 'is_disjoint';
      case ast.StdFuncCallKind.ENQUEUE:
        return 'enqueue';
      case ast.StdFuncCallKind.DEQUEUE:
        return 'dequeue';
      case ast.StdFuncCallKind.PUSH:
        return 'push';
      case ast.StdFuncCallKind.POP:
        return 'pop';
      case ast.StdFuncCallKind.PUSH_FRONT:
        return 'push_front';
      case ast.StdFuncCallKind.POP_FRONT:
        return 'pop_front';
      case ast.StdFuncCallKind.PUSH_BACK:
        return 'push_back';
      case ast.StdFuncCallKind.POP_BACK:
        return 'pop_back';
      case ast.StdFuncCallKind.DIMS:
        return 'dims';
      case ast.StdFuncCallKind.LEN:
        return 'len';
      case ast.StdFuncCallKind.STR_TO_LIST:
        return 'str_to_list';
      case ast.StdFuncCallKind.LIST_TO_STR:
        return 'list_to_str';
      case ast.StdFuncCallKind.SLICE:
        return 'slice';
      default:
        throw new Error(`Internal Error: Unknown std function ${stdFuncCallKind}`);
    }
  }

  private emitStdFuncCallExpr(stdFuncCallExprNode: ast.ExprNode): void {
    this.pushSpanNodeStack(stdFuncCallExprNode);
    const stdFuncCallNode = stdFuncCallExprNode.getStdFuncCall();
    const keyword = this.stdFuncCallKeyword(
      stdFuncCallNode.getKind(),
    );
    this.emitKeyword(keyword);
    this.emitSeperator('(');
    if (stdFuncCallNode.hasUnaryExpr()) {
      this.emitExpr(stdFuncCallNode.getUnaryExpr());
    } else if (stdFuncCallNode.hasBinaryExpr()) {
      this.emitExpr(stdFuncCallNode.getBinaryExpr().getExpr1());
      this.emitSeperator(',');
      this.emitWhitespace();
      this.emitExpr(stdFuncCallNode.getBinaryExpr().getExpr2());
    } else if (stdFuncCallNode.hasTernaryExpr()) {
      this.emitExpr(stdFuncCallNode.getTernaryExpr().getExpr1());
      this.emitSeperator(',');
      this.emitWhitespace();
      this.emitExpr(stdFuncCallNode.getTernaryExpr().getExpr2());
      this.emitSeperator(',');
      this.emitWhitespace();
      this.emitExpr(stdFuncCallNode.getTernaryExpr().getExpr3());
    }
    this.emitSeperator(')');
    this.instuctionSetBuilder.emitInstruction(
      new instruction_set.ExprInstruction(
        this.currentStepIdNode(),
        stdFuncCallExprNode,
      ),
    );
    this.reduceSpanNodeStack(stdFuncCallExprNode);
  }

  private emitFuncCallExpr(funcCallExprNode: ast.ExprNode): void {
    const funcCallNode = funcCallExprNode.getFuncCall();
    this.pushSpanNodeStack(funcCallExprNode);
    this.emitExpr(funcCallNode.getFuncExpr());
    this.emitSeperator('(');
    this.emitNameExprList(
      span_nodes.SpanNodeTextKind.ParamName,
      funcCallNode.getArguments(),
    );
    this.emitSeperator(')');
    this.instuctionSetBuilder.emitInstruction(
      new instruction_set.ReduceNameExprListInstruction(
        this.currentStepIdNode(),
        funcCallNode.getArguments(),
      ),
    );
    this.instuctionSetBuilder.emitInstruction(
      new instruction_set.ExprInstruction(
        this.currentStepIdNode(),
        funcCallExprNode,
      ),
    );
    this.reduceSpanNodeStack(funcCallExprNode);
  }

  private emitUnaryExpr(
    operator: string,
    unaryExprNode: ast.ExprNode,
  ): void {
    if (unaryExprNode.getStepId() !== undefined) {
      this.pushSpanNodeStack(unaryExprNode);
    }
    this.emitUnaryOperator(operator);
    this.emitExpr(unaryExprNode.getUnaryExpr(), ast.ExprPrecedence.EP_UNARY);
    this.instuctionSetBuilder.emitInstruction(
      new instruction_set.ExprInstruction(
        this.currentStepIdNode(),
        unaryExprNode,
      ),
    );
    if (unaryExprNode.getStepId() !== undefined) {
      this.reduceSpanNodeStack(unaryExprNode);
    }
  }

  private emitBinaryExpr(
    operator: string,
    exprPrecedence: ast.ExprPrecedenceMap[keyof ast.ExprPrecedenceMap],
    binaryExprNode: ast.ExprNode,
    parentExprPrecedence: ast.ExprPrecedenceMap[keyof ast.ExprPrecedenceMap],
  ): void {
    if (binaryExprNode.getStepId() !== undefined) {
      this.pushSpanNodeStack(binaryExprNode);
    }
    if (parentExprPrecedence < exprPrecedence) {
      this.emitSeperator('(');
    }
    this.emitExpr(binaryExprNode.getBinaryExpr().getExpr1(), exprPrecedence);
    this.emitBinaryOperator(operator);
    this.emitExpr(binaryExprNode.getBinaryExpr().getExpr2(), exprPrecedence);
    this.instuctionSetBuilder.emitInstruction(
      new instruction_set.ExprInstruction(
        this.currentStepIdNode(),
        binaryExprNode,
      ),
    );
    if (parentExprPrecedence < exprPrecedence) {
      this.emitSeperator(')');
    }
    if (binaryExprNode.getStepId() !== undefined) {
      this.reduceSpanNodeStack(binaryExprNode);
    }
  }

  private emitExpr(
    exprNode: ast.ExprNode,
    parentExprPrecedence: ast.ExprPrecedenceMap[
      keyof ast.ExprPrecedenceMap] = ast.ExprPrecedence.EP_BOOLEAN,
  ): void {
    switch (exprNode.getKind()) {
      case ast.ExprNodeKind.INT_LTRL:
        this.instuctionSetBuilder.emitInstruction(
          new instruction_set.ExprInstruction(
            this.currentStepIdNode(),
            exprNode,
          ),
        );
        return this.emitLiteral(exprNode.getIntLiteral().getValue());
      case ast.ExprNodeKind.NUMBER_LTRL:
        this.instuctionSetBuilder.emitInstruction(
          new instruction_set.ExprInstruction(
            this.currentStepIdNode(),
            exprNode,
          ),
        );
        return this.emitLiteral(exprNode.getNumLiteral().getValue());
      case ast.ExprNodeKind.TRUE_LTRL:
        this.instuctionSetBuilder.emitInstruction(
          new instruction_set.ExprInstruction(
            this.currentStepIdNode(),
            exprNode,
          ),
        );
        return this.emitLiteral('true');
      case ast.ExprNodeKind.FALSE_LTRL:
        this.instuctionSetBuilder.emitInstruction(
          new instruction_set.ExprInstruction(
            this.currentStepIdNode(),
            exprNode,
          ),
        );
        return this.emitLiteral('false');
      case ast.ExprNodeKind.CHAR_LTRL:
        this.instuctionSetBuilder.emitInstruction(
          new instruction_set.ExprInstruction(
            this.currentStepIdNode(),
            exprNode,
          ),
        );
        return this.emitLiteral(
          `'${String.fromCharCode(exprNode.getCharLiteral().getValue())}'`,
        );
      case ast.ExprNodeKind.STRING_LTRL:
        this.instuctionSetBuilder.emitInstruction(
          new instruction_set.ExprInstruction(
            this.currentStepIdNode(),
            exprNode,
          ),
        );
        return this.emitLiteral(`"${exprNode.getStringLiteral().getValue()}"`);
      case ast.ExprNodeKind.BITS_LTRL:
        this.instuctionSetBuilder.emitInstruction(
          new instruction_set.ExprInstruction(
            this.currentStepIdNode(),
            exprNode,
          ),
        );
        return this.emitLiteral(
          `#${exprNode.getBitsLiteral().getNumBits()
          }x${exprNode.getBitsLiteral().getValueHex()}`,
        );
      case ast.ExprNodeKind.NULL_LTRL:
        this.instuctionSetBuilder.emitInstruction(
          new instruction_set.ExprInstruction(
            this.currentStepIdNode(),
            exprNode,
          ),
        );
        return this.emitLiteral('null');
      case ast.ExprNodeKind.TUPLE_CTOR:
        return this.emitTupleCtorExpr(exprNode);
      case ast.ExprNodeKind.ARRAY_CTOR:
        return this.emitArrayCtorExpr(exprNode);
      case ast.ExprNodeKind.STRUCT_CTOR:
        return this.emitStructCtorExpr(exprNode);
      case ast.ExprNodeKind.LIST_CTOR:
        return this.emitContainerCtorExpr(ast.TypeNodeKind.LIST, exprNode);
      case ast.ExprNodeKind.SET_CTOR:
        return this.emitContainerCtorExpr(ast.TypeNodeKind.SET, exprNode);
      case ast.ExprNodeKind.QUEUE_CTOR:
        return this.emitContainerCtorExpr(ast.TypeNodeKind.QUEUE, exprNode);
      case ast.ExprNodeKind.STACK_CTOR:
        return this.emitContainerCtorExpr(ast.TypeNodeKind.STACK, exprNode);
      case ast.ExprNodeKind.DEQUE_CTOR:
        return this.emitContainerCtorExpr(ast.TypeNodeKind.DEQUE, exprNode);
      case ast.ExprNodeKind.MAP_CTOR:
        return this.emitMapCtorExpr(exprNode);
      case ast.ExprNodeKind.NAME_ACCESS:
        this.instuctionSetBuilder.emitInstruction(
          new instruction_set.ExprInstruction(
            this.currentStepIdNode(),
            exprNode,
          ),
        );
        return this.emitName(
          span_nodes.SpanNodeTextKind.RefName,
          exprNode.getName(),
        );
      case ast.ExprNodeKind.INDEXED_ACCESS:
        return this.emitIndexedAccessExpr(exprNode);
      case ast.ExprNodeKind.FIELD_ACCESS:
        return this.emitFieldAccessExpr(exprNode);
      case ast.ExprNodeKind.STD_FUNC_CALL:
        return this.emitStdFuncCallExpr(exprNode);
      case ast.ExprNodeKind.FUNC_CALL:
        return this.emitFuncCallExpr(exprNode);
      case ast.ExprNodeKind.NEGATION:
        return this.emitUnaryExpr('-', exprNode);
      case ast.ExprNodeKind.BIT_NOT:
        return this.emitUnaryExpr('~', exprNode);
      case ast.ExprNodeKind.BOOL_NOT:
        return this.emitUnaryExpr('!', exprNode);
      case ast.ExprNodeKind.POWER:
        return this.emitBinaryExpr(
          '**',
          ast.ExprPrecedence.EP_POWER,
          exprNode,
          parentExprPrecedence,
        );
      case ast.ExprNodeKind.MULTIPLICATION:
        return this.emitBinaryExpr(
          '*',
          ast.ExprPrecedence.EP_MULTIPLICATIVE,
          exprNode,
          parentExprPrecedence,
        );
      case ast.ExprNodeKind.DIVISION:
        return this.emitBinaryExpr(
          '/',
          ast.ExprPrecedence.EP_MULTIPLICATIVE,
          exprNode,
          parentExprPrecedence,
        );
      case ast.ExprNodeKind.MODULO:
        return this.emitBinaryExpr(
          '%',
          ast.ExprPrecedence.EP_MULTIPLICATIVE,
          exprNode,
          parentExprPrecedence,
        );
      case ast.ExprNodeKind.BIT_AND:
        return this.emitBinaryExpr(
          '&',
          ast.ExprPrecedence.EP_BITWISE,
          exprNode,
          parentExprPrecedence,
        );
      case ast.ExprNodeKind.BIT_OR:
        return this.emitBinaryExpr(
          '|',
          ast.ExprPrecedence.EP_BITWISE,
          exprNode,
          parentExprPrecedence,
        );
      case ast.ExprNodeKind.BIT_XOR:
        return this.emitBinaryExpr(
          '^',
          ast.ExprPrecedence.EP_BITWISE,
          exprNode,
          parentExprPrecedence,
        );
      case ast.ExprNodeKind.LSHIFT:
        return this.emitBinaryExpr(
          '<<',
          ast.ExprPrecedence.EP_BIT_SHIFT,
          exprNode,
          parentExprPrecedence,
        );
      case ast.ExprNodeKind.RSHIFT:
        return this.emitBinaryExpr(
          '>>',
          ast.ExprPrecedence.EP_BIT_SHIFT,
          exprNode,
          parentExprPrecedence,
        );
      case ast.ExprNodeKind.ADDITION:
        return this.emitBinaryExpr(
          '+',
          ast.ExprPrecedence.EP_ADDITIVE,
          exprNode,
          parentExprPrecedence,
        );
      case ast.ExprNodeKind.SUBTRACTION:
        return this.emitBinaryExpr(
          '-',
          ast.ExprPrecedence.EP_ADDITIVE,
          exprNode,
          parentExprPrecedence,
        );
      case ast.ExprNodeKind.EQ:
        return this.emitBinaryExpr(
          '==',
          ast.ExprPrecedence.EP_COMPARE,
          exprNode,
          parentExprPrecedence,
        );
      case ast.ExprNodeKind.NEQ:
        return this.emitBinaryExpr(
          '!=',
          ast.ExprPrecedence.EP_ADDITIVE,
          exprNode,
          parentExprPrecedence,
        );
      case ast.ExprNodeKind.LT:
        return this.emitBinaryExpr(
          '<',
          ast.ExprPrecedence.EP_ADDITIVE,
          exprNode,
          parentExprPrecedence,
        );
      case ast.ExprNodeKind.LTEQ:
        return this.emitBinaryExpr(
          '<=',
          ast.ExprPrecedence.EP_ADDITIVE,
          exprNode,
          parentExprPrecedence,
        );
      case ast.ExprNodeKind.GT:
        return this.emitBinaryExpr(
          '>',
          ast.ExprPrecedence.EP_ADDITIVE,
          exprNode,
          parentExprPrecedence,
        );
      case ast.ExprNodeKind.GTEQ:
        return this.emitBinaryExpr(
          '>=',
          ast.ExprPrecedence.EP_ADDITIVE,
          exprNode,
          parentExprPrecedence,
        );
      case ast.ExprNodeKind.BOOL_AND:
        return this.emitBinaryExpr(
          '&&',
          ast.ExprPrecedence.EP_BOOLEAN,
          exprNode,
          parentExprPrecedence,
        );
      case ast.ExprNodeKind.BOOL_OR:
        return this.emitBinaryExpr(
          '||',
          ast.ExprPrecedence.EP_BOOLEAN,
          exprNode,
          parentExprPrecedence,
        );
      default:
        throw new Error(`Internal error: unknown expr node kind: ${exprNode.getKind()}`);
    }
  }

  private emitLValueExpr(lValueExpr: ast.LValueExprNode): void {
    if (lValueExpr.hasName()) {
      this.emitName(
        span_nodes.SpanNodeTextKind.RefName,
        lValueExpr.getName(),
      );
      this.instuctionSetBuilder.emitInstruction(
        new instruction_set.LValueVarInstruction(
          this.currentStepIdNode(),
          lValueExpr.getName(),
        ),
      );
    } else if (lValueExpr.hasIndexedAccess()) {
      const lIndexedAccessNode = lValueExpr.getIndexedAccess();
      this.pushSpanNodeStack();
      this.emitLValueExpr(lIndexedAccessNode.getContainerExpr());
      this.emitSeperator('[');
      this.emitExprList(lIndexedAccessNode.getIndices());
      this.emitSeperator(']');
      this.instuctionSetBuilder.emitInstruction(
        new instruction_set.ReduceExprListInstruction(
          this.currentStepIdNode(),
          lIndexedAccessNode.getIndices(),
        ),
      );
      this.instuctionSetBuilder.emitInstruction(
        new instruction_set.LValueIndexInstruction(
          this.currentStepIdNode(),
          lIndexedAccessNode,
        ),
      );
      this.reduceSpanNodeStack();
    } else if (lValueExpr.hasFieldAccess()) {
      const lFieldAccessNode = lValueExpr.getFieldAccess();
      this.pushSpanNodeStack();
      this.emitLValueExpr(lFieldAccessNode.getStructExpr());
      this.emitSeperator('.');
      this.emitName(
        span_nodes.SpanNodeTextKind.FieldName,
        lFieldAccessNode.getFieldName(),
      );
      this.instuctionSetBuilder.emitInstruction(
        new instruction_set.LValueFieldInstruction(
          this.currentStepIdNode(),
          lFieldAccessNode,
        ),
      );
      this.reduceSpanNodeStack();
    }
  }

  private emitVarDeclStmt(varDeclNode: ast.StatementNode): void {
    this.pushSpanNodeStack(varDeclNode);
    this.emitKeyword('vardecl');
    this.emitWhitespace();
    this.emitNameTypeList(
      span_nodes.SpanNodeTextKind.DefName,
      varDeclNode.getVarDecl().getDeclarations(),
    );
    this.emitSeperator(';');
    this.instuctionSetBuilder.emitInstruction(
      new instruction_set.VarDeclInstruction(
        this.currentStepIdNode(),
        varDeclNode.getVarDecl(),
      ),
    );
    this.reduceSpanNodeStack(varDeclNode);
  }

  private emitVarDefStmt(varDefNode: ast.StatementNode): void {
    this.pushSpanNodeStack(varDefNode);
    this.emitKeyword('vardef');
    this.emitWhitespace();
    const definition = varDefNode.getVarDef();
    this.emitNameType(
      span_nodes.SpanNodeTextKind.DefName,
      definition.getVarName(),
    );
    this.emitBinaryOperator('=');
    this.emitExpr(definition.getValueExpr());
    this.emitSeperator(';');
    this.instuctionSetBuilder.emitInstruction(
      new instruction_set.VarDefInstruction(
        this.currentStepIdNode(),
        definition,
      ),
    );
    this.reduceSpanNodeStack(varDefNode);
  }

  private emitAssignStmt(assignStmtNode: ast.StatementNode): void {
    this.pushSpanNodeStack(assignStmtNode);
    const assignNode = assignStmtNode.getAssign();
    this.emitLValueExpr(assignNode.getLValueExpr());
    this.emitBinaryOperator('=');
    this.emitExpr(assignNode.getRValueExpr());
    this.emitSeperator(';');
    this.instuctionSetBuilder.emitInstruction(
      new instruction_set.AssignInstruction(
        this.currentStepIdNode(),
        assignNode,
      ),
    );
    this.reduceSpanNodeStack(assignStmtNode);
  }

  private emitExprStmt(exprStmtNode: ast.StatementNode): void {
    this.pushSpanNodeStack(exprStmtNode);
    this.emitExpr(exprStmtNode.getExpr());
    this.emitSeperator(';');
    this.instuctionSetBuilder.emitInstruction(
      new instruction_set.PopInstruction(
        this.currentStepIdNode(),
        1,
      ),
    );
    this.reduceSpanNodeStack(exprStmtNode);
  }

  private emitPrintStmt(printStmtNode: ast.StatementNode): void {
    this.pushSpanNodeStack(printStmtNode);
    this.emitKeyword('print');
    this.emitWhitespace();
    this.emitExprList(printStmtNode.getExprList());
    this.emitSeperator(';');
    this.instuctionSetBuilder.emitInstruction(
      new instruction_set.PrintInstruction(
        this.currentStepIdNode(),
        printStmtNode.getExprList().getExprsList().length,
      ),
    );
    this.reduceSpanNodeStack(printStmtNode);
  }

  private emitReturnStmt(returnStmtNode: ast.StatementNode): void {
    this.pushSpanNodeStack(returnStmtNode);
    this.emitKeyword('return');
    if (returnStmtNode.hasExpr()) {
      this.emitWhitespace();
      this.emitExpr(returnStmtNode.getExpr());
    } else {
      this.instuctionSetBuilder.emitInstruction(
        new instruction_set.PushVoidInstruction(
          this.currentStepIdNode(),
        ),
      );
    }
    this.emitSeperator(';');
    this.instuctionSetBuilder.emitInstruction(
      new instruction_set.ReturnInstruction(
        this.currentStepIdNode(),
      ),
    );
    this.reduceSpanNodeStack(returnStmtNode);
  }

  private emitIfElseStmt(ifElseStmtNode: ast.StatementNode): void {
    this.pushSpanNodeStack(ifElseStmtNode);
    const ifElseNode = ifElseStmtNode.getIfElse();
    this.emitKeyword('if');
    this.emitWhitespace();
    this.emitExpr(ifElseNode.getIfExprBlock().getExpr());
    const blockEndJumps: Array<instruction_set.JumpInstruction> = [];
    let prevIfNotJump = this.instuctionSetBuilder.emitIfNotJumpInstruction(
      this.currentStepIdNode(),
    );
    this.emitWhitespace();
    this.emitBlock(ifElseNode.getIfExprBlock().getBlock());
    blockEndJumps.push(
      this.instuctionSetBuilder.emitJumpInstruction(
        this.currentStepIdNode(),
      ),
    );
    for (const elIfExprBlock of ifElseNode.getElifExprBlockList()) {
      this.emitWhitespace();
      this.emitKeyword('elif');
      this.emitWhitespace();
      this.instuctionSetBuilder.patchJumpInstructionToCurrent(prevIfNotJump);
      this.emitExpr(elIfExprBlock.getExpr());
      prevIfNotJump = this.instuctionSetBuilder.emitIfNotJumpInstruction(
        this.currentStepIdNode(),
      );
      this.emitWhitespace();
      this.emitBlock(elIfExprBlock.getBlock());
      blockEndJumps.push(
        this.instuctionSetBuilder.emitJumpInstruction(
          this.currentStepIdNode(),
        ),
      );
    }
    this.instuctionSetBuilder.patchJumpInstructionToCurrent(prevIfNotJump);
    if (ifElseNode.getElseBlock() !== undefined) {
      this.emitWhitespace();
      this.emitKeyword('else');
      this.emitWhitespace();
      this.emitBlock(ifElseNode.getElseBlock());
    }
    for (const jumpInstruction of blockEndJumps) {
      this.instuctionSetBuilder.patchJumpInstructionToCurrent(jumpInstruction);
    }
    this.reduceSpanNodeStack(ifElseStmtNode);
  }

  private emitWhileStmt(whileStmtNode: ast.StatementNode): void {
    this.pushSpanNodeStack(whileStmtNode);
    const whileNode = whileStmtNode.getWhile();
    if (whileNode.getLabel() !== undefined) {
      this.emitName(
        span_nodes.SpanNodeTextKind.DefName,
        whileNode.getLabel(),
      );
      this.emitSeperator(':');
      this.emitWhitespace();
      this.instuctionSetBuilder.pushLoop(whileNode.getLabel().getName());
    } else {
      this.instuctionSetBuilder.pushLoop();
    }
    this.emitKeyword('while');
    this.emitWhitespace();
    this.emitExpr(whileNode.getExprBlock().getExpr());
    const ifNotJump = this.instuctionSetBuilder.emitIfNotJumpInstruction(
      this.currentStepIdNode(),
    );
    this.emitWhitespace();
    this.emitBlock(whileNode.getExprBlock().getBlock());
    const jumpbackInstr = this.instuctionSetBuilder.emitJumpInstruction(
      this.currentStepIdNode(),
    );
    jumpbackInstr.setLocation(this.instuctionSetBuilder.loopStart());
    this.instuctionSetBuilder.patchJumpInstructionToCurrent(ifNotJump);
    this.instuctionSetBuilder.popLoop();
    this.reduceSpanNodeStack(whileStmtNode);
  }

  private emitBreakStmt(breakStmtNode: ast.StatementNode): void {
    this.pushSpanNodeStack(breakStmtNode);
    this.emitKeyword('break');
    if (breakStmtNode.getLabel() !== undefined) {
      this.emitWhitespace();
      this.emitName(
        span_nodes.SpanNodeTextKind.RefName,
        breakStmtNode.getLabel(),
      );
      this.instuctionSetBuilder.emitBreakInstruction(
        this.currentStepIdNode(),
        breakStmtNode.getLabel().getName(),
      );
    } else {
      this.instuctionSetBuilder.emitBreakInstruction(
        this.currentStepIdNode(),
      );
    }
    this.emitSeperator(';');
    this.reduceSpanNodeStack(breakStmtNode);
  }

  private emitContinueStmt(continueStmtNode: ast.StatementNode): void {
    this.pushSpanNodeStack(continueStmtNode);
    this.emitKeyword('continue');
    if (continueStmtNode.getLabel() !== undefined) {
      this.emitWhitespace();
      this.emitName(
        span_nodes.SpanNodeTextKind.RefName,
        continueStmtNode.getLabel(),
      );
      this.instuctionSetBuilder.emitContinueInstruction(
        this.currentStepIdNode(),
        continueStmtNode.getLabel().getName(),
      );
    } else {
      this.instuctionSetBuilder.emitContinueInstruction(
        this.currentStepIdNode(),
      );
    }
    this.emitSeperator(';');
    this.reduceSpanNodeStack(continueStmtNode);
  }

  private emitStmt(stmtNode: ast.StatementNode): void {
    switch (stmtNode.getKind()) {
      case ast.StmtNodeKind.ASSIGN:
        return this.emitAssignStmt(stmtNode);
      case ast.StmtNodeKind.EXPR_STMT:
        return this.emitExprStmt(stmtNode);
      case ast.StmtNodeKind.VAR_DECL:
        return this.emitVarDeclStmt(stmtNode);
      case ast.StmtNodeKind.VAR_DEFS:
        return this.emitVarDefStmt(stmtNode);
      case ast.StmtNodeKind.PRINT:
        return this.emitPrintStmt(stmtNode);
      case ast.StmtNodeKind.RETURN:
        return this.emitReturnStmt(stmtNode);
      case ast.StmtNodeKind.IF:
        return this.emitIfElseStmt(stmtNode);
      case ast.StmtNodeKind.WHILE:
        return this.emitWhileStmt(stmtNode);
      case ast.StmtNodeKind.BREAK:
        return this.emitBreakStmt(stmtNode);
      case ast.StmtNodeKind.CONTINUE:
        return this.emitContinueStmt(stmtNode);
      default:
        throw new Error(`Internal error: unknown statement: ${stmtNode.getKind()}`);
    }
  }

  private emitBlock(blockNode: ast.BlockNode): void {
    this.pushSpanNodeStack(blockNode);
    this.emitBlockStart();
    this.emitNewLine();
    for (const stmtNode of blockNode.getStatementsList()) {
      this.emitIndent();
      this.emitStmt(stmtNode);
      this.emitNewLine();
    }
    this.emitBlockEnd();
    this.reduceSpanNodeStack(blockNode);
  }

  private emitAliasTypeDef(typeDefNode: ast.TypeDefNode): void {
    this.pushSpanNodeStack();
    this.emitKeyword('typedef');
    this.emitWhitespace();
    this.emitName(
      span_nodes.SpanNodeTextKind.DefName,
      typeDefNode.getName(),
    );
    this.emitBinaryOperator('=');
    this.emitType(typeDefNode.getAliasDef());
    this.emitSeperator(';');
    this.reduceSpanNodeStack();
  }

  private emitStructDef(structDefNode: ast.TypeDefNode): void {
    this.pushSpanNodeStack();
    this.emitKeyword('typedef');
    this.emitWhitespace();
    this.emitName(
      span_nodes.SpanNodeTextKind.DefName,
      structDefNode.getName(),
    );
    this.emitBinaryOperator('=');
    this.emitKeyword('struct');
    this.emitWhitespace();
    this.emitSeperator('{');
    this.doIndent();
    this.emitNewLine();
    for (const field of structDefNode.getStructDef().getFields().getNameTypesList()) {
      this.emitIndent();
      this.emitNameType(
        span_nodes.SpanNodeTextKind.DefName,
        field,
      );
      this.emitSeperator(',');
      this.emitNewLine();
    }
    this.doUnindent();
    this.emitIndent();
    this.emitSeperator('}');
    this.reduceSpanNodeStack();
  }

  private emitEnumDef(enumDefNode: ast.TypeDefNode): void {
    this.pushSpanNodeStack();
    this.emitKeyword('typedef');
    this.emitWhitespace();
    this.emitName(
      span_nodes.SpanNodeTextKind.DefName,
      enumDefNode.getName(),
    );
    this.emitBinaryOperator('=');
    this.emitKeyword('enum');
    this.emitWhitespace();
    this.emitSeperator('{');
    this.doIndent();
    this.emitNewLine();
    for (const enumElementNode of enumDefNode.getEnumDef().getEnumElementsList()) {
      this.emitIndent();
      this.emitName(
        span_nodes.SpanNodeTextKind.DefName,
        enumElementNode,
      );
      this.emitSeperator(',');
      this.emitNewLine();
    }
    this.doUnindent();
    this.emitIndent();
    this.emitSeperator('}');
    this.reduceSpanNodeStack();
  }

  private emitTypeDef(typeDefNode: ast.TypeDefNode): void {
    switch (typeDefNode.getKind()) {
      case ast.TypeDefNodeKind.ALIAS_DEF:
        return this.emitAliasTypeDef(typeDefNode);
      case ast.TypeDefNodeKind.STRUCT_DEF:
        return this.emitStructDef(typeDefNode);
      case ast.TypeDefNodeKind.ENUM_DEF:
        return this.emitEnumDef(typeDefNode);
      default:
        throw new Error(`Internal error: Unknown type def node ${typeDefNode.getKind()}`);
    }
  }

  private emitFuncDef(funcDefNode: ast.FuncDefNode): void {
    this.resetStepIdNodeStack();
    this.instuctionSetBuilder = new instruction_set.InstructionSetBuilder();
    this.pushSpanNodeStack();
    this.emitKeyword('funcdef');
    this.emitWhitespace();
    this.emitName(
      span_nodes.SpanNodeTextKind.DefName,
      funcDefNode.getName(),
    );
    this.emitBinaryOperator('=');
    this.emitFuncType(funcDefNode.getFuncType());
    this.emitWhitespace();
    this.emitBlock(funcDefNode.getBlock());
    this.reduceSpanNodeStack();
    this.instructionsTable.set(
      funcDefNode.getName().getName(),
      this.instuctionSetBuilder.instructions,
    );
    this.sourceMap.addFuncRootStepId(
      funcDefNode.getName().getName(),
      funcDefNode.getBlock().getStepId(),
    );
  }

  private emitDataDef(dataDefNode: ast.DataDefNode): void {
    this.resetStepIdNodeStack();
    this.instuctionSetBuilder = new instruction_set.InstructionSetBuilder();
    this.pushSpanNodeStack();
    this.emitKeyword('let');
    this.emitWhitespace();
    this.emitName(
      span_nodes.SpanNodeTextKind.DefName,
      dataDefNode.getName(),
    );
    this.emitBinaryOperator('=');
    this.emitExpr(dataDefNode.getExpr());
    this.emitSeperator(';');
    this.reduceSpanNodeStack();
    this.instructionsTable.set(
      dataDefNode.getName().getName(),
      this.instuctionSetBuilder.instructions,
    );
    // Ignore the step ids in data def
  }

  public loadProgram(programNode: ast.ProgramNode): void {
    this.typeSystem.loadTypeDefs(programNode.getTypeDefsList());
    for (const typeDefNode of programNode.getTypeDefsList()) {
      this.emitTypeDef(typeDefNode);
      this.emitNewLine();
    }
    this.sourceMap.setIsActive(true);
    for (const funcDefNode of programNode.getFuncDefsList()) {
      this.emitFuncDef(funcDefNode);
      this.emitNewLine();
    }
    this.sourceMap.setIsActive(false);
    for (const dataDefNode of programNode.getDataDefsList()) {
      this.emitDataDef(dataDefNode);
      this.emitNewLine();
    }
    this.sourceMap.setIsActive(true);
    this.resetStepIdNodeStack();
    this.stepIdNodeStack.push(this.sourceMap.programRootStepIdNode);
    this.instuctionSetBuilder = new instruction_set.InstructionSetBuilder();
    for (const stmtNode of programNode.getStatementsList()) {
      this.emitStmt(stmtNode);
      this.emitNewLine();
    }
    this.reduceSpanNodeStack();
  }

  public loadedProgramAST(): LoadedAST {
    return new LoadedAST(
      this.spanNodeStack[0][0],
      this.sourceMap,
      this.typeSystem,
      this.instructionsTable,
      this.instuctionSetBuilder.instructions,
    );
  }
}

export function loadProgram(program: ast.ProgramNode): LoadedAST {
  const astProgramLoader = new ASTProgramLoader();
  astProgramLoader.loadProgram(program);
  return astProgramLoader.loadedProgramAST();
}
