import { Buffer } from 'buffer';
import {
  VuexModule,
  Module,
  Mutation,
  Action,
} from 'vuex-module-decorators';
import * as content from '@/generated/content_pb';
import {
  AlgorithmSummary,
  algorthimSummaryFromPB,
} from '@/store/algorithm_summary';

@Module({
  name: 'algorithmSummariesModule',
  namespaced: true,
})
export default class AlgorithmSummariesModule extends VuexModule {
  public algorithmSummaries: Array<AlgorithmSummary> = [];

  @Mutation
  public setAlgorithmSummaries(algorithmSummaries: Array<AlgorithmSummary>): void {
    this.algorithmSummaries = algorithmSummaries;
  }

  @Action
  public async fetchAlgorithmSummaries(): Promise<void> {
    const resp = await fetch('/gen/summaries.pb');
    const encodedPBBase64 = await resp.text();
    const serializedPBBin = Buffer.from(encodedPBBase64, 'base64');
    const summaries = content.AlgorithmSummaries.deserializeBinary(serializedPBBin);
    const algorithmSummaries: Array<AlgorithmSummary> = [];
    for (const algoSummaryPB of summaries.getSummariesList()) {
      algorithmSummaries.push(algorthimSummaryFromPB(algoSummaryPB));
    }
    this.setAlgorithmSummaries(algorithmSummaries);
  }
}
