






























import {
  Vue,
  Component,
} from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { AlgorithmSummary } from '@/store/algorithm_summary';
import VueBaseComponent from '@/components/VueBaseComponent';
import AlgoCard from '@/components/AlgoCard.vue';

const algorithmSummariesModule = namespace('algorithmSummariesModule');

@Component({
  components: {
    AlgoCard,
  },
})
export default class Home extends VueBaseComponent {
  @algorithmSummariesModule.State
  algorithmSummaries: Array<AlgorithmSummary>;

  filter: string = '';

  @algorithmSummariesModule.Action
  fetchAlgorithmSummaries;

  get filteredAlgorithmList(): Array<AlgorithmSummary> {
    if (this.filter.length === 0) {
      return this.algorithmSummaries;
    }
    const lFilter = this.filter.toLowerCase();
    return this.algorithmSummaries.filter((alg) => {
      if (alg.title.toLowerCase().includes(lFilter)) {
        return true;
      }
      if (alg.programDescription.toLowerCase().includes(lFilter)) {
        return true;
      }
      return false;
    });
  }

  created() {
    this.algorithmSummariesModule.fetchAlgorithmSummaries();
  }
}
