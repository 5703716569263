






















import {
  Component,
  Prop,
} from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import VueBaseComponent from '@/components/VueBaseComponent';
import { VarVizData } from '@/store/rev_module';

@Component
export default class VarNamePill extends VueBaseComponent {
  @Prop({
    type: VarVizData,
    required: true,
  }) readonly varVizData: VarVizData;
}
