import * as content from '@/generated/content_pb';

export class AlgorithmSummary {
  public constructor(
    readonly id: String,
    readonly title: String,
    readonly programDescription: String,
  ) {}
}

export function algorthimSummaryFromPB(
  algoSummaryPB: content.AlgorithmSummary,
): AlgorithmSummary {
  return new AlgorithmSummary(
    algoSummaryPB.getId(),
    algoSummaryPB.getTitle(),
    algoSummaryPB.getProgramDescription(),
  );
}
