










































































import {
  Component,
  Prop,
} from 'vue-property-decorator';
import VueBaseComponent from '@/components/VueBaseComponent';
import VarNamePill from '@/components/revlang/visualizers/VarNamePill.vue';
import { SimpleContainerVarVizData } from '@/store/rev_module';

@Component({
  components: {
    VarNamePill,
  },
})
export default class SimpleContainerVarVisualizer extends VueBaseComponent {
  @Prop({
    type: SimpleContainerVarVizData,
    required: true,
  }) readonly varVizData: SimpleContainerVarVizData;
}
