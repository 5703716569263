







































































































































import { Component } from 'vue-property-decorator';
import {
  Mutation,
  namespace,
} from 'vuex-class';
import VueBaseComponent from '@/components/VueBaseComponent';
import CodeView from '@/components/revlang/CodeView.vue';
import AlgoCard from '@/components/AlgoCard.vue';
import { AlgorithmSummary } from '@/store/algorithm_summary';

const revModule = namespace('revModule');

@Component({
  components: {
    CodeView,
    AlgoCard,
  },
})
export default class CodeStepper extends VueBaseComponent {
  algorithmSummaryShown: boolean = false;

  @revModule.State
  algorithmSummary: AlgorithmSummary;

  @revModule.State('isLoaded')
  canRestart: boolean;

  @revModule.State
  canUndoStep: boolean;

  @revModule.State
  canRedoStep: boolean;

  @revModule.State
  canStepOver: boolean;

  @revModule.State
  canStepInto: boolean;

  @revModule.State
  canStepOut: boolean;

  @revModule.Mutation
  restart: () => void;

  @revModule.Mutation
  undoStep: () => void;

  @revModule.Mutation
  redoStep: () => void;

  @revModule.Mutation
  stepOver: () => void;

  @revModule.Mutation
  stepInto: () => void;

  @revModule.Mutation
  stepOut: () => void;

  info(): void {
    this.algorithmSummaryShown = true;
  }
}
