




















import { Component } from 'vue-property-decorator';
import VueBaseComponent from '@/components/VueBaseComponent';
import StateVisualizer from '@/components/revlang/visualizers/StateVisualizer.vue';
@Component({
  components: {
    StateVisualizer,
  },
})
export default class StateVisualizerContainer extends VueBaseComponent {
}
