var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex flex-column",staticStyle:{"height":"100%"}},[_c('div',{staticClass:"flex-grow-0"},[_c('div',{staticStyle:{"overflow-x":"scroll"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({},on),[_c('v-btn',{staticClass:"ma-1",attrs:{"color":"warning","small":"","disabled":!_vm.canRestart},on:{"click":_vm.restart}},[_c('v-icon',[_vm._v("mdi-restart")])],1)],1)]}}])},[_c('span',[_vm._v("Restart code execution")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({},on),[_c('v-btn',{staticClass:"ma-1",attrs:{"color":"secondary","small":"","disabled":!_vm.canUndoStep},on:{"click":_vm.undoStep}},[_c('v-icon',[_vm._v("mdi-undo-variant")])],1)],1)]}}])},[_c('span',[_vm._v("Undo the last step")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({},on),[_c('v-btn',{staticClass:"ma-1",attrs:{"color":"secondary","small":"","disabled":!_vm.canRedoStep},on:{"click":_vm.redoStep}},[_c('v-icon',[_vm._v("mdi-redo-variant")])],1)],1)]}}])},[_c('span',[_vm._v("Redo the last step")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({},on),[_c('v-btn',{staticClass:"ma-1",attrs:{"color":"primary","small":"","disabled":!_vm.canStepInto},on:{"click":_vm.stepInto}},[_c('v-icon',[_vm._v("mdi-debug-step-into")])],1)],1)]}}])},[_c('span',[_vm._v("Step into the code block")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({},on),[_c('v-btn',{staticClass:"ma-1",attrs:{"color":"primary","small":"","disabled":!_vm.canStepOver},on:{"click":_vm.stepOver}},[_c('v-icon',[_vm._v("mdi-debug-step-over")])],1)],1)]}}])},[_c('span',[_vm._v("Step over the code block")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({},on),[_c('v-btn',{staticClass:"ma-1",attrs:{"color":"primary","small":"","disabled":!_vm.canStepOut},on:{"click":_vm.stepOut}},[_c('v-icon',[_vm._v("mdi-debug-step-out")])],1)],1)]}}])},[_c('span',[_vm._v("Step out of the code block")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({},on),[_c('v-btn',{staticClass:"ma-1 ml-4",attrs:{"color":"primary","small":""},on:{"click":_vm.info}},[_c('v-icon',[_vm._v("mdi-information-outline")])],1)],1)]}}])},[_c('span',[_vm._v("Probleam Details")])])],1)]),_c('div',{staticClass:"overflow-auto flex-grow-1"},[_c('CodeView',{staticClass:"ml-2 mr-2 mb-2"})],1),_c('v-dialog',{attrs:{"max-width":"400"},model:{value:(_vm.algorithmSummaryShown),callback:function ($$v) {_vm.algorithmSummaryShown=$$v},expression:"algorithmSummaryShown"}},[_c('AlgoCard',{attrs:{"algorithm-summary":_vm.algorithmSummary,"visualize-link":false}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }