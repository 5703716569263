import Vue from 'vue';
import { DynamicReactiveRefs } from '@/utils/reactive_refs';
import App from '@/App.vue';
import router from '@/router';
import store from '@/store';
import vuetify from '@/plugins/vuetify';

Vue.use(DynamicReactiveRefs);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
}).$mount('#app');
