


























import { Component } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import VueBaseComponent from '@/components/VueBaseComponent';

const revModule = namespace('revModule');

@Component
export default class CallStackView extends VueBaseComponent {
  @revModule.Mutation
  selectCallFrame: (index: number) => void;

  get lastIndex(): number {
    return this.revModule.callStack.length - 1;
  }

  get displayCallStack(): Array<string> {
    const cs = this.revModule.callStack;
    return [...cs].reverse();
  }

  get selectedCallStackIndex(): number {
    return this.revModule.callStack.length - this.revModule.selectedCallStackIndex - 1;
  }

  set selectedCallStackIndex(selected: number) {
    this.selectCallFrame(this.revModule.callStack.length - selected - 1);
  }
}
