import * as ast from '@/generated/ast_pb';
import * as values from '@/revlang/values';
import * as instruction_set from '@/revlang/instruction_set';
import * as type_system from '@/revlang/type_system';
import * as runtime from '@/revlang/runtime';

enum ExecTraceKind {
  PUSH_OPSTACK,
  POP_OPSTACK,
  REPLACE_OPSTACK,
  REPLACE_OPSTACK_BINARY,
  REPLACE_OPSTACK_TERNARY,
  REPLACE_OPSTACK_NARY,
  ASSIGN_LOCAL_VAR,
  DECL_LOCAL_VAR,
  DEF_LOCAL_VAR,
  JUMP,
  SET_FUNC_ARGS,
  PUSH_BLOCK,
  POP_BLOCK,
  ASSIGN_INDEXED,
  ASSIGN_MAP_INDEXED,
  ASSIGN_FIELD,
  EMIT_PRINT,
  PUSH_CALL_FRAME,
  POP_CALL_FRAME,

  APPEND_STD_FUNC,
  REMOVE_AT_STD_FUNC,
  INSERT_AT_STD_FUNC,
  EXTEND_AT_STD_FUNC,
  CLEAR_STD_FUNC,
  REVERSE_STD_FUNC,
  ADD_K_STD_FUNC,
  REMOVE_STD_FUNC,
  ENQUEUE_STD_FUNC,
  DEQUEUE_STD_FUNC,
  PUSH_STD_FUNC,
  POP_STD_FUNC,
  PUSH_FRONT_STD_FUNC,
  POP_FRONT_STD_FUNC,
  PUSH_BACK_STD_FUNC,
  POP_BACK_STD_FUNC,
}

export abstract class ExecutionTrace {
  public constructor(
    readonly kind: ExecTraceKind,
    readonly instruction: instruction_set.Instruction,
  ) {}

  public abstract undo(runtime: runtime.RevlangRuntime): void;

  public abstract redo(runtime: runtime.RevlangRuntime): void;
}

export class PushOpStackTrace extends ExecutionTrace {
  public constructor(
    instruction: instruction_set.Instruction,
    readonly callFrame: runtime.CallFrame,
    readonly pushedValue: values.Value,
  ) {
    super(ExecTraceKind.PUSH_OPSTACK, instruction);
  }

  public undo(_revlangRuntime: runtime.RevlangRuntime): void {
    this.callFrame.popValue();
  }

  public redo(_revlangRuntime: runtime.RevlangRuntime): void {
    this.callFrame.pushValue(this.pushedValue);
  }
}

export class PopOpStackTrace extends ExecutionTrace {
  public constructor(
    instruction: instruction_set.Instruction,
    readonly callFrame: runtime.CallFrame,
    readonly poppedValues: Array<values.Value>,
  ) {
    super(ExecTraceKind.POP_OPSTACK, instruction);
  }

  public undo(_revlangRuntime: runtime.RevlangRuntime): void {
    this.callFrame.pushValuesArray(this.poppedValues);
  }

  public redo(_revlangRuntime: runtime.RevlangRuntime): void {
    this.callFrame.popValuesArray(this.poppedValues.length);
  }
}

export class ReplaceOpStackTrace extends ExecutionTrace {
  public constructor(
    instruction: instruction_set.Instruction,
    readonly callFrame: runtime.CallFrame,
    readonly oldValue: values.Value,
    readonly newValue: values.Value,
  ) {
    super(ExecTraceKind.REPLACE_OPSTACK, instruction);
  }

  public undo(_revlangRuntime: runtime.RevlangRuntime): void {
    this.callFrame.popValue();
    this.callFrame.pushValue(this.oldValue);
  }

  public redo(_revlangRuntime: runtime.RevlangRuntime): void {
    this.callFrame.popValue();
    this.callFrame.pushValue(this.newValue);
  }
}

export class ReplaceOpStackBinaryTrace extends ExecutionTrace {
  public constructor(
    instruction: instruction_set.Instruction,
    readonly callFrame: runtime.CallFrame,
    readonly oldValue1: values.Value,
    readonly oldValue2: values.Value,
    readonly newValue: values.Value,
  ) {
    super(ExecTraceKind.REPLACE_OPSTACK_BINARY, instruction);
  }

  public undo(_revlangRuntime: runtime.RevlangRuntime): void {
    this.callFrame.popValue();
    this.callFrame.pushValue(this.oldValue1);
    this.callFrame.pushValue(this.oldValue2);
  }

  public redo(_revlangRuntime: runtime.RevlangRuntime): void {
    this.callFrame.popValue();
    this.callFrame.popValue();
    this.callFrame.pushValue(this.newValue);
  }
}

export class ReplaceOpStackTernaryTrace extends ExecutionTrace {
  public constructor(
    instruction: instruction_set.Instruction,
    readonly callFrame: runtime.CallFrame,
    readonly oldValue1: values.Value,
    readonly oldValue2: values.Value,
    readonly oldValue3: values.Value,
    readonly newValue: values.Value,
  ) {
    super(ExecTraceKind.REPLACE_OPSTACK_TERNARY, instruction);
  }

  public undo(_revlangRuntime: runtime.RevlangRuntime): void {
    this.callFrame.popValue();
    this.callFrame.pushValue(this.oldValue1);
    this.callFrame.pushValue(this.oldValue2);
    this.callFrame.pushValue(this.oldValue3);
  }

  public redo(_revlangRuntime: runtime.RevlangRuntime): void {
    this.callFrame.popValue();
    this.callFrame.popValue();
    this.callFrame.popValue();
    this.callFrame.pushValue(this.newValue);
  }
}

export class ReplaceOpStackNaryTrace extends ExecutionTrace {
  public constructor(
    instruction: instruction_set.Instruction,
    readonly callFrame: runtime.CallFrame,
    readonly oldValues: Array<values.Value>,
    readonly newValue: values.Value,
  ) {
    super(ExecTraceKind.REPLACE_OPSTACK_NARY, instruction);
  }

  public undo(_revlangRuntime: runtime.RevlangRuntime): void {
    this.callFrame.popValue();
    this.callFrame.pushValuesArray(this.oldValues);
  }

  public redo(_revlangRuntime: runtime.RevlangRuntime): void {
    this.callFrame.popValuesArray(this.oldValues.length);
    this.callFrame.pushValue(this.newValue);
  }
}

export class AssignLocalVarTrace extends ExecutionTrace {
  public constructor(
    instruction: instruction_set.Instruction,
    readonly callFrame: runtime.CallFrame,
    readonly localVarName: string,
    readonly oldValue: values.Value,
    readonly newValue: values.Value,
  ) {
    super(ExecTraceKind.ASSIGN_LOCAL_VAR, instruction);
  }

  public undo(_revlangRuntime: runtime.RevlangRuntime): void {
    this.callFrame.setLocalVariable(this.localVarName, this.oldValue);
  }

  public redo(_revlangRuntime: runtime.RevlangRuntime): void {
    this.callFrame.setLocalVariable(this.localVarName, this.newValue);
  }
}

export class DeclareLocalVarTrace extends ExecutionTrace {
  public constructor(
    instruction: instruction_set.Instruction,
    readonly callFrame: runtime.CallFrame,
    readonly localVarName: string,
    readonly type: type_system.Type,
    readonly attrs: Array<ast.AttributeNode>,
  ) {
    super(ExecTraceKind.DECL_LOCAL_VAR, instruction);
  }

  public undo(_revlangRuntime: runtime.RevlangRuntime): void {
    this.callFrame.removeLocalVariable(this.localVarName);
  }

  public redo(_revlangRuntime: runtime.RevlangRuntime): void {
    this.callFrame.addLocalVariable(this.localVarName, this.type, this.attrs, undefined);
  }
}

export class DefineLocalVarTrace extends ExecutionTrace {
  public constructor(
    instruction: instruction_set.Instruction,
    readonly callFrame: runtime.CallFrame,
    readonly localVarName: string,
    readonly type: type_system.Type,
    readonly attrs: Array<ast.AttributeNode>,
    readonly value: values.Value,
  ) {
    super(ExecTraceKind.DEF_LOCAL_VAR, instruction);
  }

  public undo(_revlangRuntime: runtime.RevlangRuntime): void {
    this.callFrame.removeLocalVariable(this.localVarName);
  }

  public redo(_revlangRuntime: runtime.RevlangRuntime): void {
    this.callFrame.addLocalVariable(this.localVarName, this.type, this.attrs, this.value);
  }
}

export class JumpTrace extends ExecutionTrace {
  public constructor(
    instruction: instruction_set.Instruction,
    readonly callFrame: runtime.CallFrame,
    readonly fromInstrPointer: number,
    readonly toInstrPointer: number,
  ) {
    super(ExecTraceKind.JUMP, instruction);
  }

  public undo(_revlangRuntime: runtime.RevlangRuntime): void {
    this.callFrame.setCurrentInstuctionPointer(this.fromInstrPointer);
  }

  public redo(_revlangRuntime: runtime.RevlangRuntime): void {
    this.callFrame.setCurrentInstuctionPointer(this.toInstrPointer);
  }
}

export class SetFuncArgsTrace extends ExecutionTrace {
  public constructor(
    instruction: instruction_set.Instruction,
    readonly callFrame: runtime.CallFrame,
    readonly funcValue: values.FuncValue,
    readonly funcArgs: values.IntrNameValueDataValue,
  ) {
    super(ExecTraceKind.SET_FUNC_ARGS, instruction);
  }

  public undo(_revlangRuntime: runtime.RevlangRuntime): void {
    this.callFrame.unSetFuncArgs(this.funcArgs);
  }

  public redo(_revlangRuntime: runtime.RevlangRuntime): void {
    this.callFrame.reSetFuncArgs(this.funcValue, this.funcArgs);
  }
}

export class PushBlockTrace extends ExecutionTrace {
  public constructor(
    instruction: instruction_set.Instruction,
    readonly callFrame: runtime.CallFrame,
  ) {
    super(ExecTraceKind.PUSH_BLOCK, instruction);
  }

  public undo(_revlangRuntime: runtime.RevlangRuntime): void {
    this.callFrame.unPushBlock();
  }

  public redo(_revlangRuntime: runtime.RevlangRuntime): void {
    this.callFrame.rePushBlock();
  }
}

export class PopBlockTrace extends ExecutionTrace {
  public constructor(
    instruction: instruction_set.Instruction,
    readonly callFrame: runtime.CallFrame,
    readonly blockVarNames: Array<string>,
    readonly blockVarData: Array<values.VarData>,
  ) {
    super(ExecTraceKind.POP_BLOCK, instruction);
  }

  public undo(_revlangRuntime: runtime.RevlangRuntime): void {
    this.callFrame.unPopBlock(
      this.blockVarNames,
      this.blockVarData,
    );
  }

  public redo(_revlangRuntime: runtime.RevlangRuntime): void {
    this.callFrame.rePopBlock(this.blockVarNames);
  }
}

export class AssignIndexedTrace extends ExecutionTrace {
  public constructor(
    instruction: instruction_set.Instruction,
    readonly containerValue: values.ArrayValue | values.ListValue | values.TupleValue,
    readonly indicesValue: values.IntrArrayDataValue,
    readonly oldValue: values.Value,
    readonly newValue: values.Value,
  ) {
    super(ExecTraceKind.ASSIGN_INDEXED, instruction);
  }

  public undo(_revlangRuntime: runtime.RevlangRuntime): void {
    this.containerValue.unSetAtIndex(this.indicesValue, this.oldValue);
  }

  public redo(_revlangRuntime: runtime.RevlangRuntime): void {
    this.containerValue.reSetAtIndex(this.indicesValue, this.newValue);
  }
}

export class AssignMapIndexedTrace extends ExecutionTrace {
  public constructor(
    instruction: instruction_set.Instruction,
    readonly mapValue: values.PrimitiveMapValue | values.MapValue,
    readonly payload: object,
  ) {
    super(ExecTraceKind.ASSIGN_MAP_INDEXED, instruction);
  }

  public undo(_revlangRuntime: runtime.RevlangRuntime): void {
    this.mapValue.unSetAtIndex(this.payload);
  }

  public redo(_revlangRuntime: runtime.RevlangRuntime): void {
    this.mapValue.reSetAtIndex(this.payload);
  }
}

export class AssignFieldTrace extends ExecutionTrace {
  public constructor(
    instruction: instruction_set.Instruction,
    readonly structValue: values.StructValue,
    readonly fieldName: string,
    readonly oldValue: values.Value,
    readonly newValue: values.Value,
  ) {
    super(ExecTraceKind.ASSIGN_FIELD, instruction);
  }

  public undo(_revlangRuntime: runtime.RevlangRuntime): void {
    this.structValue.unSetFieldValue(this.fieldName, this.oldValue);
  }

  public redo(_revlangRuntime: runtime.RevlangRuntime): void {
    this.structValue.unSetFieldValue(this.fieldName, this.newValue);
  }
}

export class EmitPrintTrace extends ExecutionTrace {
  public constructor(
    instruction: instruction_set.Instruction,
    readonly log: string,
  ) {
    super(ExecTraceKind.DEF_LOCAL_VAR, instruction);
  }

  public undo(revlangRuntime: runtime.RevlangRuntime): void {
    revlangRuntime.unPrint();
  }

  public redo(revlangRuntime: runtime.RevlangRuntime): void {
    revlangRuntime.rePrint(this.log);
  }
}

export class PushCallFrameTrace extends ExecutionTrace {
  public constructor(
    instruction: instruction_set.Instruction,
    readonly callFrame: runtime.CallFrame,
  ) {
    super(ExecTraceKind.PUSH_CALL_FRAME, instruction);
  }

  public undo(revlangRuntime: runtime.RevlangRuntime): void {
    revlangRuntime.unPushCallFrame();
  }

  public redo(revlangRuntime: runtime.RevlangRuntime): void {
    revlangRuntime.rePushCallFrame(this.callFrame);
  }
}

export class PopCallFrameTrace extends ExecutionTrace {
  public constructor(
    instruction: instruction_set.Instruction,
    readonly callFrame: runtime.CallFrame,
  ) {
    super(ExecTraceKind.POP_CALL_FRAME, instruction);
  }

  public undo(revlangRuntime: runtime.RevlangRuntime): void {
    revlangRuntime.unPopCallFrame(this.callFrame);
  }

  public redo(revlangRuntime: runtime.RevlangRuntime): void {
    revlangRuntime.rePopCallFrame();
  }
}

export class AppendStdFuncTrace extends ExecutionTrace {
  public constructor(
    instruction: instruction_set.ExprInstruction,
    readonly targetListValue: values.ListValue,
    readonly appendValue: values.Value,
  ) {
    super(ExecTraceKind.APPEND_STD_FUNC, instruction);
  }

  public undo(_revlangRuntime: runtime.RevlangRuntime): void {
    this.targetListValue.unAppend();
  }

  public redo(_revlangRuntime: runtime.RevlangRuntime): void {
    this.targetListValue.reAppend(this.appendValue);
  }
}

export class RemoveAtStdFuncTrace extends ExecutionTrace {
  public constructor(
    instruction: instruction_set.ExprInstruction,
    readonly targetListValue: values.ListValue,
    readonly indexValue: values.IntValue,
    readonly removedValue: values.Value,
  ) {
    super(ExecTraceKind.REMOVE_AT_STD_FUNC, instruction);
  }

  public undo(_revlangRuntime: runtime.RevlangRuntime): void {
    this.targetListValue.unRemoveAt(this.indexValue, this.removedValue);
  }

  public redo(_revlangRuntime: runtime.RevlangRuntime): void {
    this.targetListValue.reRemoveAt(this.indexValue);
  }
}

export class InsertAtStdFuncTrace extends ExecutionTrace {
  public constructor(
    instruction: instruction_set.ExprInstruction,
    readonly targetListValue: values.ListValue,
    readonly indexValue: values.IntValue,
    readonly value: values.Value,
  ) {
    super(ExecTraceKind.INSERT_AT_STD_FUNC, instruction);
  }

  public undo(_revlangRuntime: runtime.RevlangRuntime): void {
    this.targetListValue.unInsertAt(this.indexValue);
  }

  public redo(_revlangRuntime: runtime.RevlangRuntime): void {
    this.targetListValue.reInsertAt(this.indexValue, this.value);
  }
}

export class ExtendAtStdFuncTrace extends ExecutionTrace {
  public constructor(
    instruction: instruction_set.ExprInstruction,
    readonly targetListValue: values.ListValue,
    readonly indexValue: values.IntValue,
    readonly srcListValue: values.ListValue,
  ) {
    super(ExecTraceKind.EXTEND_AT_STD_FUNC, instruction);
  }

  public undo(_revlangRuntime: runtime.RevlangRuntime): void {
    this.targetListValue.unExtendAt(this.indexValue, this.srcListValue);
  }

  public redo(_revlangRuntime: runtime.RevlangRuntime): void {
    this.targetListValue.reExtendAt(this.indexValue, this.srcListValue);
  }
}

export class ClearStdFuncTrace extends ExecutionTrace {
  public constructor(
    instruction: instruction_set.ExprInstruction,
    readonly targetValue: values.Value,
    readonly payload: object,
  ) {
    super(ExecTraceKind.CLEAR_STD_FUNC, instruction);
  }

  public undo(_revlangRuntime: runtime.RevlangRuntime): void {
    this.targetValue.unClear(this.payload);
  }

  public redo(_revlangRuntime: runtime.RevlangRuntime): void {
    this.targetValue.reClear();
  }
}

export class ReverseStdFuncTrace extends ExecutionTrace {
  public constructor(
    instruction: instruction_set.ExprInstruction,
    readonly targetListValue: values.ListValue,
  ) {
    super(ExecTraceKind.REVERSE_STD_FUNC, instruction);
  }

  public undo(_revlangRuntime: runtime.RevlangRuntime): void {
    this.targetListValue.reverse();
  }

  public redo(_revlangRuntime: runtime.RevlangRuntime): void {
    this.targetListValue.reverse();
  }
}

export class AddKStdFuncTrace extends ExecutionTrace {
  public constructor(
    instruction: instruction_set.ExprInstruction,
    readonly targetSetValue: values.PrimitiveSetValue | values.SetValue,
    readonly payload: object,
  ) {
    super(ExecTraceKind.ADD_K_STD_FUNC, instruction);
  }

  public undo(_revlangRuntime: runtime.RevlangRuntime): void {
    this.targetSetValue.unAddKey(this.payload);
  }

  public redo(_revlangRuntime: runtime.RevlangRuntime): void {
    this.targetSetValue.reAddKey(this.payload);
  }
}

export class RemoveStdFuncTrace extends ExecutionTrace {
  public constructor(
    instruction: instruction_set.ExprInstruction,
    readonly targetSetMapValue:
      values.PrimitiveSetValue | values.SetValue | values.PrimitiveMapValue | values.MapValue,
    readonly payload: object,
  ) {
    super(ExecTraceKind.REMOVE_STD_FUNC, instruction);
  }

  public undo(_revlangRuntime: runtime.RevlangRuntime): void {
    this.targetSetMapValue.unRemove(this.payload);
  }

  public redo(_revlangRuntime: runtime.RevlangRuntime): void {
    this.targetSetMapValue.reRemove(this.payload);
  }
}

export class EnqueueStdFuncTrace extends ExecutionTrace {
  public constructor(
    instruction: instruction_set.ExprInstruction,
    readonly targetQueueValue: values.QueueValue,
    readonly enqueuedValue: values.Value,
  ) {
    super(ExecTraceKind.ENQUEUE_STD_FUNC, instruction);
  }

  public undo(_revlangRuntime: runtime.RevlangRuntime): void {
    this.targetQueueValue.unEnqueue();
  }

  public redo(_revlangRuntime: runtime.RevlangRuntime): void {
    this.targetQueueValue.reEnqueue(this.enqueuedValue);
  }
}

export class DequeueStdFuncTrace extends ExecutionTrace {
  public constructor(
    instruction: instruction_set.ExprInstruction,
    readonly targetQueueValue: values.QueueValue,
    readonly dequeuedValue: values.Value,
  ) {
    super(ExecTraceKind.DEQUEUE_STD_FUNC, instruction);
  }

  public undo(_revlangRuntime: runtime.RevlangRuntime): void {
    this.targetQueueValue.unDequeue(this.dequeuedValue);
  }

  public redo(_revlangRuntime: runtime.RevlangRuntime): void {
    this.targetQueueValue.reDequeue();
  }
}

export class PushStdFuncTrace extends ExecutionTrace {
  public constructor(
    instruction: instruction_set.ExprInstruction,
    readonly targetStackValue: values.StackValue,
    readonly pushedValue: values.Value,
  ) {
    super(ExecTraceKind.PUSH_STD_FUNC, instruction);
  }

  public undo(_revlangRuntime: runtime.RevlangRuntime): void {
    this.targetStackValue.unPush();
  }

  public redo(_revlangRuntime: runtime.RevlangRuntime): void {
    this.targetStackValue.rePush(this.pushedValue);
  }
}

export class PopStdFuncTrace extends ExecutionTrace {
  public constructor(
    instruction: instruction_set.ExprInstruction,
    readonly targetStackValue: values.StackValue,
    readonly popedValue: values.Value,
  ) {
    super(ExecTraceKind.POP_STD_FUNC, instruction);
  }

  public undo(_revlangRuntime: runtime.RevlangRuntime): void {
    this.targetStackValue.unPop(this.popedValue);
  }

  public redo(_revlangRuntime: runtime.RevlangRuntime): void {
    this.targetStackValue.rePop();
  }
}

export class PushFrontStdFuncTrace extends ExecutionTrace {
  public constructor(
    instruction: instruction_set.ExprInstruction,
    readonly targetDequeValue: values.DequeValue,
    readonly pushedFrontValue: values.Value,
  ) {
    super(ExecTraceKind.PUSH_FRONT_STD_FUNC, instruction);
  }

  public undo(_revlangRuntime: runtime.RevlangRuntime): void {
    this.targetDequeValue.unPushFront();
  }

  public redo(_revlangRuntime: runtime.RevlangRuntime): void {
    this.targetDequeValue.rePushFront(this.pushedFrontValue);
  }
}

export class PopFrontStdFuncTrace extends ExecutionTrace {
  public constructor(
    instruction: instruction_set.ExprInstruction,
    readonly targetDequeValue: values.DequeValue,
    readonly poppedFrontValue: values.Value,
  ) {
    super(ExecTraceKind.POP_FRONT_STD_FUNC, instruction);
  }

  public undo(_revlangRuntime: runtime.RevlangRuntime): void {
    this.targetDequeValue.unPopFront(this.poppedFrontValue);
  }

  public redo(_revlangRuntime: runtime.RevlangRuntime): void {
    this.targetDequeValue.rePopFront();
  }
}

export class PushBackStdFuncTrace extends ExecutionTrace {
  public constructor(
    instruction: instruction_set.ExprInstruction,
    readonly targetDequeValue: values.DequeValue,
    readonly pushedBackValue: values.Value,
  ) {
    super(ExecTraceKind.PUSH_BACK_STD_FUNC, instruction);
  }

  public undo(_revlangRuntime: runtime.RevlangRuntime): void {
    this.targetDequeValue.unPushBack();
  }

  public redo(_revlangRuntime: runtime.RevlangRuntime): void {
    this.targetDequeValue.rePushBack(this.pushedBackValue);
  }
}

export class PopBackStdFuncTrace extends ExecutionTrace {
  public constructor(
    instruction: instruction_set.ExprInstruction,
    readonly targetDequeValue: values.DequeValue,
    readonly poppedBackValue: values.Value,
  ) {
    super(ExecTraceKind.POP_BACK_STD_FUNC, instruction);
  }

  public undo(_revlangRuntime: runtime.RevlangRuntime): void {
    this.targetDequeValue.unPopBack(this.poppedBackValue);
  }

  public redo(_revlangRuntime: runtime.RevlangRuntime): void {
    this.targetDequeValue.rePopBack();
  }
}

export class ExecutionTracer {
  private executionTrace: Array<ExecutionTrace> = null;

  public startTrace(): void {
    this.executionTrace = [];
  }

  public stopTrace(): void {
    this.executionTrace = null;
  }

  public tracePoint(): number {
    return this.executionTrace.length;
  }

  public resetToTracePoint(tracePoint: number): void {
    if (tracePoint > this.executionTrace.length) {
      throw new Error('Internal error: uncorrect tracePoint');
    }
    this.executionTrace.length = tracePoint;
  }

  public trace(
    createTrace: () => ExecutionTrace,
  ): void {
    if (this.executionTrace !== null) {
      this.executionTrace.push(createTrace());
    }
  }

s

public undoTraces(
  revlangRuntime: runtime.RevlangRuntime,
  fromTracePoint: number,
  toTracePoint: number,
): void {
  for (let i = toTracePoint - 1; i >= fromTracePoint; i -= 1) {
    this.executionTrace[i].undo(revlangRuntime);
  }
}

public redoTraces(
  revlangRuntime: runtime.RevlangRuntime,
  fromTracePoint: number,
  toTracePoint: number,
): void {
  for (let i = fromTracePoint; i < toTracePoint; i += 1) {
    this.executionTrace[i].redo(revlangRuntime);
  }
}
}
