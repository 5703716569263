import Vue from 'vue';
import Vuex from 'vuex';
import RevModule from '@/store/rev_module';
import AlgorithmSummariesModule from '@/store/algorithm_summaries';

Vue.use(Vuex);

export default new Vuex.Store({
  strict: process.env.NODE_ENV !== 'production',
  modules: {
    revModule: RevModule,
    algorithmSummariesModule: AlgorithmSummariesModule,
  },
});
