import * as ast from '@/generated/ast_pb';
import * as span_nodes from '@/revlang/span_nodes';
import * as ast_loader from '@/revlang/ast_loader';
import * as type_system from '@/revlang/type_system';
import * as library from '@/revlang/library';
import * as instruction_set from '@/revlang/instruction_set';
import * as values from '@/revlang/values';
import * as execution_tracer from '@/revlang/execution_tracer';

export class CallFrame {
  private instrPointer: number;

  // This is different from instruction's stepId since we might have selected block.
  private _currentStepIdNode: span_nodes.StepIdNode;

  private readonly localVarData = new Map<string, values.VarData>();

  private readonly blockVariables: Array<Set<string>> = [];

  private readonly operandStack: Array<values.Value> = [];

  public constructor(
    private readonly executionTracer: execution_tracer.ExecutionTracer,
    public readonly name: string,
    public readonly instructions: Array<instruction_set.Instruction>,
    public readonly startStepIdNode: span_nodes.StepIdNode,
  ) {
    this.instrPointer = 0;
    this._currentStepIdNode = startStepIdNode;
  }

  public debugInstructionInfo(): string {
    return `${this.operandStackToString()} bs:${this.blockVariables.length} `
      + `@${this.instrPointer} ${this.currentInstruction.toString()}`;
  }

  public get currentInstructionPointer(): number {
    return this.instrPointer;
  }

  public get currentInstruction(): instruction_set.Instruction {
    return this.instructions[this.instrPointer];
  }

  public setCurrentInstuctionPointer(instrPointer: number): void {
    this.instrPointer = instrPointer;
  }

  public execIncrInstructionPointer(instruction: instruction_set.Instruction): void {
    const oldLocation = this.instrPointer;
    this.instrPointer += 1;
    this.executionTracer.trace(() => new execution_tracer.JumpTrace(
      instruction,
      this,
      oldLocation,
      this.instrPointer,
    ));
  }

  public execInstrPointerFromJumpInstruction(
    jumpInstruction: instruction_set.JumpIfNotInstruction | instruction_set.JumpInstruction,
  ): void {
    const oldInstrPointer = this.instrPointer;
    this.instrPointer = jumpInstruction.location;
    this.executionTracer.trace(() => new execution_tracer.JumpTrace(
      jumpInstruction,
      this,
      oldInstrPointer,
      this.instrPointer,
    ));
  }

  public isEnd(): boolean {
    return this.instrPointer >= this.instructions.length;
  }

  public get currentStepIdNode(): span_nodes.StepIdNode {
    return this._currentStepIdNode;
  }

  public changeCurrentStepIdNode(stepIdNode: span_nodes.StepIdNode): void {
    this._currentStepIdNode = stepIdNode;
  }

  public get currentStepId(): string {
    if (this._currentStepIdNode === undefined) {
      return undefined;
    }
    return this._currentStepIdNode.stepId;
  }

  public execPushBlock(pushBlockInstruction: instruction_set.PopBlockInstruction): void {
    this.rePushBlock();
    this.executionTracer.trace(() => new execution_tracer.PushBlockTrace(
      pushBlockInstruction,
      this,
    ));
  }

  public rePushBlock(): void {
    this.blockVariables.push(new Set<string>());
  }

  public unPushBlock(): void {
    this.blockVariables.pop();
  }

  public execPopBlock(popBlockInstruction: instruction_set.PopBlockInstruction): void {
    const blockVarNames = Array.from<string>(
      this.blockVariables[this.blockVariables.length - 1],
    );
    const blockVarData: Array<values.VarData> = [];
    for (const blockVar of blockVarNames) {
      blockVarData.push(this.localVarData.get(blockVar));
    }
    this.rePopBlock(blockVarNames);
    this.executionTracer.trace(() => new execution_tracer.PopBlockTrace(
      popBlockInstruction,
      this,
      blockVarNames,
      blockVarData,
    ));
  }

  public rePopBlock(blockVarNames: Array<string>): void {
    this.blockVariables.pop();
    for (let i = 0; i < blockVarNames.length; i += 1) {
      this.localVarData.delete(blockVarNames[i]);
    }
  }

  public unPopBlock(
    blockVarNames: Array<string>,
    blockVarData: Array<values.VarData>,
  ): void {
    this.blockVariables.push(new Set<string>());
    for (let i = 0; i < blockVarNames.length; i += 1) {
      this.blockVariables[this.blockVariables.length - 1].add(blockVarNames[i]);
      this.localVarData.set(blockVarNames[i], blockVarData[i]);
    }
  }

  public execSetFuncArgs(
    exprInstruction: instruction_set.ExprInstruction,
    funcValue: values.FuncValue,
    funcArgs: values.IntrNameValueDataValue,
  ): void {
    this.reSetFuncArgs(funcValue, funcArgs);
    this.executionTracer.trace(() => new execution_tracer.SetFuncArgsTrace(
      exprInstruction,
      this,
      funcValue,
      funcArgs,
    ));
  }

  public reSetFuncArgs(
    funcValue: values.FuncValue,
    funcArgs: values.IntrNameValueDataValue,
  ): void {
    for (const nameValue of funcArgs.nameValueElements) {
      this.addLocalVariable(
        nameValue.name,
        funcValue.funcType.param(nameValue.name).type,
        funcValue.paramsAttributes.get(nameValue.name),
        nameValue.value,
      );
    }
  }

  public unSetFuncArgs(
    funcArgs: values.IntrNameValueDataValue,
  ): void {
    for (const nameValue of funcArgs.nameValueElements) {
      this.localVarData.delete(nameValue.name);
    }
  }

  public localVars(): Array<string> {
    return Array.from(this.localVarData.keys());
  }

  public lookupLocalVarData(name: string): values.VarData {
    return this.localVarData.get(name);
  }

  public addLocalVariable(
    name: string,
    type: type_system.Type,
    attrs: Array<ast.AttributeNode>,
    value: values.Value,
  ): void {
    if (this.blockVariables.length > 0) {
      this.blockVariables[this.blockVariables.length - 1].add(name);
    }
    const localVarData = new values.VarData(
      type,
      attrs,
    );
    localVarData.value = value;
    this.localVarData.set(name, localVarData);
  }

  public removeLocalVariable(name: string): void {
    this.localVarData.delete(name);
    if (this.blockVariables.length > 0) {
      this.blockVariables[this.blockVariables.length - 1].delete(name);
    }
  }

  public execDeclLocalVariable(
    varDeclInstruction: instruction_set.VarDeclInstruction,
    name: string,
    type: type_system.Type,
    attrs: Array<ast.AttributeNode>,
  ): void {
    if (this.lookupLocalVarData(name) !== undefined) {
      throw new Error(`Variable ${name} already exists`);
    }
    this.addLocalVariable(name, type, attrs, undefined);
    this.executionTracer.trace(() => new execution_tracer.DeclareLocalVarTrace(
      varDeclInstruction,
      this,
      name,
      type,
      attrs,
    ));
  }

  public execDefLocalVariable(
    varDefInstruction: instruction_set.VarDefInstruction,
    name: string,
    type: type_system.Type,
    attrs: Array<ast.AttributeNode>,
    value: values.Value,
  ): void {
    if (this.lookupLocalVarData(name) !== undefined) {
      throw new Error(`Variable ${name} already exists`);
    }
    this.addLocalVariable(name, type, attrs, value);
    this.executionTracer.trace(() => new execution_tracer.DefineLocalVarTrace(
      varDefInstruction,
      this,
      name,
      type,
      attrs,
      value,
    ));
  }

  public setLocalVariable(name: string, value: values.Value) {
    this.localVarData.get(name).value = value;
  }

  public execSetLocalVariable(
    assignInstruction: instruction_set.AssignInstruction,
    name: string,
    value: values.Value,
  ): void {
    const varData = this.lookupLocalVarData(name);
    if (varData === undefined) {
      throw new Error(`Variable ${name} not declared`);
    }
    if (!value.isAssignableToType(varData.type)) {
      throw new Error(`Value ${value.kind} not assignable to variable ${name}`);
    }
    const oldValue = this.localVarData.get(name).value;
    this.localVarData.get(name).value = value;
    this.executionTracer.trace(() => new execution_tracer.AssignLocalVarTrace(
      assignInstruction,
      this,
      name,
      oldValue,
      value,
    ));
  }

  public operandStackToString(): string {
    let opStackStr = '[ ';
    this.operandStack.forEach((value) => {
      opStackStr += `${value.toDebugString()}, `;
    });
    opStackStr += ']';
    return opStackStr;
  }

  public isSingleOperandStack(): boolean {
    return this.operandStack.length === 1;
  }

  public peekValue(indexFromTop: number): values.Value {
    return this.operandStack[this.operandStack.length - 1 - indexFromTop];
  }

  public peekValuesArray(count: number): Array<values.Value> {
    return this.operandStack.slice(
      this.operandStack.length - count,
      this.operandStack.length,
    );
  }

  public popValue(): values.Value {
    return this.operandStack.pop();
  }

  public pushValue(value: values.Value): void {
    if (value === undefined) {
      throw new Error();
    }
    this.operandStack.push(value);
  }

  public popValuesArray(count: number): Array<values.Value> {
    return this.operandStack.splice(this.operandStack.length - count, count);
  }

  public pushValuesArray(valuesArray: Array<values.Value>): void {
    this.operandStack.splice(this.operandStack.length, 0, ...valuesArray);
  }

  public execPushToOperandStack(
    instruction: instruction_set.Instruction,
    value: values.Value,
  ): void {
    this.pushValue(value);
    this.executionTracer.trace(() => new execution_tracer.PushOpStackTrace(
      instruction,
      this,
      value,
    ));
  }

  public execReplaceOperandStack(
    instruction: instruction_set.Instruction,
    value: values.Value,
  ): void {
    const oldValue = this.popValue();
    this.pushValue(value);
    this.executionTracer.trace(() => new execution_tracer.ReplaceOpStackTrace(
      instruction,
      this,
      oldValue,
      value,
    ));
  }

  public execReplaceOperandStackBinary(
    instruction: instruction_set.Instruction,
    value: values.Value,
  ): void {
    const oldValue2 = this.popValue();
    const oldValue1 = this.popValue();
    this.pushValue(value);
    this.executionTracer.trace(() => new execution_tracer.ReplaceOpStackBinaryTrace(
      instruction,
      this,
      oldValue1,
      oldValue2,
      value,
    ));
  }

  public execReplaceOperandStackTernary(
    instruction: instruction_set.Instruction,
    value: values.Value,
  ): void {
    const oldValue3 = this.popValue();
    const oldValue2 = this.popValue();
    const oldValue1 = this.popValue();
    this.pushValue(value);
    this.executionTracer.trace(() => new execution_tracer.ReplaceOpStackTernaryTrace(
      instruction,
      this,
      oldValue1,
      oldValue2,
      oldValue3,
      value,
    ));
  }

  public execReplaceOperandStackNary(
    instruction: instruction_set.Instruction,
    count: number,
    value: values.Value,
  ): void {
    const oldValues = this.popValuesArray(count);
    this.pushValue(value);
    this.executionTracer.trace(() => new execution_tracer.ReplaceOpStackNaryTrace(
      instruction,
      this,
      oldValues,
      value,
    ));
  }

  public execPopValuesArray(
    instruction: instruction_set.Instruction,
    count: number,
  ): Array<values.Value> {
    const popedValues = this.operandStack.splice(this.operandStack.length - count, count);
    this.executionTracer.trace(() => new execution_tracer.PopOpStackTrace(
      instruction,
      this,
      popedValues,
    ));
    return popedValues;
  }
}

export class RevlangRuntime {
  private _programNode: ast.ProgramNode;

  get programNode(): ast.ProgramNode {
    return this._programNode;
  }

  private _rootSpanNode: span_nodes.SpanNode;

  get rootSpanNode(): span_nodes.SpanNode {
    return this._rootSpanNode;
  }

  private _sourceMap: span_nodes.SourceMap;

  get sourceMap(): span_nodes.SourceMap {
    return this._sourceMap;
  }

  private _typeSystem: type_system.RevlangTypeSystem;

  get typeSystem(): type_system.RevlangTypeSystem {
    return this._typeSystem;
  }

  private _instructionsTable: Map<string, Array<instruction_set.Instruction>>;

  private _programInstructions: Array<instruction_set.Instruction>;

  get programInstructions(): Array<instruction_set.Instruction> {
    return this._programInstructions;
  }

  private _executionTracer: execution_tracer.ExecutionTracer;

  private _library: library.RevlangLibrary;

  get libraryForTest(): library.RevlangLibrary {
    return this._library;
  }

  private _globalDefines: Map<string, values.VarData>;

  private _functions: Map<string, values.FuncValue>;

  private _callStack: Array<CallFrame>;

  private _logStore: Array<string>;

  private _tracePointRecord: Array<[number, span_nodes.StepIdNode]>;

  private _currentRecordPoint: number;

  public loadProgram(programNode: ast.ProgramNode) {
    this._programNode = programNode;
    const loadedAST = ast_loader.loadProgram(this.programNode);
    this._rootSpanNode = loadedAST.rootSpanNode;
    this._sourceMap = loadedAST.sourceMap;
    this._typeSystem = loadedAST.typeSystem;
    this._instructionsTable = loadedAST.instructionsTable;
    this._programInstructions = loadedAST.programInstructions;
    this._executionTracer = new execution_tracer.ExecutionTracer();
    this._library = new library.RevlangLibrary(this.typeSystem, this._executionTracer);
    // Init other variables to end state
    this.stop();
  }

  public unloadProgram() {
    if (this._programNode !== undefined) {
      // Init other variables to end state
      this.stop();
      this._programNode = undefined;
      this._rootSpanNode = undefined;
      this._sourceMap = undefined;
      this._typeSystem = undefined;
      this._instructionsTable = undefined;
      this._programInstructions = undefined;
      this._executionTracer = undefined;
      this._library = undefined;
    }
  }

  public start(): void {
    this._globalDefines = new Map<string, values.VarData>();
    this._functions = new Map<string, values.FuncValue>();
    this._callStack = [];
    this._logStore = [];
    this.loadDefines();
    this._executionTracer.startTrace();
    this.pushCallFrame(
      'main',
      this._programInstructions,
      this._sourceMap.programRootStepIdNode.firstChild,
    );
    this._tracePointRecord = [];
    this._currentRecordPoint = this._tracePointRecord.length;
  }

  public stop(): void {
    this._globalDefines = undefined;
    this._functions = undefined;
    this._callStack = undefined;
    this._logStore = undefined;
    this._executionTracer.stopTrace();
    this._tracePointRecord = undefined;
    this._currentRecordPoint = undefined;
  }

  public isStarted(): boolean {
    return this._callStack !== undefined && this._callStack.length > 0;
  }

  public isFinished(): boolean {
    return this.mainCallFrame.isEnd();
  }

  // getting sourceCode to step through
  public programSpanNode(): span_nodes.SpanNode {
    return this.rootSpanNode;
  }

  public globalVars(): Array<string> {
    return Array.from(this._globalDefines.keys());
  }

  public lookupGlobalVarData(name: string): values.VarData {
    return this._globalDefines.get(name);
  }

  public localVars(callStackIndex: number): Array<string> {
    return this._callStack[callStackIndex].localVars();
  }

  public lookupLocalVarDataAtIndex(name: string, callStackIndex: number): values.VarData {
    const callFrame = this._callStack[callStackIndex];
    return callFrame.lookupLocalVarData(name);
  }

  public lookupVarData(name: string): values.VarData {
    const varData = this.currCallFrame.lookupLocalVarData(name);
    if (varData !== undefined) {
      return varData;
    }
    return this.lookupGlobalVarData(name);
  }

  public lookupVarDataAtIndex(name: string, callStackIndex: number): values.VarData {
    const callFrame = this._callStack[callStackIndex];
    const varData = callFrame.lookupLocalVarData(name);
    if (varData !== undefined) {
      return varData;
    }
    return this.lookupGlobalVarData(name);
  }

  public lookupFunction(name: string): values.FuncValue | undefined {
    return this._functions.get(name);
  }

  public get mainCallFrame(): CallFrame {
    return this._callStack[0];
  }

  public get currCallFrame(): CallFrame {
    return this._callStack[this._callStack.length - 1];
  }

  private pushCallFrame(
    name: string,
    instructions: Array<instruction_set.Instruction>,
    startStepIdNode: span_nodes.StepIdNode,
  ): CallFrame {
    const callFrame = new CallFrame(this._executionTracer, name, instructions, startStepIdNode);
    this.rePushCallFrame(callFrame);
    return callFrame;
  }

  public rePushCallFrame(callFrame: CallFrame): void {
    this._callStack.push(callFrame);
  }

  public unPushCallFrame(): void {
    this._callStack.pop();
  }

  private popCallFrame(): CallFrame {
    if (!this.currCallFrame.isSingleOperandStack()) {
      throw new Error('Internal error: Operand stack should have single value when poping');
    }
    const retCallFrame = this.currCallFrame;
    this.rePopCallFrame();
    return retCallFrame;
  }

  public rePopCallFrame(): void {
    this._callStack.pop();
  }

  public unPopCallFrame(callFrame: CallFrame): void {
    this._callStack.push(callFrame);
  }

  // stepping through the code

  public run(): void {
    // console.log(
    //   `Instructions:\n${instruction_set.instructionsToString(this._programInstructions)}`);
    while (!this.isFinished()) {
      // console.log(`${this.currCallFrame.debugInstructionInfo()}`);
      this.execCurrentInstruction();
    }
    this.currCallFrame.changeCurrentStepIdNode(undefined);
  }

  public canStepOver(): boolean {
    return !this.isFinished();
  }

  // stepOver steps to the next sibling code
  public stepOver(): void {
    if (!this.canStepOver()) {
      throw new Error('Can not step over');
    }
    this.startRecordStep();
    const startCallStackSize = this._callStack.length;
    const startCallFrame = this.currCallFrame;
    const startStepIdNode = startCallFrame.currentStepIdNode;
    while (!this.isFinished()) {
      if (this._callStack.length < startCallStackSize) {
        // If we poped the call frame, step over is done.
        const endStepIdNode = this.currCallFrame.currentInstruction.stepIdNode;
        this.currCallFrame.changeCurrentStepIdNode(endStepIdNode);
        this.endRecordStep();
        return;
      }
      const currInstrStepIdNode = this.currCallFrame.currentInstruction.stepIdNode;
      if (!currInstrStepIdNode.ancestorAndSelfSet.has(startStepIdNode)
          && !startStepIdNode.ancestorSet.has(currInstrStepIdNode)
          && this._callStack.length === startCallStackSize) {
        // If we bailed out of startStepIdNode tree and not in parent,
        // step over is done.
        const endStepIdNode = currInstrStepIdNode.findAncesteralOrSelfSiblingOf(startStepIdNode);
        this.currCallFrame.changeCurrentStepIdNode(endStepIdNode);
        this.endRecordStep();
        return;
      }
      // onsole.log(`${this.currCallFrame.debugInstructionInfo()}`);
      this.execCurrentInstruction();
    }
    this.currCallFrame.changeCurrentStepIdNode(undefined);
    this.endRecordStep();
  }

  public canStepInto(): boolean {
    if (this.isFinished()) {
      return false;
    }
    if (this.currCallFrame.currentStepIdNode.firstChild !== undefined) {
      return true;
    }
    const { astNode } = this.currCallFrame.currentStepIdNode;
    let exprNode: ast.ExprNode;
    if (astNode instanceof ast.ExprNode) {
      exprNode = astNode as ast.ExprNode;
    } else if (astNode instanceof ast.StatementNode) {
      const stmtNode = astNode as ast.StatementNode;
      if (stmtNode.getKind() === ast.StmtNodeKind.EXPR_STMT) {
        exprNode = stmtNode.getExpr();
      }
    }
    if (exprNode !== undefined) {
      return exprNode.getKind() === ast.ExprNodeKind.FUNC_CALL;
    }
    return false;
  }

  // stepInfo gets into the sub children.
  public stepInto(): void {
    if (!this.canStepInto()) {
      throw new Error('Can not step into');
    }
    this.startRecordStep();
    const startCallStackSize = this._callStack.length;
    if (this.currCallFrame.currentStepIdNode.firstChild !== undefined) {
      const startStepIdNode = this.currCallFrame.currentStepIdNode;
      while (!this.isFinished()) {
        const currInstrStepIdNode = this.currCallFrame.currentInstruction.stepIdNode;
        if (currInstrStepIdNode.ancestorSet.has(startStepIdNode)) {
          const childNode = startStepIdNode.findChildContaining(currInstrStepIdNode);
          this.currCallFrame.changeCurrentStepIdNode(childNode);
          this.endRecordStep();
          return;
        }
        if (currInstrStepIdNode !== startStepIdNode) {
          this.currCallFrame.changeCurrentStepIdNode(currInstrStepIdNode);
          this.endRecordStep();
          return;
        }
        if (this._callStack.length !== startCallStackSize) {
          // We need !== since we might be both getting into function or getting out of
          // function when stepping in.  Getting out might happen in the case where
          // we started to return from call previously made.
          this.currCallFrame.changeCurrentStepIdNode(currInstrStepIdNode);
          // If we pushed or popped a call frame, step into is done.
          this.endRecordStep();
          return;
        }
        // console.log(`${this.currCallFrame.debugInstructionInfo()}`);
        this.execCurrentInstruction();
      }
      this.currCallFrame.changeCurrentStepIdNode(undefined);
      this.endRecordStep();
    } else {
      while (!this.isFinished()) {
        if (this._callStack.length > startCallStackSize) {
          const currInstrStepIdNode = this.currCallFrame.currentInstruction.stepIdNode;
          this.currCallFrame.changeCurrentStepIdNode(currInstrStepIdNode);
          // If we pushed the call frame, step into is done.
          this.endRecordStep();
          return;
        }
        // console.log(`${this.currCallFrame.debugInstructionInfo()}`);
        this.execCurrentInstruction();
      }
      this.currCallFrame.changeCurrentStepIdNode(undefined);
      this.endRecordStep();
    }
  }

  public canStepOut(): boolean {
    if (this.isFinished()) {
      return false;
    }
    if (this.currCallFrame.currentStepIdNode.parent !== undefined) {
      return true;
    }
    if (this._callStack.length > 1) {
      return true;
    }
    return false;
  }

  // stepout to the parent's tree's next
  public stepOut(): void {
    if (!this.canStepOut()) {
      throw new Error('Can not step into');
    }
    this.startRecordStep();
    const startCallStackSize = this._callStack.length;
    if (this.currCallFrame.currentStepIdNode.parent !== undefined) {
      const startStepIdNode = this.currCallFrame.currentStepIdNode;
      const parentStepIdNode = this.currCallFrame.currentStepIdNode.parent;
      while (!this.isFinished()) {
        const currInstrStepIdNode = this.currCallFrame.currentInstruction.stepIdNode;
        // If we are somewhere deeper in stack than we started, we need to continue.
        if (this._callStack.length <= startCallStackSize) {
          if (this._callStack.length < startCallStackSize) {
            this.currCallFrame.changeCurrentStepIdNode(currInstrStepIdNode);
            // If we pushed or popped a call frame, step into is done.
            this.endRecordStep();
            return;
          }
          if (!currInstrStepIdNode.ancestorAndSelfSet.has(parentStepIdNode)
              && !parentStepIdNode.ancestorSet.has(currInstrStepIdNode)
              && this._callStack.length === startCallStackSize) {
            const endStepIdNode = currInstrStepIdNode.findAncesteralOrSelfSiblingOf(
              startStepIdNode,
            );
            this.currCallFrame.changeCurrentStepIdNode(endStepIdNode);
            this.endRecordStep();
            return;
          }
        }
        // console.log(`${this.currCallFrame.debugInstructionInfo()}`);
        this.execCurrentInstruction();
      }
      this.currCallFrame.changeCurrentStepIdNode(undefined);
      this.endRecordStep();
    } else {
      while (!this.isFinished()) {
        if (this._callStack.length < startCallStackSize) {
          // If we poped the call frame, step out is done.
          const endStepIdNode = this.currCallFrame.currentInstruction.stepIdNode;
          this.currCallFrame.changeCurrentStepIdNode(endStepIdNode);
          this.endRecordStep();
          return;
        }
        // console.log(`${this.currCallFrame.debugInstructionInfo()}`);
        this.execCurrentInstruction();
      }

      this.currCallFrame.changeCurrentStepIdNode(undefined);
      this.endRecordStep();
    }
  }

  // Undo and redo

  private startRecordStep(): void {
    if (this._currentRecordPoint > this._tracePointRecord.length) {
      throw new Error('Internal Error: Unexpected currentRecordIndex');
    }
    if (this._currentRecordPoint === 0) {
      this._executionTracer.resetToTracePoint(0);
      this._tracePointRecord.length = 0;
    } else {
      const [endTracePoint] = this._tracePointRecord[this._currentRecordPoint - 1];
      this._executionTracer.resetToTracePoint(endTracePoint);
      this._tracePointRecord.length = this._currentRecordPoint;
    }
  }

  private endRecordStep(): void {
    this._tracePointRecord.push(
      [this._executionTracer.tracePoint(), this.currCallFrame.currentStepIdNode],
    );
    this._currentRecordPoint += 1;
  }

  public canUndoStep(): boolean {
    return this._currentRecordPoint > 0;
  }

  // undo the last action
  public undoStep(): void {
    if (!this.canUndoStep()) {
      throw new Error('Can not undo');
    }
    let fromTracePoint: number;
    let finalStepIdNode: span_nodes.StepIdNode;
    if (this._currentRecordPoint > 1) {
      [fromTracePoint, finalStepIdNode] = this._tracePointRecord[this._currentRecordPoint - 2];
    } else {
      fromTracePoint = 0;
      finalStepIdNode = this._sourceMap.programRootStepIdNode.firstChild;
    }
    const [toTracePoint] = this._tracePointRecord[this._currentRecordPoint - 1];
    this._executionTracer.undoTraces(this, fromTracePoint, toTracePoint);
    this.currCallFrame.changeCurrentStepIdNode(finalStepIdNode);
    this._currentRecordPoint -= 1;
  }

  public canRedoStep(): boolean {
    return this._currentRecordPoint < this._tracePointRecord.length;
  }

  // redo the last action
  public redoStep(): void {
    if (!this.canRedoStep()) {
      throw new Error('Can not redo');
    }
    let fromTracePoint: number;
    if (this._currentRecordPoint > 0) {
      [fromTracePoint] = this._tracePointRecord[this._currentRecordPoint - 1];
    } else {
      fromTracePoint = 0;
    }
    const [toTracePoint, finalStepIdNode] = this._tracePointRecord[this._currentRecordPoint];
    this._executionTracer.redoTraces(this, fromTracePoint, toTracePoint);
    this.currCallFrame.changeCurrentStepIdNode(finalStepIdNode);
    this._currentRecordPoint += 1;
  }

  public get callStack(): Array<CallFrame> {
    return this._callStack;
  }

  // explore logs
  public get logs(): Array<string> {
    return this._logStore;
  }

  private lookupName(name: string): values.Value {
    const localVarData = this.currCallFrame.lookupLocalVarData(name);
    if (localVarData !== undefined) {
      return localVarData.value;
    }
    const globalValue = this.lookupGlobalVarData(name);
    if (globalValue !== undefined) {
      return globalValue.value;
    }
    const funcValue = this.lookupFunction(name);
    if (funcValue !== undefined) {
      return funcValue;
    }
    const type = this.typeSystem.lookupType(name);
    if (type !== undefined) {
      return new values.TypeValue(
        this.typeSystem.typeType,
        type,
      );
    }
    throw new Error(`Value ${name} not found`);
  }

  private astToAttributes(
    astAttributes: ast.AttributeListNode,
  ): Array<ast.AttributeNode> {
    const attributes: Array<ast.AttributeNode> = [];
    // If parse tree does not contain attributes,  treat it as empty.
    if (astAttributes === undefined) {
      return attributes;
    }
    for (const astAttribute of astAttributes.getAttributesList()) {
      attributes.push(astAttribute);
    }
    return attributes;
  }

  private funcParamsAttributes(
    funcTypeNode: ast.FuncTypeNode,
  ): Map<string, Array<ast.AttributeNode>> {
    const attrMap = new Map<string, Array<ast.AttributeNode>>();
    funcTypeNode.getParams().getNameTypesList().forEach((nameTypeNode) => {
      attrMap.set(
        nameTypeNode.getName().getName(),
        this.astToAttributes(nameTypeNode.getAttributesList()),
      );
    });
    return attrMap;
  }

  private loadDefines() {
    const defSet = new Set<string>();
    for (const dataDefNode of this.programNode.getDataDefsList()) {
      const name = dataDefNode.getName().getName();
      if (defSet.has(name)) {
        throw new Error(`Name ${name} already defined`);
      }
      defSet.add(name);
      const attrs = this.astToAttributes(dataDefNode.getAttributesList());
      const value = this.evalGlobalExpr(this._instructionsTable.get(name));
      const varData = new values.VarData(value.type, attrs);
      varData.value = value;
      this._globalDefines.set(name, varData);
    }
    for (const funcDefNode of this.programNode.getFuncDefsList()) {
      const name = funcDefNode.getName().getName();
      if (defSet.has(name)) {
        throw new Error(`Name ${name} already defined`);
      }
      defSet.add(name);
      const funcType = this.typeSystem.funcTypeForNode(funcDefNode.getFuncType());
      const paramsAttributes = this.funcParamsAttributes(funcDefNode.getFuncType());
      this._functions.set(name, this._library.funcValueFromBlock(
        name,
        funcType,
        paramsAttributes,
        funcDefNode.getBlock(),
        this._instructionsTable.get(name),
        this._sourceMap.funcRootStepIdMap.get(name),
      ));
    }
  }

  private evalGlobalExpr(instructions: Array<instruction_set.Instruction>): values.Value {
    this.pushCallFrame('<<gexpr>>', instructions, undefined);
    // console.log('Evaluating:');
    while (!this.currCallFrame.isEnd()) {
      // console.log(`${this.currCallFrame.debugInstructionInfo()}`);
      this.execCurrentInstruction();
    }
    // console.log(this.currCallFrame.operandStackToString());
    const retValue = this.currCallFrame.peekValue(0);
    this.popCallFrame();
    return retValue;
  }

  private execCurrentInstruction(): void {
    const { currentInstruction } = this.currCallFrame;
    switch (currentInstruction.kind) {
      case instruction_set.InstructionKind.EXPR:
        return this.execExprInstruction(
          currentInstruction as instruction_set.ExprInstruction,
        );
      case instruction_set.InstructionKind.REDUCE_EXPR_LIST:
        return this.execReduceExprListInstruction(
          currentInstruction as instruction_set.ReduceExprListInstruction,
        );
      case instruction_set.InstructionKind.REDUCE_EXPR_LIST_LIST:
        return this.execReduceExprListListInstruction(
          currentInstruction as instruction_set.ReduceExprListListInstruction,
        );
      case instruction_set.InstructionKind.NAME_EXPR:
        return this.execNameExprInstruction(
          currentInstruction as instruction_set.NameExprInstruction,
        );
      case instruction_set.InstructionKind.REDUCE_NAME_EXPR_LIST:
        return this.execReduceNameExprListInstruction(
          currentInstruction as instruction_set.ReduceNameExprListInstruction,
        );
      case instruction_set.InstructionKind.MAP_ELEMENT:
        return this.execMapElementInstruction(
          currentInstruction as instruction_set.MapElementInstruction,
        );
      case instruction_set.InstructionKind.REDUCE_MAP_ELEMENTS_LIST:
        return this.execReduceMapElementsListInstruction(
          currentInstruction as instruction_set.ReduceMapElementsListInstruction,
        );
      case instruction_set.InstructionKind.LVALUE_VAR:
        return this.execLValueVarInstruction(
          currentInstruction as instruction_set.LValueVarInstruction,
        );
      case instruction_set.InstructionKind.LVALUE_INDEX:
        return this.execLValueIndexInstruction(
          currentInstruction as instruction_set.LValueIndexInstruction,
        );
      case instruction_set.InstructionKind.LVALUE_FIELD:
        return this.execLValueFieldInstruction(
          currentInstruction as instruction_set.LValueFieldInstruction,
        );
      case instruction_set.InstructionKind.ASSIGN:
        return this.execAssignInstruction(
          currentInstruction as instruction_set.AssignInstruction,
        );
      case instruction_set.InstructionKind.VAR_DECL:
        return this.execVarDeclInstruction(
          currentInstruction as instruction_set.VarDeclInstruction,
        );
      case instruction_set.InstructionKind.VAR_DEF:
        return this.execVarDefInstruction(
          currentInstruction as instruction_set.VarDefInstruction,
        );
      case instruction_set.InstructionKind.POP:
        return this.execPopInstruction(
          currentInstruction as instruction_set.PopInstruction,
        );
      case instruction_set.InstructionKind.PUSH_VOID:
        return this.execPushVoidInstruction(
          currentInstruction as instruction_set.PushVoidInstruction,
        );
      case instruction_set.InstructionKind.PRINT:
        return this.execPrintInstruction(
          currentInstruction as instruction_set.PrintInstruction,
        );
      case instruction_set.InstructionKind.JUMP:
        return this.execJumpInstruction(
          currentInstruction as instruction_set.JumpInstruction,
        );
      case instruction_set.InstructionKind.JUMP_IF_NOT:
        return this.execJumpIfNotInstruction(
          currentInstruction as instruction_set.JumpIfNotInstruction,
        );
      case instruction_set.InstructionKind.RETURN:
        return this.execReturnInstruction(
          currentInstruction as instruction_set.ReturnInstruction,
        );
      case instruction_set.InstructionKind.PUSH_BLOCK:
        return this.execPushBlockInstruction(
          currentInstruction as instruction_set.PushBlockInstruction,
        );
      case instruction_set.InstructionKind.POP_BLOCK:
        return this.execPopBlockInstruction(
          currentInstruction as instruction_set.PopBlockInstruction,
        );
      default:
        throw new Error(`Internal error: unknown instruction kind ${currentInstruction.kind}`);
    }
  }

  private execExprInstruction(exprInstruction: instruction_set.ExprInstruction): void {
    const { exprNode } = exprInstruction;
    const exprKind = exprNode.getKind();
    switch (exprKind) {
      case ast.ExprNodeKind.INT_LTRL: {
        const val = this._library.intValueFromString(
          exprNode.getIntLiteral().getValue(),
        );
        this.currCallFrame.execPushToOperandStack(exprInstruction, val);
        this.currCallFrame.execIncrInstructionPointer(exprInstruction);
        break;
      }
      case ast.ExprNodeKind.NUMBER_LTRL: {
        const val = this._library.numberValueFromString(
          exprNode.getNumLiteral().getValue(),
        );
        this.currCallFrame.execPushToOperandStack(exprInstruction, val);
        this.currCallFrame.execIncrInstructionPointer(exprInstruction);
        break;
      }
      case ast.ExprNodeKind.CHAR_LTRL: {
        const val = this._library.charValueFromNumber(
          exprNode.getCharLiteral().getValue(),
        );
        this.currCallFrame.execPushToOperandStack(exprInstruction, val);
        this.currCallFrame.execIncrInstructionPointer(exprInstruction);
        break;
      }
      case ast.ExprNodeKind.STRING_LTRL: {
        const val = this._library.stringValueFromString(
          exprNode.getStringLiteral().getValue(),
        );
        this.currCallFrame.execPushToOperandStack(exprInstruction, val);
        this.currCallFrame.execIncrInstructionPointer(exprInstruction);
        break;
      }
      case ast.ExprNodeKind.BITS_LTRL: {
        const val = this._library.bitsValueFromString(
          exprNode.getBitsLiteral().getNumBits(),
          exprNode.getBitsLiteral().getValueHex(),
        );
        this.currCallFrame.execPushToOperandStack(exprInstruction, val);
        this.currCallFrame.execIncrInstructionPointer(exprInstruction);
        break;
      }
      case ast.ExprNodeKind.TRUE_LTRL: {
        const val = this._library.trueValue;
        this.currCallFrame.execPushToOperandStack(exprInstruction, val);
        this.currCallFrame.execIncrInstructionPointer(exprInstruction);
        break;
      }
      case ast.ExprNodeKind.FALSE_LTRL: {
        const val = this._library.falseValue;
        this.currCallFrame.execPushToOperandStack(exprInstruction, val);
        this.currCallFrame.execIncrInstructionPointer(exprInstruction);
        break;
      }
      case ast.ExprNodeKind.NULL_LTRL: {
        const val = this._library.nullValue;
        this.currCallFrame.execPushToOperandStack(exprInstruction, val);
        this.currCallFrame.execIncrInstructionPointer(exprInstruction);
        break;
      }
      case ast.ExprNodeKind.TUPLE_CTOR: {
        const tupleCtorNode = exprNode.getTupleCtor();
        let tupleType: type_system.TupleType;
        if (tupleCtorNode.hasTupleType()) {
          tupleType = this.typeSystem.tupleTypeForNode(tupleCtorNode.getTupleType());
        } else {
          tupleType = this.typeSystem.aliasTypeForNode(
            tupleCtorNode.getAlias(),
            type_system.TypeKind.TUPLE,
          ) as type_system.TupleType;
        }
        const intrArrayDataValue = this.currCallFrame.peekValue(0);
        if (intrArrayDataValue.kind !== values.ValueKind.INTR_ARRAY_DATA) {
          throw new Error('Internal Error: Incorrect type of value for tuple constrcutor');
        }
        const val = this._library.tupleValueFromArrayDataValue(
          intrArrayDataValue as values.IntrArrayDataValue,
          tupleType,
        );
        this.currCallFrame.execReplaceOperandStack(exprInstruction, val);
        this.currCallFrame.execIncrInstructionPointer(exprInstruction);
        break;
      }
      case ast.ExprNodeKind.ARRAY_CTOR: {
        const arrayCtorNode = exprNode.getArrayCtor();
        let arrayType: type_system.ArrayType;
        if (arrayCtorNode.hasArrayType()) {
          arrayType = this.typeSystem.arrayTypeForNode(arrayCtorNode.getArrayType());
        } else {
          arrayType = this.typeSystem.aliasTypeForNode(
            arrayCtorNode.getAlias(),
            type_system.TypeKind.ARRAY,
          ) as type_system.ArrayType;
        }
        const shapeIntrArrayDataValue = this.currCallFrame.peekValue(1);
        if (shapeIntrArrayDataValue.kind !== values.ValueKind.INTR_ARRAY_DATA) {
          throw new Error('Internal Error: Incorrect type of value for array constrcutor');
        }
        const elementsIntrArrayDataValue = this.currCallFrame.peekValue(0);
        if (elementsIntrArrayDataValue.kind !== values.ValueKind.INTR_ARRAY_DATA) {
          throw new Error('Internal Error: Incorrect type of value for array constrcutor');
        }
        const val = this._library.arrayValueFromArrayDataValue(
          shapeIntrArrayDataValue as values.IntrArrayDataValue,
          elementsIntrArrayDataValue as values.IntrArrayDataValue,
          arrayType,
        );
        this.currCallFrame.execReplaceOperandStackBinary(exprInstruction, val);
        this.currCallFrame.execIncrInstructionPointer(exprInstruction);
        break;
      }
      case ast.ExprNodeKind.STRUCT_CTOR: {
        const structCtorNode = exprNode.getStructCtor();
        const structType = this.typeSystem.aliasTypeForNode(
          structCtorNode.getAlias(),
          type_system.TypeKind.STRUCT,
        ) as type_system.StructType;
        const intrNameValueDataValue = this.currCallFrame.peekValue(0);
        if (intrNameValueDataValue.kind !== values.ValueKind.INTR_NAME_VALUE_DATA) {
          throw new Error('Internal Error: Incorrect type of value for struct constrcutor');
        }
        const val = this._library.structValueFromNameExprMap(
          intrNameValueDataValue as values.IntrNameValueDataValue,
          structType,
        );
        this.currCallFrame.execReplaceOperandStack(exprInstruction, val);
        this.currCallFrame.execIncrInstructionPointer(exprInstruction);
        break;
      }
      case ast.ExprNodeKind.LIST_CTOR:
      case ast.ExprNodeKind.SET_CTOR:
      case ast.ExprNodeKind.QUEUE_CTOR:
      case ast.ExprNodeKind.STACK_CTOR:
      case ast.ExprNodeKind.DEQUE_CTOR: {
        let typeKind: type_system.TypeKind;
        if (exprKind === ast.ExprNodeKind.LIST_CTOR) {
          typeKind = type_system.TypeKind.LIST;
        } else if (exprKind === ast.ExprNodeKind.SET_CTOR) {
          typeKind = type_system.TypeKind.SET;
        } else if (exprKind === ast.ExprNodeKind.QUEUE_CTOR) {
          typeKind = type_system.TypeKind.QUEUE;
        } else if (exprKind === ast.ExprNodeKind.STACK_CTOR) {
          typeKind = type_system.TypeKind.STACK;
        } else if (exprKind === ast.ExprNodeKind.DEQUE_CTOR) {
          typeKind = type_system.TypeKind.DEQUE;
        }
        const containerCtorNode = exprNode.getContainerCtor();
        let containerType: type_system.ContainerType;
        if (containerCtorNode.hasElementType()) {
          containerType = this.typeSystem.containerTypeForNode(
            typeKind,
            containerCtorNode.getElementType(),
          );
        } else {
          containerType = this.typeSystem.aliasTypeForNode(
            containerCtorNode.getAlias(),
            typeKind,
          ) as type_system.ContainerType;
        }
        const intrArrayDataValue = this.currCallFrame.peekValue(0);
        if (intrArrayDataValue.kind !== values.ValueKind.INTR_ARRAY_DATA) {
          throw new Error('Internal Error: Incorrect type of value for container constrcutor');
        }
        const val = this._library.containerValueFromArrayDataValue(
          intrArrayDataValue as values.IntrArrayDataValue,
          containerType,
        );
        this.currCallFrame.execReplaceOperandStack(exprInstruction, val);
        this.currCallFrame.execIncrInstructionPointer(exprInstruction);
        break;
      }
      case ast.ExprNodeKind.MAP_CTOR: {
        const mapCtorNode = exprNode.getMapCtor();
        let mapType: type_system.MapType;
        if (mapCtorNode.hasMapType()) {
          mapType = this.typeSystem.mapTypeForNode(mapCtorNode.getMapType());
        } else {
          mapType = this.typeSystem.aliasTypeForNode(
            mapCtorNode.getAlias(),
            type_system.TypeKind.MAP,
          ) as type_system.MapType;
        }
        const intrMapDataValue = this.currCallFrame.peekValue(0);
        if (intrMapDataValue.kind !== values.ValueKind.INTR_MAP_DATA) {
          throw new Error('Internal Error: Incorrect type of value for map constrcutor');
        }
        const val = this._library.mapValueFromMapDataValue(
          intrMapDataValue as values.IntrMapDataValue,
          mapType,
        );
        this.currCallFrame.execReplaceOperandStack(exprInstruction, val);
        this.currCallFrame.execIncrInstructionPointer(exprInstruction);
        break;
      }
      case ast.ExprNodeKind.NAME_ACCESS: {
        const name = exprNode.getName().getName();
        const val = this.lookupName(name);
        this.currCallFrame.execPushToOperandStack(exprInstruction, val);
        this.currCallFrame.execIncrInstructionPointer(exprInstruction);
        break;
      }
      case ast.ExprNodeKind.STD_FUNC_CALL: {
        const stdFuncCallNode = exprNode.getStdFuncCall();
        if (stdFuncCallNode.hasUnaryExpr()) {
          const argValue = this.currCallFrame.peekValue(0);
          const val = this._library.execStdFuncCall(
            exprInstruction,
            stdFuncCallNode.getKind(),
            argValue,
          );
          this.currCallFrame.execReplaceOperandStack(exprInstruction, val);
        } else if (stdFuncCallNode.hasBinaryExpr()) {
          const arg1Value = this.currCallFrame.peekValue(1);
          const arg2Value = this.currCallFrame.peekValue(0);
          const val = this._library.execStdFuncCall(
            exprInstruction,
            stdFuncCallNode.getKind(),
            arg1Value,
            arg2Value,
          );
          this.currCallFrame.execReplaceOperandStackBinary(exprInstruction, val);
        } else if (stdFuncCallNode.hasTernaryExpr()) {
          const arg1Value = this.currCallFrame.peekValue(2);
          const arg2Value = this.currCallFrame.peekValue(1);
          const arg3Value = this.currCallFrame.peekValue(0);
          const val = this._library.execStdFuncCall(
            exprInstruction,
            stdFuncCallNode.getKind(),
            arg1Value,
            arg2Value,
            arg3Value,
          );
          this.currCallFrame.execReplaceOperandStackTernary(exprInstruction, val);
        } else {
          throw new Error('Standard function call without args');
        }
        this.currCallFrame.execIncrInstructionPointer(exprInstruction);
        break;
      }
      case ast.ExprNodeKind.INDEXED_ACCESS: {
        const containerValue = this.currCallFrame.peekValue(1);
        const indicesValue = this.currCallFrame.peekValue(0);
        if (indicesValue.kind !== values.ValueKind.INTR_ARRAY_DATA) {
          throw new Error('Incorrect index value');
        }
        const val = this._library.indexedAccess(
          containerValue,
          indicesValue as values.IntrArrayDataValue,
        );
        this.currCallFrame.execReplaceOperandStackBinary(exprInstruction, val);
        this.currCallFrame.execIncrInstructionPointer(exprInstruction);
        break;
      }
      case ast.ExprNodeKind.FIELD_ACCESS: {
        const fieldAccessNode = exprNode.getFieldAccess();
        const value = this.currCallFrame.peekValue(0);
        const val = this._library.fieldAccess(
          value,
          fieldAccessNode.getFieldName().getName(),
        );
        this.currCallFrame.execReplaceOperandStack(exprInstruction, val);
        this.currCallFrame.execIncrInstructionPointer(exprInstruction);
        break;
      }
      case ast.ExprNodeKind.FUNC_CALL: {
        const popedValues = this.currCallFrame.execPopValuesArray(exprInstruction, 2);
        if (popedValues[0].kind !== values.ValueKind.FUNC) {
          throw new Error('Incorrect func value');
        }
        if (popedValues[1].kind !== values.ValueKind.INTR_NAME_VALUE_DATA) {
          throw new Error('Incorrect func args value');
        }
        const funcValue = popedValues[0] as values.FuncValue;
        const funcArgs = popedValues[1] as values.IntrNameValueDataValue;
        funcArgs.checkArgsCompatibleForFuncCall(funcValue.funcType);
        const callFrame = this.pushCallFrame(
          funcValue.funcName,
          funcValue.instructions,
          funcValue.funcRootStepId,
        );
        this._executionTracer.trace(() => new execution_tracer.PushCallFrameTrace(
          exprInstruction,
          callFrame,
        ));
        this.currCallFrame.execSetFuncArgs(
          exprInstruction,
          funcValue,
          funcArgs,
        );
        break;
      }
      case ast.ExprNodeKind.NEGATION: {
        const operandValue = this.currCallFrame.peekValue(0);
        const resultValue = this._library.negation(operandValue);
        this.currCallFrame.execReplaceOperandStack(exprInstruction, resultValue);
        this.currCallFrame.execIncrInstructionPointer(exprInstruction);
        break;
      }
      case ast.ExprNodeKind.BIT_NOT: {
        const operandValue = this.currCallFrame.peekValue(0);
        const resultValue = this._library.bitNot(operandValue);
        this.currCallFrame.execReplaceOperandStack(exprInstruction, resultValue);
        this.currCallFrame.execIncrInstructionPointer(exprInstruction);
        break;
      }
      case ast.ExprNodeKind.BOOL_NOT: {
        const operandValue = this.currCallFrame.peekValue(0);
        const resultValue = this._library.boolNot(operandValue);
        this.currCallFrame.execReplaceOperandStack(exprInstruction, resultValue);
        this.currCallFrame.execIncrInstructionPointer(exprInstruction);
        break;
      }
      case ast.ExprNodeKind.POWER: {
        const operand1Value = this.currCallFrame.peekValue(1);
        const operand2Value = this.currCallFrame.peekValue(0);
        const resultValue = this._library.power(operand1Value, operand2Value);
        this.currCallFrame.execReplaceOperandStackBinary(exprInstruction, resultValue);
        this.currCallFrame.execIncrInstructionPointer(exprInstruction);
        break;
      }
      case ast.ExprNodeKind.MODULO: {
        const operand1Value = this.currCallFrame.peekValue(1);
        const operand2Value = this.currCallFrame.peekValue(0);
        const resultValue = this._library.modulo(operand1Value, operand2Value);
        this.currCallFrame.execReplaceOperandStackBinary(exprInstruction, resultValue);
        this.currCallFrame.execIncrInstructionPointer(exprInstruction);
        break;
      }
      case ast.ExprNodeKind.MULTIPLICATION: {
        const operand1Value = this.currCallFrame.peekValue(1);
        const operand2Value = this.currCallFrame.peekValue(0);
        const resultValue = this._library.multiply(operand1Value, operand2Value);
        this.currCallFrame.execReplaceOperandStackBinary(exprInstruction, resultValue);
        this.currCallFrame.execIncrInstructionPointer(exprInstruction);
        break;
      }
      case ast.ExprNodeKind.DIVISION: {
        const operand1Value = this.currCallFrame.peekValue(1);
        const operand2Value = this.currCallFrame.peekValue(0);
        const resultValue = this._library.divide(operand1Value, operand2Value);
        this.currCallFrame.execReplaceOperandStackBinary(exprInstruction, resultValue);
        this.currCallFrame.execIncrInstructionPointer(exprInstruction);
        break;
      }
      case ast.ExprNodeKind.BIT_AND: {
        const operand1Value = this.currCallFrame.peekValue(1);
        const operand2Value = this.currCallFrame.peekValue(0);
        const resultValue = this._library.bitAnd(operand1Value, operand2Value);
        this.currCallFrame.execReplaceOperandStackBinary(exprInstruction, resultValue);
        this.currCallFrame.execIncrInstructionPointer(exprInstruction);
        break;
      }
      case ast.ExprNodeKind.BIT_OR: {
        const operand1Value = this.currCallFrame.peekValue(1);
        const operand2Value = this.currCallFrame.peekValue(0);
        const resultValue = this._library.bitOr(operand1Value, operand2Value);
        this.currCallFrame.execReplaceOperandStackBinary(exprInstruction, resultValue);
        this.currCallFrame.execIncrInstructionPointer(exprInstruction);
        break;
      }
      case ast.ExprNodeKind.BIT_XOR: {
        const operand1Value = this.currCallFrame.peekValue(1);
        const operand2Value = this.currCallFrame.peekValue(0);
        const resultValue = this._library.bitXor(operand1Value, operand2Value);
        this.currCallFrame.execReplaceOperandStackBinary(exprInstruction, resultValue);
        this.currCallFrame.execIncrInstructionPointer(exprInstruction);
        break;
      }
      case ast.ExprNodeKind.LSHIFT: {
        const operand1Value = this.currCallFrame.peekValue(1);
        const operand2Value = this.currCallFrame.peekValue(0);
        const resultValue = this._library.leftShift(operand1Value, operand2Value);
        this.currCallFrame.execReplaceOperandStackBinary(exprInstruction, resultValue);
        this.currCallFrame.execIncrInstructionPointer(exprInstruction);
        break;
      }
      case ast.ExprNodeKind.RSHIFT: {
        const operand1Value = this.currCallFrame.peekValue(1);
        const operand2Value = this.currCallFrame.peekValue(0);
        const resultValue = this._library.rightShift(operand1Value, operand2Value);
        this.currCallFrame.execReplaceOperandStackBinary(exprInstruction, resultValue);
        this.currCallFrame.execIncrInstructionPointer(exprInstruction);
        break;
      }
      case ast.ExprNodeKind.ADDITION: {
        const operand1Value = this.currCallFrame.peekValue(1);
        const operand2Value = this.currCallFrame.peekValue(0);
        const resultValue = this._library.add(operand1Value, operand2Value);
        this.currCallFrame.execReplaceOperandStackBinary(exprInstruction, resultValue);
        this.currCallFrame.execIncrInstructionPointer(exprInstruction);
        break;
      }
      case ast.ExprNodeKind.SUBTRACTION: {
        const operand1Value = this.currCallFrame.peekValue(1);
        const operand2Value = this.currCallFrame.peekValue(0);
        const resultValue = this._library.subtract(operand1Value, operand2Value);
        this.currCallFrame.execReplaceOperandStackBinary(exprInstruction, resultValue);
        this.currCallFrame.execIncrInstructionPointer(exprInstruction);
        break;
      }
      case ast.ExprNodeKind.EQ: {
        const operand1Value = this.currCallFrame.peekValue(1);
        const operand2Value = this.currCallFrame.peekValue(0);
        const resultValue = this._library.equals(operand1Value, operand2Value);
        this.currCallFrame.execReplaceOperandStackBinary(exprInstruction, resultValue);
        this.currCallFrame.execIncrInstructionPointer(exprInstruction);
        break;
      }
      case ast.ExprNodeKind.NEQ: {
        const operand1Value = this.currCallFrame.peekValue(1);
        const operand2Value = this.currCallFrame.peekValue(0);
        const resultValue = this._library.notEquals(operand1Value, operand2Value);
        this.currCallFrame.execReplaceOperandStackBinary(exprInstruction, resultValue);
        this.currCallFrame.execIncrInstructionPointer(exprInstruction);
        break;
      }
      case ast.ExprNodeKind.LT: {
        const operand1Value = this.currCallFrame.peekValue(1);
        const operand2Value = this.currCallFrame.peekValue(0);
        const resultValue = this._library.lessThan(operand1Value, operand2Value);
        this.currCallFrame.execReplaceOperandStackBinary(exprInstruction, resultValue);
        this.currCallFrame.execIncrInstructionPointer(exprInstruction);
        break;
      }
      case ast.ExprNodeKind.LTEQ: {
        const operand1Value = this.currCallFrame.peekValue(1);
        const operand2Value = this.currCallFrame.peekValue(0);
        const resultValue = this._library.lessThanEquals(operand1Value, operand2Value);
        this.currCallFrame.execReplaceOperandStackBinary(exprInstruction, resultValue);
        this.currCallFrame.execIncrInstructionPointer(exprInstruction);
        break;
      }
      case ast.ExprNodeKind.GT: {
        const operand1Value = this.currCallFrame.peekValue(1);
        const operand2Value = this.currCallFrame.peekValue(0);
        const resultValue = this._library.greaterThan(operand1Value, operand2Value);
        this.currCallFrame.execReplaceOperandStackBinary(exprInstruction, resultValue);
        this.currCallFrame.execIncrInstructionPointer(exprInstruction);
        break;
      }
      case ast.ExprNodeKind.GTEQ: {
        const operand1Value = this.currCallFrame.peekValue(1);
        const operand2Value = this.currCallFrame.peekValue(0);
        const resultValue = this._library.greaterThanEquals(operand1Value, operand2Value);
        this.currCallFrame.execReplaceOperandStackBinary(exprInstruction, resultValue);
        this.currCallFrame.execIncrInstructionPointer(exprInstruction);
        break;
      }
      case ast.ExprNodeKind.BOOL_AND: {
        const operand1Value = this.currCallFrame.peekValue(1);
        const operand2Value = this.currCallFrame.peekValue(0);
        const resultValue = this._library.boolAnd(operand1Value, operand2Value);
        this.currCallFrame.execReplaceOperandStackBinary(exprInstruction, resultValue);
        this.currCallFrame.execIncrInstructionPointer(exprInstruction);
        break;
      }
      case ast.ExprNodeKind.BOOL_OR: {
        const operand1Value = this.currCallFrame.peekValue(1);
        const operand2Value = this.currCallFrame.peekValue(0);
        const resultValue = this._library.boolOr(operand1Value, operand2Value);
        this.currCallFrame.execReplaceOperandStackBinary(exprInstruction, resultValue);
        this.currCallFrame.execIncrInstructionPointer(exprInstruction);
        break;
      }
      default:
        throw new Error(`Internal error: Unexpected expr ${exprNode.getKind()}`);
    }
  }

  private execReduceExprListInstruction(
    reduceExprListInstruction: instruction_set.ReduceExprListInstruction,
  ): void {
    const count = reduceExprListInstruction.exprListNode.getExprsList().length;
    const extractedValues = this.currCallFrame.peekValuesArray(count);
    const resultValue = new values.IntrArrayDataValue(extractedValues);
    this.currCallFrame.execReplaceOperandStackNary(
      reduceExprListInstruction,
      count,
      resultValue,
    );
    this.currCallFrame.execIncrInstructionPointer(reduceExprListInstruction);
  }

  private execReduceExprListListInstruction(
    reduceExprListListInstruction: instruction_set.ReduceExprListListInstruction,
  ): void {
    const count = reduceExprListListInstruction.arrayElementsListNode.getArrayElementsList().length;
    const extractedValues = this.currCallFrame.peekValuesArray(count);
    let valuesArray: Array<values.Value> = [];
    for (const value of extractedValues) {
      if (value.kind !== values.ValueKind.INTR_ARRAY_DATA) {
        throw new Error('Internal error: Expected array data value on stack');
      }
      const arrayDataValue = value as values.IntrArrayDataValue;
      valuesArray = valuesArray.concat(arrayDataValue.elements);
    }
    const resultValue = new values.IntrArrayDataValue(valuesArray);
    this.currCallFrame.execReplaceOperandStackNary(
      reduceExprListListInstruction,
      count,
      resultValue,
    );
    this.currCallFrame.execIncrInstructionPointer(reduceExprListListInstruction);
  }

  private execNameExprInstruction(
    nameExprInstruction: instruction_set.NameExprInstruction,
  ): void {
    const value = this.currCallFrame.peekValue(0);
    const resultValue = new values.IntrNameValueValue(
      nameExprInstruction.nameExprNode.getName().getName(),
      value,
    );
    this.currCallFrame.execReplaceOperandStack(nameExprInstruction, resultValue);
    this.currCallFrame.execIncrInstructionPointer(nameExprInstruction);
  }

  private execReduceNameExprListInstruction(
    reduceNameExprListInstruction: instruction_set.ReduceNameExprListInstruction,
  ): void {
    const count = reduceNameExprListInstruction.nameExprListNode.getNameExprsList().length;
    const extractedValues = this.currCallFrame.peekValuesArray(count);
    const nameValuesArray: Array<values.IntrNameValueValue> = [];
    for (const value of extractedValues) {
      if (value.kind !== values.ValueKind.INTR_NAME_VALUE) {
        throw new Error(`Internal error: Expected name value on stack, found ${value.kind}`);
      }
      nameValuesArray.push(value as values.IntrNameValueValue);
    }
    const resultValue = new values.IntrNameValueDataValue(nameValuesArray);
    this.currCallFrame.execReplaceOperandStackNary(
      reduceNameExprListInstruction,
      count,
      resultValue,
    );
    this.currCallFrame.execIncrInstructionPointer(reduceNameExprListInstruction);
  }

  private execMapElementInstruction(
    mapElementInstruction: instruction_set.MapElementInstruction,
  ): void {
    const keyValue = this.currCallFrame.peekValue(1);
    const valueValue = this.currCallFrame.peekValue(0);
    const resultValue = new values.IntrMapElementValue(
      keyValue,
      valueValue,
    );
    this.currCallFrame.execReplaceOperandStackBinary(
      mapElementInstruction,
      resultValue,
    );
    this.currCallFrame.execIncrInstructionPointer(mapElementInstruction);
  }

  private execReduceMapElementsListInstruction(
    reduceMapElementsListInstruction: instruction_set.ReduceMapElementsListInstruction,
  ): void {
    const count = reduceMapElementsListInstruction.mapElementsListNode.getMapElementsList().length;
    const extractedValues = this.currCallFrame.peekValuesArray(count);
    const mapElementsArray: Array<values.IntrMapElementValue> = [];
    for (const value of extractedValues) {
      if (value.kind !== values.ValueKind.INTR_MAP_ELEMENT) {
        throw new Error('Internal error: Expected name value on stack');
      }
      mapElementsArray.push(value as values.IntrMapElementValue);
    }
    const resultValue = new values.IntrMapDataValue(mapElementsArray);
    this.currCallFrame.execReplaceOperandStackNary(
      reduceMapElementsListInstruction,
      count,
      resultValue,
    );
    this.currCallFrame.execIncrInstructionPointer(reduceMapElementsListInstruction);
  }

  private resolveLValue(value: values.Value): values.Value {
    switch (value.kind) {
      case values.ValueKind.INTR_VAR_LVALUE: {
        const varLValue = value as values.IntrVarLValue;
        const varData = this.lookupVarData(varLValue.varName);
        if (varData === undefined || varData.value === undefined) {
          throw new Error(`${varLValue.varName} not found`);
        }
        return varData.value;
      }
      case values.ValueKind.INTR_INDEXED_LVALUE: {
        const indexedLValue = value as values.IntrIndexedLValue;
        return this._library.indexedAccess(
          indexedLValue.container,
          indexedLValue.indicesValue,
        );
      }
      case values.ValueKind.INTR_FIELD_LVALUE: {
        const fieldLValue = value as values.IntrFieldLValue;
        return this._library.fieldAccess(
          fieldLValue.struct,
          fieldLValue.fieldName,
        );
      }
      default:
        throw new Error('Unable to resolve LValue');
    }
  }

  private execLValueVarInstruction(
    lValueVarInstruction: instruction_set.LValueVarInstruction,
  ): void {
    const name = lValueVarInstruction.nameNode.getName();
    const varData = this.lookupVarData(name);
    if (varData === undefined) {
      throw new Error(`Variable ${name} not found`);
    }
    this.currCallFrame.execPushToOperandStack(
      lValueVarInstruction,
      new values.IntrVarLValue(name),
    );
    this.currCallFrame.execIncrInstructionPointer(lValueVarInstruction);
  }

  private execLValueIndexInstruction(
    lValueIndexInstruction: instruction_set.LValueIndexInstruction,
  ): void {
    let containerValue = this.currCallFrame.peekValue(1);
    const indicesValue = this.currCallFrame.peekValue(0);
    if (indicesValue.kind !== values.ValueKind.INTR_ARRAY_DATA) {
      throw new Error('Incorrect index value');
    }
    if (containerValue.isLValue()) {
      containerValue = this.resolveLValue(containerValue);
    }
    const lValue = new values.IntrIndexedLValue(
      containerValue,
      indicesValue as values.IntrArrayDataValue,
    );
    this.currCallFrame.execReplaceOperandStackBinary(lValueIndexInstruction, lValue);
    this.currCallFrame.execIncrInstructionPointer(lValueIndexInstruction);
  }

  private execLValueFieldInstruction(
    lValueFieldInstruction: instruction_set.LValueFieldInstruction,
  ): void {
    let structValue = this.currCallFrame.peekValue(0);
    if (structValue.isLValue()) {
      structValue = this.resolveLValue(structValue);
    }
    const lValue = new values.IntrFieldLValue(
      structValue,
      lValueFieldInstruction.lFieldAccessNode.getFieldName().getName(),
    );
    this.currCallFrame.execReplaceOperandStack(lValueFieldInstruction, lValue);
    this.currCallFrame.execIncrInstructionPointer(lValueFieldInstruction);
  }

  private execAssignInstruction(
    assignInstruction: instruction_set.AssignInstruction,
  ): void {
    const popedValues = this.currCallFrame.execPopValuesArray(assignInstruction, 2);
    const lValue = popedValues[0];
    const value = popedValues[1];
    if (lValue.kind === values.ValueKind.INTR_VAR_LVALUE) {
      const varLValue = lValue as values.IntrVarLValue;
      this.currCallFrame.execSetLocalVariable(
        assignInstruction,
        varLValue.varName,
        value,
      );
    } else if (lValue.kind === values.ValueKind.INTR_INDEXED_LVALUE) {
      const indexedLValue = lValue as values.IntrIndexedLValue;
      this._library.execSetIndexed(
        assignInstruction,
        indexedLValue.container,
        indexedLValue.indicesValue,
        value,
      );
    } else if (lValue.kind === values.ValueKind.INTR_FIELD_LVALUE) {
      const fieldLValue = lValue as values.IntrFieldLValue;
      this._library.execSetField(
        assignInstruction,
        fieldLValue.struct,
        fieldLValue.fieldName,
        value,
      );
    } else {
      throw new Error(`LValue expected, found ${lValue.kind}`);
    }
    this.currCallFrame.execIncrInstructionPointer(assignInstruction);
  }

  private execVarDeclInstruction(
    varDeclInstruction: instruction_set.VarDeclInstruction,
  ): void {
    for (const nameType of varDeclInstruction.varDeclNode.getDeclarations().getNameTypesList()) {
      const name = nameType.getName().getName();
      const attrs = this.astToAttributes(nameType.getAttributesList());
      const type = this.typeSystem.typeForTypeNode(nameType.getType());
      this.currCallFrame.execDeclLocalVariable(varDeclInstruction, name, type, attrs);
    }
    this.currCallFrame.execIncrInstructionPointer(varDeclInstruction);
  }

  private execVarDefInstruction(
    varDefInstruction: instruction_set.VarDefInstruction,
  ): void {
    const varNameType = varDefInstruction.varDefNode.getVarName();
    const initExpr = varDefInstruction.varDefNode.getValueExpr();
    const poppedValue = this.currCallFrame.execPopValuesArray(varDefInstruction, 1);
    const name = varNameType.getName().getName();
    const attrs = this.astToAttributes(varNameType.getAttributesList());
    const typeNode = varNameType.getType();
    const value = poppedValue[0];
    let type: type_system.Type;
    if (typeNode === undefined) {
      type = value.type;
    } else {
      type = this.typeSystem.typeForTypeNode(typeNode);
    }
    this.currCallFrame.execDefLocalVariable(
      varDefInstruction,
      name,
      type,
      attrs,
      value,
    );
    this.currCallFrame.execIncrInstructionPointer(varDefInstruction);
  }

  private execPopInstruction(
    popInstruction: instruction_set.PopInstruction,
  ): void {
    this.currCallFrame.execPopValuesArray(popInstruction, popInstruction.popCount);
    this.currCallFrame.execIncrInstructionPointer(popInstruction);
  }

  private execPushVoidInstruction(
    pushVoidInstruction: instruction_set.PushVoidInstruction,
  ): void {
    this.currCallFrame.execPushToOperandStack(
      pushVoidInstruction,
      this._library.voidValue,
    );
    this.currCallFrame.execIncrInstructionPointer(pushVoidInstruction);
  }

  private execPrintInstruction(
    printInstruction: instruction_set.PrintInstruction,
  ): void {
    const printValues = this.currCallFrame.execPopValuesArray(
      printInstruction,
      printInstruction.printExprCount,
    );
    let logStr = '';
    for (const printVal of printValues) {
      logStr += printVal.toString();
    }
    this.rePrint(logStr);
    this._executionTracer.trace(() => new execution_tracer.EmitPrintTrace(
      printInstruction,
      logStr,
    ));
    this.currCallFrame.execIncrInstructionPointer(printInstruction);
  }

  public rePrint(logStr: string): void {
    this._logStore.push(logStr);
  }

  public unPrint(): void {
    this._logStore.pop();
  }

  private execJumpInstruction(
    jumpInstruction: instruction_set.JumpInstruction,
  ): void {
    this.currCallFrame.execInstrPointerFromJumpInstruction(jumpInstruction);
  }

  private execJumpIfNotInstruction(
    jumpIfNotInstruction: instruction_set.JumpIfNotInstruction,
  ): void {
    const poppedValues = this.currCallFrame.execPopValuesArray(jumpIfNotInstruction, 1);
    const val = poppedValues[0];
    if (val.kind !== values.ValueKind.BOOL) {
      throw new Error(`Expected boolean value got ${val.kind}`);
    }
    const condVal = val as values.BoolValue;
    if (condVal.value) {
      this.currCallFrame.execIncrInstructionPointer(jumpIfNotInstruction);
    } else {
      this.currCallFrame.execInstrPointerFromJumpInstruction(
        jumpIfNotInstruction,
      );
    }
  }

  private execReturnInstruction(
    returnInstruction: instruction_set.ReturnInstruction,
  ): void {
    const retVal = this.currCallFrame.peekValue(0);
    const oldCallFrame = this.popCallFrame();
    this._executionTracer.trace(() => new execution_tracer.PopCallFrameTrace(
      returnInstruction,
      oldCallFrame,
    ));
    const { currCallFrame } = this;
    currCallFrame.execPushToOperandStack(
      currCallFrame.currentInstruction,
      retVal,
    );
    currCallFrame.execIncrInstructionPointer(returnInstruction);
  }

  private execPushBlockInstruction(
    pushBlockInstruction: instruction_set.PushBlockInstruction,
  ): void {
    this.currCallFrame.execPushBlock(pushBlockInstruction);
    this.currCallFrame.execIncrInstructionPointer(pushBlockInstruction);
  }

  private execPopBlockInstruction(
    popBlockInstruction: instruction_set.PopBlockInstruction,
  ): void {
    this.currCallFrame.execPopBlock(popBlockInstruction);
    this.currCallFrame.execIncrInstructionPointer(popBlockInstruction);
  }
}
