











































































import { Vue, Component } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

const revModule = namespace('revModule');

@Component
export default class App extends Vue {
  @revModule.State
  programName: string;
}
