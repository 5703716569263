
import { Vue } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import RevModule, {
  RuntimeWrapper,
  runtimeWrapper,
} from '@/store/rev_module';
import AlgorithmSummariesModule from '@/store/algorithm_summaries';

export default class VueBaseComponent extends Vue {
  get revModule(): RevModule {
    return getModule(RevModule, this.$store);
  }

  get algorithmSummariesModule(): AlgorithmSummariesModule {
    return getModule(AlgorithmSummariesModule, this.$store);
  }

  get runtimeWrapper(): RuntimeWrapper {
    return runtimeWrapper;
  }
}
