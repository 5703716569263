import * as ast from '@/generated/ast_pb';

export enum TypeKind {
  TYPE = 0,
  VOID,
  INT,
  NUMBER,
  BOOL,
  CHAR,
  STRING,
  NULL,
  BITS,
  FUNC,
  TUPLE,
  ARRAY,
  LIST,
  SET,
  QUEUE,
  STACK,
  DEQUE,
  MAP,
  STRUCT,
  ENUM,
}

export abstract class Type {
  protected constructor(
    readonly kind: TypeKind,
  ) {}

  public abstract codedType(): string;

  public abstract isPrimitive(): boolean;

  public isAssignableToType(type: Type): boolean {
    // Usually types need to be exactly same to be assignable.
    return this === type;
  }
}

export class PrimitiveType extends Type {
  public constructor(kind: TypeKind) {
    super(kind);
  }

  public codedType(): string {
    switch (this.kind) {
      case TypeKind.TYPE:
        return 'type';
      case TypeKind.VOID:
        return 'void';
      case TypeKind.INT:
        return 'int';
      case TypeKind.NUMBER:
        return 'number';
      case TypeKind.BOOL:
        return 'bool';
      case TypeKind.CHAR:
        return 'char';
      case TypeKind.STRING:
        return 'string';
      case TypeKind.NULL:
        return '<<null>>';
      default:
        throw new Error(`Internal error: unknown type kind ${this.kind}`);
    }
  }

  public isPrimitive(): boolean {
    return this.kind !== TypeKind.NULL;
  }

  public isAssignableToType(type: Type): boolean {
    if (super.isAssignableToType(type)) {
      return true;
    }
    if (this.kind === TypeKind.NULL) {
      // nulls are assignable to any non primitive.
      return !type.isPrimitive();
    }
    return false;
  }
}

// bits<$length>
export class BitsType extends Type {
  public constructor(
    readonly length: bigint,
  ) {
    super(TypeKind.BITS);
  }

  public codedType(): string {
    return `bits<${this.length}>`;
  }

  public isPrimitive(): boolean {
    return true;
  }
}

export class FuncParam {
  public constructor(
    readonly name: string,
    readonly type: Type,
  ) {}

  public codedType(): string {
    return `${this.name}:${this.type.codedType()}`;
  }
}

// ([$type,]+)->$type
export class FuncType extends Type {
  private params = new Map<string, FuncParam>();

  public constructor(
    params: Array<FuncParam>,
    readonly returnType: Type,
  ) {
    super(TypeKind.FUNC);
    for (const fp of params) {
      this.params.set(fp.name, fp);
    }
  }

  public paramsCount(): number {
    return this.params.size;
  }

  public param(name: string): FuncParam {
    return this.params.get(name);
  }

  public codedType(): string {
    let code = '(';
    const first = false;
    for (const param of this.params) {
      if (!first) {
        code += ',';
      }
      code += param[1].codedType();
    }
    code += ')->';
    code += this.returnType.codedType();
    return code;
  }

  public isPrimitive(): boolean {
    return false;
  }
}

// tuple<$elementTypes>
export class TupleType extends Type {
  public constructor(
    readonly elementTypes: Array<Type>,
  ) {
    super(TypeKind.TUPLE);
  }

  public codedType(): string {
    let code = 'tuple<';
    const first = false;
    for (const elementType of this.elementTypes) {
      if (!first) {
        code += ',';
      }
      code += elementType.codedType();
    }
    code += '>';
    return code;
  }

  public isPrimitive(): boolean {
    return false;
  }
}

// array<elementType,$shape>
export class ArrayType extends Type {
  public constructor(
    readonly elementType: Type,
    readonly dims: bigint,
  ) {
    super(TypeKind.ARRAY);
  }

  public codedType(): string {
    let code = 'array<';
    code += this.elementType.codedType();
    code += this.dims.toString();
    code += '>';
    return code;
  }

  public isPrimitive(): boolean {
    return false;
  }
}

// container<elementType>
export class ContainerType extends Type {
  public constructor(
    kind: TypeKind,
    readonly elementType: Type,
  ) {
    super(kind);
  }

  public codedType(): string {
    let code = '';
    switch (this.kind) {
      case TypeKind.LIST:
        code = 'list<';
        break;
      case TypeKind.SET:
        code = 'set<';
        break;
      case TypeKind.QUEUE:
        code = 'queue<';
        break;
      case TypeKind.STACK:
        code = 'stack<';
        break;
      case TypeKind.DEQUE:
        code = 'deque<';
        break;
      default:
        throw new Error(`Internal error:  unknown type kind ${this.kind}`);
    }
    code += this.elementType.codedType();
    code += '>';
    return code;
  }

  public isPrimitive(): boolean {
    return false;
  }
}

// map<$keyType,$valueType>
export class MapType extends Type {
  public constructor(
    readonly keyType: Type,
    readonly valueType: Type,
  ) {
    super(TypeKind.MAP);
  }

  public codedType(): string {
    let code = 'map<';
    code += this.keyType.codedType();
    code += ',';
    code += this.valueType.codedType();
    code += '>';
    return code;
  }

  public isPrimitive(): boolean {
    return false;
  }
}

export class Field {
  public constructor(
    readonly name: string,
    readonly type: Type,
    readonly structType: StructType,
  ) {}
}

export class StructType extends Type {
  private readonly fields = new Map<string, Field>();

  public constructor(
    readonly name: string,
  ) {
    super(TypeKind.STRUCT);
  }

  public codedType(): string {
    return this.name;
  }

  public fieldsCount(): number {
    return this.fields.size;
  }

  public hasField(name: string): Boolean {
    return this.fields.has(name);
  }

  public field(name: string): Field {
    return this.fields.get(name);
  }

  public addField(name: string, type: Type) {
    this.fields.set(name, new Field(name, type, this));
  }

  public isPrimitive(): boolean {
    return false;
  }
}

export class EnumElement {
  public constructor(
    readonly name: string,
    readonly enumType: EnumType,
  ) {}
}

export class EnumType extends Type {
  private enumElements = new Map<string, EnumElement>();

  public constructor(
    readonly name: string,
  ) {
    super(TypeKind.ENUM);
  }

  public codedType(): string {
    return this.name;
  }

  public addEnumElement(name: string) {
    this.enumElements.set(name, new EnumElement(name, this));
  }

  public isPrimitive(): boolean {
    return true;
  }

  public findEnumElement(name: string): EnumElement {
    return this.enumElements.get(name);
  }
}

export class RevlangTypeSystem {
  public readonly typeType = new PrimitiveType(TypeKind.TYPE);

  public readonly voidType = new PrimitiveType(TypeKind.VOID);

  public readonly intType = new PrimitiveType(TypeKind.INT);

  public readonly numberType = new PrimitiveType(TypeKind.NUMBER);

  public readonly boolType = new PrimitiveType(TypeKind.BOOL);

  public readonly charType = new PrimitiveType(TypeKind.CHAR);

  public readonly stringType = new PrimitiveType(TypeKind.STRING);

  public readonly nullType = new PrimitiveType(TypeKind.NULL);

  private typeInternCache = new Map<string, Type>();

  private typeDefTable = new Map<string, Type>();

  public createBitsType(length: bigint): BitsType {
    const bitsType = new BitsType(length);
    let internedType = this.typeInternCache.get(bitsType.codedType()) as BitsType;
    if (internedType === undefined) {
      this.typeInternCache.set(bitsType.codedType(), bitsType);
      internedType = bitsType;
    }
    return internedType;
  }

  public createBitsTypeFromNumber(length: number): BitsType {
    return this.createBitsType(BigInt(length));
  }

  public createFuncType(
    params: Array<FuncParam>,
    returnType: Type,
  ): FuncType {
    const funcType = new FuncType(params, returnType);
    let internedType = this.typeInternCache.get(funcType.codedType()) as FuncType;
    if (internedType === undefined) {
      this.typeInternCache.set(funcType.codedType(), funcType);
      internedType = funcType;
    }
    return internedType;
  }

  public createTupleType(
    elementTypes: Array<Type>,
  ): TupleType {
    const tupleType = new TupleType(elementTypes);
    let internedType = this.typeInternCache.get(tupleType.codedType()) as TupleType;
    if (internedType === undefined) {
      this.typeInternCache.set(tupleType.codedType(), tupleType);
      internedType = tupleType;
    }
    return internedType;
  }

  public createArrayType(
    elementType: Type,
    dims: bigint,
  ): ArrayType {
    const arrayType = new ArrayType(elementType, dims);
    let internedType = this.typeInternCache.get(arrayType.codedType()) as ArrayType;
    if (internedType === undefined) {
      this.typeInternCache.set(arrayType.codedType(), arrayType);
      internedType = arrayType;
    }
    return internedType;
  }

  public createContainerType(
    kind: TypeKind,
    elementType: Type,
  ): ContainerType {
    const containerType = new ContainerType(kind, elementType);
    let internedType = this.typeInternCache.get(containerType.codedType()) as ContainerType;
    if (internedType === undefined) {
      this.typeInternCache.set(containerType.codedType(), containerType);
      internedType = containerType;
    }
    return internedType;
  }

  public createMapType(
    keyType: Type,
    valueType: Type,
  ): MapType {
    const mapType = new MapType(keyType, valueType);
    let internedType = this.typeInternCache.get(mapType.codedType()) as MapType;
    if (internedType === undefined) {
      this.typeInternCache.set(mapType.codedType(), mapType);
      internedType = mapType;
    }
    return internedType;
  }

  public startStructType(name: string): StructType {
    if (this.typeInternCache.get(name) !== undefined) {
      throw new Error(`Name ${name} already defined`);
    }
    const structType = new StructType(name);
    this.typeInternCache.set(name, structType);
    return structType;
  }

  public finishStructType(
    struct: StructType,
    structDefNode: ast.StructDefNode,
  ): void {
    for (const nameTypeNode of structDefNode.getFields().getNameTypesList()) {
      const name = nameTypeNode.getName().getName();
      if (struct.field(nameTypeNode.getName().getName()) !== undefined) {
        throw new Error(`Field ${name} is already defined`);
      }
      const type = this.typeForTypeNode(nameTypeNode.getType());
      struct.addField(name, type);
    }
  }

  public createEnumType(
    name: string,
    enumDefNode: ast.EnumDefNode,
  ): EnumType {
    if (this.typeInternCache.get(name) !== undefined) {
      throw new Error(`Name ${name} already defined`);
    }
    const enumType = new EnumType(name);
    this.typeInternCache.set(name, enumType);
    for (const nameNode of enumDefNode.getEnumElementsList()) {
      enumType.addEnumElement(nameNode.getName());
    }
    return enumType;
  }

  public funcTypeForNode(funcTypeNode: ast.FuncTypeNode): FuncType {
    const funcParams = funcTypeNode.getParams().getNameTypesList().map(
      nameTypeNode => new FuncParam(
        nameTypeNode.getName().getName(),
        this.typeForTypeNode(nameTypeNode.getType()),
      ),
    );
    const returnType = this.typeForTypeNode(funcTypeNode.getReturnType());
    return this.createFuncType(funcParams, returnType);
  }

  public tupleTypeForNode(tupleTypeNode: ast.TupleTypeNode): TupleType {
    const elementTypes = tupleTypeNode.getElementTypesList().map(
      elementTypeNode => this.typeForTypeNode(elementTypeNode),
    );
    return this.createTupleType(elementTypes);
  }

  public arrayTypeForNode(arrayTypeNode: ast.ArrayTypeNode): ArrayType {
    const elementType = this.typeForTypeNode(arrayTypeNode.getElementType());
    let dims: bigint;
    if (arrayTypeNode.getDims() === undefined) {
      dims = 1n;
    } else {
      dims = BigInt(arrayTypeNode.getDims().getValue());
    }
    return this.createArrayType(elementType, dims);
  }

  public containerTypeForNode(
    typeKind: TypeKind,
    elementTypeNode: ast.TypeNode,
  ): ContainerType {
    return this.createContainerType(
      typeKind,
      this.typeForTypeNode(elementTypeNode),
    );
  }

  public mapTypeForNode(mapTypeNode: ast.MapTypeNode): MapType {
    return this.createMapType(
      this.typeForTypeNode(mapTypeNode.getKeyType()),
      this.typeForTypeNode(mapTypeNode.getValueType()),
    );
  }

  public lookupType(name: string): Type {
    const def = this.typeDefTable.get(name);
    if (def !== undefined) {
      return def;
    }
    return undefined;
  }

  public aliasTypeForNode(
    nameNode: ast.NameNode,
    asTypeKind?: TypeKind,
  ): Type {
    const name = nameNode.getName();
    const foundType = this.lookupType(name);
    if (foundType === undefined) {
      throw new Error(`Type ${name} not found`);
    }
    if (asTypeKind !== undefined
        && foundType.kind !== asTypeKind) {
      throw new Error(`type ${name} is not of kind: ${asTypeKind}`);
    }
    return foundType;
  }

  public typeForTypeNode(typeNode: ast.TypeNode): Type {
    switch (typeNode.getKind()) {
      case ast.TypeNodeKind.VOID:
        return this.voidType;
      case ast.TypeNodeKind.INT:
        return this.intType;
      case ast.TypeNodeKind.NUMBER:
        return this.numberType;
      case ast.TypeNodeKind.BOOL:
        return this.boolType;
      case ast.TypeNodeKind.CHAR:
        return this.charType;
      case ast.TypeNodeKind.STRING:
        return this.stringType;
      case ast.TypeNodeKind.BITS:
        return this.createBitsType(BigInt(typeNode.getLength().getValue()));
      case ast.TypeNodeKind.FUNC:
        return this.funcTypeForNode(typeNode.getFuncType());
      case ast.TypeNodeKind.TUPLE:
        return this.tupleTypeForNode(typeNode.getTupleType());
      case ast.TypeNodeKind.ARRAY:
        return this.arrayTypeForNode(typeNode.getArrayType());
      case ast.TypeNodeKind.LIST:
        return this.containerTypeForNode(
          TypeKind.LIST,
          typeNode.getElementType(),
        );
      case ast.TypeNodeKind.SET:
        return this.containerTypeForNode(
          TypeKind.SET,
          typeNode.getElementType(),
        );
      case ast.TypeNodeKind.QUEUE:
        return this.containerTypeForNode(
          TypeKind.QUEUE,
          typeNode.getElementType(),
        );
      case ast.TypeNodeKind.STACK:
        return this.containerTypeForNode(
          TypeKind.STACK,
          typeNode.getElementType(),
        );
      case ast.TypeNodeKind.DEQUE:
        return this.containerTypeForNode(
          TypeKind.DEQUE,
          typeNode.getElementType(),
        );
      case ast.TypeNodeKind.MAP:
        return this.mapTypeForNode(typeNode.getMapType());
      case ast.TypeNodeKind.ALIAS:
        return this.aliasTypeForNode(typeNode.getAlias());
      default:
        throw new Error(`Intenal error:  Unknown type node kind ${typeNode.getKind()}`);
    }
  }

  public loadTypeDefs(typeDefs: Array<ast.TypeDefNode>): void {
    const defSet = new Set<string>();
    for (const typeDef of typeDefs) {
      const name = typeDef.getName().getName();
      if (defSet.has(name)) {
        throw new Error(`Name ${name} already defined`);
      }
      defSet.add(name);
    }
    for (const typeDef of typeDefs) {
      const name = typeDef.getName().getName();
      switch (typeDef.getKind()) {
        case ast.TypeDefNodeKind.STRUCT_DEF:
          this.typeDefTable.set(name, this.startStructType(name));
          break;
        case ast.TypeDefNodeKind.ENUM_DEF:
          this.typeDefTable.set(name, this.createEnumType(
            name,
            typeDef.getEnumDef(),
          ));
          break;
        default:
          // ignore other global decl types for now.
          break;
      }
    }
    for (const typeDef of typeDefs) {
      const name = typeDef.getName().getName();
      switch (typeDef.getKind()) {
        case ast.TypeDefNodeKind.STRUCT_DEF: {
          const structType = this.typeDefTable.get(name) as StructType;
          this.finishStructType(
            structType,
            typeDef.getStructDef(),
          );
          break;
        }
        case ast.TypeDefNodeKind.ALIAS_DEF: {
          this.typeDefTable.set(name, this.typeForTypeNode(typeDef.getAliasDef()));
          break;
        }
        default:
          // ignore other global decl types for now.
          break;
      }
    }
  }
}
