import { Decimal as BigDecimal } from 'decimal.js/decimal';
import * as ast from '@/generated/ast_pb';
import * as type_system from '@/revlang/type_system';
import * as instruction_set from '@/revlang/instruction_set';
import * as span_nodes from '@/revlang/span_nodes';

export class VarData {
  public readonly vizAttrData: ast.VisualizerAttrData;

  public readonly indexAttrDatas: Array<ast.IndexAttrData> = [];

  public value: Value;

  public constructor(
    readonly type: type_system.Type,
    attrs: Array<ast.AttributeNode>,
  ) {
    for (const attr of attrs) {
      switch (attr.getType()) {
        case ast.AttributeType.VISUALIZER:
          this.vizAttrData = attr.getVisualizerData();
          break;
        case ast.AttributeType.INDEX:
          this.indexAttrDatas.push(attr.getIndexData());
          break;
        default:
          break;
      }
    }
  }
}

export enum ValueKind {
  VOID = 0,
  INT,
  NUMBER,
  BOOL,
  CHAR,
  STRING,
  NULL,
  BITS,
  FUNC,
  TUPLE,
  ARRAY,
  LIST,
  PRIMITIVE_SET,
  SET,
  QUEUE,
  STACK,
  DEQUE,
  PRIMITIVE_MAP,
  MAP,
  STRUCT,
  ENUM,
  TYPE,
  // Intermediate ealuation only values.
  // Type of all these will be null.
  INTR_ARRAY_DATA,
  INTR_NAME_VALUE,
  INTR_NAME_VALUE_DATA,
  INTR_MAP_ELEMENT,
  INTR_MAP_DATA,
  INTR_VAR_LVALUE,
  INTR_INDEXED_LVALUE,
  INTR_FIELD_LVALUE
}

export abstract class Value {
  protected constructor(readonly type: type_system.Type) { }

  public abstract get kind(): ValueKind;

  public abstract toDebugString(): string;

  public isContainer(): boolean {
    return false;
  }

  public isLValue(): boolean {
    return false;
  }

  public copy(): Value {
    throw new Error(`copy not supported on ${this.kind}`);
  }

  public size(): number {
    throw new Error(`size not supported on ${this.kind}`);
  }

  public clear(): object {
    throw new Error(`clear not supported on ${this.kind}`);
  }

  public reClear(): void {
    throw new Error(`reClear not supported on ${this.kind}`);
  }

  public unClear(payload: object): void {
    throw new Error(`unClear not supported on ${this.kind}`);
  }

  public toString(): string {
    throw new Error(`toString not supported on ${this.kind}`);
  }

  public isPrimitive(): boolean {
    return this.type.isPrimitive();
  }

  public isNumerical(): boolean {
    return false;
  }

  public codedValue(): string {
    throw new Error('Coding not supported for non primitive type.');
  }

  public isAssignableToType(type: type_system.Type): boolean {
    return this.type.isAssignableToType(type);
  }
}

export class VoidValue extends Value {
  public constructor(readonly type: type_system.PrimitiveType) {
    super(type);
  }

  public get kind(): ValueKind {
    return ValueKind.VOID;
  }

  public toDebugString(): string {
    return 'Void';
  }

  public codedValue(): string {
    return '<<void>>';
  }
}

export class IntValue extends Value {
  public constructor(
    readonly type: type_system.PrimitiveType,
    readonly value: bigint,
  ) {
    super(type);
  }

  public get kind(): ValueKind {
    return ValueKind.INT;
  }

  public toDebugString(): string {
    return `Int(${this.value})`;
  }

  public toString(): string {
    return `${this.codedValue()}`;
  }

  public isNumerical(): boolean {
    return true;
  }

  public codedValue(): string {
    return this.value.toString();
  }
}

export class NumberValue extends Value {
  public constructor(
    readonly type: type_system.PrimitiveType,
    readonly value: BigDecimal,
  ) {
    super(type);
  }

  public get kind(): ValueKind {
    return ValueKind.NUMBER;
  }

  public toDebugString(): string {
    return `Number(${this.value.toString()})`;
  }

  public toString(): string {
    return `${this.codedValue()}`;
  }

  public isNumerical(): boolean {
    return true;
  }

  public codedValue(): string {
    return this.value.toString();
  }
}

export class BoolValue extends Value {
  public constructor(
    readonly type: type_system.PrimitiveType,
    readonly value: boolean,
  ) {
    super(type);
  }

  public get kind(): ValueKind {
    return ValueKind.BOOL;
  }

  public toDebugString(): string {
    return `Bool(${this.codedValue()})`;
  }

  public toString(): string {
    return `${this.codedValue()}`;
  }

  public codedValue(): string {
    return this.value ? 'true' : 'false';
  }
}

export class CharValue extends Value {
  public constructor(
    readonly type: type_system.PrimitiveType,
    readonly value: number,
  ) {
    super(type);
  }

  public get kind(): ValueKind {
    return ValueKind.CHAR;
  }

  public toDebugString(): string {
    return `Char(${this.codedValue()})`;
  }

  public toString(): string {
    return `${this.codedValue()}`;
  }

  public codedValue(): string {
    return String.fromCharCode(this.value);
  }
}

export class StringValue extends Value {
  public constructor(
    readonly type: type_system.PrimitiveType,
    readonly value: string,
  ) {
    super(type);
  }

  public get kind(): ValueKind {
    return ValueKind.STRING;
  }

  public toDebugString(): string {
    return `String(${this.codedValue()})`;
  }

  public toString(): string {
    return `${this.codedValue()}`;
  }

  public codedValue(): string {
    return this.value;
  }
}

export class NullValue extends Value {
  public constructor(readonly type: type_system.PrimitiveType) {
    super(type);
  }

  public get kind(): ValueKind {
    return ValueKind.NULL;
  }

  public toDebugString(): string {
    return 'Null';
  }

  public toString(): string {
    return 'null';
  }

  public codedValue(): string {
    return '<<null>>';
  }
}

export class BitsValue extends Value {
  public constructor(
    readonly bitsType: type_system.BitsType,
    readonly value: bigint,
  ) {
    super(bitsType);
    this.value = BigInt.asUintN(Number(this.bitsType.length), this.value);
  }

  public get kind(): ValueKind {
    return ValueKind.BITS;
  }

  public toDebugString(): string {
    return `Bits(${this.codedValue()})`;
  }

  public toString(): string {
    return `${this.codedValue()}`;
  }

  public codedValue(): string {
    return `#${this.bitsType.length}x${this.value.toString(16)}`;
  }
}

export class FuncValue extends Value {
  public constructor(
    readonly funcName: string,
    readonly funcType: type_system.FuncType,
    readonly paramsAttributes: Map<string, Array<ast.AttributeNode>>,
    readonly blockNode: ast.BlockNode,
    readonly instructions: Array<instruction_set.Instruction>,
    readonly funcRootStepId: span_nodes.StepIdNode,
  ) {
    super(funcType);
  }

  public get kind(): ValueKind {
    return ValueKind.FUNC;
  }

  public toDebugString(): string {
    return 'Func';
  }
}

export class TupleValue extends Value {
  public constructor(
    readonly tupleType: type_system.TupleType,
    readonly elements: Array<Value>,
  ) {
    super(tupleType);
  }

  public get kind(): ValueKind {
    return ValueKind.TUPLE;
  }

  public toDebugString(): string {
    return `Tuple[l:${this.elements.length}]`;
  }

  public static createFromArrayData(
    tupleType: type_system.TupleType,
    arrayData: IntrArrayDataValue,
  ): TupleValue {
    return new TupleValue(tupleType, arrayData.elements);
  }

  public isContainer(): boolean {
    return true;
  }

  public copy(): TupleValue {
    return new TupleValue(this.tupleType, this.elements.slice());
  }

  public size(): number {
    return this.elements.length;
  }

  private checkIndexibleWith(indicesValue: IntrArrayDataValue): void {
    if (indicesValue.elements.length !== 1) {
      throw new Error('Expected 1 index');
    }
    const indexValue = indicesValue.elements[0];
    if (indexValue.type.kind !== type_system.TypeKind.INT) {
      throw new Error('Tuple index is expected to be int');
    }
    const indexIntValue = indexValue as IntValue;
    if (indexIntValue.value >= this.elements.length) {
      throw new Error('Tuple index out of bound');
    }
  }

  public getAtIndex(indicesValue: IntrArrayDataValue): Value {
    this.checkIndexibleWith(indicesValue);
    const indexValue = indicesValue.elements[0] as IntValue;
    return this.elements[Number(indexValue.value)];
  }

  public setAtIndex(indicesValue: IntrArrayDataValue, newValue: Value): Value {
    this.checkIndexibleWith(indicesValue);
    const indexValue = indicesValue.elements[0] as IntValue;
    if (!newValue.isAssignableToType(this.tupleType.elementTypes[Number(indexValue.value)])) {
      throw new Error(`${newValue.kind} not assignable to tuple`);
    }
    return this.reSetAtIndex(indicesValue, newValue);
  }

  public reSetAtIndex(indicesValue: IntrArrayDataValue, newValue: Value): Value {
    const indexValue = indicesValue.elements[0] as IntValue;
    const oldValue = this.elements[Number(indexValue.value)];
    this.elements[Number(indexValue.value)] = newValue;
    return oldValue;
  }

  public unSetAtIndex(indicesValue: IntrArrayDataValue, oldValue: Value): Value {
    const indexValue = indicesValue.elements[0] as IntValue;
    this.elements[Number(indexValue.value)] = oldValue;
    return oldValue;
  }
}

export class ArrayValue extends Value {
  readonly strides: Array<bigint>;

  readonly totalElements: bigint;

  public constructor(
    readonly arrayType: type_system.ArrayType,
    readonly shape: Array<bigint>,
    readonly elements: Array<Value>,
  ) {
    super(arrayType);
    this.strides = new Array<bigint>(this.shape.length);
    this.strides[this.shape.length - 1] = 1n;
    this.totalElements = this.shape[this.shape.length - 1];
    for (let i = this.shape.length - 2; i >= 0; i -= 1) {
      this.strides[i] = this.strides[i + 1] * this.shape[i + 1];
      this.totalElements *= this.shape[i];
    }
  }

  public get kind(): ValueKind {
    return ValueKind.ARRAY;
  }

  public toDebugString(): string {
    return `Array[l:${this.elements.length}]`;
  }

  public static createFromArrayData(
    arrayType: type_system.ArrayType,
    shape: Array<bigint>,
    arrayData: IntrArrayDataValue,
  ): ArrayValue {
    return new ArrayValue(arrayType, shape, arrayData.elements);
  }

  public isContainer(): boolean {
    return true;
  }

  public copy(): ArrayValue {
    return new ArrayValue(this.arrayType, this.shape, this.elements.slice());
  }

  public size(): number {
    return this.elements.length;
  }

  public dims(): bigint {
    return BigInt(this.arrayType.dims);
  }

  public dimLenNum(dim: number): number {
    if (dim < 0 || dim >= this.shape.length) {
      throw new Error(`Too big dimension ${dim}`);
    }
    return Number(this.shape[Number(dim)]);
  }

  public dimLen(dim: IntValue): bigint {
    if (dim.value < 0 || dim.value >= this.shape.length) {
      throw new Error(`Too big dimension ${dim.value}`);
    }
    return BigInt(this.shape[Number(dim.value)]);
  }

  private checkIndexibleWith(indicesValue: IntrArrayDataValue): void {
    if (indicesValue.elements.length !== Number(this.shape.length)) {
      throw new Error(`Expected ${this.totalElements} indices`);
    }
    const { length } = indicesValue.elements;
    for (let i = 0; i < length; i += 1) {
      const indexValue = indicesValue.elements[i];
      if (indexValue.type.kind !== type_system.TypeKind.INT) {
        throw new Error('Array indices are expected to be int');
      }
      const indexIntValue = indexValue as IntValue;
      if (indexIntValue.value >= this.shape[i]) {
        throw new Error(`Array index ${i} out of bound`);
      }
    }
  }

  public getAtNumIndex(...index: Array<number>): Value {
    if (BigInt(index.length) !== this.dims()) {
      throw new Error('Array Index dimension illegal');
    }
    let linearIndex = 0;
    for (let i = 0; i < index.length; i += 1) {
      const indexValue = index[i];
      if (indexValue >= this.shape[i]) {
        throw new Error(`Array index ${i} out of bound`);
      }
      linearIndex += Number(this.strides[i]) * indexValue;
    }
    return this.elements[linearIndex];
  }

  public getAtIndex(indicesValue: IntrArrayDataValue): Value {
    this.checkIndexibleWith(indicesValue);
    let index = 0n;
    const { length } = indicesValue.elements;
    for (let i = 0; i < length; i += 1) {
      const indexValue = indicesValue.elements[i];
      const indexIntValue = indexValue as IntValue;
      index += this.strides[i] * indexIntValue.value;
    }
    return this.elements[Number(index)];
  }

  public setAtIndex(indicesValue: IntrArrayDataValue, newValue: Value): Value {
    this.checkIndexibleWith(indicesValue);
    if (!newValue.isAssignableToType(this.arrayType.elementType)) {
      throw new Error(`${newValue.kind} not assignable to array`);
    }
    return this.reSetAtIndex(indicesValue, newValue);
  }

  public reSetAtIndex(indicesValue: IntrArrayDataValue, newValue: Value): Value {
    let index = 0n;
    const { length } = indicesValue.elements;
    for (let i = 0; i < length; i += 1) {
      const indexValue = indicesValue.elements[i];
      const indexIntValue = indexValue as IntValue;
      index += this.strides[i] * indexIntValue.value;
    }
    const oldValue = this.elements[Number(index)];
    this.elements[Number(index)] = newValue;
    return oldValue;
  }

  public unSetAtIndex(indicesValue: IntrArrayDataValue, oldValue: Value): void {
    let index = 0n;
    const { length } = indicesValue.elements;
    for (let i = 0; i < length; i += 1) {
      const indexValue = indicesValue.elements[i];
      const indexIntValue = indexValue as IntValue;
      index += this.strides[i] * indexIntValue.value;
    }
    this.elements[Number(index)] = oldValue;
  }
}

export class ListValue extends Value {
  public constructor(
    readonly listType: type_system.ContainerType,
    readonly elements: Array<Value>,
  ) {
    super(listType);
  }

  public static createFromArrayData(
    listType: type_system.ContainerType,
    arrayData: IntrArrayDataValue,
  ): ListValue {
    return new ListValue(listType, arrayData.elements);
  }

  public get kind(): ValueKind {
    return ValueKind.LIST;
  }

  public toDebugString(): string {
    return `List[l:${this.elements.length}]`;
  }

  public isContainer(): boolean {
    return true;
  }

  public copy(): ListValue {
    return new ListValue(this.listType, this.elements.slice());
  }

  public size(): number {
    return this.elements.length;
  }

  public clear(): object {
    const copy = this.elements.slice();
    this.reClear();
    return copy;
  }

  public reClear(): void {
    this.elements.length = 0;
  }

  public unClear(payload: object): void {
    if (this.elements.length !== 0) {
      throw new Error('Internal error: should have been empty');
    }
    const elements = payload as Array<Value>;
    this.elements.splice(0, 0, ...elements);
  }

  private checkIndexibleWith(indicesValue: IntrArrayDataValue): void {
    if (indicesValue.elements.length !== 1) {
      throw new Error('Expected 1 index');
    }
    const indexValue = indicesValue.elements[0];
    if (indexValue.type.kind !== type_system.TypeKind.INT) {
      throw new Error('List index is expected to be int');
    }
    const indexIntValue = indexValue as IntValue;
    if (indexIntValue.value >= this.elements.length) {
      throw new Error('List index out of bound');
    }
  }

  public getAtIndex(indicesValue: IntrArrayDataValue): Value {
    this.checkIndexibleWith(indicesValue);
    const indexValue = indicesValue.elements[0] as IntValue;
    return this.elements[Number(indexValue.value)];
  }

  public setAtIndex(indicesValue: IntrArrayDataValue, newValue: Value): Value {
    this.checkIndexibleWith(indicesValue);
    if (!newValue.isAssignableToType(this.listType.elementType)) {
      throw new Error(`${newValue.kind} not assignable to list`);
    }
    return this.reSetAtIndex(indicesValue, newValue);
  }

  public reSetAtIndex(indicesValue: IntrArrayDataValue, newValue: Value): Value {
    const indexValue = indicesValue.elements[0] as IntValue;
    const oldValue = this.elements[Number(indexValue.value)];
    this.elements[Number(indexValue.value)] = newValue;
    return oldValue;
  }

  public unSetAtIndex(indicesValue: IntrArrayDataValue, oldValue: Value): Value {
    const indexValue = indicesValue.elements[0] as IntValue;
    this.elements[Number(indexValue.value)] = oldValue;
    return oldValue;
  }

  private checkCanBeElement(element: Value): void {
    if (!element.type.isAssignableToType(this.listType.elementType)) {
      throw new Error('Element not addable to list');
    }
  }

  public append(element: Value): void {
    this.checkCanBeElement(element);
    this.reAppend(element);
  }

  public reAppend(element: Value): void {
    this.elements.push(element);
  }

  public unAppend(): void {
    this.elements.pop();
  }

  private checkRemovableIndex(indexValue: IntValue): void {
    if (indexValue.value >= BigInt(this.elements.length)
        || indexValue.value < 0n) {
      throw new Error(`Index ${indexValue.value} out of bound for remove`);
    }
  }

  public removeAt(indexValue: IntValue): Value {
    this.checkRemovableIndex(indexValue);
    return this.reRemoveAt(indexValue);
  }

  public reRemoveAt(indexValue: IntValue): Value {
    const removedValues = this.elements.splice(Number(indexValue.value), 1);
    return removedValues[0];
  }

  public unRemoveAt(indexValue: IntValue, removedValue: Value): void {
    this.elements.splice(Number(indexValue.value), 0, removedValue);
  }

  private checkInsertableIndex(indexValue: IntValue): void {
    if (indexValue.value > BigInt(this.elements.length)
        || indexValue.value < 0n) {
      throw new Error(`Index ${indexValue.value} out of bound for insert`);
    }
  }

  public insertAt(indexValue: IntValue, element: Value): void {
    this.checkCanBeElement(element);
    this.checkInsertableIndex(indexValue);
    this.reInsertAt(indexValue, element);
  }

  public reInsertAt(indexValue: IntValue, element: Value): void {
    this.elements.splice(Number(indexValue.value), 0, element);
  }

  public unInsertAt(indexValue: IntValue): void {
    this.elements.splice(Number(indexValue.value), 1);
  }

  private checkExtendableWith(otherList: ListValue): void {
    if (this.type !== otherList.type) {
      throw new Error('Two lists not compatible for extend');
    }
  }

  public extendAt(indexValue: IntValue, otherList: ListValue): void {
    this.checkInsertableIndex(indexValue);
    this.checkExtendableWith(otherList);
    this.reExtendAt(indexValue, otherList);
  }

  public reExtendAt(indexValue: IntValue, otherList: ListValue): void {
    this.elements.splice(Number(indexValue.value), 0, ...otherList.elements);
  }

  public unExtendAt(indexValue: IntValue, otherList: ListValue): void {
    this.elements.splice(Number(indexValue.value), otherList.elements.length);
  }

  public reverse(): void {
    this.elements.reverse();
  }
}

export class PrimitiveSetValue extends Value {
  public constructor(
    readonly setType: type_system.ContainerType,
    readonly keyValues: Map<string, Value>,
    readonly keySet: Set<string>,
  ) {
    super(setType);
  }

  public static createFromArrayData(
    setType: type_system.ContainerType,
    arrayData: IntrArrayDataValue,
  ): PrimitiveSetValue {
    const keyValues = new Map<string, Value>();
    const keySet = new Set<string>();
    for (const element of arrayData.elements) {
      const code = element.codedValue();
      keyValues.set(code, element);
      keySet.add(code);
    }
    return new PrimitiveSetValue(setType, keyValues, keySet);
  }

  public get kind(): ValueKind {
    return ValueKind.PRIMITIVE_SET;
  }

  public toDebugString(): string {
    return `PrimitiveSet[kv:${this.keyValues.size},ks:${this.keySet.size}]`;
  }

  public isContainer(): boolean {
    return true;
  }

  public copy(): PrimitiveSetValue {
    return new PrimitiveSetValue(
      this.setType,
      new Map<string, Value>(this.keyValues),
      new Set<string>(this.keySet),
    );
  }

  public size(): number {
    return this.keyValues.size;
  }

  public clear(): object {
    const oldKeyValues = new Map<string, Value>(this.keyValues);
    const oldKeySet = new Set<string>(this.keySet);
    this.reClear();
    return [oldKeyValues, oldKeySet];
  }

  public reClear(): void {
    this.keyValues.clear();
    this.keySet.clear();
  }

  public unClear(payload: object): void {
    if (this.keyValues.size !== 0) {
      throw new Error('Internal Error: Expected empty set');
    }
    const oldKeyValues = payload[0] as Map<string, Value>;
    const oldKeySet = payload[1] as Set<string>;
    oldKeyValues.forEach((val, key, _) => {
      this.keyValues.set(key, val);
    });
    oldKeySet.forEach((val, _, __) => {
      this.keySet.add(val);
    });
  }

  public contains(keyValue: Value): boolean {
    if (keyValue.type !== this.setType.elementType) {
      return false;
    }
    return this.keySet.has(keyValue.codedValue());
  }

  private checkAddable(keyValue: Value): void {
    if (!keyValue.type.isAssignableToType(this.setType.elementType)) {
      throw new Error('Unexpect element type');
    }
  }

  public addKey(keyValue: Value): object {
    this.checkAddable(keyValue);
    const code = keyValue.codedValue();
    const oldKeyValue = this.keyValues.get(code);
    const payload = [code, oldKeyValue, keyValue];
    this.reAddKey(payload);
    return payload;
  }

  public reAddKey(payload: object): void {
    const code = payload[0] as string;
    const keyValue = payload[2] as Value;
    this.keyValues.set(code, keyValue);
    this.keySet.add(code);
  }

  public unAddKey(payload: object): void {
    const code = payload[0] as string;
    const oldKeyValue = payload[1] as Value;
    if (oldKeyValue === undefined) {
      this.keyValues.delete(code);
      this.keySet.delete(code);
    } else {
      this.keyValues.set(code, oldKeyValue);
      this.keySet.add(code);
    }
  }

  private checkIndexable(keyValue: Value): void {
    if (!keyValue.type.isAssignableToType(this.setType.elementType)) {
      throw new Error('Unexpect element type');
    }
  }

  public remove(keyValue: Value): object {
    this.checkIndexable(keyValue);
    const code = keyValue.codedValue();
    const oldKeyValue = this.keyValues.get(code);
    const payload = [code, oldKeyValue];
    this.reRemove(payload);
    return payload;
  }

  public reRemove(payload: object): void {
    const code = payload[0] as string;
    this.keyValues.delete(code);
    this.keySet.delete(code);
  }

  public unRemove(payload: object): void {
    const code = payload[0] as string;
    const oldKeyValue = payload[1] as Value;
    if (oldKeyValue !== undefined) {
      this.keyValues.set(code, oldKeyValue);
      this.keySet.add(code);
    }
  }

  public isDisjoint(otherSet: PrimitiveSetValue): boolean {
    if (!otherSet.setType.elementType.isAssignableToType(this.setType.elementType)) {
      return true;
    }
    for (const keyCode of this.keySet) {
      if (otherSet.keySet.has(keyCode)) {
        return false;
      }
    }
    return true;
  }
}

export class SetValue extends Value {
  public constructor(
    readonly setType: type_system.ContainerType,
    readonly valueSet: Set<Value>,
  ) {
    super(setType);
  }

  public static createFromArrayData(
    setType: type_system.ContainerType,
    arrayData: IntrArrayDataValue,
  ): SetValue {
    const valueSet = new Set<Value>();
    for (const value of arrayData.elements) {
      valueSet.add(value);
    }
    return new SetValue(setType, valueSet);
  }

  public get kind(): ValueKind {
    return ValueKind.SET;
  }

  public toDebugString(): string {
    return `Set[k:${this.valueSet.size}]`;
  }

  public isContainer(): boolean {
    return true;
  }

  public copy(): SetValue {
    return new SetValue(
      this.setType,
      new Set<Value>(this.valueSet),
    );
  }

  public size(): number {
    return this.valueSet.size;
  }

  public clear(): object {
    const oldValueSet = new Set<Value>(this.valueSet);
    this.reClear();
    return oldValueSet;
  }

  public reClear(): void {
    this.valueSet.clear();
  }

  public unClear(payload: object): void {
    if (this.valueSet.size !== 0) {
      throw new Error('Internal Error: Expected empty set');
    }
    const oldValueSet = payload as Set<Value>;
    oldValueSet.forEach((val, _, __) => {
      this.valueSet.add(val);
    });
  }

  public contains(value: Value): boolean {
    return this.valueSet.has(value);
  }

  private checkAddable(keyValue: Value): void {
    if (!keyValue.type.isAssignableToType(this.setType.elementType)) {
      throw new Error('Unexpect element type');
    }
  }

  public addKey(keyValue: Value): object {
    this.checkAddable(keyValue);
    let payload: object;
    if (this.valueSet.has(keyValue)) {
      payload = [keyValue, keyValue];
    } else {
      payload = [undefined, keyValue];
    }
    this.reAddKey(payload);
    return payload;
  }

  public reAddKey(payload: object): void {
    const keyValue = payload[1] as Value;
    this.valueSet.add(keyValue);
  }

  public unAddKey(payload: object): void {
    const oldKeyValue = payload[0] as Value;
    if (oldKeyValue === undefined) {
      this.valueSet.delete(oldKeyValue);
    }
  }

  private checkIndexable(keyValue: Value): void {
    if (!keyValue.type.isAssignableToType(this.setType.elementType)) {
      throw new Error('Unexpect element type');
    }
  }

  public remove(keyValue: Value): object {
    this.checkIndexable(keyValue);
    let payload: object;
    if (this.valueSet.has(keyValue)) {
      payload = [keyValue, keyValue];
    } else {
      payload = [undefined, keyValue];
    }
    this.reRemove(payload);
    return payload;
  }

  public reRemove(payload: object): void {
    const keyValue = payload[1] as Value;
    this.valueSet.delete(keyValue);
  }

  public unRemove(payload: object): void {
    const oldKeyValue = payload[0] as Value;
    if (oldKeyValue !== undefined) {
      this.valueSet.add(oldKeyValue);
    }
  }

  public isDisjoint(otherSet: SetValue): boolean {
    if (!otherSet.setType.elementType.isAssignableToType(this.setType.elementType)) {
      return true;
    }
    for (const value of this.valueSet) {
      if (otherSet.valueSet.has(value)) {
        return false;
      }
    }
    return true;
  }
}

export class QueueValue extends Value {
  public constructor(
    readonly queueType: type_system.ContainerType,
    readonly elements: Array<Value>,
  ) {
    super(queueType);
  }

  public static createFromArrayData(
    queueType: type_system.ContainerType,
    arrayData: IntrArrayDataValue,
  ): QueueValue {
    return new QueueValue(queueType, arrayData.elements);
  }

  public get kind(): ValueKind {
    return ValueKind.QUEUE;
  }

  public toDebugString(): string {
    return `Queue[l:${this.elements.length}]`;
  }

  public isContainer(): boolean {
    return true;
  }

  public copy(): QueueValue {
    return new QueueValue(this.queueType, this.elements.slice());
  }

  public size(): number {
    return this.elements.length;
  }

  public clear(): object {
    const copy = this.elements.slice();
    this.reClear();
    return copy;
  }

  public reClear(): void {
    this.elements.length = 0;
  }

  public unClear(payload: object): void {
    if (this.elements.length !== 0) {
      throw new Error('Internal error: should have been empty');
    }
    const elements = payload as Array<Value>;
    this.elements.splice(0, 0, ...elements);
  }

  private checkAddable(element: Value): void {
    if (!element.type.isAssignableToType(this.queueType.elementType)) {
      throw new Error('Unexpect element type');
    }
  }

  public enqueue(element: Value): void {
    this.checkAddable(element);
    this.reEnqueue(element);
  }

  public reEnqueue(element: Value): void {
    this.elements.push(element);
  }

  public unEnqueue(): void {
    this.elements.pop();
  }

  private checkDequeable(): void {
    if (this.elements.length <= 0) {
      throw new Error('No elements to dequeue');
    }
  }

  public dequeue(): Value {
    this.checkDequeable();
    return this.reDequeue();
  }

  public reDequeue(): Value {
    return this.elements.shift();
  }

  public unDequeue(element: Value): void {
    this.elements.unshift(element);
  }
}

export class StackValue extends Value {
  public constructor(
    readonly stackType: type_system.ContainerType,
    readonly elements: Array<Value>,
  ) {
    super(stackType);
  }

  public static createFromArrayData(
    stackType: type_system.ContainerType,
    arrayData: IntrArrayDataValue,
  ): StackValue {
    return new StackValue(stackType, arrayData.elements);
  }

  public get kind(): ValueKind {
    return ValueKind.STACK;
  }

  public toDebugString(): string {
    return `Stack[kv:${this.elements.length}]`;
  }

  public isContainer(): boolean {
    return true;
  }

  public copy(): StackValue {
    return new StackValue(this.stackType, this.elements.slice());
  }

  public size(): number {
    return this.elements.length;
  }

  public clear(): object {
    const copy = this.elements.slice();
    this.reClear();
    return copy;
  }

  public reClear(): void {
    this.elements.length = 0;
  }

  public unClear(payload: object): void {
    if (this.elements.length !== 0) {
      throw new Error('Internal error: should have been empty');
    }
    const elements = payload as Array<Value>;
    this.elements.splice(0, 0, ...elements);
  }

  private checkAddable(element: Value): void {
    if (!element.type.isAssignableToType(this.stackType.elementType)) {
      throw new Error('Unexpect element type');
    }
  }

  public push(element: Value): void {
    this.checkAddable(element);
    this.rePush(element);
  }

  public rePush(element: Value): void {
    this.elements.push(element);
  }

  public unPush(): void {
    this.elements.pop();
  }

  private checkPopable(): void {
    if (this.elements.length <= 0) {
      throw new Error('No elements to pop');
    }
  }

  public pop(): Value {
    this.checkPopable();
    return this.rePop();
  }

  public rePop(): Value {
    this.checkPopable();
    return this.elements.pop();
  }

  public unPop(poppedValue: Value): void {
    this.elements.push(poppedValue);
  }
}

export class DequeValue extends Value {
  public constructor(
    readonly dequeType: type_system.ContainerType,
    readonly elements: Array<Value>,
  ) {
    super(dequeType);
  }

  public static createFromArrayData(
    dequeType: type_system.ContainerType,
    arrayData: IntrArrayDataValue,
  ): DequeValue {
    return new DequeValue(dequeType, arrayData.elements);
  }

  public get kind(): ValueKind {
    return ValueKind.DEQUE;
  }

  public toDebugString(): string {
    return `Deque[l:${this.elements.length}]`;
  }

  public isContainer(): boolean {
    return true;
  }

  public copy(): DequeValue {
    return new DequeValue(this.dequeType, this.elements.slice());
  }

  public size(): number {
    return this.elements.length;
  }

  public clear(): object {
    const copy = this.elements.slice();
    this.reClear();
    return copy;
  }

  public reClear(): void {
    this.elements.length = 0;
  }

  public unClear(payload: object): void {
    if (this.elements.length !== 0) {
      throw new Error('Internal error: should have been empty');
    }
    const elements = payload as Array<Value>;
    this.elements.splice(0, 0, ...elements);
  }

  private checkAddable(element: Value): void {
    if (!element.type.isAssignableToType(this.dequeType.elementType)) {
      throw new Error('Unexpect element type');
    }
  }

  public pushFront(element: Value): void {
    this.checkAddable(element);
    this.rePushFront(element);
  }

  public rePushFront(element: Value): void {
    this.elements.unshift(element);
  }

  public unPushFront(): void {
    this.elements.shift();
  }

  public pushBack(element: Value): void {
    this.checkAddable(element);
    this.rePushBack(element);
  }

  public rePushBack(element: Value): void {
    this.elements.push(element);
  }

  public unPushBack(): void {
    this.elements.pop();
  }

  private checkPopable(): void {
    if (this.elements.length <= 0) {
      throw new Error('No elements to pop');
    }
  }

  public popFront(): Value {
    this.checkPopable();
    return this.rePopFront();
  }

  public rePopFront(): Value {
    return this.elements.shift();
  }

  public unPopFront(poppedValue: Value): void {
    this.elements.unshift(poppedValue);
  }

  public popBack(): Value {
    this.checkPopable();
    return this.rePopBack();
  }

  public rePopBack(): Value {
    return this.elements.pop();
  }

  public unPopBack(poppedValue: Value): void {
    this.elements.push(poppedValue);
  }
}

export class PrimitiveMapValue extends Value {
  public constructor(
    readonly mapType: type_system.MapType,
    readonly keyValues: Map<string, Value>,
    readonly valueValues: Map<string, Value>,
  ) {
    super(mapType);
  }

  public static createFromMapData(
    mapType: type_system.MapType,
    mapDataValue: IntrMapDataValue,
  ): PrimitiveMapValue {
    const keyValues = new Map<string, Value>();
    const valueValues = new Map<string, Value>();
    for (const mapElement of mapDataValue.mapElements) {
      const code = mapElement.keyValue.codedValue();
      keyValues.set(code, mapElement.keyValue);
      valueValues.set(code, mapElement.valueValue);
    }
    return new PrimitiveMapValue(mapType, keyValues, valueValues);
  }

  public get kind(): ValueKind {
    return ValueKind.PRIMITIVE_MAP;
  }

  public toDebugString(): string {
    return `PrimitiveMap[kv:${this.keyValues.size},vv:${this.valueValues.size}]`;
  }

  public isContainer(): boolean {
    return true;
  }

  public copy(): PrimitiveMapValue {
    return new PrimitiveMapValue(
      this.mapType,
      new Map<string, Value>(this.keyValues),
      new Map<string, Value>(this.valueValues),
    );
  }

  public size(): number {
    return this.keyValues.size;
  }

  public clear(): object {
    const oldKeyValues = new Map<string, Value>(this.keyValues);
    const oldValueValues = new Map<string, Value>(this.valueValues);
    this.reClear();
    return [oldKeyValues, oldValueValues];
  }

  public reClear(): void {
    this.keyValues.clear();
    this.valueValues.clear();
  }

  public unClear(payload: object): void {
    if (this.keyValues.size !== 0) {
      throw new Error('Internal Error: Expected empty set');
    }
    const oldKeyValues = payload[0] as Map<string, Value>;
    const oldValueValues = payload[1] as Map<string, Value>;
    oldKeyValues.forEach((val, key, _) => {
      this.keyValues.set(key, val);
    });
    oldValueValues.forEach((val, key, _) => {
      this.valueValues.set(key, val);
    });
  }

  private checkIndexibleWith(
    indicesValue: IntrArrayDataValue,
    checkIndexExists: boolean,
  ): void {
    if (indicesValue.elements.length !== 1) {
      throw new Error('Expected 1 index');
    }
    const indexValue = indicesValue.elements[0];
    if (indexValue.type !== this.mapType.keyType) {
      throw new Error('Map index is unexpected type');
    }
    if (checkIndexExists) {
      const keyString = indexValue.codedValue();
      if (!this.keyValues.has(keyString)) {
        throw new Error('Key not found');
      }
    }
  }

  public getAtIndex(indicesValue: IntrArrayDataValue): Value {
    this.checkIndexibleWith(indicesValue, true);
    const indexValue = indicesValue.elements[0];
    const keyString = indexValue.codedValue();
    const retValue = this.valueValues.get(keyString);
    return retValue;
  }

  public setAtIndex(
    indicesValue: IntrArrayDataValue,
    newValueValue: Value,
  ): object {
    this.checkIndexibleWith(indicesValue, false);
    if (!newValueValue.isAssignableToType(this.mapType.valueType)) {
      throw new Error(`${newValueValue.kind} not assignable to map`);
    }
    const newKeyValue = indicesValue.elements[0];
    const keyCode = newKeyValue.codedValue();
    const oldKeyValue = this.keyValues.get(keyCode);
    const oldValueValue = this.valueValues.get(keyCode);
    const payload = [keyCode, oldKeyValue, oldValueValue, newKeyValue, newValueValue];
    this.reSetAtIndex(payload);
    return payload;
  }

  public reSetAtIndex(payload: object): void {
    const keyCode = payload[0] as string;
    const newKeyValue = payload[3] as Value;
    const newValueValue = payload[4] as Value;
    this.keyValues.set(keyCode, newKeyValue);
    this.valueValues.set(keyCode, newValueValue);
  }

  public unSetAtIndex(payload: object): void {
    const keyCode = payload[0] as string;
    const oldKeyValue = payload[1] as Value;
    const oldValueValue = payload[2] as Value;
    if (oldKeyValue === undefined) {
      this.keyValues.delete(keyCode);
      this.valueValues.delete(keyCode);
    } else {
      this.keyValues.set(keyCode, oldKeyValue);
      this.valueValues.set(keyCode, oldValueValue);
    }
  }

  public contains(keyValue: Value): boolean {
    if (keyValue.type !== this.mapType.keyType) {
      return false;
    }
    return this.keyValues.has(keyValue.codedValue());
  }

  private checkIndexable(keyValue: Value): void {
    if (!keyValue.type.isAssignableToType(this.mapType.keyType)) {
      throw new Error('Unexpect element type');
    }
  }

  public remove(keyValue: Value): object {
    this.checkIndexable(keyValue);
    const keyCode = keyValue.codedValue();
    const oldKeyValue = this.keyValues.get(keyCode);
    const oldValueValue = this.valueValues.get(keyCode);
    const payload = [keyCode, oldKeyValue, oldValueValue];
    this.reRemove(payload);
    return payload;
  }

  public reRemove(payload: object): void {
    const keyCode = payload[0] as string;
    this.keyValues.delete(keyCode);
    this.valueValues.delete(keyCode);
  }

  public unRemove(payload: object): void {
    const code = payload[0] as string;
    const oldKeyValue = payload[1] as Value;
    const oldValueValue = payload[2] as Value;
    if (oldKeyValue !== undefined) {
      this.keyValues.set(code, oldKeyValue);
      this.valueValues.set(code, oldValueValue);
    }
  }
}

export class MapValue extends Value {
  public constructor(
    readonly mapType: type_system.MapType,
    readonly keyValues: Map<Value, Value>,
  ) {
    super(mapType);
  }

  public static createFromMapData(
    mapType: type_system.MapType,
    mapDataValue: IntrMapDataValue,
  ): MapValue {
    const keyValues = new Map<Value, Value>();
    for (const mapElement of mapDataValue.mapElements) {
      keyValues.set(mapElement.keyValue, mapElement.valueValue);
    }
    return new MapValue(mapType, keyValues);
  }

  public get kind(): ValueKind {
    return ValueKind.MAP;
  }

  public toDebugString(): string {
    return `Map[kv:${this.keyValues.size}]`;
  }

  public isContainer(): boolean {
    return true;
  }

  public copy(): MapValue {
    return new MapValue(
      this.mapType,
      new Map<Value, Value>(this.keyValues),
    );
  }

  public size(): number {
    return this.keyValues.size;
  }

  public clear(): object {
    const oldKeyValues = new Map<Value, Value>(this.keyValues);
    this.reClear();
    return oldKeyValues;
  }

  public reClear(): void {
    this.keyValues.clear();
  }

  public unClear(payload: object): void {
    if (this.keyValues.size !== 0) {
      throw new Error('Internal Error: Expected empty set');
    }
    const oldKeyValues = payload as Map<Value, Value>;
    oldKeyValues.forEach((val, key, _) => {
      this.keyValues.set(key, val);
    });
  }

  private checkIndexibleWith(indicesValue: IntrArrayDataValue): void {
    if (indicesValue.elements.length !== 1) {
      throw new Error('Expected 1 index');
    }
    const indexValue = indicesValue.elements[0];
    if (indexValue.type !== this.mapType.keyType) {
      throw new Error('Map index is unexpected type');
    }
    if (!this.keyValues.has(indexValue)) {
      throw new Error('Key not found');
    }
  }

  public getAtIndex(indicesValue: IntrArrayDataValue): Value {
    this.checkIndexibleWith(indicesValue);
    const keyValue = indicesValue.elements[0];
    return this.keyValues.get(keyValue);
  }

  public setAtIndex(indicesValue: IntrArrayDataValue, newValueValue: Value): object {
    this.checkIndexibleWith(indicesValue);
    if (!newValueValue.isAssignableToType(this.mapType.valueType)) {
      throw new Error(`${newValueValue.kind} not assignable to map`);
    }
    const keyValue = indicesValue.elements[0];
    const oldValueValue = this.keyValues.get(keyValue);
    const payload = [keyValue, oldValueValue, newValueValue];
    this.reSetAtIndex(payload);
    return payload;
  }

  public reSetAtIndex(payload: object): void {
    const keyValue = payload[0] as Value;
    const newValueValue = payload[2] as Value;
    this.keyValues.set(keyValue, newValueValue);
  }

  public unSetAtIndex(payload: object): void {
    const keyValue = payload[0] as Value;
    const oldValueValue = payload[1] as Value;
    if (oldValueValue === undefined) {
      this.keyValues.delete(keyValue);
    } else {
      this.keyValues.set(keyValue, oldValueValue);
    }
  }

  public contains(keyValue: Value): boolean {
    return this.keyValues.has(keyValue);
  }

  private checkIndexable(keyValue: Value): void {
    if (!keyValue.type.isAssignableToType(this.mapType.keyType)) {
      throw new Error('Unexpect element type');
    }
  }

  public remove(keyValue: Value): object {
    this.checkIndexable(keyValue);
    const oldValueValue = this.keyValues.get(keyValue);
    const payload = [keyValue, oldValueValue];
    this.reRemove(payload);
    return payload;
  }

  public reRemove(payload: object): void {
    const keyValue = payload[0] as Value;
    this.keyValues.delete(keyValue);
    this.keyValues.delete(keyValue);
  }

  public unRemove(payload: object): void {
    const keyValue = payload[0] as Value;
    const oldValueValue = payload[1] as Value;
    if (oldValueValue !== undefined) {
      this.keyValues.set(keyValue, oldValueValue);
    }
  }
}

export class StructValue extends Value {
  public constructor(
    readonly structType: type_system.StructType,
    readonly fieldValuesMap: Map<string, Value>,
  ) {
    super(structType);
  }

  public static createFromNameValueData(
    structType: type_system.StructType,
    nameValueDataValue: IntrNameValueDataValue,
  ): StructValue {
    const fieldValuesMap = new Map<string, Value>();
    for (const nameValueValue of nameValueDataValue.nameValueElements) {
      fieldValuesMap.set(nameValueValue.name, nameValueValue.value);
    }
    return new StructValue(structType, fieldValuesMap);
  }

  public get kind(): ValueKind {
    return ValueKind.STRUCT;
  }

  public toDebugString(): string {
    return `Struct[f:${this.fieldValuesMap.size}]`;
  }

  public isContainer(): boolean {
    return true;
  }

  public copy(): StructValue {
    return new StructValue(
      this.structType,
      new Map<string, Value>(this.fieldValuesMap),
    );
  }

  public size(): number {
    return this.fieldValuesMap.size;
  }

  public checkFieldAccess(fieldName: string): void {
    if (!this.structType.hasField(fieldName)) {
      throw new Error(`Field ${fieldName} not found`);
    }
  }

  public getFieldValue(fieldName: string): Value {
    this.checkFieldAccess(fieldName);
    return this.fieldValuesMap.get(fieldName);
  }

  public setFieldValue(fieldName: string, value: Value): Value {
    this.checkFieldAccess(fieldName);
    const field = this.structType.field(fieldName);
    if (!value.isAssignableToType(field.type)) {
      throw new Error(`Expression not assignable to field: ${fieldName}`);
    }
    const oldValue = this.fieldValuesMap.get(fieldName);
    this.reSetFieldValue(fieldName, value);
    return oldValue;
  }

  public reSetFieldValue(fieldName: string, value: Value): void {
    this.fieldValuesMap.set(fieldName, value);
  }

  public unSetFieldValue(fieldName: string, oldValue: Value): void {
    if (oldValue === undefined) {
      this.fieldValuesMap.delete(fieldName);
    } else {
      this.fieldValuesMap.set(fieldName, oldValue);
    }
  }
}

export class EnumValue extends Value {
  public constructor(
    readonly enumType: type_system.EnumType,
    readonly enumElement: type_system.EnumElement,
  ) {
    super(enumType);
  }

  public get kind(): ValueKind {
    return ValueKind.ENUM;
  }

  public toDebugString(): string {
    return `Enum[n:${this.enumElement.name}]`;
  }

  public codedValue(): string {
    return `${this.enumType.codedType()}.${this.enumElement.name}`;
  }
}

export class TypeValue extends Value {
  public constructor(
    readonly type: type_system.Type,
    readonly typeValue: type_system.Type,
  ) {
    super(type);
  }

  public get kind(): ValueKind {
    return ValueKind.TYPE;
  }

  public toDebugString(): string {
    return 'Type';
  }
}

export class IntrArrayDataValue extends Value {
  public constructor(readonly elements: Array<Value>) {
    super(null);
  }

  public get kind(): ValueKind {
    return ValueKind.INTR_ARRAY_DATA;
  }

  public toDebugString(): string {
    return `IntrArrayData[c:${this.elements.length}]`;
  }

  public checkExtractShape(): Array<bigint> {
    const shape: Array<bigint> = [];
    const valueArray = this.elements;
    for (let i = 0; i < valueArray.length; i += 1) {
      if (valueArray[i].kind !== ValueKind.INT) {
        throw new Error(`Shape expected to be int at index: ${i}`);
      }
      const lenValue = valueArray[i] as IntValue;
      if (lenValue.value <= 0n) {
        throw new Error(`Shape expected to be > 0 at index: ${i}`);
      }
      shape.push(lenValue.value);
    }
    return shape;
  }

  public checkAssignableToTupleType(tupleType: type_system.TupleType): void {
    const valueArray = this.elements;
    if (valueArray.length !== tupleType.elementTypes.length) {
      throw new Error('Incorrect length for tuple');
    }
    for (let i = 0; i < valueArray.length; i += 1) {
      if (!valueArray[i].isAssignableToType(tupleType.elementTypes[i])) {
        throw new Error(`Tuple element not assignable at index: ${i}`);
      }
    }
  }

  public checkAssignableToArrayType(
    shape: Array<bigint>,
    arrayType: type_system.ArrayType,
  ): void {
    const valueArray = this.elements;
    const totalElements = shape.reduce(
      (accumulator, currentValue) => accumulator * currentValue,
      1n,
    );
    if (shape.length !== Number(arrayType.dims)) {
      throw new Error('Incorrect length for shape');
    }
    if (this.elements.length !== Number(totalElements)) {
      throw new Error('Incorrect number of elements');
    }
    for (let i = 0; i < valueArray.length; i += 1) {
      if (!valueArray[i].isAssignableToType(arrayType.elementType)) {
        throw new Error(`Array element not assignable at index: ${i}`);
      }
    }
  }

  public checkAssignableToContainerType(containerType: type_system.ContainerType): void {
    const valueArray = this.elements;
    const { length } = valueArray;
    for (let i = 0; i < length; i += 1) {
      if (!valueArray[i].isAssignableToType(containerType.elementType)) {
        throw new Error(`Container element not assignable at index: ${i}`);
      }
    }
  }
}

export class IntrNameValueValue extends Value {
  public constructor(
    readonly name: string,
    readonly value: Value,
  ) {
    super(null);
  }

  public get kind(): ValueKind {
    return ValueKind.INTR_NAME_VALUE;
  }

  public toDebugString(): string {
    return `IntrNameValue[n:${this.name}]`;
  }
}

export class IntrNameValueDataValue extends Value {
  public constructor(readonly nameValueElements: Array<IntrNameValueValue>) {
    super(null);
  }

  public get kind(): ValueKind {
    return ValueKind.INTR_NAME_VALUE_DATA;
  }

  public toDebugString(): string {
    return `IntrNameValueData[c:${this.nameValueElements.length}]`;
  }

  public checkAssignableToStructType(structType: type_system.StructType): void {
    if (this.nameValueElements.length !== structType.fieldsCount()) {
      throw new Error('Not all fields specified');
    }
    for (const nameValueValue of this.nameValueElements) {
      const field = structType.field(nameValueValue.name);
      if (field === undefined) {
        throw new Error(`Field ${nameValueValue.name} not found in ${structType.name}`);
      }
      if (!nameValueValue.value.isAssignableToType(field.type)) {
        throw new Error(`Field ${nameValueValue.name} not assignable in ${structType.name}`);
      }
    }
  }

  public checkArgsCompatibleForFuncCall(funcType: type_system.FuncType): void {
    if (this.nameValueElements.length !== funcType.paramsCount()) {
      throw new Error('Not all fields specified');
    }
    for (const nameValueValue of this.nameValueElements) {
      const param = funcType.param(nameValueValue.name);
      if (param === undefined) {
        throw new Error(`Arg ${nameValueValue.name} not found in function`);
      }
      if (!nameValueValue.value.isAssignableToType(param.type)) {
        throw new Error(`Arg ${nameValueValue.name} not assignable in function`);
      }
    }
  }
}

export class IntrMapElementValue extends Value {
  public constructor(
    readonly keyValue: Value,
    readonly valueValue: Value,
  ) {
    super(null);
  }

  public get kind(): ValueKind {
    return ValueKind.INTR_MAP_ELEMENT;
  }

  public toDebugString(): string {
    return 'IntrMapElement';
  }
}

export class IntrMapDataValue extends Value {
  public constructor(readonly mapElements: Array<IntrMapElementValue>) {
    super(null);
  }

  public get kind(): ValueKind {
    return ValueKind.INTR_MAP_DATA;
  }

  public toDebugString(): string {
    return `IntrMapData[c:${this.mapElements.length}]`;
  }

  public checkAssignableToMapType(mapType: type_system.MapType) {
    const { length } = this.mapElements;
    for (let i = 0; i < length; i += 1) {
      if (!this.mapElements[i].keyValue.isAssignableToType(mapType.keyType)) {
        throw new Error(`Key element not assignable at index: ${i}`);
      }
      if (!this.mapElements[i].valueValue.isAssignableToType(mapType.valueType)) {
        throw new Error(`Value element not assignable at index: ${i}`);
      }
    }
  }
}

export class IntrVarLValue extends Value {
  public constructor(
    readonly varName: string,
  ) {
    super(null);
  }

  public get kind(): ValueKind {
    return ValueKind.INTR_VAR_LVALUE;
  }

  public toDebugString(): string {
    return 'IntrVarL';
  }

  public isLValue(): boolean {
    return true;
  }
}

export class IntrIndexedLValue extends Value {
  public constructor(
    readonly container: Value,
    readonly indicesValue: IntrArrayDataValue,
  ) {
    super(null);
  }

  public get kind(): ValueKind {
    return ValueKind.INTR_INDEXED_LVALUE;
  }

  public toDebugString(): string {
    return 'IntrIndexedL';
  }

  public isLValue(): boolean {
    return true;
  }
}

export class IntrFieldLValue extends Value {
  public constructor(
    readonly struct: Value,
    readonly fieldName: string,
  ) {
    super(null);
  }

  public get kind(): ValueKind {
    return ValueKind.INTR_FIELD_LVALUE;
  }

  public toDebugString(): string {
    return 'IntrFieldL';
  }

  public isLValue(): boolean {
    return true;
  }
}
