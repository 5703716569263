























import { Component } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import VueBaseComponent from '@/components/VueBaseComponent';

const revModule = namespace('revModule');

@Component
export default class LogView extends VueBaseComponent {
  @revModule.State
  logs: Array<string>;
}
