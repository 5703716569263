



































import {
  Component,
  Vue,
} from 'vue-property-decorator';
import {
  Splitpanes,
  Pane,
} from 'splitpanes';
import 'splitpanes/dist/splitpanes.css';
import { Draggable } from 'draggable-vue-directive';
import CodeStepper from '@/components/revlang/CodeStepper.vue';
import LogView from '@/components/revlang/LogView.vue';
import CallStack from '@/components/revlang/CallStack.vue';
import StateVisualizerContainer from '@/components/revlang/StateVisualizerContainer.vue';
import VueBaseComponent from '@/components/VueBaseComponent';

@Component({
  components: {
    Splitpanes,
    Pane,
    CodeStepper,
    LogView,
    CallStack,
    StateVisualizerContainer,
  },
  directives: {
    Draggable,
  },
})
export default class RevlangVisualizer extends VueBaseComponent {
}
