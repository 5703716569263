































import {
  Component,
  Prop,
} from 'vue-property-decorator';
import VueBaseComponent from '@/components/VueBaseComponent';
import { SimpleVarVizData } from '@/store/rev_module';
import VarNamePill from '@/components/revlang/visualizers/VarNamePill.vue';

@Component({
  components: {
    VarNamePill,
  },
})
export default class SimpleVarVisualizer extends VueBaseComponent {
  @Prop({
    type: SimpleVarVizData,
    required: true,
  }) readonly varVizData: SimpleVarVizData;
}
