




import { Component } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import VueBaseComponent from '@/components/VueBaseComponent';
import RevlangVisualizer from '@/components/revlang/RevlangVisualizer.vue';
import AlgoCard from '@/components/AlgoCard.vue';

const revModule = namespace('revModule');

@Component({
  components: {
    RevlangVisualizer,
    AlgoCard,
  },
})
export default class AlgoViz extends VueBaseComponent {
  created() {
    this.revModule.fetchLoadAlgorithmData(this.$route.params.algid);
  }

  destroyed() {
    this.revModule.unloadAlgorithmData();
  }
}
