
import { VNode } from 'vue';
import { Component } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import VueBaseComponent from '@/components/VueBaseComponent';
import * as span_nodes from '@/revlang/span_nodes';

const revModule = namespace('revModule');

@Component
export default class CodeView extends VueBaseComponent {
  @revModule.State
  rootSpanNode: span_nodes.SpanNode;

  variableToShow: string = '';

  valueToShow: string = undefined;

  hoverValueElementX: string = '';

  hoverValueElementY: string = '';

  get selectedStepId(): string {
    return `id_${this.revModule.selectedStep}`;
  }

  get isSelectedTopCallFrame(): boolean {
    return this.revModule.selectedCallStackIndex === this.revModule.callStack.length - 1;
  }

  render(createElement): VNode {
    const codeElement = this.rootSpanNodeToPreNode(
      this,
      createElement,
      this.rootSpanNode,
    );
    if (this.variableToShow !== '') {
      const hoverValueElement = createElement(
        'span',
        {
          class: {
            tooltiptext: true,
          },
          style: {
            top: this.hoverValueElementY,
            left: this.hoverValueElementX,
            visibility: this.variableToShow === '' ? 'hidden' : 'visible',
          },
        },
        [`${this.variableToShow} = ${this.valueToShow}`],
      );
      return createElement(
        'div',
        [hoverValueElement, codeElement],
      );
    }
    return codeElement;
  }

  private rootSpanNodeToPreNode(vueComp, createElement, spanNode: span_nodes.SpanNode): VNode {
    if (spanNode === undefined) {
      return createElement('pre');
    }
    return createElement(
      'pre',
      this.spanNodesToVNodes(vueComp, createElement, spanNode.subNodes),
    );
  }

  private spanNodesToVNodes(
    vueComp,
    createElement,
    spanNodes: Array<span_nodes.SpanNode>,
  ): Array<VNode | string> {
    return spanNodes.map(sn => this.spanNodeToVNode(vueComp, createElement, sn));
  }

  private spanNodeToVNode(vueComp, createElement, spanNode: span_nodes.SpanNode): VNode | string {
    if (spanNode.kind === span_nodes.SpanNodeKind.Newline) {
      return '\n';
    }
    if (spanNode.kind === span_nodes.SpanNodeKind.Whitespace) {
      return ' ';
    }
    if (spanNode.kind === span_nodes.SpanNodeKind.Indent) {
      return '  '.repeat(spanNode.indentSize);
    }
    if (spanNode.kind === span_nodes.SpanNodeKind.Text) {
      if (spanNode.textKind === span_nodes.SpanNodeTextKind.Keyword) {
        return createElement(
          'span',
          {
            class: {
              code: true,
              keyword: true,
            },
          },
          [spanNode.text],
        );
      }
      if (spanNode.textKind === span_nodes.SpanNodeTextKind.DefName) {
        return createElement(
          'span',
          {
            class: {
              code: true,
              boldName: true,
            },
            on: {
              mouseenter: this.mouseEnter,
              mouseleave: this.mouseLeave,
            },
          },
          [spanNode.text],
        );
      }
      if (spanNode.textKind === span_nodes.SpanNodeTextKind.RefName) {
        return createElement(
          'span',
          {
            class: {
              code: true,
              name: true,
            },
            on: {
              mouseenter: this.mouseEnter,
              mouseleave: this.mouseLeave,
            },
          },
          [spanNode.text],
        );
      }
      if (spanNode.textKind === span_nodes.SpanNodeTextKind.ParamName) {
        return createElement(
          'span',
          {
            class: {
              code: true,
              name: true,
            },
          },
          [spanNode.text],
        );
      }
      if (spanNode.textKind === span_nodes.SpanNodeTextKind.FieldName) {
        return createElement(
          'span',
          {
            class: {
              code: true,
              name: true,
            },
          },
          [spanNode.text],
        );
      }
      if (spanNode.textKind === span_nodes.SpanNodeTextKind.Literal) {
        return createElement(
          'span',
          {
            class: {
              code: true,
              literal: true,
            },
          },
          [spanNode.text],
        );
      }
      return spanNode.text;
    }
    const subNodes = this.spanNodesToVNodes(vueComp, createElement, spanNode.subNodes);
    if (spanNode.spanId !== undefined) {
      const spanId = `id_${spanNode.spanId}`;
      return createElement(
        'span',
        {
          attrs: {
            id: spanId,
          },
          class: {
            currentSelection:
                vueComp.selectedStepId === spanId && !vueComp.isSelectedTopCallFrame,
            activeSelection:
                vueComp.selectedStepId === spanId && vueComp.isSelectedTopCallFrame,
            code: true,
          },
        },
        subNodes,
      );
    }
    return createElement(
      'span',
      {
        class: {
          code: true,
        },
      },
      subNodes,
    );
  }

  mounted() {
    this.scrollToSelectedStep();
  }

  updated() {
    this.scrollToSelectedStep();
  }

  private scrollToSelectedStep() {
    const el = this.$el.ownerDocument.getElementById(this.selectedStepId);

    if (el) {
      el.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'nearest',
      });
    }
  }

  private mouseEnter(mouseEvent: MouseEvent): void {
    // const spanElement = mouseEvent.target as HTMLSpanElement;
    // const varName = spanElement.innerText;
    // const value = this.runtimeWrapper.lookupValueAsString(
    //   varName,
    //   this.revModule.selectedCallStackIndex,
    // );
    // if (value !== undefined) {
    //   this.variableToShow = spanElement.innerText;
    //   this.valueToShow = value;
    //   this.hoverValueElementX = `${mouseEvent.clientX + 12}px`;
    //   this.hoverValueElementY = `${mouseEvent.clientY - 36}px`;
    // }
  }

  private mouseLeave(mouseEvent: MouseEvent): void {
    // this.clearHover();
  }

  private clearHover(): void {
    this.variableToShow = '';
    this.valueToShow = undefined;
  }
}
