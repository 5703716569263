import { Buffer } from 'buffer';
import Vue from 'vue';
import {
  VuexModule,
  Module,
  Mutation,
  Action,
} from 'vuex-module-decorators';
import * as ast from '@/generated/ast_pb';
import * as content from '@/generated/content_pb';
import {
  AlgorithmSummary,
  algorthimSummaryFromPB,
} from '@/store/algorithm_summary';
import * as runtime from '@/revlang/runtime';
import * as span_nodes from '@/revlang/span_nodes';
import * as values from '@/revlang/values';
import {
  truncateStr,
  widthForStr,
  widthForStrs,
  lineDivisionOffsets,
  textCenterOffsets,
} from '@/utils/layouts';

export enum VarVizDataType {
  Simple,
  SimpleContainer,
}

export enum ContainerType {
  Tuple,
  Array,
  List,
  Set,
  Queue,
  Stack,
  Deque,
  Map,
}

function containerTypeFromVarData(varData: values.VarData): ContainerType {
  switch (varData.value.kind) {
    case values.ValueKind.TUPLE:
      return ContainerType.Tuple;
    case values.ValueKind.ARRAY:
      return ContainerType.Array;
    case values.ValueKind.LIST:
      return ContainerType.List;
    case values.ValueKind.PRIMITIVE_SET:
      return ContainerType.Set;
    case values.ValueKind.QUEUE:
      return ContainerType.Queue;
    case values.ValueKind.STACK:
      return ContainerType.Stack;
    case values.ValueKind.DEQUE:
      return ContainerType.Deque;
    case values.ValueKind.PRIMITIVE_MAP:
      return ContainerType.Map;
    default:
      throw new Error(`Unknown container type: ${varData.type.kind}`);
  }
}

function valuesFromVarData(varData: values.VarData): Array<string> {
  switch (varData.value.kind) {
    case values.ValueKind.TUPLE: {
      const tupleValue = varData.value as values.TupleValue;
      const vals = tupleValue.elements.map(
        valElem => valElem.toString(),
      );
      return vals;
    }
    case values.ValueKind.ARRAY: {
      const arrayValue = varData.value as values.ArrayValue;
      const vals = arrayValue.elements.map(
        valElem => valElem.toString(),
      );
      return vals;
    }
    case values.ValueKind.LIST: {
      const listValue = varData.value as values.ListValue;
      const vals = listValue.elements.map(
        valElem => valElem.toString(),
      );
      return vals;
    }
    case values.ValueKind.PRIMITIVE_SET: {
      const setValue = varData.value as values.PrimitiveSetValue;
      const vals: Array<string> = [];
      setValue.keyValues.forEach(
        valElem => vals.push(valElem.toString()),
      );
      return vals;
    }
    case values.ValueKind.QUEUE: {
      const queueValue = varData.value as values.QueueValue;
      const vals = queueValue.elements.map(
        valElem => valElem.toString(),
      );
      return vals;
    }
    case values.ValueKind.STACK: {
      const stackValue = varData.value as values.StackValue;
      const vals = stackValue.elements.map(
        valElem => valElem.toString(),
      );
      // Values in stack need to be displayed in reverse
      // first element comes in the end.
      vals.reverse();
      return vals;
    }
    case values.ValueKind.DEQUE: {
      const dequeValue = varData.value as values.DequeValue;
      const vals = dequeValue.elements.map(
        valElem => valElem.toString(),
      );
      return vals;
    }
    case values.ValueKind.PRIMITIVE_MAP: {
      const mapValue = varData.value as values.PrimitiveMapValue;
      const vals: Array<string> = [];
      mapValue.keyValues.forEach(
        valElem => vals.push(valElem.toString()),
      );
      mapValue.valueValues.forEach(
        valElem => vals.push(valElem.toString()),
      );
      return vals;
    }
    default:
      throw new Error(`Unknown container type: ${varData.type.kind}`);
  }
}

export class VarVizData {
  readonly varNameTruncated: string;

  readonly pillHeight: number;

  readonly pillWidth: number;

  readonly contentX: number;

  readonly contentY: number;

  extraContentOffsetX: number = 0;

  extraContentOffsetY: number = 0;

  public constructor(
    readonly type: VarVizDataType,
    readonly varName: string,
    readonly varData: values.VarData,
  ) {
    this.varNameTruncated = truncateStr(this.varName, 8);
    this.pillHeight = 18;
    this.pillWidth = widthForStr(this.varNameTruncated, 10, 14);
    this.contentX = this.varData.vizAttrData.getX();
    this.contentY = this.varData.vizAttrData.getY() + this.pillHeight / 2;
  }

  setExtraContentOffsets(extraContentOffsetX: number, extraContentOffsetY: number): void {
    this.extraContentOffsetX = extraContentOffsetX;
    this.extraContentOffsetY = extraContentOffsetY;
  }

  get pillTransform(): string {
    return `translate(${this.varData.vizAttrData.getX() + this.extraContentOffsetX + 4}, ${this.varData.vizAttrData.getY() + this.extraContentOffsetY})`;
  }

  get contentTransform(): string {
    return `translate(${this.contentX}, ${this.contentY})`;
  }
}

export class SimpleVarVizData extends VarVizData {
  readonly value: string;

  readonly valueTruncated: string;

  readonly rectWidth: number;

  readonly rectHeight: number;

  public constructor(
    readonly varName: string,
    readonly varData: values.VarData,
  ) {
    super(VarVizDataType.Simple, varName, varData);
    this.value = this.varData.value.toString();
    this.valueTruncated = truncateStr(this.value, 8);
    const textWidth = widthForStr(this.valueTruncated, 10, 16);
    this.rectWidth = Math.max(textWidth + 16, this.pillWidth + 16);
    this.rectHeight = this.pillHeight / 2 + 24;
  }

  get centerX(): number {
    return this.rectWidth / 2;
  }

  get centerY(): number {
    return this.rectHeight / 2;
  }
}

export class SimpleContainerVarVizData extends VarVizData {
  readonly containerType: ContainerType;

  readonly values: Array<string>;

  readonly valuesTruncated: Array<string>;

  readonly numRows: number;

  readonly numColumns: number;

  readonly verticalContentBoundY1: number;

  readonly rowLineOffsets: Array<number>;

  readonly verticalContentBoundY2: number;

  readonly verticalBoundEnd: number;

  readonly horizontalContentBoundX1: number;

  readonly colLineOffsets: Array<number>;

  readonly horizontalContentBoundX2: number;

  readonly horizonalBoundEnd: number;

  readonly textCenterXOffsets: Array<number>;

  readonly textCenterYOffsets: Array<number>;

  public constructor(
    readonly varName: string,
    readonly varData: values.VarData,
    readonly transpose: boolean,
  ) {
    super(VarVizDataType.SimpleContainer, varName, varData);
    this.containerType = containerTypeFromVarData(varData);
    this.values = valuesFromVarData(varData);
    this.valuesTruncated = this.values.map(
      val => truncateStr(val, 8),
    );
    [this.numRows, this.numColumns] = this.rowsColumnsFromVarData();
    const numCols = this.numColumns ? this.numColumns : 1;
    const textWidth = widthForStrs(this.valuesTruncated, 10, 16);
    const rectWidth = Math.max(textWidth * numCols + 16, this.pillWidth + 16);
    const rectHeight = this.pillHeight / 2 + 24 * this.numRows;

    let verticalContentStartOffset = 0;
    let verticalContentEndOffset = 0;
    if (!this.transpose) {
      if (this.containerType === ContainerType.Stack
          || this.containerType === ContainerType.Queue
          || this.containerType === ContainerType.Deque) {
        verticalContentStartOffset += 10;
      }
      if (this.containerType === ContainerType.Queue
          || this.containerType === ContainerType.Deque) {
        verticalContentEndOffset += 10;
      }
    }
    this.verticalContentBoundY1 = verticalContentStartOffset;
    this.verticalContentBoundY2 = this.verticalContentBoundY1 + rectHeight;
    this.verticalBoundEnd = this.verticalContentBoundY2 + verticalContentEndOffset;
    this.rowLineOffsets = lineDivisionOffsets(
      this.numRows - 1,
      this.verticalContentBoundY1,
      this.pillHeight / 2,
      this.verticalContentBoundY2,
    );
    this.textCenterYOffsets = textCenterOffsets(
      this.numRows,
      this.verticalContentBoundY1,
      this.pillHeight / 2,
      this.verticalContentBoundY2,
    );

    let horizontalContentStartOffset = 0;
    let horizontalContentEndOffset = 0;
    if (this.transpose) {
      if (this.containerType === ContainerType.Stack
          || this.containerType === ContainerType.Queue
          || this.containerType === ContainerType.Deque) {
        horizontalContentStartOffset += 10;
      }
      if (this.containerType === ContainerType.Queue
          || this.containerType === ContainerType.Deque) {
        horizontalContentEndOffset += 10;
      }
    }
    this.horizontalContentBoundX1 = horizontalContentStartOffset;
    this.horizontalContentBoundX2 = this.horizontalContentBoundX1 + rectWidth;
    this.horizonalBoundEnd = this.horizontalContentBoundX2 + horizontalContentEndOffset;
    this.colLineOffsets = lineDivisionOffsets(
      this.numColumns - 1,
      this.horizontalContentBoundX1,
      0,
      this.horizontalContentBoundX2,
    );
    this.textCenterXOffsets = textCenterOffsets(
      this.numColumns,
      this.horizontalContentBoundX1,
      0,
      this.horizontalContentBoundX2,
    );

    this.setExtraContentOffsets(horizontalContentStartOffset, verticalContentStartOffset);
  }

  rowsColumnsFromVarData(): [number, number] {
    switch (this.varData.value.kind) {
      case values.ValueKind.TUPLE: {
        const tupleValue = this.varData.value as values.TupleValue;
        const len = tupleValue.elements.length;
        const rows = this.transpose ? 1 : len;
        const columns = this.transpose ? len : 1;
        return [rows, columns];
      }
      case values.ValueKind.ARRAY: {
        const arrayValue = this.varData.value as values.ArrayValue;
        if (arrayValue.dims() === 1n) {
          const rows = this.transpose ? 1 : arrayValue.dimLenNum(0);
          const columns = this.transpose ? arrayValue.dimLenNum(0) : 1;
          return [rows, columns];
        }
        return [arrayValue.dimLenNum(0), arrayValue.dimLenNum(1)];
      }
      case values.ValueKind.LIST: {
        const listValue = this.varData.value as values.ListValue;
        const len = listValue.elements.length;
        const rows = this.transpose ? 1 : len;
        const columns = this.transpose ? len : 1;
        return [rows, columns];
      }
      case values.ValueKind.PRIMITIVE_SET: {
        const setValue = this.varData.value as values.PrimitiveSetValue;
        const len = setValue.keyValues.size;
        const rows = this.transpose ? 1 : len;
        const columns = this.transpose ? len : 1;
        return [rows, columns];
      }
      case values.ValueKind.QUEUE: {
        const queueValue = this.varData.value as values.QueueValue;
        const len = queueValue.elements.length;
        const rows = this.transpose ? 1 : len;
        const columns = this.transpose ? len : 1;
        return [rows, columns];
      }
      case values.ValueKind.STACK: {
        const stackValue = this.varData.value as values.StackValue;
        const len = stackValue.elements.length;
        const rows = this.transpose ? 1 : len;
        const columns = this.transpose ? len : 1;
        return [rows, columns];
      }
      case values.ValueKind.DEQUE: {
        const dequeValue = this.varData.value as values.DequeValue;
        const len = dequeValue.elements.length;
        const rows = this.transpose ? 1 : len;
        const columns = this.transpose ? len : 1;
        return [rows, columns];
      }
      case values.ValueKind.PRIMITIVE_MAP: {
        const mapValue = this.varData.value as values.PrimitiveMapValue;
        const len = mapValue.keyValues.size;
        const rows = this.transpose ? 2 : len;
        const columns = this.transpose ? len : 2;
        return [rows, columns];
      }
      default:
        throw new Error(`Unknown container type: ${this.varData.type.kind}`);
    }
  }

  isDimColumn(dim: number): boolean {
    if (this.varData.value.kind !== values.ValueKind.ARRAY) {
      return this.transpose;
    }
    const arrayValue = this.varData.value as values.ArrayValue;
    if (arrayValue.dims() === 1n) {
      return this.transpose;
    }
    return dim === 1;
  }
}

export class RuntimeWrapper {
  readonly revlangRuntime = new runtime.RevlangRuntime();

  // We return undefined because we dont want to show values when var does not exist.
  lookupValueAsString(varName: string, callStackIndex: number): string {
    const varData = this.revlangRuntime.lookupVarDataAtIndex(varName, callStackIndex);
    if (varData === undefined || varData.value === undefined) {
      return undefined;
    }
    if (varData.value.isPrimitive()) {
      return varData.value.toString();
    }
    return undefined;
  }
}

export class PointerVizData {
  public constructor(
    readonly x1: number,
    readonly y1: number,
    readonly x2: number,
    readonly y2: number,
  ) {
  }
}

export const runtimeWrapper = new RuntimeWrapper();

function varDataToVarVizData(
  varName: string,
  varData: values.VarData,
): VarVizData {
  if (varData.vizAttrData === undefined || varData.value === undefined) {
    return undefined;
  }
  if (varData.vizAttrData.getVisualizerType() === ast.VisualizerType.SIMPLE_VIZ) {
    return new SimpleVarVizData(varName, varData);
  }
  if (varData.vizAttrData.getVisualizerType() === ast.VisualizerType.SIMPLE_CONTAINER_VIZ) {
    return new SimpleContainerVarVizData(varName, varData, false);
  }
  if (varData.vizAttrData.getVisualizerType()
        === ast.VisualizerType.SIMPLE_CONTAINER_TRANSPOSE_VIZ) {
    return new SimpleContainerVarVizData(varName, varData, true);
  }
  throw new Error('Unknown visualizerType');
}

function varVizDataToPointerVizData(
  varVizData: VarVizData,
  varVizState: Record<string, VarVizData>,
): Array<PointerVizData> {
  if (varVizData.type !== VarVizDataType.Simple) {
    return [];
  }
  const retPointerVizDatas: Array<PointerVizData> = [];
  const srcVarVizData = varVizData as SimpleVarVizData;
  for (const idxAttrData of srcVarVizData.varData.indexAttrDatas) {
    const tgtVarName = idxAttrData.getVarName().getName();
    const tgtVarVizData = varVizState[tgtVarName];
    if (tgtVarVizData === undefined
        || tgtVarVizData.type !== VarVizDataType.SimpleContainer) {
      continue;
    }
    const tgtArrayVarVizData = tgtVarVizData as SimpleContainerVarVizData;
    const indexValue = srcVarVizData.varData.value as values.IntValue;
    const index = Number(indexValue.value);
    const isDimColumn = tgtArrayVarVizData.isDimColumn(idxAttrData.getDimension());
    const x1 = srcVarVizData.contentX + srcVarVizData.centerX;
    const y1 = srcVarVizData.contentY + srcVarVizData.centerY;
    let x2 = tgtArrayVarVizData.contentX;
    let y2 = tgtArrayVarVizData.contentY;
    if (isDimColumn) {
      if (index < 0 || index >= tgtArrayVarVizData.numColumns) {
        continue;
      }
      x2 += tgtArrayVarVizData.textCenterXOffsets[index];
      y2 += tgtArrayVarVizData.verticalContentBoundY2;
    } else {
      if (index < 0 || index >= tgtArrayVarVizData.numRows) {
        continue;
      }
      x2 += tgtArrayVarVizData.horizontalContentBoundX1;
      y2 += tgtArrayVarVizData.textCenterYOffsets[index];
    }
    retPointerVizDatas.push(
      new PointerVizData(x1, y1, x2, y2),
    );
  }
  return retPointerVizDatas;
}

function updateState(revModule: RevModule) {
  revModule.isLoaded = runtimeWrapper.revlangRuntime.programNode !== undefined;
  revModule.canStepOver = runtimeWrapper.revlangRuntime.canStepOver();
  revModule.canStepInto = revModule.canStepOver || runtimeWrapper.revlangRuntime.canStepInto();
  revModule.canStepOut = runtimeWrapper.revlangRuntime.canStepOut();
  revModule.canUndoStep = runtimeWrapper.revlangRuntime.canUndoStep();
  revModule.canRedoStep = runtimeWrapper.revlangRuntime.canRedoStep();
  revModule.logs = runtimeWrapper.revlangRuntime.logs;
  const callStack: Array<string> = [];
  for (const callFrame of runtimeWrapper.revlangRuntime.callStack) {
    callStack.push(callFrame.name);
  }
  revModule.callStack = callStack;
  revModule.isSelectedTopCallFrame = (
    revModule.selectedCallStackIndex === runtimeWrapper.revlangRuntime.callStack.length - 1);
  const selectedCallFrame = (
    runtimeWrapper.revlangRuntime.callStack[revModule.selectedCallStackIndex]
  );
  revModule.selectedStep = selectedCallFrame.currentStepId;

  for (const key of Object.keys(revModule.varVizState)) {
    Vue.delete(revModule.varVizState, key);
  }
  for (const gv of runtimeWrapper.revlangRuntime.globalVars()) {
    const varVizData = varDataToVarVizData(
      gv,
      runtimeWrapper.revlangRuntime.lookupGlobalVarData(gv),
    );
    if (varVizData !== undefined) {
      Vue.set(revModule.varVizState, varVizData.varName, varVizData);
    }
  }
  for (const lv of runtimeWrapper.revlangRuntime.localVars(
    revModule.selectedCallStackIndex,
  )) {
    const varVizData = varDataToVarVizData(
      lv,
      runtimeWrapper.revlangRuntime.lookupLocalVarDataAtIndex(lv, revModule.selectedCallStackIndex),
    );
    if (varVizData !== undefined) {
      Vue.set(revModule.varVizState, varVizData.varName, varVizData);
    }
  }
  revModule.pointerVizState.length = 0;
  for (const varVizData of Object.values(revModule.varVizState)) {
    revModule.pointerVizState.push(...varVizDataToPointerVizData(
      varVizData,
      revModule.varVizState,
    ));
  }
}

@Module({
  name: 'revModule',
  namespaced: true,
})
export default class RevModule extends VuexModule {
  public algorithmSummary: AlgorithmSummary = undefined;

  public programName: string = '';

  public isLoaded: boolean = false;

  public rootSpanNode: span_nodes.SpanNode = undefined;

  public canStepOver: boolean = false;

  public canStepInto: boolean = false;

  public canStepOut: boolean = false;

  public canUndoStep: boolean = false;

  public canRedoStep: boolean = false;

  public logs: Array<string> = [];

  public callStack: Array<string> = [];

  public selectedCallStackIndex: number = 0;

  public isSelectedTopCallFrame: boolean = true;

  public selectedStep: string = '';

  public varVizState: Record<string, VarVizData> = {}

  public pointerVizState: Array<PointerVizData> = []

  @Mutation
  public loadAlgorithmData(algorithmData: content.AlgorithmData): void {
    runtimeWrapper.revlangRuntime.loadProgram(algorithmData.getProgram());
    runtimeWrapper.revlangRuntime.start();
    this.algorithmSummary = algorthimSummaryFromPB(algorithmData.getSummary());
    this.programName = algorithmData.getSummary().getTitle();
    this.rootSpanNode = runtimeWrapper.revlangRuntime.rootSpanNode;
    this.selectedCallStackIndex = runtimeWrapper.revlangRuntime.callStack.length - 1;
    updateState(this);
  }

  @Mutation
  public unloadAlgorithmData(): void {
    this.programName = '';
    this.rootSpanNode = undefined;
    this.selectedCallStackIndex = 0;
    runtimeWrapper.revlangRuntime.unloadProgram();
  }

  @Mutation
  public restart(): void {
    runtimeWrapper.revlangRuntime.stop();
    runtimeWrapper.revlangRuntime.start();
    this.selectedCallStackIndex = runtimeWrapper.revlangRuntime.callStack.length - 1;
    updateState(this);
  }

  @Mutation
  public stepOver(): void {
    runtimeWrapper.revlangRuntime.stepOver();
    this.selectedCallStackIndex = runtimeWrapper.revlangRuntime.callStack.length - 1;
    updateState(this);
  }

  @Mutation
  public stepInto(): void {
    if (runtimeWrapper.revlangRuntime.canStepInto()) {
      runtimeWrapper.revlangRuntime.stepInto();
    } else {
      runtimeWrapper.revlangRuntime.stepOver();
    }
    this.selectedCallStackIndex = runtimeWrapper.revlangRuntime.callStack.length - 1;
    updateState(this);
  }

  @Mutation
  public stepOut(): void {
    runtimeWrapper.revlangRuntime.stepOut();
    this.selectedCallStackIndex = runtimeWrapper.revlangRuntime.callStack.length - 1;
    updateState(this);
  }

  @Mutation
  public undoStep(): void {
    runtimeWrapper.revlangRuntime.undoStep();
    this.selectedCallStackIndex = runtimeWrapper.revlangRuntime.callStack.length - 1;
    updateState(this);
  }

  @Mutation
  public redoStep(): void {
    runtimeWrapper.revlangRuntime.redoStep();
    this.selectedCallStackIndex = runtimeWrapper.revlangRuntime.callStack.length - 1;
    updateState(this);
  }

  @Mutation
  public selectCallFrame(index: number): void {
    this.selectedCallStackIndex = index;
    updateState(this);
  }

  @Action
  public async fetchLoadAlgorithmData(algoId: string): Promise<void> {
    const resp = await fetch(`/gen/${algoId}.pb`);
    const encodedPBBase64 = await resp.text();
    const serializedPBBin = Buffer.from(encodedPBBase64, 'base64');
    const algorithmData = content.AlgorithmData.deserializeBinary(serializedPBBin);
    this.loadAlgorithmData(algorithmData);
  }
}
