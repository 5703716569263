import * as ast from '@/generated/ast_pb';
import * as span_nodes from '@/revlang/span_nodes';

export enum InstructionKind {
  EXPR, // expr       ???
  REDUCE_EXPR_LIST, // ExprListNode     [Value] -> Array<Value>
  REDUCE_EXPR_LIST_LIST, // ArrayElementsListNode     [Array<Value>] -> Array<Value>
  NAME_EXPR, // NameExprNode  Value -> NameValue
  REDUCE_NAME_EXPR_LIST, // NameExprListNode     [NameValue] -> Map<string, Value>
  MAP_ELEMENT, // MapElementNode  Value, Value -> [KeyValue]
  REDUCE_MAP_ELEMENTS_LIST, // MapElementsListNode [KeyValue] -> Map<Value, Value>
  LVALUE_VAR, // [] -> LValue
  LVALUE_INDEX, // Value, [Value] -> LValue
  LVALUE_FIELD, // Value -> LValue
  ASSIGN, // AssignNode  LValue, Value  -> []
  VAR_DECL, // VarDeclNode
  VAR_DEF, // VarDefNode  [Value] -> []
  POP, // number   [Value] -> []
  PUSH_VOID, // [] -> VoidValue
  PRINT, // [Value] -> []
  JUMP, // loc
  JUMP_IF_NOT, // loc  Value -> []
  RETURN,
  PUSH_BLOCK, // For adding scope for local vars
  POP_BLOCK, // For removing scope for local vars
}

export function instructionsToString(
  instructions: Array<Instruction>,
): string {
  let ret = '';
  for (let idx = 0; idx < instructions.length; idx += 1) {
    ret += `${idx}@ ${instructions[idx].toString()}\n`;
  }
  return ret;
}

export abstract class Instruction {
  protected constructor(
    readonly kind: InstructionKind,
    readonly stepIdNode: span_nodes.StepIdNode,
  ) {}

  public abstract toString(): string;
}

export class ExprInstruction extends Instruction {
  public constructor(
    stepIdNode: span_nodes.StepIdNode,
    readonly exprNode: ast.ExprNode,
  ) {
    super(InstructionKind.EXPR, stepIdNode);
  }

  public toString(): string {
    let exprInfo;
    if (this.exprNode.getKind() === ast.ExprNodeKind.STD_FUNC_CALL) {
      exprInfo = `stdCall: ${this.exprNode.getStdFuncCall().getKind()}`;
    } else {
      exprInfo = `${this.exprNode.getKind()}`;
    }
    return `Expr(${this.stepIdNode.stepId}): ${exprInfo}`;
  }
}

export class ReduceExprListInstruction extends Instruction {
  public constructor(
    stepIdNode: span_nodes.StepIdNode,
    readonly exprListNode: ast.ExprListNode,
  ) {
    super(InstructionKind.REDUCE_EXPR_LIST, stepIdNode);
  }

  public toString(): string {
    return `ReduceExprList(${this.stepIdNode.stepId}): ${this.exprListNode.getExprsList().length}`;
  }
}

export class ReduceExprListListInstruction extends Instruction {
  public constructor(
    stepIdNode: span_nodes.StepIdNode,
    readonly arrayElementsListNode: ast.ArrayElementsListNode,
  ) {
    super(InstructionKind.REDUCE_EXPR_LIST_LIST, stepIdNode);
  }

  public toString(): string {
    return `ReduceExprListList(${this.stepIdNode.stepId}): ${this.arrayElementsListNode.getArrayElementsList().length}`;
  }
}

export class NameExprInstruction extends Instruction {
  public constructor(
    stepIdNode: span_nodes.StepIdNode,
    readonly nameExprNode: ast.NameExprNode,
  ) {
    super(InstructionKind.NAME_EXPR, stepIdNode);
  }

  public toString(): string {
    return `NameExpr(${this.stepIdNode.stepId}): ${this.nameExprNode.getName().getName()} ${this.nameExprNode.getExpr().getKind()}`;
  }
}

export class ReduceNameExprListInstruction extends Instruction {
  public constructor(
    stepIdNode: span_nodes.StepIdNode,
    readonly nameExprListNode: ast.NameExprListNode,
  ) {
    super(InstructionKind.REDUCE_NAME_EXPR_LIST, stepIdNode);
  }

  public toString(): string {
    return `ReduceNameExprList(${this.stepIdNode.stepId}): ${this.nameExprListNode.getNameExprsList().length}`;
  }
}

export class MapElementInstruction extends Instruction {
  public constructor(
    stepIdNode: span_nodes.StepIdNode,
    readonly mapElementNode: ast.MapElementNode,
  ) {
    super(InstructionKind.MAP_ELEMENT, stepIdNode);
  }

  public toString(): string {
    return `MapElement(${this.stepIdNode.stepId}): ${this.mapElementNode.getKeyExpr().getKind()} ${this.mapElementNode.getValueExpr().getKind()}`;
  }
}

export class ReduceMapElementsListInstruction extends Instruction {
  public constructor(
    stepIdNode: span_nodes.StepIdNode,
    readonly mapElementsListNode: ast.MapElementsListNode,
  ) {
    super(InstructionKind.REDUCE_MAP_ELEMENTS_LIST, stepIdNode);
  }

  public toString(): string {
    return `ReduceMapElementsList(${this.stepIdNode.stepId}): ${this.mapElementsListNode.getMapElementsList().length}`;
  }
}

export class LValueVarInstruction extends Instruction {
  public constructor(
    stepIdNode: span_nodes.StepIdNode,
    readonly nameNode: ast.NameNode,
  ) {
    super(InstructionKind.LVALUE_VAR, stepIdNode);
  }

  public toString(): string {
    return `LValueVar(${this.stepIdNode.stepId}): ${this.nameNode.getName()}`;
  }
}

export class LValueIndexInstruction extends Instruction {
  public constructor(
    stepIdNode: span_nodes.StepIdNode,
    readonly lIndexedAccessNode: ast.LIndexedAccessNode,
  ) {
    super(InstructionKind.LVALUE_INDEX, stepIdNode);
  }

  public toString(): string {
    return `LValueIndex(${this.stepIdNode.stepId}): ${this.lIndexedAccessNode.getIndices().getExprsList().length}`;
  }
}

export class LValueFieldInstruction extends Instruction {
  public constructor(
    stepIdNode: span_nodes.StepIdNode,
    readonly lFieldAccessNode: ast.LFieldAccessNode,
  ) {
    super(InstructionKind.LVALUE_FIELD, stepIdNode);
  }

  public toString(): string {
    return `LValueField(${this.stepIdNode.stepId}): ${this.lFieldAccessNode.getFieldName().getName()}`;
  }
}

export class AssignInstruction extends Instruction {
  public constructor(
    stepIdNode: span_nodes.StepIdNode,
    readonly assignNode: ast.AssignNode,
  ) {
    super(InstructionKind.ASSIGN, stepIdNode);
  }

  public toString(): string {
    return `Assign(${this.stepIdNode.stepId}):`;
  }
}

export class VarDeclInstruction extends Instruction {
  public constructor(
    stepIdNode: span_nodes.StepIdNode,
    readonly varDeclNode: ast.VarDeclNode,
  ) {
    super(InstructionKind.VAR_DECL, stepIdNode);
  }

  public toString(): string {
    return `VarDecl(${this.stepIdNode.stepId}): ${this.varDeclNode.getDeclarations().getNameTypesList().length}`;
  }
}

export class VarDefInstruction extends Instruction {
  public constructor(
    stepIdNode: span_nodes.StepIdNode,
    readonly varDefNode: ast.VarDefNode,
  ) {
    super(InstructionKind.VAR_DEF, stepIdNode);
  }

  public toString(): string {
    return `VarDef(${this.stepIdNode.stepId}): ${this.varDefNode.getVarName().getName().getName()}`;
  }
}

export class PopInstruction extends Instruction {
  public constructor(
    stepIdNode: span_nodes.StepIdNode,
    readonly popCount: number,
  ) {
    super(InstructionKind.POP, stepIdNode);
  }

  public toString(): string {
    return `Pop(${this.stepIdNode.stepId}):`;
  }
}

export class PushVoidInstruction extends Instruction {
  public constructor(
    stepIdNode: span_nodes.StepIdNode,
  ) {
    super(InstructionKind.PUSH_VOID, stepIdNode);
  }

  public toString(): string {
    return `PushVoid(${this.stepIdNode.stepId}):`;
  }
}

export class PrintInstruction extends Instruction {
  public constructor(
    stepIdNode: span_nodes.StepIdNode,
    readonly printExprCount: number,
  ) {
    super(InstructionKind.PRINT, stepIdNode);
  }

  public toString(): string {
    return `Print(${this.stepIdNode.stepId}):`;
  }
}

export class JumpInstruction extends Instruction {
  public _location: number;

  public constructor(stepIdNode: span_nodes.StepIdNode) {
    super(InstructionKind.JUMP, stepIdNode);
  }

  public get location() : number {
    return this._location;
  }

  public setLocation(location: number): void {
    this._location = location;
  }

  public toString(): string {
    return `Jump(${this.stepIdNode.stepId}): ${this._location}`;
  }
}

export class JumpIfNotInstruction extends Instruction {
  public _location: number;

  public constructor(stepIdNode: span_nodes.StepIdNode) {
    super(InstructionKind.JUMP_IF_NOT, stepIdNode);
  }

  public get location() : number {
    return this._location;
  }

  public setLocation(location: number): void {
    this._location = location;
  }

  public toString(): string {
    return `JumpIfNot(${this.stepIdNode.stepId}): ${this._location}`;
  }
}

export class ReturnInstruction extends Instruction {
  public constructor(
    stepIdNode: span_nodes.StepIdNode,
  ) {
    super(InstructionKind.RETURN, stepIdNode);
  }

  public toString(): string {
    return `Return(${this.stepIdNode.stepId}):`;
  }
}

export class PushBlockInstruction extends Instruction {
  public constructor(
    stepIdNode: span_nodes.StepIdNode,
  ) {
    super(InstructionKind.PUSH_BLOCK, stepIdNode);
  }

  public toString(): string {
    return `PushBlock(${this.stepIdNode.stepId}):`;
  }
}

export class PopBlockInstruction extends Instruction {
  public constructor(
    stepIdNode: span_nodes.StepIdNode,
  ) {
    super(InstructionKind.POP_BLOCK, stepIdNode);
  }

  public toString(): string {
    return `PopBlock(${this.stepIdNode.stepId}):`;
  }
}

export class InstructionSetBuilder {
  readonly labelMap = new Map<string, number>();

  readonly loopStarts: Array<number> = [];

  readonly labeledBreaks = new Map<string, Array<JumpInstruction>>();

  readonly loopBreaks: Array<Array<JumpInstruction>> = [];

  readonly instructions: Array<Instruction> = [];

  public inLoop(): boolean {
    return this.loopStarts.length > 0;
  }

  public loopStart(): number {
    return this.loopStarts[this.loopStarts.length - 1];
  }

  public pushLoop(label?: string): void {
    this.loopStarts.push(this.instructions.length);
    this.loopBreaks.push([]);
    if (label !== undefined) {
      if (this.labelMap.has(label)) {
        throw new Error(`Label ${label} already exists`);
      }
      this.labelMap.set(label, this.instructions.length);
      this.labeledBreaks.set(label, []);
    }
  }

  public popLoop(label?: string): void {
    this.loopStarts.pop();
    const breakInstructions = this.loopBreaks.pop();
    for (const breakInstruction of breakInstructions) {
      this.patchJumpInstructionToCurrent(breakInstruction);
    }
    if (label !== undefined) {
      this.labelMap.delete(label);
      const labeledBreakInstructions = this.labeledBreaks.get(label);
      this.labeledBreaks.delete(label);
      for (const breakInstruction of breakInstructions) {
        this.patchJumpInstructionToCurrent(breakInstruction);
      }
    }
  }

  private findLoopStart(label?: string): number | undefined {
    if (label !== undefined) {
      return this.labelMap.get(label);
    }
    if (this.loopStarts.length > 0) {
      return this.loopStarts[this.loopStarts.length - 1];
    }
    return undefined;
  }

  public emitBreakInstruction(
    stepIdNode: span_nodes.StepIdNode,
    label?: string,
  ): void {
    if (this.findLoopStart(label) === undefined) {
      throw new Error(`Loop for break ${label} not found`);
    }
    const breakInstruction = this.emitJumpInstruction(stepIdNode);
    if (label !== undefined) {
      this.labeledBreaks.get(label).push(breakInstruction);
    } else {
      this.loopBreaks[this.loopBreaks.length - 1].push(breakInstruction);
    }
  }

  public emitContinueInstruction(
    stepIdNode: span_nodes.StepIdNode,
    label?: string,
  ): void {
    const loopStart = this.findLoopStart(label);
    if (loopStart === undefined) {
      throw new Error(`Loop for break ${label} not found`);
    }
    const continueInstruction = this.emitJumpInstruction(stepIdNode);
    continueInstruction.setLocation(loopStart);
  }

  public emitInstruction(instruction: Instruction): void {
    this.instructions.push(instruction);
  }

  public emitJumpInstruction(stepIdNode: span_nodes.StepIdNode): JumpInstruction {
    const jumpInstruction = new JumpInstruction(stepIdNode);
    this.emitInstruction(jumpInstruction);
    return jumpInstruction;
  }

  public emitIfNotJumpInstruction(stepIdNode: span_nodes.StepIdNode): JumpIfNotInstruction {
    const jumpIfNotInstruction = new JumpIfNotInstruction(stepIdNode);
    this.emitInstruction(jumpIfNotInstruction);
    return jumpIfNotInstruction;
  }

  public patchJumpInstructionToCurrent(jumpInstruction: JumpInstruction | JumpIfNotInstruction) {
    jumpInstruction.setLocation(this.instructions.length);
  }
}
